import { useLazyQuery } from "@apollo/client";
import { useEffect, useMemo, useState } from "react";
import { useDebounce } from "use-debounce";
import localization from "../../../assets/svg/localization.svg";
import magnifyingGlass from "../../../assets/svg/magnifying-glass-input.svg";
import {
  CitiesSearch,
  SEARCH_LOCATION,
} from "../../../queries/citiesSearch/citiesSearch";
import { translateTypeToFrench } from "../../../utils/localization";
import useSearchBarHooks from "../../others/searchBar/hooks/useSearchBarhooks";
import MobileModal from "../index";
import MobileLocalizationSkeleton from "./MobileLocalization.skeleton";

const MobileLocalization = ({ onClose }) => {
  const [inputValue, setInputValue] = useState("");
  const [fetchCities, { data, loading }] = useLazyQuery(SEARCH_LOCATION);
  const [debouncedValue] = useDebounce(inputValue, 700);
  const { handleChange } = useSearchBarHooks({
    onSearch: () => null,
    resetSearch: false,
  });

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  useEffect(() => {
    if (debouncedValue) {
      fetchCities({
        variables: { address: debouncedValue },
      }).then((res) => console.log(res));
    }
  }, [debouncedValue, fetchCities]);

  const filteredPlace = useMemo(() => {
    let defaultPlace = [{ value: "ALL", label: "Toute la France" }];

    if (!data || !inputValue) return defaultPlace;

    if (data) {
      defaultPlace = [...defaultPlace, ...data.searchLocation];
    }

    return defaultPlace;
  }, [data, inputValue]);

  return (
    <MobileModal title="Localisation" onClose={onClose}>
      <div className="flex h-full flex-col font-roboto">
        <div className="relative">
          <input
            className="w-full border border-t-0 border-mplgray py-5 pl-12 outline-none"
            placeholder="Ex. ville, région"
            value={inputValue}
            onChange={handleInputChange}
          />
          <img
            alt="magnifying glass"
            className="absolute left-3 top-1/2 -translate-y-1/2"
            src={magnifyingGlass}
          />
        </div>
        <div className="overflow-auto">
          {loading ? (
            <MobileLocalizationSkeleton />
          ) : (
            filteredPlace.map((city) => (
              <button
                key={city.value}
                className="flex w-full border !border-b-mplgray py-5 text-left"
                onClick={() => {
                  handleChange(city);
                  onClose();
                }}
              >
                <img
                  alt="localization"
                  className="pl-3 pr-5"
                  src={localization}
                />
                {city.value === "ALL" ? (
                  <span className="font-roboto text-lg font-bold">
                    {city.label}
                  </span>
                ) : (
                  <div className="mr-4 flex w-full flex-col">
                    <span className="font-roboto text-lg font-bold">
                      {city.label}{" "}
                      {city.postal_code ? `(${city.postal_code})` : ""}
                    </span>
                    <div className="flex w-full items-center justify-between text-gray-700">
                      <span>
                        {city.administrative_area}, {city.country}
                      </span>
                      <span>{translateTypeToFrench(city.type)}</span>
                    </div>
                  </div>
                )}
              </button>
            ))
          )}
        </div>
      </div>
    </MobileModal>
  );
};

export default MobileLocalization;
