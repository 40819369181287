import { useMemo, useState } from "react";
import RegisterStep1 from "./components/RegisterStep1";
import RegisterStep2 from "./components/RegisterStep2";

const ModalRegister = ({ onClose, toggleLoginForm }) => {
  const [step, setStep] = useState(0);
  const [formState, setFormState] = useState();

  const stepComponent = useMemo(() => {
    return [
      <RegisterStep1
        key={0}
        setFormState={setFormState}
        setStep={setStep}
        toggleLoginForm={toggleLoginForm}
        onClose={onClose}
      />,
      <RegisterStep2 key={1} formState={formState} onClose={onClose} />,
    ];
  }, [formState, onClose, toggleLoginForm]);

  return stepComponent[step];
};

export default ModalRegister;
