import { gql } from "@apollo/client";

export const CreateQuote = gql`
  mutation createEstimate(
    $userId: String!
    $listingId: String!
    $fileId: String!
    $isFromMulti: Boolean!
    $isWorkBook: Boolean!
    $message: String!
  ) {
    createEstimate(
      CreateEstimateInput: {
        userId: $userId
        listingId: $listingId
        fileId: $fileId
        isWorkBook: $isWorkBook
        message: $message
        isFromMulti: $isFromMulti
      }
    ) {
      id
    }
  }
`;
