import { IonIcon } from "@ionic/react";
import cx from "classnames";
import { informationCircleOutline } from "ionicons/icons";
import { useState } from "react";
import { BsCheckSquare, BsSquare } from "react-icons/bs";
import TippyTooltip from "../../../../../../components/TippyTooltip";

const MobileFilterCheckboxChoice = ({
  title,
  options,
  selectedFilter,
  onChange,
  selectOn,
}) => {
  const [displayMore, setDisplayMore] = useState(false);

  const selectOption = (item) => {
    const tmp = [...selectedFilter];

    const index = tmp.find((selected) => {
      return selected[selectOn] === item[selectOn];
    });

    if (index) {
      tmp.splice(index, 1);
    } else {
      tmp.push(options.find((option) => option[selectOn] === item[selectOn]));
    }

    onChange(tmp);
  };

  return (
    <div className="flex flex-col gap-5 border-b border-[#e5e5e5] py-5 font-roboto">
      <h3 className="text-3xl font-medium">{title}</h3>
      <ul
        className={cx(
          "grid grid-cols-2 gap-x-4 overflow-hidden transition-all duration-200 ease-in-out",
          {
            "max-h-[2000px]": displayMore,
            "max-h-[92px]": !displayMore,
          }
        )}
      >
        {options.map((item) => {
          const isSelected = !!selectedFilter.find(
            (selected) => selected[selectOn] === item[selectOn]
          );
          return (
            <li key={item.code}>
              <div className="relative flex items-start gap-3">
                {!isSelected && (
                  <BsSquare className="mt-[4px] shrink-0" fill="#1FC585" />
                )}
                {isSelected && (
                  <BsCheckSquare className="mt-[4px] shrink-0" fill="#1FC585" />
                )}
                <label className="flex gap-1" htmlFor={item.label}>
                  <span>
                    {item.label}{" "}
                    {item.description && (
                      <TippyTooltip content={item.description}>
                        <IonIcon icon={informationCircleOutline} />
                      </TippyTooltip>
                    )}
                  </span>
                  <input
                    checked={isSelected}
                    className="absolute inset-0 opacity-0"
                    id={item.label}
                    type="checkbox"
                    onChange={(evt) => selectOption(item, evt.target.checked)}
                  />
                </label>
              </div>
            </li>
          );
        })}
      </ul>
      {options.length > 8 && (
        <button
          className="flex items-center gap-2 font-semibold text-[#1FC585] underline underline-offset-2"
          onClick={() => setDisplayMore(!displayMore)}
        >
          {displayMore ? "Voir moins" : "Voir plus"}
        </button>
      )}
    </div>
  );
};

export default MobileFilterCheckboxChoice;
