import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // Optional styling

const TippyTooltip = ({ children, content }) => {
  return (
    <Tippy content={content}>
      <button
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        {children}
      </button>
    </Tippy>
  );
};

export default TippyTooltip;
