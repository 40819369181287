import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import CustomButton from "../../../ui/CustomButton";
import CustomInput from "../../../ui/CustomInput";
import CustomTextarea from "../../../ui/CustomTextarea";

const urlValidator = Yup.string().url("L'URL '{{value}}' n'est pas valide");

const step2Schema = Yup.object({
  serviceDescription: Yup.string().required(
    "La description des prestations est obligatoire"
  ),
  contractDuration: Yup.string().required(
    "La durée du contrat est obligatoire"
  ),
  contact: Yup.string().required(
    "Les coordonnées de contact sont obligatoires"
  ),
  complementaryLinks: Yup.string()
    .notRequired()
    .test("valid-urls", "", (value) => {
      if (!value) return true;

      const urls = value.split(/[\s,]+/);
      for (let url of urls) {
        try {
          urlValidator.validateSync(url);
        } catch (err) {
          return new Yup.ValidationError(
            `L'URL '${url}' n'est pas valide`,
            null,
            "complementaryLinks"
          );
        }
      }
      return true; // If all URLs are valid
    }),
});

const TenderFormStep2 = ({
  nextStep,
  previousStep,
  createLoading,
  defaultValues,
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    setError,
  } = useForm({
    resolver: yupResolver(step2Schema),
    defaultValues,
  });

  const onSubmit = (values) => {
    nextStep("step2", values);
  };

  return (
    <form
      className="grid grid-cols-1 gap-3 lg:grid-cols-2"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="sm:col-span-2">
        <CustomTextarea
          error={errors?.serviceDescription}
          label="Description des prestations *"
          name="serviceDescription"
          placeholder="Décrivez les prestations que vous attendez"
          register={register}
        />
      </div>
      <div className="sm:col-span-1">
        <CustomInput
          error={errors?.estimatedValue}
          label="Valeur estimée (€)"
          name="estimatedValue"
          placeholder="Précisez la valeur estimée de votre appel d’offre"
          register={register}
        />
      </div>
      <div className="sm:col-span-1">
        <CustomInput
          error={errors?.contractDuration}
          label="Durée du contrat *"
          name="contractDuration"
          placeholder="Précisez la durée du contrat"
          register={register}
        />
      </div>
      <div className="sm:col-span-2">
        <CustomTextarea
          label="Information complémentaires"
          name="additionalInformation"
          register={register}
        />
      </div>
      <div className="sm:col-span-2">
        <CustomTextarea
          error={errors?.contact}
          label="Contact *"
          name="contact"
          placeholder="Coordonnées de la ou les personnes en charges de l’appel d’offre (nom, prénom, mail, numéro de téléphone)"
          register={register}
        />
      </div>
      <div className="mt-4 flex items-center justify-between sm:col-span-2">
        <CustomButton color="black" variant="outlined" onClick={previousStep}>
          Précédent
        </CustomButton>
        <CustomButton isLoading={createLoading} type="submit">
          Confirmer
        </CustomButton>
      </div>
    </form>
  );
};

export default TenderFormStep2;
