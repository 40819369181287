import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import useTailwindBreakpoint from "../../hooks/useTailwindBreakpoint";
import MobileCongratsSpecification from "./MobileCongratsSpecification";

export default function CongratsSpecification() {
  const navigate = useNavigate();
  const { isXsTw } = useTailwindBreakpoint();

  const previousStep = () => {
    navigate("/dashboard/specifications");
  };

  const nextStep = () => {
    navigate("/dashboard/specifications");
  };

  if (isXsTw) {
    return (
      <MobileCongratsSpecification
        nextStep={nextStep}
        previousStep={previousStep}
      />
    );
  }

  return (
    <Container>
      <Title>Félicitations, votre cahier des charges a été créé !</Title>
      <Content>
        <NextButton
          style={{ backgroundColor: "#1FC585", color: "#fff" }}
          onClick={nextStep}
        >
          Retour à l’accueil
        </NextButton>
        <PreviousButton onClick={previousStep}>Voir</PreviousButton>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 40px;
  height: 100%;
  flex-grow: 1;
  justify-content: center;
  gap: 100px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  gap: 20px;
`;
const Title = styled.p`
  font-size: 27px;
  color: #000;
  font-weight: 700;
  cursor: pointer;
`;

const PreviousButton = styled.button`
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  background-color: transparent;
  color: #000000;
  font-weight: 700;
  height: 3rem;
  border-width: 0;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 1);
  border-radius: 10px;
  width: 200px;
`;

const NextButton = styled.button`
  padding: 0 1.25rem;
  color: #ffffff;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 700;
  height: 3rem;
  border-radius: 0.5rem;
  border-width: 0;
  cursor: pointer;
  background: #1fc585;
  width: 200px;
`;
