import { useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { NavContext } from "../../../context/NavContext";
import { useWrapperRef } from "../LayoutWrapper";

const useHeaderHook = ({ setIsSearchBarVisible, displayBigSearchBar }) => {
  const {
    isLoginFormShowed,
    toggleLoginForm,
    isRegisterFormShowed,
    isSubscriptionShowed,
    toggleSubscription,
    toggleRegisterForm,
    isForgetPasswordFormShowed,
    toggleForgetPasswordForm,
  } = useContext(NavContext);
  const [isScrollable, setIsScrollable] = useState(false);
  const wrapperRef = useWrapperRef();
  const location = useLocation();

  const handleSearchBarVisible = (isVisible) => {
    setIsSearchBarVisible(isVisible);
  };

  const listenToScroll = useCallback(() => {
    if (!wrapperRef) return;

    const scrollPosition = wrapperRef.scrollTop;

    const path = window.location.pathname;

    if (path !== "/") {
      setIsSearchBarVisible(true);
    }
    if (path === "/") {
      if (scrollPosition < 400) {
        setIsSearchBarVisible(false);
      } else {
        setIsSearchBarVisible(true);
      }
    }
  }, [setIsSearchBarVisible, wrapperRef]);

  useEffect(() => {
    if (location.pathname === "/") {
      setIsSearchBarVisible(false);
    } else {
      setIsSearchBarVisible(true);
    }
  }, [location.pathname, setIsSearchBarVisible]);

  useEffect(() => {
    if (wrapperRef) {
      wrapperRef.addEventListener("scroll", listenToScroll);
    }

    return () => {
      wrapperRef?.removeEventListener("scroll", listenToScroll);
    };
  }, [listenToScroll, wrapperRef]);

  useEffect(() => {
    if (displayBigSearchBar) {
      setIsScrollable(false);
    } else {
      setIsScrollable(true);
    }
    if (
      isScrollable &&
      !window.location.pathname.includes("/dashboard/listing-create")
    ) {
      document.body.style.overflow = "unset";
    } else {
      document.body.style.overflow = "hidden";
    }
  }, [isScrollable, displayBigSearchBar]);

  return {
    isLoginFormShowed,
    toggleLoginForm,
    isRegisterFormShowed,
    toggleRegisterForm,
    handleSearchBarVisible,
    isSubscriptionShowed,
    toggleSubscription,
    isForgetPasswordFormShowed,
    toggleForgetPasswordForm,
  };
};

export default useHeaderHook;
