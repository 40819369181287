import { gql } from "@apollo/client";

export const GetListingSettingDatas = gql`
  query getListingSettingDatas {
    getListingSettingDatas {
      workingDomains {
        code
        label
      }
      transportTypology {
        code
        label
        description
      }
      materialTypes {
        code
        label
        description
      }
      ecommerceTypes {
        code
        label
        description
      }
      workingArea {
        code
        label
        order
      }
      languages {
        code
        label
      }
      logisticGigTypes {
        code
        label
        description
      }
      emballageGigTypes {
        code
        label
        description
      }
      expeditionGigTypes {
        code
        label
        description
      }
      interfaceGigTypes {
        code
        label
        description
      }
      preparationGigTypes {
        code
        label
        description
      }
      receptionGigTypes {
        code
        label
        description
      }
      stockageGigTypes {
        code
        label
        description
      }
      transportGigTypes {
        code
        label
        description
      }
      specTypes {
        code
        label
        description
      }
      developmentTypes {
        code
        label
        description
      }
      techTypes {
        code
        label
        description
      }
      certificationTypes {
        code
        label
        description
      }
      approvalTypes {
        code
        label
        description
      }
    }
  }
`;

export default GetListingSettingDatas;
