import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import cx from "classnames";
import { Fragment, useEffect, useState } from "react";
import DefaultSpinner from "../../../../components/ui/DefaultSpinner";
import SelectedLittleGrayBubble from "../../../../components/ui/SelectedLittleGrayBubble";

function SelectFromList({
  options = [],
  onValuesChange,
  selectOn = "id",
  filterList = true,
  filterSearch = true,
  isMulti = true,
  showSelectionList = true,
  onSearchFieldChange = null,
  customHeight,
  customWidth,
  alwaysOpen,
  label,
  isLoading,
  picked,
  isCol,
  placeholder,
  displayInline,
  error,
  register,
  name,
}) {
  const [searchKey, setSearchKey] = useState("");
  const [selected, setSelected] = useState([]);
  const [isFocus, setIsFocus] = useState(false);

  useEffect(() => {
    setSelected(Array.isArray(picked) ? picked : [picked]);
  }, [picked]);

  const selectOption = (option) => {
    let newList = [...selected];
    const optionIndex = selected.findIndex(
      (x) => x[selectOn] === option[selectOn]
    );

    if (optionIndex !== -1) {
      newList.splice(optionIndex, 1);
    } else {
      newList.push(option);
    }

    setSelected(newList);

    if (!filterSearch) {
      setSearchKey("");
      onSearchFieldChange && onSearchFieldChange("");
    }

    onValuesChange(newList);
  };

  return (
    <>
      <div
        className={cx("w-full", {
          "flex-col": isCol,
          "flex gap-3": !displayInline,
        })}
      >
        <Combobox
          as="div"
          value={searchKey}
          onChange={(value) => {
            selectOption(value);
          }}
        >
          {({ open }) => (
            <div className={`w-full ${customWidth}`}>
              {label && (
                <label className="mb-2 ml-px block text-sm font-medium leading-6 text-black lg:text-lg">
                  {label}
                </label>
              )}
              <Combobox.Button className="relative w-full">
                <div
                  className={cx(
                    "w-full rounded-xl border border-gray-300 bg-white py-2.5 pl-3 pr-10 text-gray-900 shadow-sm transition-all duration-300 placeholder:text-gray-400 sm:text-sm sm:leading-6",
                    {
                      "!border-emerald-500 ring ring-emerald-500/20": isFocus,
                    }
                  )}
                >
                  <div className="flex flex-wrap items-center justify-start gap-2">
                    {picked.length > 0 && displayInline && (
                      <div className="flex flex-wrap items-center gap-2">
                        {picked.map((p) => {
                          return (
                            <SelectedLittleGrayBubble
                              key={p[selectOn]}
                              handleRemove={() => selectOption(p)}
                              label={p.label}
                              size="xs"
                            />
                          );
                        })}
                      </div>
                    )}
                    <Combobox.Input
                      {...(register ? register(name) : {})}
                      className={cx("border-0 p-0 grow bg-transparent", {
                        "w-0 min-w-[30px]": displayInline && picked.length,
                      })}
                      data-testid="select-geo"
                      displayValue={searchKey}
                      placeholder={
                        !picked.length ? placeholder || `Rechercher` : ""
                      }
                      value={searchKey}
                      onBlur={() => setIsFocus(false)}
                      onChange={(evt) => {
                        setSearchKey(evt.target.value);
                        onSearchFieldChange &&
                          onSearchFieldChange(evt.target.value);
                      }}
                      onFocus={() => setIsFocus(true)}
                    />
                  </div>
                </div>
                {!alwaysOpen && (
                  <div className="absolute inset-y-0 right-0 flex items-center rounded-r-xl px-2 focus:outline-none">
                    {isLoading ? (
                      <DefaultSpinner />
                    ) : (
                      <MagnifyingGlassIcon
                        aria-hidden="true"
                        className="h-5 w-5 text-gray-400"
                      />
                    )}
                  </div>
                )}
                {options.length > 0 &&
                  (isMulti || (!isMulti && selected.length === 0)) && (
                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                      show={alwaysOpen || open}
                    >
                      <Combobox.Options
                        className={`absolute z-10 mt-1 max-h-60 ${customHeight} w-full overflow-auto rounded-xl bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm`}
                        data-testid="select-geo-options"
                      >
                        {options
                          .filter((item) =>
                            filterList
                              ? !selected.find(
                                  (x) => x[selectOn] === item[selectOn]
                                )
                              : true
                          )
                          .filter((item) =>
                            filterSearch
                              ? item.label.toLowerCase().includes(searchKey)
                              : true
                          )
                          .map((item) => {
                            let isSelected = false;
                            if (Array.isArray(picked))
                              isSelected = picked?.includes(item[selectOn]);
                            else if (typeof picked === "object")
                              isSelected = picked[selectOn] === item[selectOn];

                            return (
                              <Combobox.Option
                                key={item.code}
                                className={cx(
                                  "relative cursor-pointer select-none py-2 pl-3 pr-9 text-left text-gray-900 hover:bg-mplgreen hover:text-white",
                                  {
                                    "bg-mplgreen text-white": isSelected,
                                  }
                                )}
                                value={item}
                              >
                                {({ active, selected }) => (
                                  <>
                                    <span
                                      className={cx(
                                        "block truncate",
                                        selected && "font-semibold"
                                      )}
                                    >
                                      {item.label}
                                    </span>

                                    {selected && (
                                      <span
                                        className={cx(
                                          "absolute inset-y-0 right-0 flex items-center pr-4",
                                          active
                                            ? "text-white"
                                            : "text-gray-400"
                                        )}
                                      >
                                        <CheckIcon
                                          aria-hidden="true"
                                          className="h-5 w-5"
                                        />
                                      </span>
                                    )}
                                  </>
                                )}
                              </Combobox.Option>
                            );
                          })}
                      </Combobox.Options>
                    </Transition>
                  )}
              </Combobox.Button>
              {error && (
                <p className="mt-1 text-left text-sm text-red-600">
                  {error.message}
                </p>
              )}
            </div>
          )}
        </Combobox>
        {!!picked.length && showSelectionList && !displayInline && (
          <div className="flex flex-wrap items-start gap-2">
            {picked.map((item) => {
              return (
                <SelectedLittleGrayBubble
                  key={item[selectOn]}
                  handleRemove={() => selectOption(item)}
                  label={item.label}
                  size="xs"
                />
              );
            })}
          </div>
        )}
      </div>
    </>
  );
}

export default SelectFromList;
