import { IonIcon } from "@ionic/react";
import { checkmarkCircle } from "ionicons/icons";
import { useEffect } from "react";
import styled from "styled-components";

const QuoteSteps0 = ({ choice, setChoice }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex flex-col">
      <div className="mt-9 font-medium lg:mb-20 lg:mt-28 lg:text-2xl">
        Est-ce que vous avez un cahier des charges existant par rapport à votre
        demande ?
      </div>
      <div className="flex flex-col">
        <button
          className="flex items-center justify-between border-b border-[#A8A8A8] py-6"
          onClick={() => setChoice(true)}
        >
          <label className="lg:text-xl" htmlFor="yesChoice">
            Oui
          </label>
          {choice === true ? (
            <RadioIcon icon={checkmarkCircle} />
          ) : (
            <Radio id="yesChoice" type="radio" />
          )}
        </button>
        <button
          className="flex items-center justify-between border-b border-[#A8A8A8] py-6"
          onClick={() => setChoice(false)}
        >
          <label className="lg:text-xl" htmlFor="noChoice">
            Non
          </label>
          {choice === false ? (
            <RadioIcon icon={checkmarkCircle} />
          ) : (
            <Radio id="noChoice" type="radio" />
          )}
        </button>
      </div>
    </div>
  );
};

const RadioIcon = styled(IonIcon)`
  font-size: 25px;
  color: #1fc585;
`;

const Radio = styled.input`
  width: 20px;
  height: 20px;
`;

export default QuoteSteps0;
