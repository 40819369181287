const CustomSearch = ({ value, onChange, placeholder }) => {
  return (
    <div className="relative">
      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
        <svg
          aria-hidden="true"
          className="h-4 w-4 text-gray-500 dark:text-gray-400"
          fill="none"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </svg>
      </div>
      <input
        className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2 pl-10 text-sm text-gray-900 focus:border-mplgreen focus:bg-white focus:ring-1 focus:ring-mplgreen"
        id="default-search"
        placeholder={placeholder}
        type="search"
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default CustomSearch;
