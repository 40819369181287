import { useContext, useMemo } from "react";
import placeholder from "../../../../assets/images/placeholder-annonce.png";
import { AuthContext } from "../../../../context/AuthContext";
import { NavContext } from "../../../../context/NavContext";
import { formatResponseTime } from "../../../../utils/date";
import Rating from "../../../ui/Rating";

const InfoCard = ({ selecterGoogleMapMarker }) => {
  const { user } = useContext(AuthContext);
  const { toggleRegisterForm } = useContext(NavContext);

  const getGeoSearch = () => {
    const params = new URLSearchParams(location.search);
    const geoSearch = JSON.parse(params.get("GeoSearch"));

    return geoSearch ? JSON.stringify(geoSearch.bounds) : null;
  };

  const locationName = selecterGoogleMapMarker?._geoloc[0]?.label;
  const numberOfLocation =
    selecterGoogleMapMarker?.geolocTotal > 1
      ? ` +${selecterGoogleMapMarker?.geolocTotal - 1} autres sites`
      : null;

  const infoArea = useMemo(() => {
    let area = null;
    let availableNow = null;

    if (
      selecterGoogleMapMarker?.areaFreeValue ||
      selecterGoogleMapMarker?.areaValue
    ) {
      const formattedArea = new Intl.NumberFormat().format(
        selecterGoogleMapMarker.areaFreeValue ||
          selecterGoogleMapMarker?.areaValue
      );

      if (selecterGoogleMapMarker?.areaFreeValue) {
        availableNow = (
          <>
            disponible maintenant
            <span className="h-2 w-2 shrink-0 rounded-full bg-mplgreen" />
          </>
        );
      }

      area = (
        <span className="flex items-center gap-1">
          {formattedArea} m² {availableNow}
        </span>
      );
    }

    return area;
  }, [
    selecterGoogleMapMarker?.areaFreeValue,
    selecterGoogleMapMarker?.areaValue,
  ]);

  const onAnnonceClicked = () => {
    if (!user) {
      toggleRegisterForm();
    } else {
      const bounds = getGeoSearch();
      let url = `/detail-announcements/${selecterGoogleMapMarker.id}`;

      if (bounds) {
        url += `?bounds=${bounds}`;
      }
      window.open(url, "_blank");
    }
  };

  return (
    <button
      className="absolute inset-x-3 bottom-5 overflow-hidden rounded-lg border border-black bg-white"
      onClick={onAnnonceClicked}
    >
      <div className="flex">
        <img
          alt="placeholder"
          className="aspect-square w-[133px] object-cover"
          src={
            selecterGoogleMapMarker?.primaryPhoto
              ? selecterGoogleMapMarker?.primaryPhoto
              : placeholder
          }
        />
        <div className="flex flex-col gap-1 p-3">
          <span className="text-left font-roboto font-medium">
            {selecterGoogleMapMarker.title}
          </span>
          <Rating
            readOnly
            rating={
              selecterGoogleMapMarker.averageRating
                ? selecterGoogleMapMarker.averageRating
                : 5
            }
            ratingCount={
              selecterGoogleMapMarker.countRating
                ? selecterGoogleMapMarker.countRating
                : 1
            }
            ratingCountClass="text-xs"
            sizeStar={14}
          />
          {selecterGoogleMapMarker?.averageResponseTime && (
            <div
              className="mb-1 rounded-xl bg-gray-100 px-2 py-1 text-left text-xs font-medium"
              style={{
                boxShadow: "0px 4px 4px 0px #00000040",
              }}
            >
              Délai de réponse:{" "}
              {formatResponseTime(selecterGoogleMapMarker?.averageResponseTime)}
            </div>
          )}
          {(locationName || numberOfLocation || infoArea) && (
            <div className="flex flex-wrap gap-2">
              {locationName && (
                <div className="rounded-xl border border-black px-3 py-1 text-left font-roboto text-xs font-medium">
                  {locationName}
                </div>
              )}
              {numberOfLocation && (
                <div className="rounded-xl border border-black px-3 py-1 text-left font-roboto text-xs font-medium">
                  {numberOfLocation}
                </div>
              )}
              {infoArea && (
                <div className="rounded-xl border border-black px-3 py-1 text-left font-roboto text-xs font-medium">
                  {infoArea}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </button>
  );
};

export default InfoCard;
