import { useLazyQuery } from "@apollo/client";

import {
  createContext,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import useModal from "../hooks/useModal";
import { GetUserSubscriptions } from "../queries/subscription/subscription";
import { AuthContext } from "./AuthContext";

export const NavContext = createContext();

export const NavProvider = ({ children }) => {
  const [isSticky, setIsSticky] = useState(true);
  const [isCreateListing, setIsCreateListing] = useState(false);
  const [isCreateSpec, setIsCreateSpec] = useState(false);
  const [location, setLocation] = useState(null);
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [isOpenArea, setIsOpenArea] = useState(false);
  const [isOpenService, setIsOpenService] = useState(false);
  const [isActive, setIsactive] = useState(false);
  const {
    isShowing: isForgetPasswordFormShowed,
    toggle: toggleForgetPasswordForm,
  } = useModal();
  const { isShowing: isLoginFormShowed, toggle: toggleLoginForm } = useModal();
  const { isShowing: isRegisterFormShowed, toggle: toggleRegisterForm } =
    useModal();
  const {
    isShowing: isSubscriptionShowed,
    toggle: toggleSubscription,
    params: subscriptionParams,
    setParams: setSubscriptionParams,
  } = useModal();
  const {
    isShowing: isPremiumShowed,
    toggle: togglePremium,
    params: premiumParams,
    setParams: setPremiumParams,
  } = useModal();
  useEffect(() => {
    if (window.location.pathname.includes("/dashboard/listing-create")) {
      setIsCreateListing(true);
    } else {
      setIsCreateListing(false);
    }
    if (window.location.pathname.includes("/dashboard/specification-create"))
      setIsCreateSpec(true);
    else setIsCreateSpec(false);
  }, [window.location.pathname]);
  const { dispatch } = useContext(AuthContext);
  const [getUserSubscriptions] = useLazyQuery(GetUserSubscriptions, {
    fetchPolicy: "no-cache",
  });
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const success = params.get("success");
  const portal = params.get("portal");
  useEffect(() => {
    if (success || portal) {
      getUserSubscriptions().then((r) => {
        dispatch({
          type: "SET_SUBSCRIPTIONS",
          payload: r.data?.getUserSubscriptions,
        });
        if (!isSubscriptionShowed && !portal) {
          toggleSubscription(true);
        }
      });
    }
  }, [success, portal]);
  const { pathname } = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [pathname]);

  return (
    <NavContext.Provider
      value={{
        isLoginFormShowed,
        toggleLoginForm,
        isRegisterFormShowed,
        toggleRegisterForm,
        isSticky,
        setIsSticky,
        isCreateSpec,
        isCreateListing,
        setIsCreateListing,
        setIsCreateSpec,
        location,
        selectedAreas,
        selectedServices,
        setLocation,
        setSelectedAreas,
        setSelectedServices,
        isOpenArea,
        setIsOpenArea,
        isOpenService,
        setIsOpenService,
        isActive,
        setIsactive,
        isSubscriptionShowed,
        toggleSubscription,
        subscriptionParams,
        setSubscriptionParams,
        togglePremium,
        isPremiumShowed,
        premiumParams,
        setPremiumParams,
        isForgetPasswordFormShowed,
        toggleForgetPasswordForm,
      }}
    >
      {children}
    </NavContext.Provider>
  );
};
