import cx from "classnames";
import tw from "tailwind-styled-components";
import CustomButton from "../../ui/CustomButton";
import { Title, TitlePart } from "./styled/PageTitleElements";

function StepsWrapper({
  children,
  step = 0,
  title = "",
  subtitle = "",
  stepsTitle = [],
  onStepChanges = null,
  hideTitleAtEnd = false,
  hideControlsAtEnd = false,
  showProgress = true,
  stepMax = 0,
}) {
  return (
    <>
      <FullWrapper>
        {hideTitleAtEnd && step === stepMax ? null : (
          <Title>
            <TitlePart>{title}</TitlePart>
            {subtitle && <TitlePart>{subtitle}</TitlePart>}
          </Title>
        )}
        <Wrapper>
          <Container>
            {/* septs marker */}
            <StepsMarkers>
              <ol className="flex w-full items-center justify-between text-center text-sm font-medium text-gray-500">
                {!!stepsTitle.length &&
                  stepsTitle.map((title, index) => (
                    <>
                      <li key={title} className="flex items-center">
                        <span
                          className={cx("flex items-center", {
                            "text-mplgreen": index === step - 1,
                          })}
                        >
                          <span className="mr-2">{index + 1}. </span>
                          {title}
                        </span>
                      </li>

                      {index < stepsTitle.length - 1 && (
                        <span className="text-gray-200">/</span>
                      )}
                    </>
                  ))}
              </ol>

              {showProgress && (
                <StepsBar style={{ marginTop: 16 }}>
                  <StepsBarIndicator
                    style={{ width: (step / stepsTitle.length) * 100 + "%" }}
                  />
                </StepsBar>
              )}
            </StepsMarkers>
            <StepsContainer>{children}</StepsContainer>
          </Container>
        </Wrapper>
      </FullWrapper>
      {hideControlsAtEnd && step === stepMax ? null : (
        <div className="sticky bottom-0 mt-auto flex justify-center gap-4 border-t border-mplgray bg-white px-3 py-4 md:justify-between">
          <div className="flex-1 md:grow-0">
            <CustomButton
              isFull
              color="black"
              variant="outlined"
              onClick={() => onStepChanges(step - 1)}
            >
              Précédent
            </CustomButton>
          </div>
          <div className="flex-1 md:grow-0">
            <CustomButton isFull onClick={() => onStepChanges(step + 1)}>
              {step < stepsTitle.length - 1 ? "Suivant" : "Valider"}
            </CustomButton>
          </div>
        </div>
      )}
    </>
  );
}

const FullWrapper = tw.div`w-full lg:min-h-[700px] h-full box-border p-4 md:p-8 lg:p-10`;
const Wrapper = tw.div`w-full mt-[28px]`;
const Container = tw.div`w-full`;
const StepsMarkers = tw.div``;
const StepsBar = tw.div`h-[13px] w-full bg-[#e7e7e7]`;
const StepsBarIndicator = tw.div`h-full bg-[#1FC585]`;
const StepsContainer = tw.div``;

export default StepsWrapper;
