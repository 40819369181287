import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../../context/AuthContext";
import { NotificationContext } from "../../../../context/NotificationContext";
import useTailwindBreakpoint from "../../../../hooks/useTailwindBreakpoint";
import {
  isAuthenticated,
  isRoleCompany,
  isRoleLogistician,
} from "../../../../utils/user";

function Dropdown({ setIsDropdownShow, toggleLoginForm, toggleRegisterForm }) {
  const { user } = useContext(AuthContext);
  const { isLgTw } = useTailwindBreakpoint();
  const { notifications } = useContext(NotificationContext);
  const navigate = useNavigate();
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownShow(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [setIsDropdownShow]);

  const needSubscription = useCallback(
    (link) => {
      if (user?.subscriptions?.length) {
        navigate(link);
      } else {
        navigate("/subscriptions");
      }
    },
    [navigate, user?.subscriptions?.length]
  );

  const liNodes = useMemo(() => {
    const listNodes = [];

    if (isLgTw) {
      if (!isAuthenticated(user)) {
        listNodes.push(<button onClick={toggleLoginForm}>Connexion</button>);
        listNodes.push(
          <button onClick={toggleRegisterForm}>Inscription</button>
        );
        listNodes.push(<Link to="/expert">Parler à un expert</Link>);
        listNodes.push(<Link to="/faq">Aide</Link>);
        listNodes.push(
          <Link to="https://blog.monprestatairelogistique.com/">Blog</Link>
        );
      } else {
        if (isRoleLogistician(user)) {
          listNodes.push(<Link to="/dashboard/profile">Profil</Link>);
          listNodes.push(
            <Link to="/notifications">
              <div className="relative flex max-w-fit items-center gap-x-1">
                Notifications
                {notifications?.length > 0 && (
                  <div className="inline-flex h-6 w-6 items-center justify-center rounded-full border-2 border-white bg-mplgreen text-xs font-bold text-white">
                    {notifications.length}
                  </div>
                )}
              </div>
            </Link>
          );
          listNodes.push(
            <button onClick={() => needSubscription("/dashboard/listings")}>
              {user?.listingNumber > 1
                ? "Gérer mes annonces"
                : "Gérer mon annonce"}
            </button>
          );
          listNodes.push(
            <Link to="/dashboard/quote">Mes demandes de devis</Link>
          );
          listNodes.push(<Link to="/faq">Aide</Link>);
          listNodes.push(
            <Link to="https://blog.monprestatairelogistique.com/">Blog</Link>
          );
          listNodes.push(
            <Link className="text-red-500" to="/logout">
              Déconnexion
            </Link>
          );
        } else {
          listNodes.push(<Link to="/dashboard/profile">Profil</Link>);
          listNodes.push(
            <Link to="/notifications">
              <div className="relative flex max-w-fit items-center gap-x-1">
                Notifications
                {notifications?.length > 0 && (
                  <div className="inline-flex h-6 w-6 items-center justify-center rounded-full border-2 border-white bg-mplgreen text-xs font-bold text-white">
                    {notifications.length}
                  </div>
                )}
              </div>
            </Link>
          );
          listNodes.push(<Link to="/expert">Parler à un expert</Link>);
          listNodes.push(<Link to="/dashboard/quote">Mes devis</Link>);
          listNodes.push(<Link to="/faq">Aide</Link>);
          listNodes.push(
            <Link to="https://blog.monprestatairelogistique.com/">Blog</Link>
          );
          listNodes.push(
            <Link className="text-red-500" to="/logout">
              Déconnexion
            </Link>
          );
        }
      }
    } else {
      if (!isAuthenticated(user)) {
        listNodes.push(<button onClick={toggleLoginForm}>Connexion</button>);
        listNodes.push(
          <button onClick={toggleRegisterForm}>Inscription</button>
        );
        listNodes.push(<Link to="/expert">Parler à un expert</Link>);
        listNodes.push(
          <Link to="/tender-notice/create">Créer mon appel d'offre</Link>
        );
        listNodes.push(<Link to="/faq">Aide</Link>);
        listNodes.push(
          <Link to="https://blog.monprestatairelogistique.com/">Blog</Link>
        );
      } else {
        if (isRoleCompany(user)) {
          listNodes.push(<Link to="/dashboard/profile">Profil</Link>);
          listNodes.push(
            <Link to="/notifications">
              <div className="relative flex max-w-fit items-center gap-x-1">
                Notifications
                {notifications?.length > 0 && (
                  <div className="inline-flex h-6 w-6 items-center justify-center rounded-full border-2 border-white bg-mplgreen text-xs font-bold text-white">
                    {notifications.length}
                  </div>
                )}
              </div>
            </Link>
          );
          listNodes.push(<Link to="/expert">Parler à un expert</Link>);
          listNodes.push(
            <Link to="/dashboard/specifications">Mon cahier des charges</Link>
          );
          listNodes.push(
            <Link to="/dashboard/quote">Mes demandes de devis</Link>
          );
          listNodes.push(
            <Link to="/dashboard/security">Connexion et sécurité</Link>
          );
          listNodes.push(
            <Link to="/tender-notice/create">Créer mon appel d'offre</Link>
          );
          listNodes.push(<Link to="/faq">Aide</Link>);
          listNodes.push(
            <Link to="https://blog.monprestatairelogistique.com/">Blog</Link>
          );
          listNodes.push(
            <Link className="text-red-500" to="/logout">
              Déconnexion
            </Link>
          );
        } else if (isRoleLogistician(user)) {
          listNodes.push(<Link to="/dashboard/profile">Profil</Link>);
          listNodes.push(
            <Link to="/notifications">
              <div className="relative flex max-w-fit items-center gap-x-1">
                Notifications
                {notifications?.length > 0 && (
                  <div className="inline-flex h-6 w-6 items-center justify-center rounded-full border-2 border-white bg-mplgreen text-xs font-bold text-white">
                    {notifications.length}
                  </div>
                )}
              </div>
            </Link>
          );
          listNodes.push(
            <button onClick={() => needSubscription("/dashboard/listings")}>
              {user?.listingNumber > 1
                ? "Gérer mes annonces"
                : "Gérer mon annonce"}
            </button>
          );
          listNodes.push(
            <Link to="/dashboard/quote">Mes demandes de devis</Link>
          );
          listNodes.push(
            <Link to="/dashboard/tender-notices">Appel d'offre</Link>
          );
          listNodes.push(<Link to="/faq">Aide</Link>);
          listNodes.push(
            <Link to="https://blog.monprestatairelogistique.com/">Blog</Link>
          );
          listNodes.push(
            <Link className="text-red-500" to="/logout">
              Déconnexion
            </Link>
          );
        }
      }
    }

    const closeDropdown = () => {
      setIsDropdownShow(false);
    };

    return listNodes.map((node, index) => (
      <li
        key={index}
        className="block whitespace-nowrap px-4 py-2 text-gray-700 hover:bg-gray-100"
      >
        {React.cloneElement(node, {
          className: `w-full flex ${node.props.className}`,
          onClick: () => {
            node.props.onClick?.();
            closeDropdown();
          },
        })}
      </li>
    ));
  }, [
    isLgTw,
    needSubscription,
    notifications.length,
    setIsDropdownShow,
    toggleLoginForm,
    toggleRegisterForm,
    user,
  ]);

  return (
    <div
      ref={dropdownRef}
      className="absolute right-0 top-full z-50 my-2 w-min list-none divide-y divide-gray-100 rounded-lg bg-white text-base shadow"
    >
      <ul aria-labelledby="user-menu-button" className="py-2">
        {liNodes}
      </ul>
    </div>
  );
}

export default Dropdown;
