import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Fragment } from "react";
import { Controller } from "react-hook-form";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function CustomSelect({
  items,
  label,
  name,
  control,
  placeholder,
  error,
  isMultiple,
  alwaysOpen,
  customHeight,
}) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange } }) => (
        <Listbox multiple={isMultiple} value={value} onChange={onChange}>
          {({ open }) => (
            <div className="mb-2">
              {label && (
                <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
                  {label}
                </Listbox.Label>
              )}
              <div className="relative mt-2">
                <Listbox.Button className="relative w-full cursor-default rounded-xl bg-white py-2.5 pl-3 pr-10 text-left leading-6 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-black sm:text-sm sm:leading-6">
                  <span className="block min-h-[24px] truncate">
                    {value ? (
                      value.label
                    ) : placeholder ? (
                      <span className="text-gray-400">{placeholder}</span>
                    ) : (
                      ""
                    )}
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon
                      aria-hidden="true"
                      className="h-5 w-5 text-gray-400"
                    />
                  </span>
                </Listbox.Button>
                {error && (
                  <p className="mt-1 text-left text-sm text-red-600">
                    {error.message}
                  </p>
                )}
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  show={alwaysOpen || open}
                >
                  <Listbox.Options
                    className={`absolute z-10 ${customHeight} max-h-60 w-full overflow-auto rounded-md border bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm ${
                      error ? "-my-5" : "mt-1"
                    }`}
                  >
                    {items.length === 0 ? (
                      <Listbox.Option
                        disabled
                        className="relative cursor-not-allowed select-none py-2 pl-3 pr-9"
                        value={null}
                      >
                        <span className="block truncate text-left text-gray-400">
                          Aucune option disponible
                        </span>
                      </Listbox.Option>
                    ) : (
                      items.map((item) => (
                        <Listbox.Option
                          key={item.value}
                          className={({ active }) =>
                            classNames(
                              active
                                ? "bg-mplgreen text-white"
                                : "text-gray-900",
                              "relative cursor-default select-none py-2 pl-3 pr-9"
                            )
                          }
                          value={item}
                        >
                          {({ selected, active }) => (
                            <>
                              <span
                                className={classNames(
                                  selected ? "font-semibold" : "font-normal",
                                  "block truncate text-left"
                                )}
                              >
                                {item.label}
                              </span>

                              {selected ? (
                                <span
                                  className={classNames(
                                    active ? "text-white" : "text-black",
                                    "absolute inset-y-0 right-0 flex items-center pr-4"
                                  )}
                                >
                                  <CheckIcon
                                    aria-hidden="true"
                                    className="h-5 w-5"
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))
                    )}
                  </Listbox.Options>
                </Transition>
              </div>
            </div>
          )}
        </Listbox>
      )}
    />
  );
}
