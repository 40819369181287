import ReactSlider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const Slider = ({ children, ...overrideSettings }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    adaptiveHeight: true,
    ...overrideSettings,
  };

  return <ReactSlider {...settings}>{children}</ReactSlider>;
};

export default Slider;
