import { EyeIcon, EyeSlashIcon } from "@heroicons/react/20/solid";
import { IonIcon } from "@ionic/react";
import { informationCircleOutline } from "ionicons/icons";
import { useCallback, useState } from "react";
import TippyTooltip from "../../TippyTooltip";

const CustomInput = ({
  name,
  error,
  label,
  placeholder,
  register,
  type = "text",
  tip,
  onlyNumeric,
  maxLength,
  inputClassName,
  disabled,
}) => {
  const [isPasswordShow, setIsPasswordShow] = useState(false);

  const handleClickShow = useCallback((e) => {
    e.stopPropagation();
    setIsPasswordShow((prevState) => !prevState);
  }, []);

  const handleKeyPress = (e) => {
    if (
      onlyNumeric &&
      !/[0-9]/.test(e.key) &&
      e.key !== "Backspace" &&
      e.key !== "Tab"
    ) {
      e.preventDefault();
    }
  };

  return (
    <div>
      {label && (
        <label
          className="mb-2 ml-px block text-sm font-medium leading-6 text-black lg:text-lg"
          htmlFor={name}
        >
          <span>
            {label}{" "}
            {tip && (
              <TippyTooltip content={tip}>
                <IonIcon icon={informationCircleOutline} />
              </TippyTooltip>
            )}
          </span>
        </label>
      )}
      <div className="relative">
        <input
          className={`block w-full rounded-xl border-gray-300 p-2.5 text-gray-900 shadow-sm transition-all duration-300 placeholder:text-gray-400 focus:border-emerald-500 focus:ring focus:ring-emerald-500/20 sm:text-sm sm:leading-6 ${inputClassName}`}
          id={name}
          placeholder={placeholder}
          type={isPasswordShow ? "text" : type}
          onKeyDown={handleKeyPress}
          {...register(name, { valueAsNumber: type === "number" })}
          disabled={disabled}
          maxLength={maxLength}
        />
        {type === "password" && (
          <button
            className="absolute inset-y-0 right-1 flex items-center pr-2"
            type="button"
            onClick={handleClickShow}
          >
            {isPasswordShow ? (
              <EyeSlashIcon
                aria-hidden="true"
                className="h-5 w-5 text-gray-400"
              />
            ) : (
              <EyeIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
            )}
          </button>
        )}
      </div>
      {error && (
        <p className="mt-1 text-left text-sm text-red-600">{error.message}</p>
      )}
    </div>
  );
};

export default CustomInput;
