import CustomButton from "../../../components/ui/CustomButton";
import CustomTextarea from "../../../components/ui/CustomTextarea";

const MobileAddMessageSpecification = ({
  nextStep,
  previousStep,
  register,
  isLoading,
}) => {
  return (
    <div className="flex h-full flex-1 flex-col overflow-auto p-5 pb-0 md:p-8 md:pb-0">
      <h1 className="mb-4 text-xl font-bold sm:text-2xl md:text-3xl">
        Demande de devis
      </h1>
      <CustomTextarea
        name="optionalMessage"
        placeholder="Ajouter un message (optionnel)"
        register={register}
      />

      <div className="sticky bottom-0 -mx-5 mt-auto flex justify-center gap-4 border-t border-mplgray bg-white px-3 py-4 md:-mx-8">
        <CustomButton
          isFull
          color="black"
          variant="outlined"
          onClick={previousStep}
        >
          Retour
        </CustomButton>
        <CustomButton isFull isLoading={isLoading} onClick={nextStep}>
          Valider
        </CustomButton>
      </div>
    </div>
  );
};

export default MobileAddMessageSpecification;
