import { useLazyQuery } from "@apollo/client";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { config } from "../../config/env.config";
import { AuthContext } from "../../context/AuthContext";
import { NavContext } from "../../context/NavContext";
import {
  FindAllSubscription,
  GetStripeSessionId,
  GetUserSubscriptions,
} from "../../queries/subscription/subscription";
import { userHasSubscription } from "../../utils/user";
import SubType from "./components/SubType";
import SuccessFullPayment from "./components/SuccessFullPayment";

function SubscriptionsHome({ modalParams }) {
  const [loading, setLoading] = useState(false);
  const { user, token, dispatch } = useContext(AuthContext);
  const { toggleRegisterForm, isSubscriptionShowed, toggleSubscription } =
    useContext(NavContext);
  const [findAllSubscription, { data }] = useLazyQuery(FindAllSubscription);

  const [getUserSubscriptions] = useLazyQuery(GetUserSubscriptions, {
    fetchPolicy: "no-cache",
  });
  const [getStripeSessionId] = useLazyQuery(GetStripeSessionId);

  useEffect(() => {
    findAllSubscription();
  }, [findAllSubscription]);

  const redirectToStripe = () => {
    if (user.subscriptions.length > 0) {
      getStripeSessionId({
        variables: {
          successUrl: window.location.href.includes("?")
            ? window.location.href + "&portal=true"
            : window.location.href + "?portal=true",
        },
      }).then(async (r) => window.open(r.data.getStripeSessionId, "_self"));
    } else {
      window.location.href = "/subscriptions";
    }
  };

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const success = params.get("success");

  useEffect(() => {
    if (success) {
      getUserSubscriptions().then((r) =>
        dispatch({
          type: "SET_SUBSCRIPTIONS",
          payload: r.data?.getUserSubscriptions,
        })
      );
    }
  }, [dispatch, getUserSubscriptions, success]);

  const getSubscription = (_choosen) => {
    if (!user) {
      toggleRegisterForm();
      return;
    }

    setLoading(true);
    const successUrl = window.location.href.includes("?")
      ? window.location.href + "&success=true"
      : window.location.href + "?success=true";
    const cancelUrl = window.location.href.includes("?")
      ? window.location.href + "&cancel=true"
      : window.location.href + "?cancel=true";
    if (user?.subscriptions?.length > 0) {
      redirectToStripe();
    } else {
      axios
        .post(
          `${config.get("BACKEND_URL")}/subscription/checkout-session`,
          { subId: _choosen.id, successUrl, cancelUrl, user },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then(async (res) => {
          window.open(res.data, "_self");
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };

  if (success) {
    return (
      <SuccessFullPayment
        isSubscriptionShowed={isSubscriptionShowed}
        modalParams={modalParams}
        toggleSubscription={toggleSubscription}
        user={user}
      />
    );
  }

  if (!data) {
    return null;
  }

  return (
    <>
      <div className="p-6 pt-0 md:p-10 md:pt-0 lg:pt-0">
        {(!modalParams || !user) && (
          <div className="flex flex-col">
            {!modalParams && (
              <h1 className="my-5 font-roboto-regular text-xl font-bold md:my-7 md:text-2xl lg:my-10 lg:text-3xl">
                {userHasSubscription(user)
                  ? "Changez votre abonnement"
                  : "Nos formules d’abonnements"}
              </h1>
            )}
          </div>
        )}
        <div>
          <SubType
            data={data?.subscriptions ?? []}
            fullWidth={!!modalParams}
            loading={loading}
            subscriptions={user?.subscriptions || []}
            onSubChoose={(c) => getSubscription(c)}
          />
        </div>
      </div>
    </>
  );
}

export default SubscriptionsHome;
