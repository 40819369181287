import { IonIcon } from "@ionic/react";
import { closeCircle, document } from "ionicons/icons";
import { useCallback, useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import devisIcon from "../../../assets/images/devisIcon.png";
import specificationIcon from "../../../assets/images/specificationIcon.png";
import ModalChooseSpecification from "../../../components/ModalTW/ModalChooseSpecification";
import { AuthContext } from "../../../context/AuthContext";
import useModal from "../../../hooks/useModal";
import { isRoleCompany } from "../../../utils/user";

const QuoteSteps1 = ({
  filename,
  setFile,
  specification,
  setSpecification,
  choice,
  state,
}) => {
  const fileInputRef = useRef(null);
  const specificationModal = useModal();
  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    setFile(file);
    handleDeleteSpecification();
  };
  const { user } = useContext(AuthContext);
  const selectSpecification = (value) => {
    setSpecification(value);
    handleDeleteFile();
  };

  const handleDeleteFile = useCallback(() => {
    setFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  }, [setFile]);

  const handleDeleteSpecification = useCallback(() => {
    setSpecification(null);
  }, [setSpecification]);

  useEffect(() => {
    handleDeleteSpecification();
    handleDeleteFile();
  }, [choice, handleDeleteFile, handleDeleteSpecification]);

  const nav = useNavigate();
  const onSpecificationClick = () => {
    if (choice) {
      specificationModal.toggle();
    } else {
      nav("/dashboard/specification-create", {
        state: { redirect: "/devis", state: { ...state, choice: true } },
      });
    }
  };

  return (
    <div className="text-center">
      <div className="mt-9 flex flex-col items-center gap-4 lg:flex-row lg:items-start">
        {choice === true && (
          <>
            <div className="flex flex-1 flex-col items-center">
              <div className="mb-6 text-left font-medium md:h-[48px]">
                Télécharger votre cahier des charges (PDF)
              </div>
              <div className="relative overflow-hidden lg:w-full">
                <button className="flex h-20 w-40 items-center justify-center rounded border border-dashed border-black bg-white lg:h-52 lg:w-full">
                  <img src={devisIcon} />
                </button>
                <InputFile
                  ref={fileInputRef}
                  accept="application/pdf"
                  type="file"
                  onChange={handleFileSelect}
                />
              </div>
              {filename !== "" && (
                <FileRender>
                  <DocIcon icon={document} />
                  <Filename>{filename}</Filename>
                  <CloseIcon icon={closeCircle} onClick={handleDeleteFile} />
                </FileRender>
              )}
            </div>
            {isRoleCompany(user) && <TextOr>OU</TextOr>}
          </>
        )}
        {isRoleCompany(user) && (
          <div className="flex flex-1 flex-col items-center">
            <h1 className="mb-6 font-medium">
              {choice &&
                "Choisir votre cahier des charges Monprestatairelogistique.com"}
              {!choice && "Créer votre cahier des charges en quelques clics"}
            </h1>
            <div className="relative overflow-hidden lg:w-full">
              <button
                className="flex h-20 w-40 items-center justify-center rounded border border-dashed border-black bg-white lg:h-52 lg:w-full"
                onClick={onSpecificationClick}
              >
                <img src={specificationIcon} />
              </button>
            </div>
            {specification && (
              <FileRender>
                <DocIcon icon={document} />
                <Filename>
                  {specification.description?.name || "Cahier des charges"}
                </Filename>
                <CloseIcon
                  icon={closeCircle}
                  onClick={handleDeleteSpecification}
                />
              </FileRender>
            )}
          </div>
        )}
      </div>
      {specificationModal.isShowing && (
        <ModalChooseSpecification
          onClose={specificationModal.toggle}
          onSelect={selectSpecification}
        />
      )}
    </div>
  );
};

const InputFile = styled.input`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  cursor: pointer;
`;

const TextOr = styled.p`
  font-size: 25px;
  color: #000;
  font-weight: 600;
  text-align: center;
  margin-top: 70px;
  align-self: center;

  @media (max-width: 768px) {
    margin-top: 0px;
  }
`;

const FileRender = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;

const DocIcon = styled(IonIcon)`
  font-size: 25px;
  color: #1fc585;
  margin-right: 5px;
`;

const Filename = styled.p`
  font-size: 15px;
  color: #1fc585;
  font-weight: 600;
  text-align: center;
  margin: 0;
`;

const CloseIcon = styled(IonIcon)`
  font-size: 20px;
  color: #1fc585;
  margin-left: 10px;
  cursor: pointer;
`;

export default QuoteSteps1;
