import { useCallback, useMemo } from "react";

function BubbleChoiceItem({
  options = [],
  selectedFilter,
  handleOnChange,
  selectOn = "id",
  isMultiple = false,
  type = "string",
}) {
  const handleMultipleSelection = useCallback(
    (value) => {
      const isValueSelected = !!selectedFilter.find(
        (selected) => selected[selectOn] === value[selectOn]
      );

      const updatedSelection = isValueSelected
        ? selectedFilter.filter((t) => t[selectOn] !== value[selectOn])
        : [...selectedFilter, value];

      handleOnChange(updatedSelection);
    },

    [handleOnChange, selectOn, selectedFilter]
  );

  const handleSingleSelection = useCallback(
    (value) => {
      const newValue = value === selectedFilter ? null : value;
      handleOnChange(newValue);
    },
    [handleOnChange, selectedFilter]
  );

  const selectOption = useCallback(
    (value) => {
      const formattedValue = type === "number" ? parseFloat(value) : value;

      if (isMultiple) {
        handleMultipleSelection(formattedValue);
        return;
      }

      handleSingleSelection(formattedValue);
    },
    [handleMultipleSelection, handleSingleSelection, isMultiple, type]
  );

  const renderedOptions = useMemo(() => {
    return options.map((item) => {
      const isSelected = !!selectedFilter.find(
        (selected) => selected[selectOn] === item[selectOn]
      );

      return (
        <li
          key={item.label}
          className={`cursor-pointer whitespace-nowrap rounded-full border px-4 py-1 text-center ${
            isSelected
              ? "border-mplgreen bg-mplgreen text-white"
              : "border-gray-400 hover:bg-gray-200"
          }`}
          onClick={() => selectOption(item)}
        >
          {item.label}
        </li>
      );
    });
  }, [options, selectOn, selectedFilter, selectOption]);

  return (
    <ul className="flex gap-2 overflow-x-auto pt-5 lg:flex-wrap lg:gap-5">
      {renderedOptions}
    </ul>
  );
}

export default BubbleChoiceItem;
