import { XCircleIcon } from "@heroicons/react/20/solid";
import { useMemo } from "react";

const SelectedLittleGrayBubble = ({ handleRemove, label, size, className }) => {
  const textSize = useMemo(() => {
    switch (size) {
      case "xs":
        return "text-xs";
      case "sm":
        return "text-sm";
      default:
        throw new Error(`Invalid size property: size ==> ${size}`);
    }
  }, [size]);

  const classString = `
    flex w-max items-center gap-1 whitespace-nowrap rounded-full border px-2 py-1 ${textSize} text-[#706E6E]
  `;

  const content = (
    <>
      {label}
      {handleRemove && (
        <XCircleIcon aria-hidden="true" className="h-4 w-4 text-gray-400" />
      )}
    </>
  );

  return (
    <div className={className}>
      {handleRemove ? (
        <button
          className={classString}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleRemove();
          }}
        >
          {content}
        </button>
      ) : (
        <div className={classString}>{content}</div>
      )}
    </div>
  );
};

export default SelectedLittleGrayBubble;
