import { gql } from "@apollo/client";

export const GetReporting = gql`
  query GetReporting($start: Date!, $end: Date!) {
    getReporting(start: $start, end: $end) {
      numberOfVisitor
      quoteInitied
      quoteSend
      websiteClicked
      activityAreaChart {
        name
        count
      }
      cityChart {
        name
        count
      }
    }
  }
`;
