import { gql } from '@apollo/client';

export const GET_FAQS = gql`
  query faqs {
    faqs {
      id
      question
      answer
    }
  }
`;
