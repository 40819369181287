import React from "react";
import styled from "styled-components";

export const homePageDots = (dots) => {
  const customDots = dots.map((dot) => {
    const isActive = dot.props.className.includes("slick-active");

    return React.cloneElement(
      dot,
      {
        className: `!w-4 !h-4 rounded-full ${
          isActive ? "bg-mplgreen" : "bg-black"
        }`,
      },
      <button onClick={dot.props.children.props.onClick} />
    );
  });

  return (
    <WrapperHomeDots>
      <ul className="relative top-5"> {customDots} </ul>
    </WrapperHomeDots>
  );
};

const WrapperHomeDots = styled.div`
  & li button:before {
    content: "" !important;
  }
`;

export const announcementDetailsDots = (dots) => {
  const customDots = dots.map((dot) => {
    const isActive = dot.props.className.includes("slick-active");

    return React.cloneElement(
      dot,
      {
        className: `!w-2 !h-2 rounded-full ${
          isActive ? "bg-mplgreen" : "bg-black opacity-40"
        }`,
      },
      <button onClick={dot.props.children.props.onClick} />
    );
  });

  return (
    <WrapperAnnounceDetailsDots>
      <ul className="relative -top-10"> {customDots} </ul>
    </WrapperAnnounceDetailsDots>
  );
};

const WrapperAnnounceDetailsDots = styled.div`
  & li button:before {
    color: green;
  }
  & li button:before {
    content: "" !important;
  }
`;
