let isInit = false;
let envs = [];
function init() {
  envs = require(`./config-${
    process.env["REACT_APP_ENV"] ?? process.env.NODE_ENV
  }.json`);
  isInit = true;
}

function get(key) {
  if (!isInit) {
    init();
  }
  const env = envs.find((env) => env.name === key);
  return env?.value ?? "";
}

export const config = {
  get,
};
