import { useLazyQuery } from "@apollo/client";
import debounce from "lodash.debounce";
import { useCallback, useContext, useEffect, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { NavContext } from "../../../../context/NavContext";
import { SearchContext } from "../../../../context/SearchContext";
import useLocationPlaceId from "../../../../hooks/useLocationPlaceId";
import {
  GeoSearch,
  SEARCH_LOCATION,
} from "../../../../queries/citiesSearch/citiesSearch";

const useSearchBarHooks = ({ onSearch, resetSearch }) => {
  const { placeId: placeIdAroundMe } = useLocationPlaceId();
  const {
    isOpenLocation,
    setIsOpenLocation,
    selectedOption,
    setSelectedOption,
    showOptions,
    setShowOptions,
    selectedArea,
    setSelectedArea,
    selectedService,
    setSelectedService,
    options,
    settingDatas,
    setOptions,
  } = useContext(SearchContext);

  const {
    isOpenService,
    setIsOpenService,
    isOpenArea,
    setIsOpenArea,
    location,
    selectedAreas,
    selectedServices,
    setLocation,
    setSelectedAreas,
    setSelectedServices,
  } = useContext(NavContext);

  const [error, setError] = useState(false);
  const [searchBig, setSearchBig] = useState(false);
  // OPTIONS
  const togglingLocation = (e) => {
    e.preventDefault();
    setError(false);
    setIsOpenLocation((prev) => !prev);
    setIsOpenArea(false);
    setIsOpenService(false);
    setSearchBig(true);
  };

  const togglingArea = (e) => {
    e.preventDefault();
    setError(false);
    setIsOpenArea((prev) => !prev);
    setIsOpenLocation(false);
    setIsOpenService(false);
    setSearchBig(true);
  };

  const togglingService = () => {
    setIsOpenService((prev) => !prev);
    setError(false);
    setIsOpenArea(false);
    setIsOpenLocation(false);
    setSearchBig(true);
  };

  const toggleOptionArea = ({ item }) => {
    const handleItem = (prevSelected) => {
      const newArray = [...prevSelected];
      if (newArray.find((e) => e.id === item.id)) {
        return newArray.filter((itemFilter) => itemFilter.id !== item.id);
      }
      newArray.push(item);
      return newArray;
    };
    setSelectedAreas(handleItem);
    setSelectedArea(handleItem);
  };

  const toggleOptionService = ({ item }) => {
    const handleItem = (prevSelected) => {
      const newArray = [...prevSelected];
      if (newArray.find((e) => e.id === item.id)) {
        return newArray.filter((itemFilter) => itemFilter.id !== item.id);
      }
      newArray.push(item);

      return newArray;
    };
    setSelectedService(handleItem);
    setSelectedServices(handleItem);
  };

  const clearSelectedAria = useCallback(() => {
    setSelectedArea([]);
    setSelectedAreas([]);
    setSearchBig(false);
  }, [setSelectedArea, setSelectedAreas]);

  const clearSelectedService = useCallback(() => {
    setSelectedService([]);
    setSelectedServices([]);
    setSearchBig(false);
  }, [setSelectedService, setSelectedServices]);

  const handleChange = async (selected) => {
    if (!selected) {
      setLocation(null);
      setSelectedOption(null);
      return;
    }

    if (selected.value === "ALL") {
      setLocation(selected);
      setSelectedOption(selected);
      navigateToSecondarySearch();
      return;
    }

    const fetchedBounds = await fetchInsideBounds(selected);
    if (fetchedBounds) {
      navigateToSecondarySearch(fetchedBounds);
    }
  };

  useEffect(() => {
    if (resetSearch) {
      clearSelectedAria();
      clearSelectedService();
      setSelectedOption(null);
      setSearchBig(false);
      setLocation(null);
    }
  }, [
    clearSelectedAria,
    clearSelectedService,
    resetSearch,
    setLocation,
    setSelectedOption,
  ]);

  const [geoApiSearch] = useLazyQuery(SEARCH_LOCATION);
  const handleInputChange = debounce((text) => {
    if (text.length > 1) {
      geoApiSearch({ variables: { address: text } })
        .then((res) => {
          setOptions(
            res.data.searchLocation.map((location) => {
              return {
                ...location,
                label: `${location.label} ${
                  location.postal_code ? `(${location.postal_code}), ` : ""
                } ${
                  location.administrative_area
                    ? `${location.administrative_area}, `
                    : ""
                } ${
                  location.country === location.label ? "" : location.country
                }`.trim(),
                city: location.label,
              };
            })
          );
          setShowOptions(true);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setOptions(() => {
        const options = [];
        if (placeIdAroundMe) {
          options.push({ value: placeIdAroundMe, label: "Autour de moi" });
        }

        return [...options, { value: "ALL", label: "Toute la France" }];
      });
      setShowOptions(false);
    }
  }, 700);

  const [getInsideBounds] = useLazyQuery(GeoSearch);

  const nav = useNavigate();

  const handleErrorsAndSetSearchSmall = () => {
    setError(true);
    setSearchBig(false);
  };

  const setQueryParameters = () => {
    setLocation(selectedOption);
    setSelectedAreas(selectedArea);
    setSelectedServices(selectedService);
  };

  const navigateToSecondarySearch = (geoSearchOption, pathname) => {
    nav({
      pathname,
      search: createSearchParams({
        prestationsIds: selectedService
          ? JSON.stringify(selectedService)
          : null,
        selectedArea: selectedArea ? JSON.stringify(selectedArea) : null,
        GeoSearch: geoSearchOption ? JSON.stringify(geoSearchOption) : null,
      }).toString(),
    });
  };

  const fetchInsideBounds = async (location) => {
    try {
      const res = await getInsideBounds({
        variables: { placeId: location.value },
      });
      const tmp = { ...location };
      tmp.bounds = res.data?.GeoSearch.bounds ?? [];
      setLocation(tmp);
      setSelectedOption(tmp);
      return tmp;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  const toMiniSearchBar = async () => {
    if (!selectedOption && !selectedArea.length && !selectedService.length) {
      handleErrorsAndSetSearchSmall();
      return;
    }

    setQueryParameters();
    onSearch?.call();

    let geoSearchOption = selectedOption;

    if (
      selectedOption?.value &&
      selectedOption.value !== "ALL" &&
      selectedOption.value !== "Toute la France"
    ) {
      const fetchedBounds = await fetchInsideBounds(selectedOption);
      if (fetchedBounds) {
        geoSearchOption = fetchedBounds;
      }
    }

    navigateToSecondarySearch(geoSearchOption, "/secondarySearch");
  };

  return {
    toMiniSearchBar,
    isOpenArea,
    isOpenLocation,
    isOpenService,
    selectedArea,
    clearSelectedAria,
    clearSelectedService,
    handleChange,
    handleInputChange,
    toggleOptionArea,
    toggleOptionService,
    togglingArea,
    setIsOpenArea,
    togglingLocation,
    location,
    selectedAreas,
    selectedServices,
    togglingService,
    searchBig,
    showOptions,
    selectedService,
    settingDatas,
    options,
    error,
    setError,
    setLocation,
    setSelectedOption,
  };
};

export default useSearchBarHooks;
