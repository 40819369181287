import React from "react";
import styled from "styled-components";
import GreenSubHeader from "../../components/ui/GreenSubHeader";

const PolitiqueConfidentialite = (props) => {
  return (
    <Body>
      <GreenSubHeader>{props.title}</GreenSubHeader>
      <DivConditionsGenerales>
        <p>
          <h2>ARTICLE 1 : PR&Eacute;AMBULE</h2> <br />
          La pr&eacute;sente politique de confidentialit&eacute; a pour but
          d&rsquo;informer les utilisateurs du site : <br />
          &bull; Sur la mani&egrave;re dont sont collect&eacute;es leurs
          donn&eacute;es personnelles. Sont consid&eacute;r&eacute;es comme des
          donn&eacute;es personnelles, toute information permettant
          d&rsquo;identifier un utilisateur. A ce titre, il peut s&rsquo;agir :
          de ses noms et pr&eacute;noms, de son adresse postale ou email, de sa
          localisation ou encore de son adresse IP (liste non-exhaustive) ;{" "}
          <br />
          &bull; Sur les droits dont ils disposent concernant ces donn&eacute;es
          ; <br />
          &bull; Sur la personne responsable du traitement des donn&eacute;es
          &agrave; caract&egrave;re personnel collect&eacute;es et
          trait&eacute;es ; <br />
          &bull; Sur les destinataires de ces donn&eacute;es personnelles ;{" "}
          <br />
          &bull; Sur la politique du site en mati&egrave;re de cookies. <br />
          Cette politique compl&egrave;te les mentions l&eacute;gales et les
          Conditions G&eacute;n&eacute;rales d&rsquo;Utilisation consultables
          par les utilisateurs &agrave; l&rsquo;adresse suivante : <br />
          https://www.monprestatairelogistique.com/conditions_generales
          <br />
          <h2>
            ARTICLE 2 : PRINCIPES RELATIFS &Agrave; LA COLLECTE ET AU TRAITEMENT
            DES DONN&Eacute;ES PERSONNELLES
          </h2>{" "}
          <br />
          Conform&eacute;ment &agrave; l&rsquo;article 5 du R&egrave;glement
          europ&eacute;en 2016/679, les donn&eacute;es &agrave; caract&egrave;re
          personnel sont : <br />
          &bull; Trait&eacute;es de mani&egrave;re licite, loyale et
          transparente au regard de la personne concern&eacute;e ; <br />
          &bull; Collect&eacute;es pour des finalit&eacute;s
          d&eacute;termin&eacute;es (cf. Article 3.1 des pr&eacute;sentes),
          explicites et l&eacute;gitimes, et ne pas &ecirc;tre trait&eacute;es
          ult&eacute;rieurement d'une mani&egrave;re incompatible avec ces
          finalit&eacute;s ; <br />
          &bull; Ad&eacute;quates, pertinentes et limit&eacute;es &agrave; ce
          qui est n&eacute;cessaire au regard des finalit&eacute;s pour
          lesquelles elles sont trait&eacute;es ; <br />
          &bull; Exactes et, si n&eacute;cessaire, tenues &agrave; jour. Toutes
          les mesures raisonnables doivent &ecirc;tre prises pour que les
          donn&eacute;es &agrave; caract&egrave;re personnel qui sont inexactes,
          eu &eacute;gard aux finalit&eacute;s pour lesquelles elles sont
          trait&eacute;es, soient effac&eacute;es ou rectifi&eacute;es sans
          tarder ; <br />
          &bull; Conserv&eacute;es sous une forme permettant l'identification
          des personnes concern&eacute;es pendant une dur&eacute;e
          n'exc&eacute;dant pas celle n&eacute;cessaire au regard des
          finalit&eacute;s pour lesquelles elles sont trait&eacute;es ; <br />
          &bull; Trait&eacute;es de fa&ccedil;on &agrave; garantir une
          s&eacute;curit&eacute; appropri&eacute;e des donn&eacute;es
          collect&eacute;es, y compris la protection contre le traitement non
          autoris&eacute; ou illicite et contre la perte, la destruction ou les
          d&eacute;g&acirc;ts d'origine accidentelle, &agrave; l'aide de mesures
          techniques ou organisationnelles appropri&eacute;es.
        </p>

        <p>
          Le traitement n'est licite que si, et dans la mesure o&ugrave;, au
          moins une des conditions suivantes est remplie : <br />
          &bull; La personne concern&eacute;e a consenti au traitement de ses
          donn&eacute;es &agrave; caract&egrave;re personnel pour une ou
          plusieurs finalit&eacute;s sp&eacute;cifiques ;<br />
          &bull; Le traitement est n&eacute;cessaire &agrave; l'ex&eacute;cution
          d'un contrat auquel la personne concern&eacute;e est partie ou
          &agrave; l'ex&eacute;cution de mesures pr&eacute;contractuelles prises
          &agrave; la demande de celle-ci ; <br />
          &bull; Le traitement est n&eacute;cessaire au respect d'une obligation
          l&eacute;gale &agrave; laquelle le responsable du traitement est
          soumis ; <br />
          &bull; Le traitement est n&eacute;cessaire &agrave; la sauvegarde des
          int&eacute;r&ecirc;ts vitaux de la personne concern&eacute;e ou d'une
          autre personne physique ; <br />
          &bull; Le traitement est n&eacute;cessaire &agrave; l'ex&eacute;cution
          d'une mission d'int&eacute;r&ecirc;t public ou relevant de l'exercice
          de l'autorit&eacute; publique dont est investi le responsable du
          traitement ; <br />
          &bull; Le traitement est n&eacute;cessaire aux fins des
          int&eacute;r&ecirc;ts l&eacute;gitimes poursuivis par le responsable
          du traitement ou par un tiers, &agrave; moins que ne pr&eacute;valent
          les int&eacute;r&ecirc;ts ou les libert&eacute;s et droits
          fondamentaux de la personne concern&eacute;e qui exigent une
          protection des donn&eacute;es &agrave; caract&egrave;re personnel,
          notamment lorsque la personne concern&eacute;e est un enfant.
        </p>

        <h2>
          ARTICLE 3 : DONN&Eacute;ES &Agrave; CARACT&Egrave;RE PERSONNEL
          COLLECT&Eacute;ES ET TRAIT&Eacute;ES DANS LE CADRE DE LA NAVIGATION
          SUR LE SITE
        </h2>

        <p>
          <h2>Article 3.1 : Donn&eacute;es collect&eacute;es </h2>
          Les donn&eacute;es personnelles collect&eacute;es dans le cadre de
          notre activit&eacute; sont les suivantes : <br />
          Nom/Pr&eacute;nom ; Nom entreprise ; Secteur d&rsquo;activit&eacute; ;
          N&deg; de Siret ; Adresse e-mail ; Num&eacute;ro de
          t&eacute;l&eacute;phone ; Adresse
          <br />
          La collecte et le traitement de ces donn&eacute;es r&eacute;pond
          &agrave; la (aux) finalit&eacute;(s) suivante(s) : <br />
          - Permettre aux outils de benchmark et de cr&eacute;ation de cahier
          des charges
          <br />
          - Permettre le suivi et la gestion d&rsquo;indicateurs
          <br />
          Article 3.3 : H&eacute;bergement des donn&eacute;es <br />
          Le site www.monprestatairelogistique.com est h&eacute;berg&eacute; par
          : <br />
          La soci&eacute;t&eacute; OVH SAS
          <br />
          2 rue Kellermann - 59100 Roubaix - France <br />
          https://www.ovhcloud.com/fr/
        </p>

        <h2>
          ARTICLE 4 : RESPONSABLE DU TRAITEMENT DES DONN&Eacute;ES ET
          D&Eacute;L&Eacute;GU&Eacute; &Agrave; LA PROTECTION DES DONN&Eacute;ES
        </h2>

        <p>
          <h3>Article 4.1 : Le responsable du traitement des donn&eacute;es</h3>
          Les donn&eacute;es &agrave; caract&egrave;re personnelles sont
          collect&eacute;es par Monprestatairelogistique.com, SASau capital de
          1000&euro; dont le num&eacute;ro d&rsquo;immatriculation est le 801
          407 453. <br />
          Le responsable du traitement des donn&eacute;es &agrave;
          caract&egrave;re personnel peut &ecirc;tre contact&eacute; de la
          mani&egrave;re suivante : <br />
          Par courrier &agrave; l&rsquo;adresse : 66, avenue des
          Champs-&Eacute;lys&eacute;es ; <br />
          Par mail : contact@monprestatairelogistique.com.
        </p>

        <h3>
          Article 4.2 : Le d&eacute;l&eacute;gu&eacute; &agrave; la protection
          des donn&eacute;es
        </h3>

        <p>
          Le d&eacute;l&eacute;gu&eacute; &agrave; la protection des
          donn&eacute;es de l&rsquo;entreprise ou du responsable est :
        </p>

        <p>Mr Louis-Arnaud Nguyen, louisarnaud@monprestatairelogistique.com</p>

        <p>
          Si vous estimez, apr&egrave;s nous avoir contact&eacute;s, que vos
          droits &ldquo;Informatique et Libert&eacute;s&rdquo;, ne sont pas
          respect&eacute;s, vous pouvez adresser une information &agrave; la
          CNIL.
        </p>

        <h2>
          ARTICLE 5 : LES DROITS DE L&rsquo;UTILISATEUR EN MATI&Egrave;RE DE
          COLLECTE ET DE TRAITEMENT DES DONN&Eacute;ES
        </h2>
        <p>
          Tout utilisateur concern&eacute; par le traitement de ses
          donn&eacute;es personnelles peut se pr&eacute;valoir des droits
          suivants, en application du r&egrave;glement europ&eacute;en 2016/679
          et de la Loi Informatique et Libert&eacute; (Loi 78-17 du 6 janvier
          1978) : <br />
          &bull; Droit d&rsquo;acc&egrave;s, de rectification et droit &agrave;
          l&rsquo;effacement des donn&eacute;es (pos&eacute;s respectivement aux
          articles 15, 16 et 17 du RGPD) ;
        </p>

        <p>
          &bull; Droit &agrave; la portabilit&eacute; des donn&eacute;es
          (article 20 du RGPD) ;
        </p>

        <p>
          &bull; Droit &agrave; la limitation (article 18 du RGPD) et &agrave;
          l&rsquo;opposition du traitement des donn&eacute;es (article 21 du
          RGPD) ;
        </p>

        <p>
          &bull; Droit de ne pas faire l&rsquo;objet d&rsquo;une d&eacute;cision
          fond&eacute;e exclusivement sur un proc&eacute;d&eacute;
          automatis&eacute; ;
        </p>

        <p>
          &bull; Droit de d&eacute;terminer le sort des donn&eacute;es
          apr&egrave;s la mort ;
        </p>

        <p>
          &bull; Droit de saisir l&rsquo;autorit&eacute; de contr&ocirc;le
          comp&eacute;tente (article 77 du RGPD). <br />
          Pour exercer vos droits, veuillez adresser votre courrier &agrave;
          Monprestatairelogistique.com, 66 avenue des champs Elys&eacute;es,
          75008 ou par mail &agrave; contact@monprestatairelogistique.com
          <br />
          Afin que le responsable du traitement des donn&eacute;es puisse faire
          droit &agrave; sa demande, l&rsquo;utilisateur peut &ecirc;tre tenu de
          lui communiquer certaines informations telles que : ses noms et
          pr&eacute;noms, son adresse e-mail ainsi que son num&eacute;ro de
          compte. <br />
          Consultez le site cnil.fr pour plus d&rsquo;informations sur vos
          droits.
        </p>

        <h2>
          ARTICLE 6 : CONDITIONS DE MODIFICATION DE LA POLITIQUE DE
          CONFIDENTIALIT&Eacute;
        </h2>

        <p>
          L&rsquo;&eacute;diteur du site Monprestatairelogistique.com se
          r&eacute;serve le droit de pouvoir modifier la pr&eacute;sente
          Politique &agrave; tout moment afin d&rsquo;assurer aux utilisateurs
          du site sa conformit&eacute; avec le droit en vigueur. <br />
          Les &eacute;ventuelles modifications ne sauraient avoir
          d&rsquo;incidence sur les achats ant&eacute;rieurement
          effectu&eacute;s sur le site, lesquels restent soumis &agrave; la
          Politique en vigueur au moment de l&rsquo;achat et telle
          qu&rsquo;accept&eacute;e par l&rsquo;utilisateur lors de la validation
          de l&rsquo;achat. <br />
          L&rsquo;utilisateur est invit&eacute; &agrave; prendre connaissance de
          cette Politique &agrave; chaque fois qu&rsquo;il utilise nos services,
          sans qu&rsquo;il soit n&eacute;cessaire de l&rsquo;en pr&eacute;venir
          formellement. <br />
          La pr&eacute;sente politique, &eacute;dit&eacute;e le 15/06/2023 a
          &eacute;t&eacute; mise &agrave; jour le 15/06/2023.
          <br />
        </p>
      </DivConditionsGenerales>
    </Body>
  );
};

/* j'ai créé "height" seulement pour bien affichage de cette page sans contenu */
const Body = styled.div`
  width: 100%;
  margin-bottom: 120px;
`;

const DivConditionsGenerales = styled.div`
  padding: 50px 56px;
  h2 {
    color: black;
    font-size: 40px;
    font-weight: 400;
  }
  h3 {
    color: black;
    font-size: 30px;
    font-weight: 400;
  }
  p {
    color: black;
    font-size: 18px;
  }
  @media (max-width: 768px) {
    padding: 50px 24px;
    h2 {
      font-size: 30px;
    }
    h3 {
      font-size: 25px;
    }
    p {
      font-size: 16px;
    }
  }

  @media (max-width: 480px) {
    padding: 25px 40px;
  }
`;

export default PolitiqueConfidentialite;
