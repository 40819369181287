import { useContext, useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import styled from "styled-components";
import excelDownload from "../../../assets/images/excel-download.png";
import {
  cmdnbrOptions,
  contractMinOptions,
  maxcapacityOptions,
  paletnbrOptions,
  pcknbrOptions,
  preparednbrOptions,
  receivenbrOptions,
  refnbrOptions,
  shippingnbrOptions,
} from "../../../constants/listingsOptions";
import { FILTER_SPEC } from "../../../constants/specificity";
import { SearchContext } from "../../../context/SearchContext";

export default function CompareCSVDownload({ listings }) {
  const [data, setData] = useState([]);
  const { settingDatas } = useContext(SearchContext);

  useEffect(() => {
    const newData = [];
    newData.push(["", ...listings.map((x) => x.user.company)]);
    newData.push([
      "Localisation des sites",
      ...listings.map((x) =>
        x.caracteristics.workingPlaces.map((c) => c.label).join("\n")
      ),
    ]);
    newData.push([
      "Secteur",
      ...listings.map((x) =>
        x.caracteristics.workingDomains.map((c) => c.label).join("\n")
      ),
    ]);
    newData.push([
      "Langues",
      ...listings.map((x) =>
        x.caracteristics.languages.map((c) => c.label).join("\n")
      ),
    ]);
    newData.push(["Caractéristiques", ...listings.map((x) => "")]);
    newData.push([
      "Capacité de stockage en palettes",
      ...listings.map((x) =>
        x.caracteristics.capacity_palet
          ?.map((c) => maxcapacityOptions.find((o) => o.value === c)?.label)
          .join("\n")
      ),
    ]);
    newData.push([
      "Nombre expedition par jour",
      ...listings.map((x) =>
        x.caracteristics.nbr_expedition_day
          ?.map((c) => shippingnbrOptions.find((o) => o.value === c)?.label)
          .join("\n")
      ),
    ]);
    newData.push([
      "Nombre de preparation par jour",
      ...listings.map((x) =>
        x.caracteristics.nbr_prepared_day
          ?.map((c) => preparednbrOptions.find((o) => o.value === c)?.label)
          .join("\n")
      ),
    ]);
    newData.push([
      "Nombre de commande par jour",
      ...listings.map((x) =>
        x.caracteristics.nbr_cmd_day
          ?.map((c) => cmdnbrOptions.find((o) => o.value === c)?.label)
          .join("\n")
      ),
    ]);
    newData.push([
      "Nombre palette par jour",
      ...listings.map((x) =>
        x.caracteristics.nbr_palet_day
          ?.map((c) => paletnbrOptions.find((o) => o.value === c)?.label)
          .join("\n")
      ),
    ]);
    newData.push([
      "Nombre colis par jour",
      ...listings.map((x) =>
        x.caracteristics.nbr_packs_day
          ?.map((c) => pcknbrOptions.find((o) => o.value === c)?.label)
          .join("\n")
      ),
    ]);
    newData.push([
      "Nombre réception par jour",
      ...listings.map((x) =>
        x.caracteristics.nbr_recv_day
          ?.map((c) => receivenbrOptions.find((o) => o.value === c)?.label)
          .join("\n")
      ),
    ]);
    newData.push([
      "Nombre Références produits",
      ...listings.map((x) =>
        x.caracteristics.nbr_ref_products
          ?.map((c) => refnbrOptions.find((o) => o.value === c)?.label)
          .join("\n")
      ),
    ]);
    newData.push([
      "Durée contrat min.",
      ...listings.map((x) =>
        x.caracteristics.min_contract_delay
          ?.map((c) => contractMinOptions.find((o) => o.value === c)?.label)
          .join("\n")
      ),
    ]);
    newData.push([
      "Délai de livraison min. (heure)",
      ...listings.map((x) => x.caracteristics.min_delivery_delay),
    ]);
    newData.push(["Prestation", ...listings.map((x) => "")]);
    newData.push(["Stockage", ...listings.map((x) => "")]);
    settingDatas?.stockageGigTypes.map((x) =>
      newData.push([
        x.label,
        ...listings.map((l) => {
          if (
            l.logistics.storage_gigs.findIndex((s) => s.code === x.code) !== -1
          )
            return "✅";
          else return "❌";
        }),
      ])
    );
    newData.push(["Interface", ...listings.map((x) => "")]);
    settingDatas?.interfaceGigTypes.map((x) =>
      newData.push([
        x.label,
        ...listings.map((l) => {
          if (
            l.logistics.interfacing_gigs.findIndex((s) => s.code === x.code) !==
            -1
          )
            return "✅";
          else return "❌";
        }),
      ])
    );
    newData.push(["Gestion", ...listings.map((x) => "")]);
    settingDatas?.logisticGigTypes.map((x) =>
      newData.push([
        x.label,
        ...listings.map((l) => {
          if (
            l.logistics.products_base_gigs.findIndex(
              (s) => s.code === x.code
            ) !== -1
          )
            return "✅";
          else return "❌";
        }),
      ])
    );
    newData.push(["Expedition", ...listings.map((x) => "")]);
    settingDatas?.expeditionGigTypes.map((x) =>
      newData.push([
        x.label,
        ...listings.map((l) => {
          if (
            l.logistics.shipping_gigs.findIndex((s) => s.code === x.code) !== -1
          )
            return "✅";
          else return "❌";
        }),
      ])
    );
    newData.push(["Emballage", ...listings.map((x) => "")]);
    settingDatas?.emballageGigTypes.map((x) =>
      newData.push([
        x.label,
        ...listings.map((l) => {
          if (
            l.logistics.packaging_gigs.findIndex((s) => s.code === x.code) !==
            -1
          )
            return "✅";
          else return "❌";
        }),
      ])
    );
    newData.push(["Réception", ...listings.map((x) => "")]);
    settingDatas?.receptionGigTypes.map((x) =>
      newData.push([
        x.label,
        ...listings.map((l) => {
          if (
            l.logistics.receive_gigs.findIndex((s) => s.code === x.code) !== -1
          )
            return "✅";
          else return "❌";
        }),
      ])
    );
    newData.push(["Préparation", ...listings.map((x) => "")]);
    settingDatas?.preparationGigTypes.map((x) =>
      newData.push([
        x.label,
        ...listings.map((l) => {
          if (
            l.logistics.prepare_gigs.findIndex((s) => s.code === x.code) !== -1
          )
            return "✅";
          else return "❌";
        }),
      ])
    );
    newData.push(["Transport", ...listings.map((x) => "")]);
    settingDatas?.transportGigTypes.map((x) =>
      newData.push([
        x.label,
        ...listings.map((l) => {
          if (
            l.logistics.transportation_gigs.findIndex(
              (s) => s.code === x.code
            ) !== -1
          )
            return "✅";
          else return "❌";
        }),
      ])
    );
    const newClientRefs = [];
    for (const listing of listings) {
      const domains = listing.clientsRefs.map(
        (client) => client.workingDomains
      );
      for (const domain of domains) {
        const i = newClientRefs.findIndex((x) => x.code === domain.code);
        if (i === -1)
          newClientRefs.push({
            code: domain.code,
            label: domain.label,
            clients: [],
          });
      }
    }
    for (const listing of listings) {
      for (const ref of newClientRefs) {
        const listingsByCode = listing.clientsRefs.filter(
          (x) => x.workingDomains.code === ref.code
        );
        const names = listingsByCode.map((x) => x.name);
        ref.clients.push({ listingId: listing.id, names });
      }
    }
    newData.push(["Référence clients", ...listings.map((x) => "")]);
    newClientRefs.map((x) =>
      newData.push([
        x.code,
        ...listings.map((l) =>
          x.clients.find((c) => c.listingId === l.id)?.names.join("\n")
        ),
      ])
    );
    newData.push(["Spécificités", ...listings.map((x) => "")]);
    newData.push(["Gestion annexe", ...listings.map((x) => "")]);
    settingDatas?.specTypes
      .filter((x) => FILTER_SPEC.includes(x.code))
      .map((x) =>
        newData.push([
          x.label,
          ...listings.map((l) => {
            if (
              l.specifics.spec.findIndex((s) => s.type.code === x.code) !== -1
            )
              return "✅";
            else return "❌";
          }),
        ])
      );
    newData.push(["Typologie de chariot", ...listings.map((x) => "")]);
    settingDatas?.transportTypology.map((x) =>
      newData.push([
        x.label,
        ...listings.map((l) => {
          if (
            l.specifics.transportTypology.findIndex(
              (s) => s.code === x.code
            ) !== -1
          )
            return "✅";
          else return "❌";
        }),
      ])
    );
    newData.push(["Type de matériel", ...listings.map((x) => "")]);
    settingDatas?.materialTypes.map((x) =>
      newData.push([
        x.label,
        ...listings.map((l) => {
          if (
            l.specifics.materialTypes.findIndex((s) => s.code === x.code) !== -1
          )
            return "✅";
          else return "❌";
        }),
      ])
    );
    newData.push(["Développement", ...listings.map((x) => "")]);
    settingDatas?.developmentTypes.map((x) =>
      newData.push([
        x.label,
        ...listings.map((l) => {
          if (
            l.specifics.development.findIndex((s) => s.type.code === x.code) !==
            -1
          )
            return "✅";
          else return "❌";
        }),
      ])
    );
    newData.push(["IT", ...listings.map((x) => "")]);
    settingDatas?.techTypes
      .filter((x) => x.code !== "ECOM")
      .map((x) =>
        newData.push([
          x.label,
          ...listings.map((l) => {
            if (
              l.specifics.tech
                .filter((x) => x.type.code !== "ECOM")
                .findIndex((s) => s.type.code === x.code) !== -1
            )
              return "✅";
            else return "❌";
          }),
        ])
      );
    newData.push(["Plateforme e-commerce", ...listings.map((x) => "")]);
    settingDatas?.ecommerceTypes.map((x) =>
      newData.push([
        x.label,
        ...listings.map((l) => {
          if (
            l.specifics.ecommerceTypes.findIndex((s) => s.code === x.code) !==
            -1
          )
            return "✅";
          else return "❌";
        }),
      ])
    );
    newData.push(["Certifications", ...listings.map((x) => "")]);
    settingDatas?.certificationTypes.map((x) =>
      newData.push([
        x.label,
        ...listings.map((l) => {
          if (
            l.specifics.certification.findIndex(
              (s) => s.type.code === x.code
            ) !== -1
          )
            return "✅";
          else return "❌";
        }),
      ])
    );
    newData.push(["Agréments", ...listings.map((x) => "")]);
    settingDatas?.approvalTypes.map((x) =>
      newData.push([
        x.label,
        ...listings.map((l) => {
          if (
            l.specifics.approval.findIndex((s) => s.type.code === x.code) !== -1
          )
            return "✅";
          else return "❌";
        }),
      ])
    );

    setData(newData);
  }, [listings]);
  return (
    <Container>
      <CSVLink data={data} filename="Comparateur Monprestataire.csv">
        <ExcelIcon alt="excel-download" src={excelDownload} />
      </CSVLink>
    </Container>
  );
}

const Container = styled.div`
  width: 40px;
  height: 50px;
`;
const ExcelIcon = styled.img`
  width: 40px;
  height: 50px;
`;
