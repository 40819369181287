import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import jwtDecode from "jwt-decode";
import { useCallback, useContext, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { ROLES } from "../../../constants/Roles";
import { AuthContext } from "../../../context/AuthContext";
import LOGIN from "../../../queries/login/login";
import { getIp } from "../../../utils/localization";
import Alert from "../../ui/Alert";
import CustomButton from "../../ui/CustomButton";
import CustomInput from "../../ui/CustomInput";
import ModalTw from "../index";

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Doit être une adresse email valide")
    .required("Adresse mail est obligatoire"),
  password: yup.string().required("Mot de passe est obligatoire"),
});

const ModalLogin = ({ onClose, toggleForgetPasswordForm }) => {
  const [login, { loading, error }] = useMutation(LOGIN);
  const { sessionId, dispatch } = useContext(AuthContext);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const resetPassword = useCallback(() => {
    toggleForgetPasswordForm();
    onClose();
  }, [onClose, toggleForgetPasswordForm]);

  const onSubmit = useCallback(
    async (values) => {
      const { data } = await login({
        variables: {
          ...values,
          email: values.email.toLowerCase(),
          host: getIp(),
          sessionId,
        },
      });

      const { user, accessToken, refreshToken } = data.login;

      dispatch({
        type: "LOGIN_SUCCESS",
        payload: {
          token: accessToken,
          refreshToken,
          user: {
            ...jwtDecode(accessToken),
            id: user.id,
            listingNumber: user.listingNumber,
            subscriptions: user.subscriptions ?? [],
            role: user?.role,
            company: user?.company,
            name: user?.name,
          },
        },
      });

      if (user?.role === ROLES.LOGISTICIAN) {
        navigate("/dashboard/listings");
      }
      onClose();
    },
    [dispatch, login, navigate, onClose, sessionId]
  );

  const content = useMemo(() => {
    return (
      <div>
        {!loading && error && (
          <Alert message="Email ou mot de passe incorrect" type="danger" />
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-2">
            <CustomInput
              error={errors.email}
              name="email"
              placeholder="Adresse mail"
              register={register}
            />
            <CustomInput
              error={errors.password}
              name="password"
              placeholder="Mot de passe"
              register={register}
              type="password"
            />
          </div>

          <div className="mt-5 flex flex-col gap-3">
            <CustomButton isFull isLoading={loading} type="submit">
              Je me connecte
            </CustomButton>
            <button
              className="underline underline-offset-2"
              onClick={resetPassword}
            >
              Mot de passe oublié ?
            </button>
          </div>
        </form>
      </div>
    );
  }, [
    error,
    errors.email,
    errors.password,
    handleSubmit,
    loading,
    onSubmit,
    register,
    resetPassword,
  ]);

  return <ModalTw content={content} title="Connexion" onClose={onClose} />;
};

export default ModalLogin;
