import { gql } from "@apollo/client";

export const CitiesSearch = gql`
  query CitiesSearch(
    $query: String!
    $options: String
    $regional: Boolean
    $isCountryOnly: Boolean
  ) {
    CitiesSearch(
      query: $query
      options: $options
      regional: $regional
      isCountryOnly: $isCountryOnly
    ) {
      label
      value
    }
  }
`;

export const SEARCH_LOCATION = gql`
  query searchLocation($address: String!) {
    searchLocation(address: $address) {
      label
      value
      administrative_area
      country
      postal_code
      type
      isCountry
    }
  }
`;

export const CitiesSearchWithBounds = gql`
  query CitiesSearchWithBounds($address: String!) {
    CitiesSearchWithBounds(address: $address) {
      formatted_address
      place_id
      geometry {
        location {
          lat
          lng
        }
      }
    }
  }
`;

export const GeoSearch = gql`
  query GeoSearch($placeId: String!) {
    GeoSearch(placeId: $placeId) {
      bounds
    }
  }
`;

export const GET_AROUND_ME = gql`
  query getAroundMe($lat: Float!, $lng: Float!) {
    getAroundMe(lat: $lat, lng: $lng)
  }
`;
