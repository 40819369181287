import Question from "../../../components/items/Question";
import CustomButton from "../../../components/ui/CustomButton";

export default function FAQSection() {
  return (
    <div className="my-7 lg:my-0 lg:grid lg:grid-cols-2 lg:gap-6 lg:gap-x-[50px]">
      <div className="lg:mt-16 xl:col-span-1">
        <h1 className="font-roboto text-xl font-bold text-mplgreen md:text-[40px] md:leading-normal lg:font-roboto-regular xl:w-[530px] xl:text-[60px] xl:leading-[70px]">
          FAQ - Nos réponses à vos questions
        </h1>
        <p className="font-roboto lg:font-roboto-regular lg:text-xl lg:font-bold xl:my-5 xl:text-2xl">
          LES QUESTIONS FRÉQUENTES
        </p>
      </div>
      <div className="mt-6 flex flex-col items-center gap-4 lg:col-span-1">
        <Question />
        <CustomButton
          className="lg:w-[310px] lg:self-center lg:p-4 lg:text-xl"
          to="/faq"
        >
          Voir plus
        </CustomButton>
      </div>
    </div>
  );
}
