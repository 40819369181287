import { useNavigate } from "react-router-dom";
import CustomButton from "../../ui/CustomButton";
import ModalTw from "../index";

const ModalTenderNoticeSuccess = () => {
  const navigate = useNavigate();

  return (
    <ModalTw
      closable={false}
      footer={
        <CustomButton
          isFull
          onClick={() =>
            navigate("/", {
              replace: true,
            })
          }
        >
          Retour à l’accueil
        </CustomButton>
      }
      title="Votre appel d’offre a bien été transmis à nos équipes."
    />
  );
};

export default ModalTenderNoticeSuccess;
