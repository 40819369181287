function ArticleItem({ image, tags, date, title, link }) {
  return (
    <div className="relative border border-gray-200 p-2 md:max-w-[492px] xl:flex xl:flex-1 xl:flex-col xl:p-4">
      <div className="relative">
        <a href={link}>
          <img
            alt="article"
            className="max-h-min w-full object-cover lg:max-h-[170px]"
            src={image}
          />
        </a>
        <div className="absolute right-2 top-2 flex gap-3">
          {tags.map((tag, index) => (
            <div
              key={index}
              className="rounded-xl bg-mplgreen px-2 py-1 text-sm text-white"
            >
              {tag}
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-col gap-3 lg:grow">
        <p className="text-left text-[10px] text-gray-400 lg:my-5 lg:ml-4 lg:text-base lg:italic">
          {date}
        </p>
        <div>
          <h3 className="justify-center text-center font-roboto-regular font-semibold text-black lg:text-left lg:text-2xl">
            {title}
          </h3>
        </div>
        <a
          className="mt-auto text-left text-xs font-semibold text-mplgreen lg:mb-4 lg:text-base lg:leading-normal"
          href={link}
        >
          Lire plus
        </a>
      </div>
    </div>
  );
}

export default ArticleItem;
