import { useMemo } from "react";
import experienceIcon from "../../../assets/images/experienceIcon.png";
import expertStar from "../../../assets/images/expertStar.png";
import forklift from "../../../assets/images/forklift.png";
import CustomButton from "../../../components/ui/CustomButton";
import GreenButton from "../../../components/ui/GreenButton";

export default function LogisticsExpertiseSection() {
  const advantages = useMemo(() => {
    return [
      {
        logo: expertStar,
        text: "Des experts dédiés pour vous accompagner",
      },
      {
        logo: experienceIcon,
        text: "Optimisez vos demandes de devis avec notre messagerie",
      },
    ];
  }, []);

  return (
    <div>
      <div className="my-3 lg:my-16">
        <img
          alt="forklift"
          className="-mx-6 w-screen max-w-none object-cover md:hidden"
          src={forklift}
        />
        <div className="grid grid-cols-1 items-center justify-center py-10 md:grid-cols-2 md:gap-x-[50px] lg:mb-16 lg:flex-row-reverse lg:gap-0 lg:p-0">
          <GreenButton className="md:hidden" to="/expert">
            Parler à un expert
          </GreenButton>
          <div className="md:col-span-1">
            <img
              alt="forklift"
              className="hidden md:block md:object-contain"
              src={forklift}
            />
          </div>
          <div className="mt-7 flex flex-col items-center justify-center gap-3 md:col-span-1 md:mr-10 lg:pl-10 xl:mt-0 xl:flex-1 xl:items-start xl:justify-center xl:gap-0 xl:pl-10">
            <h2 className="font-roboto text-xl font-bold text-mplgreen md:text-left md:text-[38px] md:leading-normal lg:font-roboto-regular xl:w-[570px] xl:text-left xl:text-[55px] xl:leading-[64px]">
              Votre logistique : notre expertise
            </h2>
            <p className="font-roboto-regular lg:my-7 lg:text-xl">
              Que vous soyez un prestataire logistique ou une entreprise,
              bénéficiez de notre large réseau de partenaires stratégiques et de
              notre expertise pour vous accompagner.
            </p>
            <CustomButton
              className="hidden self-start text-center md:block lg:w-[310px] lg:self-center lg:p-4 lg:text-xl"
              to="/expert"
            >
              Parler à un expert
            </CustomButton>
          </div>
        </div>
        <div className="mb-5 flex flex-col gap-7 md:flex-row md:justify-evenly md:pt-3 lg:gap-0">
          {advantages.map((advantage) => (
            <div
              key={advantage.text}
              className="flex flex-1 items-center gap-4 font-roboto font-bold md:flex-initial md:gap-0"
            >
              <img
                alt="cycleIcon"
                className="w-16 md:w-[60px] lg:w-[111px]"
                src={advantage.logo}
              />
              <p className="font-roboto-regular text-base md:ml-5 md:w-[210px] lg:w-[253px] lg:text-xl">
                {advantage.text}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
