import { IonIcon } from "@ionic/react";
import { informationCircleOutline } from "ionicons/icons";
import styled from "styled-components";
import { css } from "styled-components/macro";
import tw from "tailwind-styled-components";
import { callAll } from "../../utils/function";
import TippyTooltip from "../TippyTooltip";

export default function CustomRadioButton({ title, register, tip, ...props }) {
  return (
    <Container>
      {title && (
        <label
          className="flex text-lg font-medium text-gray-900"
          style={props.width ? { width: props.width } : null}
        >
          <span>
            {title}{" "}
            {tip && (
              <TippyTooltip content={tip}>
                <IonIcon icon={informationCircleOutline} />
              </TippyTooltip>
            )}
          </span>
        </label>
      )}
      <label className="flex h-[33px] w-[130px] shrink-0 items-center justify-center rounded-2xl bg-[#F3F3F3] shadow-md">
        <HiddenCheckbox
          checked={props.checked}
          disabled={props.disabled}
          type="checkbox"
          {...register}
          onChange={callAll(props?.onChange, register?.onChange)}
        />
        <StyledCheckbox checked={props.checked}>
          <span>Oui</span>
          <span>Non</span>
        </StyledCheckbox>
      </label>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
`;

const HiddenCheckbox = tw.input`hidden`;
const StyledCheckbox = styled.div`
  ${({ checked }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    cursor: pointer;

    span {
      transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
      width: 50%;
      text-align: center;
      padding-left: 12px;
      padding-right: 12px;
      font-size: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: black;
      border-radius: 1rem;
      height: calc(100% - 2px);
      user-select: none;
    }

    span:nth-child(1) {
      background-color: ${checked ? "#1fc585" : "transparent"};
      font-weight: ${checked ? "bold" : "normal"};
      margin-left: 2px;
    }

    span:nth-child(2) {
      background-color: ${checked ? "transparent" : "#fff"};
      font-weight: ${!checked ? "bold" : "normal"};
      margin-right: 2px;
    }
  `}
`;
