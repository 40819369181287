import { IonIcon } from "@ionic/react";
import { search } from "ionicons/icons";
import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import logo from "../../../assets/images/Monprestatairelogistque.png";
import { AuthContext } from "../../../context/AuthContext";
import { NavContext } from "../../../context/NavContext";
import {
  isAuthenticated,
  isRoleCompany,
  isRoleLogistician,
} from "../../../utils/user";
import ModalForgotPassword from "../../ModalTW/ModalForgotPassword";
import ModalLogin from "../../ModalTW/ModalLogin";
import ModalPremium from "../../ModalTW/ModalPrenium";
import ModalRegister from "../../ModalTW/ModalRegister";
import ModalSubscription from "../../ModalTW/ModalSubscription";
import OutlinedButton from "../../OutlinedButton";
import useHeaderHook from "../hooks/headerHooks";
import BurgerMenuComponent from "./BurgerMenu";

const HeaderComponent = ({
  doSaveListingRef,
  doSaveSpecificationRef,
  setIsSearchBarVisible,
  isSearchBarVisible,
  displayBigSearchBar,
  handleDisplayBigSearchBar,
  setIsShowDropDown,
}) => {
  const { user } = useContext(AuthContext);
  const {
    isCreateSpec,
    isCreateListing,
    setIsCreateListing,
    setIsCreateSpec,
    location,
    selectedAreas,
    selectedServices,
    subscriptionParams,
    premiumParams,
    togglePremium,
    isPremiumShowed,
  } = useContext(NavContext);
  const navigate = useNavigate();
  const {
    isLoginFormShowed,
    isRegisterFormShowed,
    toggleLoginForm,
    toggleRegisterForm,
    isSubscriptionShowed,
    toggleSubscription,
    handleSearchBarVisible,
    isForgetPasswordFormShowed,
    toggleForgetPasswordForm,
  } = useHeaderHook({
    setIsSearchBarVisible,
    isSearchBarVisible,
    displayBigSearchBar,
    setIsShowDropDown,
  });
  const doSaveListing = () => {
    doSaveListingRef.doSaveListing("/dashboard/listings");
  };
  const doSaveSpecification = () => {
    doSaveSpecificationRef.saveSpecification("/dashboard/specifications");
  };

  const needSubscription = (link) => {
    if (user?.subscriptions?.length) {
      navigate(link);
    } else {
      if (user) {
        toggleSubscription();
      } else {
        toggleRegisterForm();
      }
    }
  };

  return (
    <>
      <div className="relative flex min-h-[50px] w-full items-center justify-between bg-black p-3 lg:min-h-[100px] lg:gap-7 lg:px-10 lg:py-5">
        <div className="flex items-center">
          <Link
            className="w-[102px] lg:mr-10 lg:w-[134px] xl:w-[200px]"
            to="/"
            onClick={() => setIsCreateSpec(false) && setIsCreateListing(false)}
          >
            <img alt="Logo Mon Prestataire" src={logo} />
          </Link>
          {!isCreateListing &&
            !isCreateSpec &&
            (!isAuthenticated(user) || isRoleCompany(user)) && (
              <div className="hidden xl:flex">
                <button onClick={() => navigate("/tender-notice/create")}>
                  <span className="font-roboto text-lg font-semibold text-white underline underline-offset-2">
                    Appel d'offres
                  </span>
                </button>
              </div>
            )}
        </div>
        {!isCreateListing && !isCreateSpec && (
          <button
            className="hidden w-[360px] max-w-[360px] items-center justify-between rounded-full bg-white px-2 py-1
            lg:flex lg:h-10"
            style={
              isSearchBarVisible && !displayBigSearchBar
                ? { visibility: "visible" }
                : { visibility: "hidden" }
            }
            onClick={() => handleDisplayBigSearchBar(true)}
          >
            {!location &&
            selectedAreas.length === 0 &&
            selectedServices.length === 0 ? (
              <TextSearchBar>Commencer ma recherche</TextSearchBar>
            ) : (
              <>
                <div className="ml-4 w-1/3 truncate text-sm text-gray-500">
                  {location ? location.label : "Localisation"}
                </div>
                <hr className="mx-4 h-5 w-[1px] border-l border-[#bbbbbb]" />
                <div className="w-1/3 truncate text-sm text-gray-500">
                  {selectedAreas.length > 0
                    ? `${selectedAreas[0].title} ${
                        selectedAreas.length > 1
                          ? `+${selectedAreas.length - 1}`
                          : ""
                      }`
                    : "Secteur"}
                </div>
                <hr className="mx-4 h-5 w-[1px] border-l border-[#bbbbbb]" />

                <div className="mr-4 w-1/3 truncate text-sm text-gray-500">
                  {selectedServices.length > 0
                    ? `${selectedServices[0].title} ${
                        selectedServices.length > 1
                          ? `+${selectedServices.length - 1}`
                          : ""
                      }`
                    : "Prestations"}
                </div>
              </>
            )}
            <SearchBarIcon onClick={handleSearchBarVisible}>
              <SearchIcon icon={search} />
            </SearchBarIcon>
          </button>
        )}
        <div className="flex items-center gap-3">
          <div className="hidden items-center gap-3 xl:flex">
            {!isCreateSpec && !isCreateListing && (
              <div className="hidden gap-3 lg:flex">
                <OutlinedButton color="white" to="/expert">
                  <span>Parler à un expert</span>
                </OutlinedButton>
                {isRoleCompany(user) && (
                  <OutlinedButton
                    color="green"
                    onClick={() => navigate("/dashboard/specification-intro")}
                  >
                    <span>Cahier des charges</span>
                  </OutlinedButton>
                )}

                {(!isAuthenticated(user) || isRoleLogistician(user)) && (
                  <OutlinedButton
                    color="green"
                    onClick={() =>
                      needSubscription(
                        user?.listingNumber > 0
                          ? "dashboard/listings"
                          : "/dashboard/listing-create"
                      )
                    }
                  >
                    <span>
                      {user?.listingNumber > 0
                        ? user?.listingNumber > 1
                          ? "Gérer mes annonces"
                          : "Gérer mon annonce"
                        : "Créer une annonce"}
                    </span>
                  </OutlinedButton>
                )}
              </div>
            )}
            {isRoleLogistician(user) && isCreateListing && (
              <OutlinedButton
                color="green"
                onClick={() => {
                  doSaveListing();
                }}
              >
                Enregistrer et quitter
              </OutlinedButton>
            )}
            {isRoleCompany(user) && isCreateSpec && (
              <OutlinedButton
                color="green"
                onClick={() => {
                  doSaveSpecification();
                }}
              >
                Enregistrer et quitter
              </OutlinedButton>
            )}
          </div>
          {!isCreateSpec && !isCreateListing && (
            <BurgerMenuComponent
              setIsShowDropDown={setIsShowDropDown}
              toggleLoginForm={toggleLoginForm}
              toggleRegisterForm={toggleRegisterForm}
            />
          )}
        </div>
      </div>

      {isLoginFormShowed && (
        <ModalLogin
          toggleForgetPasswordForm={toggleForgetPasswordForm}
          onClose={toggleLoginForm}
        />
      )}
      {isRegisterFormShowed && (
        <ModalRegister
          toggleLoginForm={toggleLoginForm}
          onClose={toggleRegisterForm}
        />
      )}
      {isForgetPasswordFormShowed && (
        <ModalForgotPassword onClose={toggleForgetPasswordForm} />
      )}

      {isPremiumShowed && (
        <ModalPremium onClose={togglePremium} {...premiumParams} />
      )}

      {isSubscriptionShowed && (
        <ModalSubscription
          modalParams={subscriptionParams}
          onClose={toggleSubscription}
        />
      )}
    </>
  );
};

const TextSearchBar = styled.p`
  font-size: 18px;
  color: grey;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  margin: 0 auto;
`;

const SearchBarIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 30px;
  min-height: 30px;
  background-color: #000;
  border: 1px solid #ffffff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
`;

const SearchIcon = styled(IonIcon)`
  font-size: 20px;
  color: #fff;
`;

export default HeaderComponent;
