import HeaderSection from "../../components/HeaderSection";
import MobileSearchBar from "../../components/MobileSearchBar";
import SearchBar from "../../components/others/searchBar/SearchBar";
import AdvantagesSection from "./sections/AdvantagesSection";
import FAQSection from "./sections/FAQSection";
import LatestArticlesSection from "./sections/LatestArticlesSection";
import LogisticsExpertiseSection from "./sections/LogisticsExpertiseSection";
import OptimizeLogisticsSection from "./sections/OptimizeLogisticsSection";
import WhyChooseUsSection from "./sections/WhyChooseUsSection";

function Home({ setDisplaySearchBar }) {
  return (
    <>
      <div className="flex flex-col items-center bg-black px-6 py-10 md:px-20 md:py-16 lg:px-0 lg:pb-[120px] lg:pt-14">
        <HeaderSection />
        <SearchBar />
        <MobileSearchBar />
        <AdvantagesSection />
      </div>
      <div className="flex flex-col px-6 lg:gap-20 lg:px-12">
        <OptimizeLogisticsSection
          handleDisplaySearchBar={setDisplaySearchBar}
        />
        <LogisticsExpertiseSection />
        <WhyChooseUsSection handleDisplaySearchBar={setDisplaySearchBar} />
        <FAQSection />
        <LatestArticlesSection />
      </div>
    </>
  );
}

export default Home;
