import PropTypes from "prop-types";
import { useContext } from "react";
import { ROLES } from "../../constants/Roles";
import { AuthContext } from "../../context/AuthContext";

function RequireAuth({ children1, children2, authType = "ALL" }) {
  const { user } = useContext(AuthContext);
  const isAuthenticated =
    user && (authType === "ALL" || authType === user?.role);

  return isAuthenticated ? children1 : children2;
}

RequireAuth.propTypes = {
  children1: PropTypes.node.isRequired,
  children2: PropTypes.oneOfType([PropTypes.node, PropTypes.exact(null)]),
  authType: PropTypes.oneOf([
    "ALL",
    ROLES.LOGISTICIAN,
    ROLES.COMPANY,
    ROLES.ADMIN,
  ]),
};

export default RequireAuth;
