import { useEffect, useState } from "react";

const useTailwindBreakpoint = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Function to update the width state when the window resizes
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isXsTw = width < 640;
  const isSmTw = width >= 640 && width < 768;
  const isMdTw = width >= 768 && width < 1024;
  const isLgTw = width >= 1024;
  const isSmall = width < 1200;

  return {
    width,
    isXsTw,
    isSmTw,
    isMdTw,
    isLgTw,
    isSmall,
    isLarge: isLgTw,
    isIpadPro: isLgTw && isSmall,
  };
};

export default useTailwindBreakpoint;
