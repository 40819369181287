import { useContext, useState } from "react";
import { NavContext } from "../../../context/NavContext";

const useLayoutHook = ({ setDisplayBigSearchBar }) => {
  const {
    isOpenService,
    setIsOpenService,
    isOpenArea,
    setIsOpenArea,
    isActive,
    isCreateSpec,
    isCreateListing,
  } = useContext(NavContext);

  const [isShowDropDown, setIsShowDropDown] = useState(false);

  const [isSearchBarVisible, setIsSearchBarVisible] = useState(false);

  const handleDisplayBigSearchBar = (isVisible) => {
    setDisplayBigSearchBar(isVisible);
  };

  const handleOutsideClick = () => {
    if ((isShowDropDown || isOpenService || isOpenArea) && !isActive) {
      setIsShowDropDown(false);
      setIsOpenService(false);
      setIsOpenArea(false);
    }
  };

  return {
    handleDisplayBigSearchBar,
    handleOutsideClick,
    setIsSearchBarVisible,
    isSearchBarVisible,
    isShowDropDown,
    setIsShowDropDown,
    isCreateSpec,
    isCreateListing,
  };
};

export default useLayoutHook;
