import { useMemo } from "react";
import useTailwindBreakpoint from "../../../hooks/useTailwindBreakpoint";
import CustomButton from "../../ui/CustomButton";
import ModalTw from "../index";

const ModalQuoteNotPremium = ({ onClose, onClick, unselectQuote }) => {
  const { isXsTw } = useTailwindBreakpoint();

  const content = useMemo(() => {
    return (
      <p>
        Pour y accéder vous devez être{" "}
        <span className="font-bold">Premium</span>
      </p>
    );
  }, []);

  return (
    <ModalTw
      closable={false}
      content={content}
      footer={
        <div className="flex gap-3">
          {isXsTw && (
            <CustomButton variant="outlined" onClick={unselectQuote}>
              Retour
            </CustomButton>
          )}
          <CustomButton isFull onClick={onClick}>
            Passer Premium
          </CustomButton>
        </div>
      }
      isFixed={false}
      title="Vous avez une demande de devis !"
      onClose={onClose}
    />
  );
};

export default ModalQuoteNotPremium;
