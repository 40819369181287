import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { SearchContext } from "../../../../context/SearchContext";

function useFilterHook() {
  const [receiveGig, setReceivegig] = useState([]);

  const [storageGig, setStoragegig] = useState([]);

  const [preparegig, setPreparegig] = useState([]);

  const [transportgig, setTransportgig] = useState([]);

  const [packagingGig, setPackaginggig] = useState([]);
  //   const packagingRef = useRefinementList({
  //       attribute: 'packaging_gigs.label',
  //   });

  const [interfacingGig, setInterfacinggig] = useState([]);

  const [productBaseGig, setProductBasegig] = useState([]);

  const [shippingGig, setShippinggig] = useState([]);
  const { settingDatas } = useContext(SearchContext);

  const [search] = useSearchParams();
  useEffect(() => {
    let queryFilter = search.get("filterhooks");
    queryFilter = JSON.parse(queryFilter);
    setReceivegig(queryFilter?.receiveGig ?? receiveGig);
    setStoragegig(queryFilter?.storageGig ?? storageGig);
    setPreparegig(queryFilter?.preparegig ?? preparegig);
    setTransportgig(queryFilter?.transportgig ?? transportgig);
    setPackaginggig(queryFilter?.packagingGig ?? packagingGig);
    setInterfacinggig(queryFilter?.interfacingGig ?? interfacingGig);
    setProductBasegig(queryFilter?.productBaseGig ?? productBaseGig);
    setShippinggig(queryFilter?.shippingGig ?? shippingGig);
  }, [search]);

  return {
    receiveGig,
    setReceivegig,
    interfacingGig,
    setInterfacinggig,
    packagingGig,
    setPackaginggig,
    transportgig,
    setTransportgig,
    shippingGig,
    setShippinggig,
    preparegig,
    setPreparegig,
    productBaseGig,
    setProductBasegig,
    storageGig,
    setStoragegig,
    settingDatas,
  };
}

export default useFilterHook;
