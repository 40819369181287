import { Suspense, lazy, useCallback, useContext, useMemo, useRef, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { NavProvider } from "../../context/NavContext";
import { SearchProvider } from "../../context/SearchContext";
import { UseRefreshUser } from "../../hooks/useRefreshUser";
import {
  isAuthenticated,
  isRoleCompany,
  isRoleLogistician,
} from "../../utils/user";
import Logout from "../../views/dashboard/Logout";
import PaymentMethods from "../../views/dashboard/payment-methods/payment-methods";
import Profile from "../../views/dashboard/profile/Profile";
import Quote from "../../views/dashboard/quote/Quote";
import Reporting from "../../views/dashboard/reporting/Reporting";
import Subscriptions from "../../views/dashboard/subscriptions/Subscriptions";
import ChangeSubscription from "../../views/dashboard/subscriptions/change-subscription";
import RevokeSubscription from "../../views/dashboard/subscriptions/revoke-subscription";
import FAQ from "../../views/faq/FAQ";
import ResetPassword from "../../views/forgotPassword/ResetPassword";
import Home from "../../views/home/Home";
import PolitiqueConfidentialite from "../../views/politiqueConfidentialite/PolitiqueConfidentialite";
import CreateQuote from "../../views/quote/Quote";
import QuoteSuccess from "../../views/quote/QuoteSuccess";
import SecondarySearch from "../../views/secondary_search/SecondarySearch";
import AddMessageSpecification from "../../views/specification/AddMessageSpecification";
import CongratsSpecification from "../../views/specification/CongratsSpecification";
import CorrespondingToSpecification from "../../views/specification/CorrespondingToSpecification";
import CreateSpecificationsIntro from "../../views/specification/CreateSpecificationsIntro";
import ListSpecification from "../../views/specification/ListSpecification";
import SubscriptionsHome from "../../views/subscriptions/subscriptionsHome";
import TenderNoticeDetails from "../TenderNoticeDetails";
import TenderNoticeForm from "../TenderNoticeForm";
import TenderNoticeList from "../TenderNoticeList";
import LayoutWrapper from "../layout/LayoutWrapper";
import RequireAuth from "../requireAuth/RequireAuth";
const Notifications = lazy(() => import("../../views/dashboard/notifications/Notifications"));
const CreateSpecifications = lazy(() => import("../../views/specification/CreateSpecifications"));
const KillAccount = lazy(() => import("../../views/dashboard/security/KillAccount"));
const PasswordEdit = lazy(() => import("../../views/dashboard/security/PasswordEdit"));
const Security = lazy(() => import("../../views/dashboard/security/Security"));
const PageNotFound = lazy(() => import("../../views/PageNotFound"));
const Apropos = lazy(() => import("../../views/apropos/Apropos"));
const AllowedNotifications = lazy(() => import("../../views/dashboard/allowed-notifications/AllowedNotifications"));
const ConditionsGenerales = lazy(() => import("../../views/conditionGenerales/ConditionsGenerales"));
const Dashboard = lazy(() => import("../../views/dashboard/Dashboard"));
const Contact = lazy(() => import("../../views/contact/Contact"));
const Announcements = lazy(() => import("../../views/announcements/Announcements"));
const DetailAnnouncement = lazy(() => import("../../views/announcements/DetailAnnouncement"));
const CreateListing = lazy(() => import("../../views/dashboard/listings/CreateListing"));
const Listings = lazy(() => import("../../views/dashboard/listings/Listings"));

function Router() {
  const [displayBigSearch, setDisplayBigSearch] = useState(false);
  const { user } = useContext(AuthContext);
  const doSaveListingRef = useRef();
  const doSaveSpecificationRef = useRef();

  UseRefreshUser();

  const handleDisplaySearchBar = useCallback(() => {
    setDisplayBigSearch(!displayBigSearch);
  }, [displayBigSearch]);

  const routes = useMemo(() => {
    let dashboardRoutes = {
      path: "/dashboard",
      element: (
        <RequireAuth
          children1={<Suspense fallback=''><Dashboard /></Suspense>}
          children2={<Navigate to="/" />}
        />
      ),
      exact: true,
      children: [
        {
          path: "",
          element: <Navigate to="/dashboard/profile" />,
        },
        {
          path: "quote",
          element: <Quote title="Mes demandes de devis" />,
        },
        {
          path: "profile",
          element: <Profile />,
        },
        {
          path: "security",
          element: <Suspense fallback=''><Security /></Suspense>,
          children: [
            {
              path: "password",
              element: <Suspense fallback=''><PasswordEdit /></Suspense>,
            },
            {
              path: "kill-account",
              element: <Suspense fallback=''><KillAccount /></Suspense>,
            },
          ],
        },
        {
          path: "allowed-notifications",
          element: <Suspense fallback=''><AllowedNotifications /></Suspense>,
        },
      ],
    };

    if (isRoleCompany(user)) {
      dashboardRoutes.children.push({
        path: "specifications",
        element: <ListSpecification />,
      });
      dashboardRoutes.children.push({
        path: "specification-intro",
        element: <CreateSpecificationsIntro />,
      });
      dashboardRoutes.children.push({
        path: "specification-create/:id?",
        element: (
          <Suspense fallback=''><CreateSpecifications
            doSaveSpecificationRef={doSaveSpecificationRef}
          /></Suspense>
        ),
      });
      dashboardRoutes.children.push({
        path: "specification-corresponding",
        element: <CorrespondingToSpecification />,
      });
      dashboardRoutes.children.push({
        path: "specification-add-message",
        element: <AddMessageSpecification />,
      });
      dashboardRoutes.children.push({
        path: "specification-congrats",
        element: <CongratsSpecification />,
      });
    } else if (isRoleLogistician(user)) {
      dashboardRoutes.children.push({
        path: "listings",
        element: <Suspense fallback=''><Listings /></Suspense>,
      });
      dashboardRoutes.children.push({
        path: "listing-create/:id?",
        element: <Suspense fallback=''><CreateListing doSaveListingRef={doSaveListingRef} /></Suspense>,
      });
      dashboardRoutes.children.push({
        path: "reporting",
        element: <Reporting />,
      });
      dashboardRoutes.children.push({
        path: "subscriptions",
        element: <Subscriptions />,
      });
      dashboardRoutes.children.push({
        path: "payment-methods",
        element: <PaymentMethods />,
      });
      dashboardRoutes.children.push({
        path: "tender-notices",
        element: <TenderNoticeList />,
      });
      dashboardRoutes.children.push({
        path: "tender-notice/:id",
        element: <TenderNoticeDetails />,
      });
    }

    let baseRoutes = [
      {
        path: "/",
        element: <Home setDisplaySearchBar={handleDisplaySearchBar} />,
        exact: true,
      },
      {
        path: "/contact",
        element: (
          <Suspense fallback=''><Contact
            subtitle="Prendre contact avec nous"
            text="Vous avez une question ? Remplissez le formulaire suivant pour que nos équipes reviennent vers vous."
            title="Nous contacter"
          /></Suspense>
        ),
        exact: true,
      },
      {
        path: "/expert",
        element: (
          <Suspense fallback=''><Contact
            subtitle="Prenez contact avec un de nos experts "
            text="En remplissant le formulaire suivant pour que nos experts vous recontacte."
            title="Parler à un expert"
          /></Suspense>
        ),
        exact: true,
      },
      {
        path: "/apropos",
        element: <Suspense fallback=''><Apropos /></Suspense>,
        exact: true,
      },
      {
        path: "/faq",
        element: <FAQ />,
        exact: true,
      },
      {
        path: "/conditions_generales",
        element: <Suspense fallback=''><ConditionsGenerales title="Conditions générales" /></Suspense>,
        exact: true,
      },
      {
        path: "/politique_confidentialite",
        element: (
          <PolitiqueConfidentialite title="Politique de confidentialité" />
        ),
        exact: true,
      },
      {
        path: "/announcements",
        element: <Suspense fallback=''><Announcements /></Suspense>,
        exact: true,
      },
      {
        path: "/reset-password/:token",
        element: <ResetPassword />,
        exact: true,
      },
      {
        path: "/notifications",
        element: <Suspense fallback=''><Notifications /></Suspense>,
        exact: true,
      },
      dashboardRoutes,
      {
        path: "/logout",
        element: <Logout />,
        exact: true,
      },
      {
        path: "/secondarySearch",
        element: <SecondarySearch />,
        exact: true,
      },
      {
        path: "/detail-announcements/:id",
        element: <Suspense fallback=''><DetailAnnouncement /></Suspense>,
        exact: true,
      },
      {
        path: "/devis",
        element: <CreateQuote />,
      },
      {
        path: "/devis/success",
        element: <QuoteSuccess />,
      },
      {
        path: "*",
        element: <Suspense fallback=''><PageNotFound /></Suspense>,
      },
    ];

    if (isRoleLogistician(user) || !isAuthenticated(user)) {
      baseRoutes.push({
        path: "/subscriptions",
        element: <SubscriptionsHome />,
        exact: true,
      });
    }
    if (isRoleCompany(user) || !isAuthenticated(user)) {
      baseRoutes.push({
        path: "/tender-notice/create",
        element: <TenderNoticeForm />,
        exact: true,
      });
    }
    if (isRoleLogistician(user)) {
      baseRoutes.push({
        path: "/subscriptions/change/:subId",
        element: <ChangeSubscription />,
        exact: true,
      });
      baseRoutes.push({
        path: "/subscriptions/revoke",
        element: <RevokeSubscription />,
        exact: true,
      });
    }
    return baseRoutes;
  }, [handleDisplaySearchBar, user]);

  const renderRoutes = (routes) =>
    routes.map((route, index) => (
      <Route
        key={index}
        element={route.element}
        exact={route.exact}
        path={route.path}
      >
        {route.children && renderRoutes(route.children)}
      </Route>
    ));

  return (
    <BrowserRouter>
      <NavProvider>
        <SearchProvider>
          <LayoutWrapper
            displayBigSearchBar={displayBigSearch}
            doSaveListingRef={doSaveListingRef}
            doSaveSpecificationRef={doSaveSpecificationRef}
            setDisplayBigSearchBar={handleDisplaySearchBar}
          >
            <Routes>{renderRoutes(routes)}</Routes>
          </LayoutWrapper>
        </SearchProvider>
      </NavProvider>
    </BrowserRouter>
  );
}

export default Router;
