import { useQuery } from "@apollo/client";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { SpecificationByUser } from "../../../queries/specification/specification";
import CustomSelect from "../../ui/CustomSelect";
import ModalTw from "../index";

const ModalChooseSpecification = ({ onClose, onSelect }) => {
  const { control, watch } = useForm();

  const { data } = useQuery(SpecificationByUser, {
    fetchPolicy: "no-cache",
  });

  const watchSpecification = watch("specification");

  useEffect(() => {
    if (watchSpecification && data) {
      const item = data.specifications?.find(
        (specification) => specification.id === watchSpecification.value
      );
      onSelect(item);
      onClose();
    }
  }, [data, onClose, onSelect, watchSpecification]);

  const specificationSelectItems = useMemo(() => {
    if (!data) return [];

    return data.specifications?.map((specification, index) => ({
      label: specification.description.name || "Cahier des charges " + index,
      value: specification.id,
    }));
  }, [data]);

  const content = useMemo(() => {
    return (
      <div className="mb-32">
        <CustomSelect
          alwaysOpen
          control={control}
          customHeight="h-36"
          items={specificationSelectItems}
          name="specification"
        />
      </div>
    );
  }, [control, specificationSelectItems]);

  return (
    <ModalTw
      content={content}
      title="Vos cahiers des charges monprestatairelogistique"
      onClose={onClose}
    />
  );
};

export default ModalChooseSpecification;
