import { MarkerClusterer } from "@googlemaps/markerclusterer";
import cx from "classnames";
import { useCallback, useContext, useEffect, useMemo } from "react";
import clusterGoogleMarker from "../../assets/svg/clusterGoogleMarker.svg";
import googleMarker from "../../assets/svg/googleMarker.svg";
import googleMarkerSelected from "../../assets/svg/googleMarkerSelected.svg";
import { SearchContext } from "../../context/SearchContext";
import useTailwindBreakpoint from "../../hooks/useTailwindBreakpoint";
import useSearchBarHooks from "../others/searchBar/hooks/useSearchBarhooks";
import BackButton from "./components/BackButton";
import InfoCard from "./components/InfoCard";

const MAP_CENTER = {
  lat: 46.603354,
  lng: 1.8883335,
};

let map;
let previousClickedMarker = null;

function calculateZoomForRadius(radius, mapWidthInPixels) {
  const EARTH_PERIMETER = 40075; // in km
  const TILE_SIZE = 256; // in pixels

  const zoom = Math.floor(
    Math.log((EARTH_PERIMETER * 2 * mapWidthInPixels) / (TILE_SIZE * radius)) /
      Math.log(2)
  );

  console.log("🚀 ~ zoom", zoom);
  return zoom - 2;
}

export const NewGoogleMap = ({
  hits,
  setSelecterGoogleMapMarker,
  setShowMapActivated,
  selecterGoogleMapMarker,
  showMapActivated
}) => {
  const { selectedOption } = useContext(SearchContext);
  const { isXsTw } = useTailwindBreakpoint();
  const { setLocation, setSelectedOption, location } = useSearchBarHooks({
    onSearch: () => null,
    resetSearch: false,
  });

  const centerFromBounds = useMemo(() => {
    if (!selectedOption?.bounds) return MAP_CENTER;

    return {
      lat: (selectedOption.bounds[0] + selectedOption.bounds[2]) / 2,
      lng: (selectedOption.bounds[1] + selectedOption.bounds[3]) / 2,
    };
  }, [selectedOption?.bounds]);

  const markers = useMemo(() => {
    return hits
      .map((hit) => {
        if (!hit._geoloc) return null;

        return {
          ...hit,
          title: hit.title,
          id: hit.id,
          position: {
            lat: hit._geoloc[0].lat,
            lng: hit._geoloc[0].lng,
          },
          label: hit._geoloc[0].label,
        };
      })
      .filter(Boolean);
  }, [hits]);

  useEffect(() => {
    if (
      selecterGoogleMapMarker &&
      !markers?.find((marker) => marker.objectID === selecterGoogleMapMarker.objectID)
    )
      setSelecterGoogleMapMarker(null);
  }, [location, markers, selecterGoogleMapMarker, setSelecterGoogleMapMarker]);

  const addMarkers = useCallback(
    async (markers) => {
      const { AdvancedMarkerElement } = await google.maps.importLibrary(
        "marker"
      );

      const resultMarkers = markers.map((m) => {
        const customGoogleMarker = document.createElement("img");
        customGoogleMarker.src = googleMarker;

        const marker = new AdvancedMarkerElement({
          map,
          position: m.position,
          content: customGoogleMarker,
        });

        marker.addListener("click", ({ domEvent }) => {
          const { target } = domEvent;

          // Reset the appearance of the previously clicked marker
          if (previousClickedMarker) {
            previousClickedMarker.src = googleMarker;
          }

          // Update the appearance of the currently clicked marker
          target.src = googleMarkerSelected;

          // Store the reference to the currently clicked marker
          previousClickedMarker = target;

          if (!isXsTw)
            document
              .getElementById(m.objectID)
              .scrollIntoView({ behavior: "smooth", block: "center" });
          setSelecterGoogleMapMarker(m);
        });

        return marker;
      });

      new MarkerClusterer({
        markers: resultMarkers,
        map,
        renderer: {
          render(cluster, stats, map) {
            const customGoogleMarker = document.createElement("img");
            customGoogleMarker.src = clusterGoogleMarker;

            const count = document.createElement("span");
            count.textContent = `${cluster.markers.length}`;
            count.style.position = "absolute";
            count.style.top = "33%";
            count.style.left = "47%";
            count.style.transform = "translate(-50%, -50%)";
            count.style.fontSize = "0.8rem";
            count.style.fontWeight = "500";

            const wrapper = document.createElement("div");
            wrapper.style.position = "relative";
            wrapper.style.top = "10px";
            wrapper.style.display = "flex";
            wrapper.style.alignItems = "center";
            wrapper.style.justifyContent = "center";
            wrapper.appendChild(customGoogleMarker);
            wrapper.appendChild(count);

            return new AdvancedMarkerElement({
              map,
              position: cluster.position,
              content: wrapper,
            });
          },
        },
      });
    },
    [isXsTw, setSelecterGoogleMapMarker]
  );

  const initMap = useCallback(async () => {
    const { Map } = await google.maps.importLibrary("maps");
    const mapWidthInPixels = 580; // Assuming your map is 580pixels
    const desiredRadius = 160; // 100km

    map = new Map(document.getElementById("google-map"), {
      center: centerFromBounds,
      zoom:
        location && location.value !== "ALL" && !selectedOption?.isCountry
          ? calculateZoomForRadius(desiredRadius, mapWidthInPixels)
          : 5,
      disableDefaultUI: true,
      mapId: "31228dc021d66fb1",
    });

    google.maps.event.addListener(map, "click", function () {
      if (previousClickedMarker) {
        previousClickedMarker.src = googleMarker;
        previousClickedMarker = null;
      }
      setSelecterGoogleMapMarker(null);
    });

    let previousZoomLevel = map.getZoom();

    google.maps.event.addListener(map, "zoom_changed", function () {
      const currentZoomLevel = map.getZoom();

      if (
        currentZoomLevel < previousZoomLevel &&
        location &&
        location.value !== "ALL"
      ) {
        setLocation({ value: "ALL", label: "Toute la France" });
        setSelectedOption({ value: "ALL", label: "Toute la France" });
        setSelecterGoogleMapMarker(null);
      }
    });

    await addMarkers(markers);
  }, [
    addMarkers,
    centerFromBounds,
    location,
    markers,
    selectedOption?.isCountry,
    setLocation,
    setSelectedOption,
    setSelecterGoogleMapMarker,
  ]);

  useEffect(() => {
    initMap(centerFromBounds).then(() => {
      console.log("map loaded");
    });
  }, [centerFromBounds, initMap, location, markers]);

  if (isXsTw) {
    return (
      <div className={cx("inset-0 flex", {
        "hidden": !showMapActivated,
        "absolute z-50": showMapActivated,
      })}>
        <div className="w-full" id="google-map"></div>
        <BackButton
          onClick={() => {
            setSelecterGoogleMapMarker(null);
            setShowMapActivated((prev) => !prev);
          }}
        />
        {selecterGoogleMapMarker && (
          <InfoCard selecterGoogleMapMarker={selecterGoogleMapMarker} />
        )}
      </div>
    );
  }

  return (
    <div
      className={cx("flex w-5/12 shrink-0 pr-5", {
        "absolute -top-full": !showMapActivated,
        "sticky top-0 mb-[58px]": showMapActivated,

      })}
      style={{ height: "calc(100vh - 340px" }}
    >
      <div className="w-full" id="google-map"></div>
      {selecterGoogleMapMarker && (
        <InfoCard selecterGoogleMapMarker={selecterGoogleMapMarker} />
      )}
    </div>
  );
};
