import { useEffect, useState } from "react";
import ArticleItem from "../../../components/items/ArticleItem";
import Slider from "../../../components/Slider";
import CustomButton from "../../../components/ui/CustomButton";
import { homePageDots } from "../../../constants/sliderDots";

function removeTime(date = new Date()) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

export default function LatestArticlesSection() {
  const [articleItem, setArticleItem] = useState([]);
  useEffect(() => {
    async function loadPosts() {
      const response = await fetch(
        "https://blog.monprestatairelogistique.com/wp-json/wp/v2/posts?per_page=3&_embed"
      );
      if (!response.ok) {
        // oups! something went wrong
        return;
      }
      const posts = await response.json();
      let tmpArticleItem = [];
      posts.forEach((element) => {
        let date = new Date(element.date);
        date = removeTime(date).toLocaleString("fr-fr").split(" ")[0];
        let temp = document.createElement("div");
        temp.innerHTML = element.content.rendered;
        let sanitizedContent = temp.textContent || temp.innerText;
        temp.innerHTML = element.title.rendered;
        let sanitizedTitle = temp.textContent || temp.innerText;
        sanitizedContent = sanitizedContent.replace(/^\s+|\s+$/g, "");
        sanitizedContent = sanitizedContent.split("Introduction")[1];
        let tags = [];
        element._embedded["wp:term"]["0"].forEach((category) =>
          tags.push(category.name)
        );

        tmpArticleItem.push({
          id: element.id,
          image: element._embedded["wp:featuredmedia"]["0"].source_url,
          date: date,
          title: sanitizedTitle,
          content: sanitizedContent,
          link: element.link,
          tags: tags,
        });
      });
      setArticleItem(tmpArticleItem);
    }
    loadPosts();
  }, []);

  return (
    <div className="pb-24 lg:flex lg:flex-col">
      <h1 className="mb-4 text-center font-roboto text-xl font-bold text-mplgreen lg:mb-10 lg:mt-24 lg:font-roboto-regular lg:text-5xl xl:text-[60px]">
        Nos derniers articles
      </h1>
      <div className="mb-20 px-6 md:mx-auto md:w-1/2 xl:hidden">
        <Slider appendDots={homePageDots}>
          {articleItem.map((article) => (
            <ArticleItem
              key={article.title}
              content={article.content}
              date={article.date}
              image={article.image}
              link={article.link}
              tags={article.tags ?? []}
              title={article.title}
            />
          ))}
        </Slider>
      </div>
      <div className="hidden xl:mb-5 xl:flex xl:justify-evenly xl:gap-5 xl:px-7">
        {articleItem.map((article) => (
          <ArticleItem
            key={article.title}
            content={article.content}
            date={article.date}
            image={article.image}
            link={article.link}
            tags={article.tags ?? []}
            title={article.title}
          />
        ))}
      </div>
      <div className="flex justify-center">
        <CustomButton
          className="lg:w-[310px] lg:self-center lg:p-4 lg:text-xl"
          to="https://blog.monprestatairelogistique.com//"
        >
          Voir plus
        </CustomButton>
      </div>
    </div>
  );
}
