import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../components/ui/CustomButton";
import imageAnnonce from "../../../assets/images/placeholder-annonce.png";

const MobileCreateSpecificationIntro = () => {
  const navigate = useNavigate();

  const handleOnClick = useCallback(() => {
    navigate("/dashboard/specification-create");
  }, [navigate]);

  return (
    <div className="flex h-screen flex-col">
      <img alt="annonce" className="flex-1 object-cover" src={imageAnnonce} />
      <div className="flex flex-1 flex-col gap-2 bg-black px-6 py-5 text-white">
        <h1 className="text-2xl font-semibold md:text-5xl">
          Créer mon cahier des charges
        </h1>
        <p className="leading-relaxed text-gray-200 md:text-2xl">
          On vous accompagne étape par étape
        </p>
        <div className="mt-auto">
          <CustomButton isFull onClick={handleOnClick}>
            Démarrer
          </CustomButton>
        </div>
      </div>
    </div>
  );
};

export default MobileCreateSpecificationIntro;
