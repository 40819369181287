import { useContext } from "react";
import { BsCheckSquare, BsSquare } from "react-icons/bs";
import styled from "styled-components";
import { NavContext } from "../../context/NavContext";

function MultiSelectDropdown({
  options,
  selected,
  toggleOption,
  isOpen,
  toggleDisplay,
  clearSelection,
  titleHeader,
  hiddenCheckbox,
  isSecteur,
}) {
  const { setIsactive } = useContext(NavContext);

  const borderBlackStyleItem = {
    border: "1px solid #B3B3B3",
    borderRadius: "99.5px",
    justifyContent: "space-between",
  };
  const borderWhiteStyleItem = {
    boxShadow: "none",
  };
  const handleMouseEnter = () => {
    setIsactive(true);
  };
  const handleMouseLeave = () => {
    setIsactive(false);
  };
  return (
    <MultiDropdownContainer
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <DropdownSelected selectedOptions={selected} onClick={toggleDisplay}>
        {selected.length > 0
          ? `${selected[0].title} ${
              selected.length > 1 ? `+${selected.length - 1}` : ""
            }`
          : Array.isArray(titleHeader)
          ? `${titleHeader[0].title} ${
              titleHeader.length > 1 ? `+${titleHeader.length - 1}` : ""
            }`
          : titleHeader}
      </DropdownSelected>
      {isOpen && (
        <DropDownListContainer>
          {isSecteur && (
            <DropdownSelectedList
              className="selectedList"
              style={{
                display: selected.length === 0 && "none",
                marginBottom: selected.length !== 0 && "0",
              }}
            >
              {selected.map((selectedItem, index) => {
                const isSelected = selected.find(
                  (e) => e.id === selectedItem.id
                );
                return (
                  <DropdownOption
                    key={index}
                    style={
                      !!isSelected && isSecteur
                        ? borderBlackStyleItem
                        : borderWhiteStyleItem
                    }
                    onClick={() => toggleOption({ item: selectedItem })}
                  >
                    {selectedItem.title}
                    <div className="close-icon">
                      <svg
                        height="20"
                        viewBox="0 0 24 24"
                        width="20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="12"
                          cy="12"
                          fill="currentColor"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="2"
                        />
                        <path
                          d="M8.46,8.46L15.54,15.54M15.54,8.46L8.46,15.54"
                          stroke="white"
                          strokeWidth="2"
                        />
                      </svg>
                    </div>
                  </DropdownOption>
                );
              })}
            </DropdownSelectedList>
          )}

          <DropdownOptions
            style={{
              marginTop: selected.length !== 0 && isSecteur && "0",
              marginBottom: selected.length !== 0 && "0",
            }}
          >
            {options.map((option, index) => {
              const isSelected = selected.find((e) => e.id === option.id);
              return (
                <DropdownOption
                  key={index}
                  style={{
                    display: !!isSelected && isSecteur && "none",
                  }}
                  onClick={() => toggleOption({ item: option })}
                >
                  <CheckboxInput
                    checked={!!isSelected}
                    hidden={hiddenCheckbox}
                    type="checkbox"
                    onChange={() => {}}
                  />
                  {!isSelected && <BsSquare fill="#1FC585" />}
                  {!!isSelected && <BsCheckSquare fill="#1FC585" />}
                  <label
                    className="ml-2"
                    htmlFor={"chklist_lab_" + index}
                    style={{ fontWeight: 400, width: "90%" }}
                  >
                    {option.title}
                  </label>
                </DropdownOption>
              );
            })}
          </DropdownOptions>
          <ButtonContainer
            style={{
              display: selected.length === 0 && "none",
            }}
          >
            <DropdownDeleteButton type="button" onClick={clearSelection}>
              Effacer
            </DropdownDeleteButton>
            <ValidButton type="button"></ValidButton>
          </ButtonContainer>
        </DropDownListContainer>
      )}
    </MultiDropdownContainer>
  );
}

export const MultiDropdownContainer = styled.div`
  position: relative;
  padding: 0 !important;
`;

const DropdownSelected = styled.div`
  cursor: pointer;
  height: 40px;
  border: none;
  outline: none;
  font-size: 18px;
  padding: 5px 0 0 0 !important;
  text-align: start;
  color: ${(props) =>
    props.selectedOptions.length === 0 ? "#9E9E9E" : "#000"};
  line-height: 1.225rem;
`;

const DropDownListContainer = styled("div")`
  position: absolute;
  top: 78px;
  left: -45px;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border-radius: 24px;
  width: 22vw;
`;

const DropdownOptions = styled.ul`
  width: 90%;
  margin: 20px;
  padding: 0;
  font-size: 18px;
  max-height: 150px;
  overflow-y: scroll;
`;

const DropdownSelectedList = styled.div`
  width: 90%;
  margin: 20px;
  padding: 0;
  font-size: 18px;
  border-bottom: 1px solid #9a9797;
  max-height: 150px;
  overflow-y: scroll;
`;

const DropdownOption = styled.li`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: "flex-start";
  list-style: none;
  min-width: 200px;
  padding: 6px;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 9px;
  margin-bottom: 9px;
  border-radius: 10px;
  &:hover {
    background-color: #f0f0f0;
  }
  .close-icon {
    display: flex;
    margin-left: 10px;
    color: #b3b3b3;
  }
  .close-icon svg {
    align-self: center;
  }
`;

const CheckboxInput = styled.input`
  margin-right: 6px;
  opacity: 0;
  position: absolute;
  border: 1px solid white;
`;
const ButtonContainer = styled.div`
  width: 90%;
  padding: 0;
  display: flex;
  margin: 0;
  margin-left: 20px;
  margin-right: 20px;
  justify-content: space-between;
  border-top: 1px solid #9a9797;
`;
const ValidButton = styled.button`
  margin-bottom: 15px;
  margin-top: 15px;
  margin-left: auto;
  margin-right: 10px;
  border: 0;
  color: #1fc585;
  background: none;
  font-weight: 700;
  font-size: 17px;
  cursor: pointer;
`;
const DropdownDeleteButton = styled.button`
  margin-bottom: 15px;
  margin-top: 15px;
  margin-right: auto;
  margin-left: 10px;
  border: 0;
  color: #838383;
  background: none;
  font-weight: 700;
  font-size: 17px;
  cursor: pointer;
`;

// MultiSelectDropdown.propTypes = {
//     options: PropTypes.arrayOf.isRequired,
//     selected: PropTypes.arrayOf.isRequired,
//     toggleOption: PropTypes.func.isRequired,
//     toggleDisplay: PropTypes.func.isRequired,
//     isOpen: PropTypes.bool.isRequired,
//     titleHeader: PropTypes.string.isRequired,
// }
export default MultiSelectDropdown;
