import { gql } from "@apollo/client";

export const ADD_USER = gql`
  mutation createUser(
    $name: String!
    $company: String!
    $activityArea: String!
    $siret: String!
    $email: String!
    $phone: String!
    $password: String!
    $role: String!
    $sessionId: String!
    $host: String!
  ) {
    createUser(
      createUserInput: {
        name: $name
        company: $company
        activityArea: $activityArea
        siret: $siret
        email: $email
        phone: $phone
        password: $password
        role: $role
      }
      sessionId: $sessionId
      host: $host
    ) {
      user {
        id
        name
        company
        activityArea {
          label
          code
        }
        siret
        email
        phone
        role
      }
      accessToken
      refreshToken
    }
  }
`;

export const CHECK_EMAIL = gql`
  query checkUserExists($email: String!) {
    checkUserExists(email: $email)
  }
`;
