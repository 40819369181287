import { useState } from "react";
import MobileMoreFilter from "../../../components/MobileModal/MobileMoreFilter";
import ModalResearch from "../../../components/MobileModal/ModalResearch";
import OutlinedButton from "../../../components/OutlinedButton";
import useSearchBarHooks from "../../../components/others/searchBar/hooks/useSearchBarhooks";
import { getTitleWithCount } from "../../../utils/search";
import { getPluralizedText } from "../../../utils/string";

const MobileFilter = ({
  numberOfResults,
  state,
  settingDatas,
  dispatch,
  nbTotalOfFilterActivated,
}) => {
  const hooks = useSearchBarHooks({ onSearch: () => null, resetSearch: false });

  const [isModalResearchActive, setIsModalResearchActive] = useState(false);
  const [isModalMobileMoreFilter, setIsModalMobileMoreFilter] = useState(false);

  return (
    <div className="flex flex-col bg-black px-4 py-2 font-roboto font-medium lg:hidden">
      <button
        className="flex items-center rounded-full bg-white p-3"
        onClick={() => setIsModalResearchActive(true)}
      >
        <div className="flex-1 truncate px-2 text-center">
          {hooks.location?.label || "Où ?"}
        </div>
        <hr className="h-6 w-[1px] border border-[#A8A8A8]" />
        <div className="w-24 flex-1 truncate px-2 text-center">
          {getTitleWithCount(
            hooks.selectedAreas,
            "Quel secteur d’expertise ?",
            true
          )}
        </div>
        <hr className="h-6 w-[1px] border border-[#A8A8A8]" />
        <div className="flex-1 px-2 text-center">
          {getTitleWithCount(hooks.selectedServices, "Quel type ?", true)}
        </div>
      </button>
      <div className="mt-2 flex items-center justify-between text-white">
        <div>
          {numberOfResults}{" "}
          {getPluralizedText(numberOfResults, "Résultat", "Résultats")}
        </div>
        <OutlinedButton
          color={nbTotalOfFilterActivated > 0 ? "green" : "white"}
          onClick={() => setIsModalMobileMoreFilter(true)}
        >
          Plus de filtres
          {nbTotalOfFilterActivated > 0 && (
            <div className="absolute -top-2 right-0 flex aspect-auto h-5 w-5 items-center justify-center rounded-full bg-mplgreen p-1 text-xs text-white">
              <span>{nbTotalOfFilterActivated}</span>
            </div>
          )}
        </OutlinedButton>
      </div>
      {isModalResearchActive && (
        <ModalResearch onClose={() => setIsModalResearchActive(false)} />
      )}
      {isModalMobileMoreFilter && (
        <MobileMoreFilter
          dispatch={dispatch}
          settingDatas={settingDatas}
          state={state}
          onClose={() => setIsModalMobileMoreFilter(false)}
        />
      )}
    </div>
  );
};

export default MobileFilter;
