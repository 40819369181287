import { gql } from "@apollo/client";

export const RetrieveUserNotification = gql`
  query retrieveUserNotification {
    retrieveUserNotification {
      id
      userId
      createdAt
      content
      redirect
      active
    }
  }
`;

export const RemoveNotification = gql`
  mutation removeNotification($id: String!) {
    removeNotification(id: $id)
  }
`;

export const NotificationSubscription = gql`
  subscription Notification {
    notification {
      id
      userId
      createdAt
      content
      redirect
      active
    }
  }
`;
