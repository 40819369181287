import { useLocation, useNavigate } from "react-router-dom";

const QuoteSuccess = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const redirectHome = () => {
    navigate("/");
  };

  const navigateBack = () => {
    navigate(location.state?.returnUrl ?? "/");
  };

  return (
    <div className="mx-auto mt-14 flex grow">
      <div className="flex max-w-[600px] flex-col lg:flex lg:items-center lg:justify-center">
        <div className="font-medium lg:text-3xl">
          Votre demande de devis a bien été envoyée !
        </div>
        <div className="mt-9 flex w-full flex-col gap-4">
          <button
            className="w-full rounded-lg bg-mplgreen py-2 text-center font-semibold text-white"
            onClick={navigateBack}
          >
            Retour à ma recherche
          </button>
          <button
            className="w-full rounded-lg border border-black bg-white py-2 text-center font-semibold"
            onClick={redirectHome}
          >
            Retour à l'accueil
          </button>
        </div>
      </div>
    </div>
  );
};

export default QuoteSuccess;
