import { gql } from "@apollo/client";

const message = `
  id
  message
  isRead
  createdAt
  sender {
      id
      name
      company
      email
      status
      role
      profilePic {
          id
          name
          url
          deleted
      }
  }
  receiver {
      id
      name
      company
      email
      role
      status
      profilePic {
          id
          name
          url
          deleted
      }
  }
  attached {
      id
      name
      url
      deleted
  }
  estimate {
    id
    listing {
      id
      description {
        title
      }
    }
    file {
      id
      name
      url
      deleted
    }
  }
`;

export const SubscriptionMessage = gql`
  subscription Message {
    message {
      ${message}
    }
  }
`;

export const GetConversation = gql`
  query getConversation {
    getConversation {
      id
      firstSender {
        id
        name
        company
        email
        role
        status
        profilePic {
          id
          name
          url
          deleted
        }
      }
      firstReceiver {
        id
        name
        company
        email
        role
        status
        profilePic {
          id
          name
          url
          deleted
        }
      }
      isFromMulti
      lastMessageDate
    }
  }
`;

export const GetConversationDetails = gql`
  query getConversationDetails($secondUserId: String!) {
    getConversationDetails(secondUserId: $secondUserId) {
      ${message}
    }
  }
`;

export const SendMessage = gql`
  mutation SendMessage($input: CreateMessageInput!) {
    sendMessage(input: $input) {
      ${message}
    }
  }
`;
