import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import cx from "classnames";
import { Fragment, useState } from "react";
import SelectedLittleGrayBubble from "../ui/SelectedLittleGrayBubble";

function SelectFromList({
  options = [],
  picked = [],
  onValuesChange,
  selectOn = "id",
  toReturn = "values",
  showSelectionList = true,
  filterList = true,
  filterSearch = true,
  toggleOnOption = true,
  isMulti = true,
  onSearchFieldChange = null,
  customWidth,
  isCol = true,
  placeholder,
  label,
  displayInline,
  error,
}) {
  const [searchKey, setSearchKey] = useState("");
  const [selected, setSelected] = useState(picked);
  const [isFocus, setIsFocus] = useState(false);

  const selectOption = (option) => {
    if (showSelectionList) {
      if (
        !toggleOnOption &&
        selected.find((x) => x[selectOn] === option[selectOn])
      )
        return;

      const oldSize = selected.length;
      const newList = selected.filter((x) => x[selectOn] !== option[selectOn]);

      if (newList.length === oldSize) newList.push(option);
      setSelected(isMulti ? newList : [option]);

      if (onValuesChange) {
        let selectedValues;

        // If toReturn is "values", map the list based on the 'selectOn' property
        if (toReturn === "values") {
          selectedValues = (isMulti ? newList : [option]).map(
            (x) => x[selectOn]
          );
        } else {
          selectedValues = isMulti ? newList : [option];
        }

        onValuesChange(selectedValues);
      }

      return;
    }

    onValuesChange(toReturn === "object" ? option : option[selectOn]);
  };

  return (
    <div
      className={`flex shrink-0 ${
        isCol ? "flex-col" : "flex-row"
      } gap-1 ${customWidth}`}
    >
      <Combobox
        as="div"
        value={searchKey}
        onChange={(value) => {
          selectOption(value);
          setSearchKey("");
        }}
      >
        {({ open }) => (
          <div>
            {label && (
              <label className="mb-2 ml-px block text-sm font-medium leading-6 text-black lg:text-lg">
                {label}
              </label>
            )}
            <Combobox.Button className="relative w-full">
              <div
                className={cx(
                  "w-full rounded-xl border border-gray-300 bg-white py-2.5 pl-3 pr-10 text-gray-900 shadow-sm transition-all duration-300 placeholder:text-gray-400 sm:text-sm sm:leading-6",
                  {
                    "!border-emerald-500 ring ring-emerald-500/20": isFocus,
                  }
                )}
              >
                <div className="flex flex-wrap items-center justify-start gap-2">
                  {picked.length > 0 && displayInline && (
                    <div className="flex flex-wrap items-center gap-2">
                      {picked.map((p) => {
                        return (
                          <SelectedLittleGrayBubble
                            key={p[selectOn]}
                            handleRemove={() => selectOption(p)}
                            label={p.label}
                            size="xs"
                          />
                        );
                      })}
                    </div>
                  )}

                  <Combobox.Input
                    className={cx("border-0 p-0 grow bg-transparent", {
                      "w-0 min-w-[30px]": displayInline && picked.length,
                    })}
                    placeholder={
                      displayInline && !picked.length
                        ? placeholder || `Rechercher`
                        : ""
                    }
                    onBlur={() => setIsFocus(false)}
                    onChange={(evt) => {
                      setSearchKey(evt.target.value);
                      onSearchFieldChange &&
                        onSearchFieldChange(evt.target.value);
                    }}
                    onFocus={() => setIsFocus(true)}
                  />
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center rounded-r-xl px-2 focus:outline-none">
                <MagnifyingGlassIcon
                  aria-hidden="true"
                  className="h-5 w-5 text-gray-400"
                />
              </div>
              {options.length > 0 && (
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  show={open}
                >
                  <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-xl bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {options
                      .filter((item) =>
                        filterList
                          ? !selected.find(
                              (x) => x[selectOn] === item[selectOn]
                            )
                          : true
                      )
                      .filter((item) =>
                        filterSearch
                          ? item.label.toLowerCase().includes(searchKey)
                          : true
                      )
                      .map((item, idx) => (
                        <Combobox.Option
                          key={item[selectOn]?.toString() + "_" + idx}
                          className={cx(
                            "relative cursor-pointer select-none py-2 pl-3 pr-9 text-left text-gray-900 hover:bg-mplgreen hover:text-white"
                          )}
                          value={item}
                          onClick={() => selectOption(item)}
                        >
                          {({ active, selected }) => (
                            <>
                              <span
                                className={cx(
                                  "block truncate",
                                  selected && "font-semibold"
                                )}
                              >
                                {item.label}
                              </span>

                              {selected && (
                                <span
                                  className={cx(
                                    "absolute inset-y-0 right-0 flex items-center pr-4",
                                    active ? "text-white" : "text-gray-400"
                                  )}
                                >
                                  <CheckIcon
                                    aria-hidden="true"
                                    className="h-5 w-5"
                                  />
                                </span>
                              )}
                            </>
                          )}
                        </Combobox.Option>
                      ))}
                  </Combobox.Options>
                </Transition>
              )}
            </Combobox.Button>
            {error && (
              <p className="mt-1 text-left text-sm text-red-600">
                {error.message}
              </p>
            )}
          </div>
        )}
      </Combobox>
      {!!selected.length && showSelectionList && !displayInline && (
        <div className="ml-2 mt-2 flex flex-wrap items-start gap-2">
          {selected.map((item, idx) => (
            <SelectedLittleGrayBubble
              key={item[selectOn]?.toString() + "_" + idx}
              handleRemove={() => selectOption(item)}
              label={item.label}
              size="xs"
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default SelectFromList;
