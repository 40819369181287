import { useMutation } from "@apollo/client";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import tw from "tailwind-styled-components";
import LoadingBtn from "../../../components/others/dashboard/LoadingBtn";
import StepsWrapper from "../../../components/others/dashboard/StepsWrapper";
import { AuthContext } from "../../../context/AuthContext";
import { RevokeUserSubscription } from "../../../queries/subscription/subscription";

function RevokeSubscription() {
  const navigate = useNavigate();
  const { user, dispatch } = useContext(AuthContext);
  const [revokeSubscription] = useMutation(RevokeUserSubscription, {
    fetchPolicy: "no-cache",
  });
  const [step, setStep] = useState(1);
  const [confirmChange, setConfirmChange] = useState(false);
  const [revokeReason, setRevokeReason] = useState("");
  const [loading, setLoading] = useState(false);

  const handleStepChange = (newstep) => {
    switch (newstep) {
      case 0:
        navigate(-1);
        break;
      case 1:
      case 2:
        setStep(newstep);
        break;
      case 3:
        if (!confirmChange) {
          navigate(-1);
          return;
        }

        setLoading("confirm");
        revokeSubscription({ variables: { reason: revokeReason } })
          .then(() => {
            const runningSubs = user.subscriptions.find((x) => x.running);
            dispatch({
              type: "SET_SUBSCRIPTIONS",
              payload: [
                {
                  ...runningSubs,
                  revokeDate: user.subscriptions.length > 1 ? null : Date.now(),
                },
              ],
            });
            setStep(3);
          })
          .catch(() =>
            alert.error("Une erreur est survenue. Veuillez réessayer.")
          )
          .finally(() => setLoading(false));
        break;
    }
  };

  return (
    <>
      <StepsWrapper
        hideControlsAtEnd={true}
        hideTitleAtEnd={false}
        loading={loading === "confirm"}
        showProgress={true}
        step={step}
        stepMax={3}
        stepsTitle={["Votre motif", "Confirmez", "Terminé"]}
        title="Resiliation de votre abonnement"
        onStepChanges={handleStepChange}
      >
        <Container>
          {step === 1 && (
            <Step1>
              <StepTitle className="mb-4 text-center">
                Quel est le motif de votre résilitation ?
              </StepTitle>
              <ReasonInputWrapper>
                <ReasonInput
                  defaultValue={revokeReason}
                  placeholder="Dites nous pourquoi vous souhaitez résilier votre abonnement ..."
                  onChange={(evt) => setRevokeReason(evt.target.value)}
                />
              </ReasonInputWrapper>
            </Step1>
          )}
          {step === 2 && (
            <Step2>
              <StepTitle>
                Confirmez vous la résilitation de votre abonnement ?
              </StepTitle>
              <SelectableList>
                <SelectableItem
                  $selected={confirmChange}
                  onClick={() => setConfirmChange(true)}
                >
                  Oui je le confirme
                </SelectableItem>
                <SelectableItem
                  $selected={!confirmChange}
                  onClick={() => setConfirmChange(false)}
                >
                  Non je souhaite annuler
                </SelectableItem>
              </SelectableList>
            </Step2>
          )}
          {step === 3 && (
            <Step3 className="text-center">
              <StepTitle className="text-2xl">
                Votre demande de résiliation a bien été prise en compte. Vos
                accès vont être restreints.
              </StepTitle>
              <LoadingBtn
                className="mt-7 !bg-[#1FC585]"
                onClick={() => navigate("/subscriptions")}
              >
                Retour à la page d'accueil
              </LoadingBtn>
            </Step3>
          )}
        </Container>
      </StepsWrapper>
    </>
  );
}

const SelectableList = tw.ul`mt-8 p-0 list-none`;
const SelectableItem = tw.li`
    relative py-7 border-0 border-b border-gray-300 border-solid text-gray-600 text-base pr-10 cursor-pointer
    before:absolute before:content-[''] before:w-4 before:h-4 before:border
    before:border-gray-300 before:border-solid before:rounded-full before:right-0 before:top-[40%]
    ${(p) => (p.$selected ? "before:bg-[#1FC585] text-black" : "")}
`;

const Container = tw.div`max-w-[700px] mx-auto box-border md:mt-12 mt-6 lg:mt-24`;
const StepTitle = tw.h3`text-2xl text-black font-semibold`;
const Step1 = tw.div``;
const Step2 = tw.div``;
const Step3 = tw.div``;
const ReasonInputWrapper = tw.div`border border-solid rounded-md overflow-hidden box-border`;
const ReasonInput = tw.textarea`border-0 p-4 text-sm h-[250px] w-full outline-none`;

export default RevokeSubscription;
