import { Controller } from "react-hook-form";
import StarRatings from "react-star-ratings";
import styled from "styled-components";

const Rating = ({
  control,
  name,
  readOnly,
  rating,
  ratingCount,
  ratingCountClass,
  ...ratingProps
}) => {
  const commonProps = {
    starDimension: "12px",
    starRatedColor: "#1fc585",
    starEmptyColor: "#e2e8f0",
    starHoverColor: "#1fc585",
    starSpacing: "1px",
    ...ratingProps,
  };

  return (
    <Container className="flex items-center">
      {readOnly ? (
        <StarRatings rating={rating} {...commonProps} />
      ) : (
        <Controller
          control={control}
          name={name}
          render={({ field }) => (
            <StarRatings
              changeRating={field.onChange}
              rating={field.value}
              {...commonProps}
            />
          )}
        />
      )}
      {ratingCount >= 0 && (
        <div
          className={`ml-1 font-roboto ${ratingCountClass} font-medium`}
        >{`(${ratingCount})`}</div>
      )}
    </Container>
  );
};

export default Rating;

const Container = styled.div`
  display: flex;
  align-items: center;

  div {
    display: flex !important;
  }
`;
