import cx from "classnames";

const SubscriptionToggle = ({ toggleItems }) => {
  return (
    <div className="flex h-12 w-[300px] items-center justify-center rounded-[40px] bg-black py-0.5 shadow-xl">
      {toggleItems.map((item) => (
        <button
          key={item.label}
          className={cx(
            "flex flex-1 items-center justify-center rounded-[40px] font-roboto-regular h-full",
            {
              "bg-white text-black border-2 border-black min-h-[52px]":
                item.isActive,
              "text-white bg-black": !item.isActive,
            }
          )}
          onClick={item.onClick}
        >
          {item.label}
        </button>
      ))}
    </div>
  );
};

export default SubscriptionToggle;
