import { useMutation } from "@apollo/client";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import CustomButton from "../../components/ui/CustomButton";
import { DeleteSpecification } from "../../queries/specification/specification";

const ListSpecificationItem = ({ item, setSpecifications, index }) => {
  const [removeSpecification, { loading: removeLoading }] =
    useMutation(DeleteSpecification);
  const navigate = useNavigate();

  const deleteSpecification = (id) => {
    removeSpecification({ variables: { id } }).then(() => {
      setSpecifications((state) => state.filter((item) => item.id !== id));
    });
  };

  return (
    <tr key={Math.random()}>
      <TableCell>
        {item.description?.name || "Cahier des c." + (index + 1)}
      </TableCell>
      <TableCell>
        {format(new Date(item.createdAt), "d MMMM yyyy", {
          locale: fr,
        })}
      </TableCell>
      <TableCell>
        {format(new Date(item.updatedAt), "d MMMM yyyy", {
          locale: fr,
        })}
      </TableCell>
      <TableCell className="center">
        <SeeLink target="_blank" to={item.workbookFile?.url}>
          Télécharger
        </SeeLink>
      </TableCell>
      <TableCell className="ml-5 flex justify-end gap-x-4">
        <CustomButton
          size="small"
          onClick={() =>
            navigate("/dashboard/specification-create/" + item.id, {
              state: { id: item.id },
            })
          }
        >
          Modifier
        </CustomButton>
        <CustomButton
          color="red-500"
          isLoading={removeLoading}
          size="small"
          variant="outlined"
          onClick={() => deleteSpecification(item.id)}
        >
          Supprimer
        </CustomButton>
      </TableCell>
    </tr>
  );
};

const TableCell = styled.td`
  min-width: 25%;
  padding: 25px 0;
  font-size: 15px;
  text-align: center;
  &.border-bottom {
    border-bottom: 0.5px solid #a8a8a8;
  }

  &.center {
    text-align: center;
  }
`;

const SeeLink = styled(Link)`
  font-size: 15px;
  font-weight: 400;
  text-decoration: underline;
  color: #000;
`;

export default ListSpecificationItem;
