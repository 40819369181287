import Modal from "../../../../components/others/modal/Modal";
import { getAdvancedFilters } from "../../../../constants/allFilters";
import MoreFilterBubbleChoice from "./components/MoreFilterBubbleChoice";
import MoreFilterCheckboxChoice from "./components/MoreFilterCheckboxChoice";

function MoreFilterModal({
  toggleAdvancedFilters,
  dispatch,
  state,
  settingDatas,
}) {
  return (
    <Modal
      header
      isShowing
      hide={toggleAdvancedFilters}
      marginTop="180px"
      titleHeader="Plus de filtres"
      width="700px"
    >
      <>
        <div className="mt-4 overflow-y-auto border-t border-black">
          {getAdvancedFilters(state, settingDatas, dispatch).map(
            (filter, index) => {
              if (filter.useBubbleComponent) {
                return (
                  <MoreFilterBubbleChoice
                    key={index}
                    handleOnChange={filter.handleOnChange}
                    options={filter.options}
                    selectedFilter={filter.selectedFilter}
                    selectOn={filter.selectOn}
                    subTitle={filter.subTitle}
                    title={filter.title}
                  />
                );
              }

              return (
                <MoreFilterCheckboxChoice
                  key={filter.title}
                  options={filter.options}
                  selectedFilter={filter.selectedFilter}
                  selectOn={filter.selectOn}
                  title={filter.title}
                  onChange={filter.handleOnChange}
                />
              );
            }
          )}
        </div>
        <div className="flex border-t border-black py-4">
          <button
            className="font-semibold text-[#838383]"
            onClick={() => {
              dispatch({ type: "RESET_ADVANCED_FILTERS" });
              toggleAdvancedFilters();
            }}
          >
            Effacer
          </button>
        </div>
      </>
    </Modal>
  );
}

export default MoreFilterModal;
