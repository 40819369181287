const ItemSearchMobile = ({ title, value, onClick }) => {
  return (
    <button
      className="flex flex-col items-start rounded-xl border border-mplgray bg-white px-4 py-3 shadow-md"
      onClick={onClick}
    >
      <div className="text-xl font-bold">{title}</div>
      <div className="leading-6 text-mplgray">{value}</div>
    </button>
  );
};

export default ItemSearchMobile;
