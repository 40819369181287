import { useApolloClient } from "@apollo/client";
import cx from "classnames";
import { Suspense, lazy, useCallback, useContext, useEffect, useState } from "react";
import { BiChevronLeft } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import compareIcon from "../../assets/images/compareIcon.png";
import localization from "../../assets/svg/localization.svg";
import { NewGoogleMap } from "../../components/CustomGoogleMap";
import AnnouncementItem from "../../components/items/AnnouncementItem";
import { AuthContext } from "../../context/AuthContext";
import { NavContext } from "../../context/NavContext";
import UseAdvancedFilter from "../../hooks/useAdvancedFilter";
import useTailwindBreakpoint from "../../hooks/useTailwindBreakpoint";
import GetListingSettingDatas from "../../queries/listings/listings";
import { isRoleLogistician } from "../../utils/user";
import SecondarySearchSkeleton from "./SecondarySearch.skeleton";
import CompareCSVDownload from "./components/CompareCSVDownload";
import Filter from "./components/Filter";
import MobileFilter from "./components/MobileFilter";
import MoreFilterModal from "./components/MoreFilterModal";
import useSecondarySearchHook from "./components/hooks/secondarySearchHooks";
import useCompareHook from "./components/hooks/useCompareHook";
const Compare = lazy(() => import("./components/Compare"));

const SecondarySearch = () => {
  const [showMapActivated, setShowMapActivated] = useState(false);
  const [selecterGoogleMapMarker, setSelecterGoogleMapMarker] = useState(null);
  const { state, dispatch, nbTotalOfFilterActivated } = UseAdvancedFilter();
  const { toggleRegisterForm } = useContext(NavContext);
  const { isXsTw } = useTailwindBreakpoint();

  const client = useApolloClient();

  const data = client.readQuery({
    query: GetListingSettingDatas,
    variables: { test: "" },
  });

  const {
    filterhooks,
    hits,
    titleHits,
    mfHooks,
    isAdvancedFiltersShowed,
    numberOfAdvancedSet,
    toggleAdvancedFilters,
    prestationIds,
    secondarySearchLoading,
  } = useSecondarySearchHook(state);
  const { user } = useContext(AuthContext);
  const {
    onListingSelected,
    onComparedListingSelected,
    removeComparedListing,
    selectedForCompare,
    isInCompareMode,
    setCompareMode,
    isInSelectMode,
    setSelectMode,
    selectedForSendingSpec,
    setSelectedForCompare,
    selectedFilter,
    filteredForCompare,
    filtered,
    setFiltered,
  } = useCompareHook(filterhooks, mfHooks, hits);
  const navigate = useNavigate();

  const goToAskQuote = () => {
    setSelectMode(false);
    navigate("/devis", {
      state: {
        listingInfo: selectedForSendingSpec.map((x) => ({
          userId: hits.find((h) => h.id === x).user_id,
          listingId: hits.find((h) => h.id === x).id,
        })),
        returnUrl: location.pathname,
      },
    });
  };

  const handleClickCompareButton = useCallback(() => {
    if (!user) {
      toggleRegisterForm();
      return;
    }
    setSelectMode((p) => !p);
    setCompareMode(false);
  }, [setCompareMode, setSelectMode, toggleRegisterForm, user]);

  useEffect(() => {
    if (!hits?.length && showMapActivated) {
      setShowMapActivated(false);
    }
  }, [hits?.length, showMapActivated]);

  return (
    <>
      <div className="flex h-full flex-col">
        <div className="sticky top-0 z-20 flex flex-col bg-white">
          <Filter
            dispatch={dispatch}
            nbrResults={hits.length}
            numberOfAdvancedSet={numberOfAdvancedSet}
            selectedFilter={selectedFilter}
            selectedIds={prestationIds ? Object.keys(prestationIds) : []}
            setSelectedForCompare={setSelectedForCompare}
            setSelectMode={setSelectMode}
            settingDatas={data?.getListingSettingDatas}
            state={state}
            toggleAdvancedFilters={toggleAdvancedFilters}
          />
          <MobileFilter
            dispatch={dispatch}
            nbTotalOfFilterActivated={nbTotalOfFilterActivated}
            numberOfResults={hits.length}
            settingDatas={data?.getListingSettingDatas}
            state={state}
          />

          <div className="hidden justify-between px-5 py-4 lg:flex">
            {!isInCompareMode && (
              <CompareButton
                disabled={isRoleLogistician(user)}
                style={isInSelectMode ? { background: "#1FC585" } : null}
                onClick={handleClickCompareButton}
              >
                <img alt="logo company" className="h-6 w-6" src={compareIcon} />
                <div>Comparer</div>
              </CompareButton>
            )}
            {isInCompareMode && (
              <button
                className="flex items-center gap-2 font-roboto-regular text-xl font-bold"
                onClick={() => setCompareMode(false)}
              >
                <BiChevronLeft /> Retour à ma recherche
              </button>
            )}
            {isInCompareMode && (
              <div
                style={{
                  marginLeft: "1rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CompareCSVDownload listings={filtered} />
              </div>
            )}
          </div>
        </div>

        <div className="relative flex w-full grow items-start overflow-y-auto">
          {(!isXsTw || !showMapActivated) && (
            <div className="relative flex w-full items-start">
              {!isInCompareMode && (
                <div
                  className={cx(
                    "relative flex flex-col w-full mt-5 lg:mt-0 px-4 lg:px-5",
                    {
                      "w-7/12": showMapActivated,
                    }
                  )}
                >
                  {titleHits && (
                    <div
                      className="mb-4 w-fit rounded-md bg-black p-4 text-base text-white shadow-2xl"
                      role="alert"
                    >
                      <span className="font-roboto-regular font-bold">
                        Aucune correspondance trouvée sur {titleHits.city}.
                      </span>{" "}
                      {titleHits.message}
                    </div>
                  )}
                  <div
                    className={`grid w-full grid-cols-1 gap-4 overflow-y-auto ${
                      showMapActivated ? "md:grid-cols-2" : "md:grid-cols-4"
                    }`}
                  >
                    {secondarySearchLoading ? (
                      <SecondarySearchSkeleton />
                    ) : (
                      hits
                        .map((hit) => {
                          return (
                            <AnnouncementItem
                              key={hit.id}
                              isInSelectMode={isInSelectMode}
                              isSelectedFromMap={
                                selecterGoogleMapMarker?.objectID === hit.objectID
                              }
                              listing={hit}
                              selected={
                                selectedForCompare?.findIndex(
                                  (x) => x === hit.id
                                ) !== -1
                              }
                              selectForCompare={(value) =>
                                onListingSelected(value, hit.id)
                              }
                            />
                          );
                        })
                    )}
                  </div>
                  {hits.length > 0 && (
                    <button
                      className="sticky inset-x-0 bottom-6 mx-auto my-6 w-fit rounded-full bg-mplgreen px-11 py-4 lg:my-12"
                      onClick={() => {
                        if (showMapActivated) {
                          setSelecterGoogleMapMarker(null);
                        }
                        setShowMapActivated((prev) => !prev);
                      }}
                    >
                      <div className="flex gap-2">
                        {showMapActivated
                          ? "Masquer la carte"
                          : "Afficher la carte"}
                        <img alt="localization" src={localization} />
                      </div>
                    </button>
                  )}
                </div>
              )}
            </div>
          )}

          <NewGoogleMap
            hits={hits}
            selecterGoogleMapMarker={selecterGoogleMapMarker}
            setSelecterGoogleMapMarker={setSelecterGoogleMapMarker}
            setShowMapActivated={setShowMapActivated}
            showMapActivated={showMapActivated}
          />
        </div>

        {isInCompareMode && filteredForCompare.length > 0 && (
          <Suspense fallback=''><Compare
            filtered={filtered}
            listingIds={filteredForCompare}
            removeComparedListing={removeComparedListing}
            setFiltered={setFiltered}
            onComparedListingSelected={onComparedListingSelected}
          /></Suspense>
        )}
        {((isInCompareMode && filteredForCompare.length === 0) ||
          (hits.length === 0 && !secondarySearchLoading)) && (
          <NoListingFound>
            <h1>Aucun résultat ne correspond à vos critères</h1>
          </NoListingFound>
        )}
      </div>
      {isInSelectMode && !isInCompareMode && hits.length > 0 && (
        <CompareFooter>
          <NbrOfCompare>
            {selectedForCompare.length} prestataires sélectionnés (max 5)
          </NbrOfCompare>
          <CompareAction>
            <CompareButton
              style={{
                background: "#1FC585",
                border: "none",
                marginRight: "1rem",
              }}
              onClick={() => setCompareMode(true)}
            >
              <div>Comparer</div>
            </CompareButton>
            <CompareButton
              onClick={() => {
                setSelectMode((p) => !p);
                setCompareMode(false);
              }}
            >
              <div>Annuler</div>
            </CompareButton>
          </CompareAction>
        </CompareFooter>
      )}
      {isInCompareMode &&
        selectedForSendingSpec.length > 0 &&
        filtered.length > 0 &&
        filteredForCompare.length > 0 && (
          <CompareFooter style={{ borderTop: "1px solid" }}>
            <CompareAction>
              <CompareButton
                style={{
                  background: "#1FC585",
                  border: "none",
                  marginRight: "1rem",
                }}
                onClick={() => goToAskQuote()}
              >
                <div>Demander un devis</div>
              </CompareButton>
              <CompareButton
                onClick={() => {
                  setSelectMode((p) => !p);
                  setCompareMode(false);
                }}
              >
                <div>Annuler</div>
              </CompareButton>
            </CompareAction>
          </CompareFooter>
        )}
      {isAdvancedFiltersShowed && (
        <MoreFilterModal
          dispatch={dispatch}
          settingDatas={data?.getListingSettingDatas}
          state={state}
          toggleAdvancedFilters={toggleAdvancedFilters}
        />
      )}
    </>
  );
};

const NbrOfCompare = styled.div`
  display: flex;
  margin-right: 1rem;
`;

const CompareAction = styled.div`
  display: flex;
`;

const CompareFooter = styled.div`
  padding-bottom: 2rem;
  padding-top: 2rem;
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  bottom: 0;
  width: 100%;
  background-color: white;
`;

const CompareButton = styled.button`
  border: 2px solid black;
  display: flex;
  min-width: 8rem;
  min-height: 35px;
  border-radius: 10px;
  font-weight: 400;
  background: white;
  font-size: 16px;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px 20px;
  &:disabled {
    background: rgba(178, 178, 178, 1);
  }
`;

const NoListingFound = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50vh;
  h1 {
    font-size: 25px;
    font-weight: 300;
    text-align: center;
    color: black;
  }
`;

export default SecondarySearch;
