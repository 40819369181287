const Title = () => {
  return <div></div>;
};

Title.H3 = ({ children }) => {
  return (
    <h3 className="text-xl font-bold sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl">
      {children}
    </h3>
  );
};
Title.H3.displayName = "TitleH3";

Title.H2 = ({ children }) => {
  return (
    <h2 className="text-2xl font-bold sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl">
      {children}
    </h2>
  );
};
Title.H2.displayName = "TitleH2";

Title.H1 = ({ children }) => {
  return (
    <h1 className="text-3xl font-bold sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl">
      {children}
    </h1>
  );
};
Title.H1.displayName = "TitleH1";

export default Title;
