import { useState } from "react";
import Datepicker from "react-tailwindcss-datepicker";

const CustomDatePicker = ({
  label,
  value,
  onChange,
  error,
  ...datePickerProps
}) => {
  const [internalDate, setInternalDate] = useState(null);

  const date = value || internalDate;
  const setDate = onChange || setInternalDate;

  const handleDateChange = (newDate) => {
    setDate(newDate);
  };

  return (
    <div>
      {label && (
        <label className="mb-2 ml-px block text-sm font-medium leading-6 text-black lg:text-lg">
          {label}
        </label>
      )}
      <Datepicker
        asSingle={true}
        displayFormat="MM-DD-YYYY"
        inputClassName="relative w-full rounded-xl border-gray-300 p-2.5 text-gray-900 shadow-sm transition-all duration-300 placeholder:text-gray-400 focus:border-emerald-500 focus:ring focus:ring-emerald-500/20 sm:text-sm sm:leading-6 "
        primaryColor="emerald"
        useRange={false}
        value={date}
        onChange={handleDateChange}
        {...datePickerProps}
      />
      {error && (
        <p className="mt-1 text-left text-sm text-red-600">{error.message}</p>
      )}
    </div>
  );
};

export default CustomDatePicker;
