import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import GreenButton from "../../../components/ui/GreenButton";
import Title from "../../../components/ui/Title";
import MobileListSpecificationItem from "./MobileListSpecificationItem";

const MobileListSpecification = ({ specifications, setSpecifications }) => {
  const navigate = useNavigate();

  const handleCreateSpecification = useCallback(() => {
    navigate("/dashboard/specification-create");
  }, [navigate]);

  return (
    <div className="relative flex h-full flex-col px-4 py-8 md:px-12">
      <Title.H3>Mon cahier des charges</Title.H3>

      <div className="my-9 flex grow flex-col gap-4">
        {specifications.map((specification) => {
          return (
            <MobileListSpecificationItem
              key={specification.id}
              setSpecifications={setSpecifications}
              specification={specification}
            />
          );
        })}
      </div>

      <div className="sticky bottom-0 z-30 -mx-4 -mb-8 flex justify-center border-t border-mplgray bg-white py-3 md:-mx-12 lg:hidden">
        <GreenButton onClick={handleCreateSpecification}>
          Créer mon cahier des charges
        </GreenButton>
      </div>
    </div>
  );
};

export default MobileListSpecification;
