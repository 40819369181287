import { IonIcon } from "@ionic/react";
import { chevronForwardOutline } from "ionicons/icons";
import MoreFilterBubbleChoice from "../../../../../views/secondary_search/components/MoreFilterModal/components/MoreFilterBubbleChoice";
import ItemMoreFilterOption from "../ItemMoreFilterOption";

const ItemMoreFilter = ({ filter }) => {
  return (
    <div className="relative grid grid-cols-12 border border-b-mplgray px-11 py-5">
      <label
        className="col-start-1 col-end-12 grow font-bold"
        htmlFor={filter.id}
      >
        {filter.placeholder}
      </label>

      <input
        className="peer absolute inset-x-0 bottom-80 top-0 z-10 opacity-0"
        defaultChecked={filter.selectedFilter.length > 0}
        id={filter.id}
        type="checkbox"
      />

      <div className="flex items-center justify-center text-right transition-transform duration-200 ease-in-out peer-checked:rotate-90">
        <IonIcon icon={chevronForwardOutline} />
      </div>

      <div className="col-span-full flex max-h-0 flex-col gap-5 space-y-2 overflow-y-auto text-left font-roboto text-sm transition-all duration-300 ease-in-out peer-checked:max-h-64">
        {filter.useBubbleComponent ? (
          <MoreFilterBubbleChoice
            handleOnChange={filter.handleOnChange}
            options={filter.options}
            selectedFilter={filter.selectedFilter}
            selectOn={filter.selectOn}
          />
        ) : (
          filter.options.map((option) => {
            return (
              <ItemMoreFilterOption
                key={option.code}
                filter={filter}
                handleOnChange={filter.handleOnChange}
                option={option}
              />
            );
          })
        )}
      </div>
    </div>
  );
};

export default ItemMoreFilter;
