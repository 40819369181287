const SecondarySearchSkeleton = () => {
  return (
    <>
      <div className="flex animate-pulse flex-col gap-y-3 py-3">
        <div className="h-[330px] rounded-xl bg-slate-300"></div>
        <div className="h-8 rounded bg-slate-300"></div>
      </div>
      <div className="flex animate-pulse flex-col gap-y-3 py-3">
        <div className="h-[330px] rounded-xl bg-slate-300"></div>
        <div className="h-8 rounded bg-slate-300"></div>
      </div>
      <div className="flex animate-pulse flex-col gap-y-3 py-3">
        <div className="h-[330px] rounded-xl bg-slate-300"></div>
        <div className="h-8 rounded bg-slate-300"></div>
      </div>
      <div className="flex animate-pulse flex-col gap-y-3 py-3">
        <div className="h-[330px] rounded-xl bg-slate-300"></div>
        <div className="h-8 rounded bg-slate-300"></div>
      </div>
    </>
  );
};

export default SecondarySearchSkeleton;
