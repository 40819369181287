import { useLazyQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { UserLogout } from "../../queries/security/security";

function Logout() {
  const [canGo, setCanGo] = useState(false);
  const { dispatch } = useContext(AuthContext);
  const [logout] = useLazyQuery(UserLogout);

  useEffect(() => {
    //logout().then((r) => console.log(r.data?.userLogout));
    setTimeout(() => {
      console.log("Logout !!!");
      dispatch({ type: "LOGOUT" });
      setCanGo(true);
    }, 100);
  }, []);

  return <><Navigate to="/" /></>;
}

export default Logout;
