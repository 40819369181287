import React from "react";
import styled from "styled-components";
import SearchBar from "../SearchBar";

function ModalSearchBar({ setDisplaySearchBar }) {
  return (
    <DivBody>
      <DivTEST>
        <DivSearch>
          <SearchBar onSearch={() => setDisplaySearchBar(false)} />
        </DivSearch>
      </DivTEST>
      <DivOverlay onClick={() => setDisplaySearchBar(false)} />
    </DivBody>
  );
}

const DivBody = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 40;
`;

const DivSearch = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const DivTEST = styled.div`
  width: 100%;
  background: black;
  padding: 0 20px;
`;

const DivOverlay = styled.div`
  height: 100vh;
  width: 100vw;
`;
export default ModalSearchBar;
