import { useLazyQuery } from "@apollo/client";
import debounce from "lodash.debounce";
import { useState } from "react";
import { CitiesSearch } from "../../../../queries/citiesSearch/citiesSearch";
import SelectFromList from "./SelectFromList";

function SelectFromGeoApi({
  picked = [],
  onValuesChange,
  isMulti = true,
  restrict = "fr",
  isCol = false,
  placeholder,
  label,
  displayInline,
  error,
  isRegional = false,
  isCountryOnly = false,
}) {
  const [options, setOptions] = useState([]);
  const [geoApiSearch, { loading }] = useLazyQuery(CitiesSearch);

  const searchGeo = debounce((text) => {
    if (!text) return setOptions([]);

    geoApiSearch({
      variables: {
        query: text,
        options: restrict,
        regional: isRegional,
        isCountryOnly,
      },
    })
      .then((res) => {
        setOptions(
          res.data.CitiesSearch.map((item) => ({
            code: item.value,
            label: item.label,
          }))
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, 700);

  return (
    <SelectFromList
      displayInline={displayInline}
      error={error}
      filterList={false}
      filterPicked={false}
      filterSearch={false}
      isCol={isCol}
      isLoading={loading}
      isMulti={isMulti}
      label={label}
      options={options}
      picked={picked}
      placeholder={placeholder}
      selectOn="code"
      toReturn="object"
      onSearchFieldChange={(text) => searchGeo(text)}
      onValuesChange={(values) => onValuesChange && onValuesChange(values)}
    />
  );
}

export default SelectFromGeoApi;
