const HeaderSection = () => {
  return (
    <div className="mb-5 font-roboto text-2xl text-white lg:w-[80%] ">
      <div>
        <h1
          className="font-roboto font-bold lg:my-[40px] lg:font-roboto-regular lg:text-[68px] lg:leading-[1.2] lg:tracking-[0.03em]"
          style={{ letterSpacing: "0.03em" }}
        >
          Trouvez votre
          <span className="font-roboto text-mplgreen lg:font-roboto-regular lg:leading-[81.6px]">
            {" "}
            prestataire logistique{" "}
          </span>
          idéal en quelques clics !
        </h1>
        <p className="mt-[25px] text-base lg:text-2xl">
          Recherchez, comparez, choisissez en fonction de vos critères et sans
          intermédiaire.
        </p>
      </div>
    </div>
  );
};

export default HeaderSection;
