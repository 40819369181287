export const FILTER_SPEC = [
  "GDR",
  "CEL",
  "OPT",
  "GDK",
  "GDE",
  "GDD",
  "GDI",
  "GDL",
  "DRO",
  "B2C",
  "B2B",
];
