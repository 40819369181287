import { HttpLink } from "@apollo/client";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import moment from "moment";
import "moment/locale/fr";
import ReactDOM from "react-dom/client";
import "react-sliding-side-panel/lib/index.css";
import App from "./app/App";
import TokenMiddlewareWrapper from "./components/TokenMiddlewareWrapper/TokenMiddlewareWrapper";
import { config } from "./config/env.config";
import { initAmplitude } from "./constants/amplitude";
import { AuthContextProvider } from "./context/AuthContext";
import "./index.css";

LogRocket.init("mavrick/monprestatairelogistique");
setupLogRocketReact(LogRocket);

const httpLink = new HttpLink({ uri: `${config.get("BACKEND_URL")}/graphql` });
moment.locale("fr");
const root = ReactDOM.createRoot(document.getElementById("root"));
initAmplitude();
// if ((process.env['REACT_APP_ENV'] ?? process.env.NODE_ENV) !== 'development') {
// 	Sentry.init({
// 		dsn: 'https://472eb93eaff74c448ca2c505de37e455@o4505244442558464.ingest.sentry.io/4505244520873984',
// 		integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
// 		release: 'mpl@' + process.env.npm_package_version,
// 		// Performance Monitoring
// 		tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
// 		// Session Replay
// 		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
// 		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// 	});
// }

root.render(
  <AuthContextProvider>
    <TokenMiddlewareWrapper uri={httpLink}>
      <App />
    </TokenMiddlewareWrapper>
  </AuthContextProvider>
);
