import { useLazyQuery, useMutation, useSubscription } from "@apollo/client";
import cx from "classnames";
import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { FcApproval } from "react-icons/fc";
import CustomSearch from "../../../components/ui/CustomSearch";
import { config } from "../../../config/env.config";
import { AuthContext } from "../../../context/AuthContext";
import useTailwindBreakpoint from "../../../hooks/useTailwindBreakpoint";
import {
  GetConversation,
  GetConversationDetails,
  SendMessage,
  SubscriptionMessage,
} from "../../../queries/messaging/message";
import DetailQuote from "./DetailQuote";

function Quote({ title }) {
  const [displayQuote, setDisplayQuote] = useState([]);
  const [selectedQuote, setSelectedQuote] = useState(null);
  const [valueSearch, setValueSearch] = useState("");
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [messages, setMessages] = useState([]);
  const { isXsTw } = useTailwindBreakpoint();
  const { user } = useContext(AuthContext);
  const [getConversation] = useLazyQuery(GetConversation, {
    fetchPolicy: "no-cache",
  });
  const [getConversationDetails, { loading: conversationDetailsLoading }] =
    useLazyQuery(GetConversationDetails, {
      fetchPolicy: "no-cache",
    });
  const [sendMessage, { loading: sendMessageLoading }] =
    useMutation(SendMessage);

  const { data, loading } = useSubscription(SubscriptionMessage);

  const isPremium =
    user?.subscriptions?.length > 0 &&
    user?.subscriptions[0].subscription.name.startsWith("Premium");

  const getSecondUserFromMessage = useCallback(
    (item) => {
      if (user.id === item?.firstSender?.id) return item.firstReceiver;
      else return item?.firstSender;
    },
    [user.id]
  );

  useEffect(() => {
    getConversation()
      .then((res) => {
        setDisplayQuote(res.data.getConversation ?? []);
        if (!isXsTw) setSelectedQuote(res.data?.getConversation[0]);
      })
      .catch((err) => console.error("error ", err));
  }, [getConversation, isXsTw]);

  useEffect(() => {
    if (data?.message && !loading) {
      const index = displayQuote.findIndex(
        (x) => getSecondUserFromMessage(x)?.id === data?.message?.sender.id
      );
      if (index !== -1) {
        getConversation()
          .then((res) => {
            setDisplayQuote(res.data.getConversation ?? []);
          })
          .catch((err) => console.error("error ", err));
        if (
          getSecondUserFromMessage(selectedQuote)?.id ===
          data?.message?.sender.id
        ) {
          setMessages((prev) => [...prev, data?.message]);
        }
      }
    }
  }, [data]);

  useLayoutEffect(() => {
    if (selectedQuote) {
      getConversationDetails({
        variables: {
          secondUserId:
            getSecondUserFromMessage(selectedQuote)?.id === user?.id
              ? getSecondUserFromMessage(selectedQuote)?.id
              : getSecondUserFromMessage(selectedQuote)?.id,
        },
      })
        .then((res) => {
          setMessages(
            res.data?.getConversationDetails?.sort(
              (a, b) => a.createdAt - b.createdAt
            ) ?? []
          );
        })
        .catch((err) => console.error("error ", err));
    }
  }, [
    getConversationDetails,
    getSecondUserFromMessage,
    selectedQuote,
    user?.id,
  ]);

  const doUploadFiles = async (_file) => {
    try {
      setIsUploadingFile(true);
      if (_file === undefined || _file === null) return;

      const fd = new FormData();
      _file.forEach((file) => fd.append("file", file));
      fd.append("file", _file);

      const r = await fetch(`${config.get("BACKEND_URL")}/files`, {
        method: "POST",
        body: fd,
      });
      return await r.json();
    } catch (error) {
      console.log(error);
    } finally {
      setIsUploadingFile(false);
    }
  };

  const onSend = async (message, receiverId, files, conversationId) => {
    if (message !== "" || files.length > 0) {
      let attached = [];
      if (files.length > 0) {
        attached = await doUploadFiles(files);
      }
      sendMessage({
        variables: {
          input: {
            receiverId,
            message,
            attached,
            conversationId,
          },
        },
      }).then((res) => {
        getConversation()
          .then((res) => {
            setDisplayQuote(res.data.getConversation ?? []);
          })
          .catch((err) => console.error("error ", err));
        if (
          getSecondUserFromMessage(selectedQuote).id === receiverId &&
          res.data?.sendMessage
        )
          setMessages((prev) => [...prev, res.data?.sendMessage]);
      });
    }
  };

  const filteredQuotes = useMemo(() => {
    if (valueSearch === "") {
      return displayQuote;
    }
    return displayQuote.filter((x) =>
      getSecondUserFromMessage(x)
        .company.toLowerCase()
        .includes(valueSearch.toLowerCase())
    );
  }, [displayQuote, getSecondUserFromMessage, valueSearch]);

  return (
    <div className="flex h-full">
      <div className="flex grow flex-row text-gray-800 antialiased">
        <div
          className={cx("flex w-80 shrink-0 flex-row bg-gray-100 p-4", {
            grow: isXsTw,
            hidden: isXsTw && selectedQuote,
          })}
        >
          <div className="-mr-4 flex h-full w-full flex-col p-4">
            <div className="mb-4 flex flex-row items-center">
              <div className="text-xl font-semibold">{title}</div>
            </div>
            <CustomSearch
              placeholder="Rechercher"
              value={valueSearch}
              onChange={(e) => setValueSearch(e.target?.value)}
            />

            <div className="mt-6 flex h-0 grow">
              <div className="-mx-4 flex grow flex-col overflow-auto">
                {filteredQuotes.map((item) => {
                  return (
                    <button
                      key={item.id}
                      className={cx("flex flex-row items-center p-4", {
                        "border-l-2 border-mplgreen bg-gradient-to-r from-mplgreen/20 to-transparent":
                          selectedQuote?.id === item.id,
                      })}
                      onClick={() => setSelectedQuote(item)}
                    >
                      <div className="flex h-10 w-10 shrink-0 items-center justify-center overflow-hidden rounded-full bg-mplgreen font-bold text-white">
                        {getSecondUserFromMessage(item)?.profilePic !== null ? (
                          <img
                            alt="image company"
                            className="h-full w-full object-cover"
                            src={getSecondUserFromMessage(item)?.profilePic.url}
                          />
                        ) : (
                          <span>
                            {getSecondUserFromMessage(
                              item
                            )?.company[0].toUpperCase()}
                          </span>
                        )}
                      </div>
                      <div className="ml-3 flex grow flex-col text-left">
                        <div className="text-sm font-medium">
                          {getSecondUserFromMessage(item)?.company}
                        </div>
                        <div className="text-xs">
                          {getSecondUserFromMessage(item)?.status ===
                          "VALIDATED" ? (
                            <p className="flex items-center">
                              <span className="mr-1">Profil vérifié</span>{" "}
                              <FcApproval></FcApproval>
                            </p>
                          ) : (
                            <p>Profil non vérifié</p>
                          )}
                        </div>
                      </div>
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        {selectedQuote && !conversationDetailsLoading && (
          <DetailQuote
            isFromMulti={selectedQuote.isFromMulti}
            isPremium={isPremium}
            messages={messages}
            selectedUser={getSecondUserFromMessage(selectedQuote)}
            sendMessageLoading={sendMessageLoading || isUploadingFile}
            unselectQuote={() => setSelectedQuote(null)}
            onSend={(message, receiverId, files) =>
              onSend(message, receiverId, files, selectedQuote.id)
            }
          />
        )}
      </div>
    </div>
  );
}
export default Quote;
