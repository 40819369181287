export function getIp() {
  let ipAddress = "";
  fetch("https://geolocation-db.com/json/")
    .then((response) => {
      return response.json();
    }, "jsonp")
    .then((res) => {
      ipAddress = res.Ipv4;
    })
    .catch((err) => console.log(err));
  return ipAddress;
}

export function translateTypeToFrench(type) {
  const translations = {
    locality: "Ville",
    administrative_area_level_1: "Région",
    administrative_area_level_2: "Département",
    country: "Pays",
    postal_code: "Code Postal",
    street_address: "Adresse",
    route: "Route",
    intersection: "Intersection",
    political: "Zone Politique",
    neighborhood: "Quartier",
    premise: "Lieu",
    subpremise: "Sous-lieu",
    natural_feature: "Caractéristique Naturelle",
    airport: "Aéroport",
    park: "Parc",
    point_of_interest: "Point d'Intérêt",
    floor: "Étage",
    establishment: "Établissement",
    parking: "Parking",
    post_box: "Boîte Postale",
    room: "Salle",
    bus_station: "Gare Routière",
    train_station: "Gare Ferroviaire",
    transit_station: "Station de Transit",
  };

  return translations[type];
}
