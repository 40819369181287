import { useMemo } from "react";
import CustomButton from "../../../components/ui/CustomButton";
import CustomToggle from "../../../components/ui/CustomToggle";
import useTailwindBreakpoint from "../../../hooks/useTailwindBreakpoint";

const MobileCorrespondingToSpecification = ({
  isLoading,
  compatibilityCount,
  previousStep,
  nextStep,
  control,
}) => {
  const { isXsTw, isMdTw } = useTailwindBreakpoint();

  const renderSubtitle = useMemo(() => {
    const count = compatibilityCount ?? 0;
    const isPlural = count > 1;

    return `${count} prestataire${isPlural ? "s" : ""} correspond${
      isPlural ? "ent" : ""
    } à vos critères`;
  }, [compatibilityCount]);

  return (
    <div className="flex h-full flex-1 flex-col overflow-auto p-5 pb-0 md:p-8 md:pb-0">
      <h1 className="mb-4 text-xl font-bold sm:text-2xl md:text-3xl">
        Votre cahier des charges en quelques clics
      </h1>
      <div className="flex flex-col gap-2">
        {isLoading ? (
          <div className="max-w-sm animate-pulse" role="status">
            <div className="mb-4 h-2.5 w-48 rounded-full bg-gray-200"></div>
            <div className="mb-2.5 h-2 max-w-[360px] rounded-full bg-gray-200"></div>
            {isMdTw && (
              <div className="mb-2.5 h-2 rounded-full bg-gray-200"></div>
            )}
            {isXsTw && (
              <>
                <div className="mb-2.5 h-2 max-w-[330px] rounded-full bg-gray-200"></div>
                <div className="mb-2.5 h-2 max-w-[300px] rounded-full bg-gray-200"></div>
                <div className="h-2 max-w-[360px] rounded-full bg-gray-200"></div>
              </>
            )}
            <span className="sr-only">Loading...</span>
          </div>
        ) : (
          <>
            {renderSubtitle}
            {compatibilityCount > 0 && (
              <CustomToggle
                control={control}
                label="Souhaitez-vous la communication de votre cahier des charges auprès des logisticiens de notre plateforme qui répondent à vos critères ?"
                name="specification-send-spec"
              />
            )}
          </>
        )}
      </div>

      <div className="sticky bottom-0 -mx-5 mt-auto flex justify-center gap-4 border-t border-mplgray bg-white px-3 py-4 md:-mx-8">
        <CustomButton
          isFull
          color="black"
          variant="outlined"
          onClick={previousStep}
        >
          Retour
        </CustomButton>
        <CustomButton isFull isLoading={isLoading} onClick={nextStep}>
          Terminer
        </CustomButton>
      </div>
    </div>
  );
};

export default MobileCorrespondingToSpecification;
