import { yupResolver } from "@hookform/resolvers/yup";
import { useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { SearchContext } from "../../../../context/SearchContext";
import SelectFromGeoApi from "../../../../views/dashboard/listings/CreateListingComponents/SelectFromGeoApi";
import SelectFromList from "../../../input/SelectFromList";
import CustomButton from "../../../ui/CustomButton";
import CustomDatePicker from "../../../ui/CustomDatePicker";
import CustomInput from "../../../ui/CustomInput";

const step1Schema = Yup.object({
  offerTitle: Yup.string().required(
    "Le titre de l'appel d'offre est obligatoire"
  ),
  companyName: Yup.string().required("Le nom de l'entreprise est obligatoire"),
  sector: Yup.array()
    .of(Yup.string().required())
    .min(1, "Au moins un secteur est requis"),
  workingArea: Yup.array()
    .of(Yup.string().required())
    .min(1, "Au moins un périmètre est requis"),
  publicationDate: Yup.object().shape({
    startDate: Yup.date().required("La date de parution est obligatoire"),
  }),
  closingDate: Yup.object().shape({
    startDate: Yup.date().required("La date de clôture est obligatoire"),
  }),
  localisation: Yup.array()
    .of(
      Yup.object().shape({
        code: Yup.string().required(),
        label: Yup.string().required(),
      })
    )
    .min(1, "Au moins un lieu est requis")
    .required("La localisation est obligatoire"),
});

const TenderFormStep1 = ({ nextStep, defaultValues }) => {
  const {
    register,
    control,
    setValue,
    watch,
    formState: { errors },
    setError,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(step1Schema),
    defaultValues,
  });
  const { settingDatas } = useContext(SearchContext);

  const watchLocation = watch("localisation");
  const watchSector = watch("sector");
  const watchWorkingArea = watch("workingArea");
  const watchPublicationDate = watch("publicationDate");

  const pickedSector = watchSector.map((s) => {
    return settingDatas?.workingDomains.find((wd) => {
      return wd.code === s;
    });
  });

  const pickedWorkingArea = watchWorkingArea.map((s) => {
    return settingDatas?.workingArea.find((wd) => {
      return wd.code === s;
    });
  });

  const onSubmit = (values) => {
    nextStep("step1", values);
  };

  return (
    <form
      className="grid grid-cols-1 gap-3 sm:grid-cols-2"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="col-span-1 lg:col-span-2">
        <CustomInput
          error={errors?.offerTitle}
          label="Titre appel d’offre *"
          name="offerTitle"
          register={register}
        />
      </div>
      <div className="col-span-1 lg:col-span-2">
        <CustomInput
          error={errors?.companyName}
          label="Nom entreprise *"
          name="companyName"
          register={register}
        />
      </div>
      <div className="col-span-1">
        <SelectFromList
          displayInline
          error={errors?.sector}
          label="Secteur(s) concerné(s) *"
          options={settingDatas?.workingDomains}
          picked={pickedSector}
          selectOn="code"
          onValuesChange={(value) => {
            if (value) setError("sector", null);
            setValue("sector", value);
          }}
        />
      </div>
      <div className="col-span-1">
        <SelectFromList
          displayInline
          error={errors?.workingArea}
          label="Périmètre(s) concerné(s) *"
          options={settingDatas?.workingArea}
          picked={pickedWorkingArea}
          selectOn="code"
          onValuesChange={(value) => {
            if (value) setError("workingArea", null);
            setValue("workingArea", value);
          }}
        />
      </div>
      <div className="col-span-1">
        <Controller
          control={control}
          name="publicationDate"
          render={({ field }) => (
            <CustomDatePicker
              error={errors?.publicationDate?.startDate}
              label="Date de parution *"
              popoverDirection="up"
              {...field}
            />
          )}
        />
      </div>
      <div className="col-span-1">
        <Controller
          control={control}
          name="closingDate"
          render={({ field }) => (
            <CustomDatePicker
              disabled={!watchPublicationDate.startDate}
              error={errors?.closingDate?.startDate}
              label="Date de clôture *"
              minDate={watchPublicationDate.startDate}
              popoverDirection="up"
              {...field}
            />
          )}
        />
      </div>
      <div className="col-span-1">
        <SelectFromGeoApi
          displayInline
          error={errors?.localisation}
          label="Localisation(s) *"
          picked={watchLocation}
          restrict="world"
          onValuesChange={(value) => {
            if (value) setError("localisation", null);
            setValue("localisation", value);
          }}
        />
      </div>
      <div className="sm:col-span-2">
        <div className="mt-4 flex items-center justify-between">
          <div />
          <CustomButton type="submit">Suivant</CustomButton>
        </div>
      </div>
    </form>
  );
};

export default TenderFormStep1;
