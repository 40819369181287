import { ROLES } from "../constants/Roles";

export const userHasSubscription = (user) => {
  if (!user) return false;

  return user?.subscriptions?.length >= 1;
};

export const isAuthenticated = (user) => {
  return user;
};

export const isRoleCompany = (user) => {
  if (!user) return false;

  return user?.role === ROLES.COMPANY;
};

export const isRoleLogistician = (user) => {
  if (!user) return false;

  return user?.role === ROLES.LOGISTICIAN;
};

export const isRoleAdmin = (user) => {
  if (!user) return false;

  return user?.role === ROLES.ADMIN;
};

export const isUserValidated = (user) => {
  if (!user) return false;

  return user.status === "VALIDATED";
};
