import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FcApproval } from "react-icons/fc";
import styled from "styled-components";
import {
  StatusPart,
  Title,
  TitlePart,
} from "../../../components/others/dashboard/styled/PageTitleElements";
import CustomButton from "../../../components/ui/CustomButton";
import CustomInput from "../../../components/ui/CustomInput";
import { AuthContext } from "../../../context/AuthContext";
import {
  AskForValidation,
  GetUserByID,
  UpdateUser,
} from "../../../queries/profile/profile";
import ProfileFieldForm from "./components/ProfileInfoForm";
import ProfilePicUploader from "./components/ProfilePicUploader";

function Profile() {
  const [isVisible, setVisible] = useState(false);
  const [canBeValidated, setCanBeValidated] = useState(false);
  const { user, dispatch } = useContext(AuthContext);
  const [saved, setSaved] = useState(false);

  const { register, getValues, setValue } = useForm();

  const [getUserById, { data, loading: getUserLoading, refetch }] =
    useLazyQuery(GetUserByID, {
      fetchPolicy: "no-cache",
    });

  const [updateUser] = useMutation(UpdateUser, {
    refetchQueries: [{ query: GetUserByID, variables: { id: user.id } }],
  });
  const [askForValidation] = useMutation(AskForValidation, {
    refetchQueries: [{ query: GetUserByID, variables: { id: user.id } }],
  });

  const parseAddress = (address) => {
    if (typeof address !== "string") {
      return { street: null, city: null, postalCode: null };
    }

    const parts = address.split(",").map((x) => x.trim());

    if (parts.length !== 3) {
      console.warn(
        "Address format is incorrect. Expected format: 'street, postalCode, city'"
      );
      return { street: null, city: null, postalCode: null };
    }

    return { street: parts[0], postalCode: parts[1], city: parts[2] };
  };

  const handleDataChange = (key, value) => {
    if (Array.isArray(value)) {
      setValue(
        key,
        value.map((x) => ({ ...x, __typename: undefined }))
      );
    } else {
      setValue(key, { ...value, __typename: undefined });
    }
    submitDatas();
  };

  const submitDatas = async () => {
    const values = getValues();
    const { street, postalCode, city } = values;

    if (street || postalCode || city) {
      values.address = [street, postalCode, city].filter(Boolean).join(", ");
    } else {
      values.address = "";
    }

    delete values.street;
    delete values.city;
    delete values.postalCode;

    const { profilePic, ...otherValues } = values;

    const variables = {
      ...otherValues,
      profilePic: profilePic || null,
    };

    const { data } = await updateUser({ variables });

    setSaved(true);

    dispatch({
      type: "UPDATE_USER",
      payload: { user: data.updateUser },
    });
  };

  useEffect(() => {
    setSaved(false);

    getUserById({ variables: { id: user.id } })
      .then((res) => {
        dispatch({
          type: "UPDATE_USER",
          payload: { user: { ...user, status: res.data.getUserById.status } },
        });
        const addressParts = parseAddress(res.data.getUserById.address);
        setValue("company", res.data.getUserById.company);
        setValue("siret", res.data.getUserById.siret);
        setValue("apecode", res.data.getUserById.apecode);
        setValue("postalCode", addressParts?.postalCode);
        setValue("city", addressParts?.city);
        setValue("street", addressParts?.street);
        setValue("email", res.data.getUserById.email);
        setValue("phone", res.data.getUserById.phone);
        setValue("profilePic", res.data.getUserById.profilePic);

        setCanBeValidated(
          !!res.data.getUserById.company &&
            !!res.data.getUserById.siret &&
            !!res.data.getUserById.apecode &&
            !!addressParts?.postalCode &&
            !!addressParts?.city &&
            !!addressParts?.street &&
            !!res.data.getUserById.email &&
            !!res.data.getUserById.phone &&
            !!res.data.getUserById.proof
        );
      })
      .catch((e) => console.log(e));
  }, [saved]);

  const Visible = () => {
    setVisible(!isVisible);
  };

  const hangleAskValidation = async () => {
    const { data } = await askForValidation();
    if (data?.askForValidation) {
      const res = await refetch();

      dispatch({
        type: "UPDATE_USER",
        payload: { user: res.data.getUserById },
      });
    }
  };

  return (
    <div className="flex flex-col px-4 py-8 md:px-12">
      <Title className="w-full md:max-w-[400px]">
        <div className="flex w-full grow justify-between">
          <div className="flex items-center">
            <TitlePart size="30">Profil</TitlePart>
            {data?.getUserById.status !== "VALIDATED" && (
              <StatusPart style={{ width: "100%" }}>
                (Statut non vérifié)
              </StatusPart>
            )}
            {data?.getUserById.status === "VALIDATED" && (
              <StatusPart style={{ width: "100%", alignItems: "center" }}>
                (vérifié <FcApproval></FcApproval>)
              </StatusPart>
            )}
          </div>
          {data?.getUserById.status === "INITIAL" && canBeValidated && (
            <CustomButton
              className="w-[40%] justify-end md:w-[30%] lg:whitespace-pre-wrap "
              onClick={hangleAskValidation}
            >
              Faire vérifier mon profil
            </CustomButton>
          )}
        </div>
      </Title>

      <Wrapper>
        <div className="w-full md:max-w-[400px]">
          <InfoList className="with-lines">
            <InfoListItem style={{ borderBottom: "none", padding: "0px" }}>
              <WrapperProfilePic>
                <div className="flex items-center gap-x-5 lg:flex-col">
                  <CompanyLogoMask>
                    {data?.getUserById.profilePic !== undefined &&
                    data?.getUserById.profilePic !== null ? (
                      <CompanyLogoThumb
                        alt=""
                        src={data.getUserById.profilePic.url}
                      ></CompanyLogoThumb>
                    ) : (
                      <span>Logo</span>
                    )}
                  </CompanyLogoMask>
                  <div className="w-[100px] text-xs lg:mt-2 lg:text-center">
                    <div> 200x200</div>
                    <div>Format jpeg / png, 20 Mo max</div>
                  </div>
                </div>
                <ProfilePicUploader
                  accept=".jpg,.jpeg,.png,.gif"
                  defaultFile={
                    data?.getUserById.profilePic
                      ? [data?.getUserById.profilePic]
                      : []
                  }
                  name="profile-pic-selector"
                  onValuesChange={(values) => {
                    handleDataChange("profilePic", values);
                  }}
                />
              </WrapperProfilePic>
            </InfoListItem>
            <InfoListItem>
              <InfoListItemLabel>
                <h3>Profil</h3>
              </InfoListItemLabel>
              <InfoListItemValue>
                <p>Entreprise</p>
              </InfoListItemValue>
            </InfoListItem>
            <InfoListItem>
              <ProfileFieldForm
                actionText="Modifier"
                isLoading={getUserLoading}
                isVisible={isVisible}
                setIsVisible={setVisible}
                title="Nom entreprise"
                value={data?.getUserById.company}
              >
                <CustomInput
                  name="company"
                  placeholder={data?.getUserById.company}
                  register={register}
                />
                <SubmitBtn
                  type="button"
                  onClick={() => {
                    Visible();
                    submitDatas();
                  }}
                >
                  Enregistrer
                </SubmitBtn>
              </ProfileFieldForm>
            </InfoListItem>
            <InfoListItem>
              <ProfileFieldForm
                actionText="Modifier"
                isLoading={getUserLoading}
                isVisible={isVisible}
                setIsVisible={setVisible}
                title="Numero de siret"
                value={data?.getUserById.siret}
              >
                <CustomInput
                  name="siret"
                  placeholder={data?.getUserById.siret || "xxxxxxxxxxxxxxxx"}
                  register={register}
                />
                <SubmitBtn
                  type="button"
                  onClick={() => {
                    Visible();
                    submitDatas();
                  }}
                >
                  Enregistrer
                </SubmitBtn>
              </ProfileFieldForm>
            </InfoListItem>
            <InfoListItem>
              <ProfileFieldForm
                actionText="Modifier"
                isLoading={getUserLoading}
                isVisible={isVisible}
                setIsVisible={setVisible}
                title="Code APE"
                value={data?.getUserById.apecode}
              >
                <CustomInput
                  name="apecode"
                  placeholder={data?.getUserById.apecode || "0000000"}
                  register={register}
                />
                <SubmitBtn
                  type="button"
                  onClick={() => {
                    Visible();
                    submitDatas();
                  }}
                >
                  Enregistrer
                </SubmitBtn>
              </ProfileFieldForm>
            </InfoListItem>
            <InfoListItem>
              <ProfileFieldForm
                actionText="Modifier"
                isLoading={getUserLoading}
                isVisible={isVisible}
                setIsVisible={setVisible}
                title="Adresse"
                value={data?.getUserById.address}
              >
                <div className="flex flex-col">
                  <div className="flex grow gap-3">
                    <CustomInput
                      name="postalCode"
                      placeholder="Code postal"
                      register={register}
                    />
                    <CustomInput
                      name="city"
                      placeholder="Ville"
                      register={register}
                    />
                  </div>
                  <CustomInput
                    name="street"
                    placeholder="numéro + rue"
                    register={register}
                  />
                </div>
                <SubmitBtn
                  type="button"
                  onClick={() => {
                    Visible();
                    submitDatas();
                  }}
                >
                  Enregistrer
                </SubmitBtn>
              </ProfileFieldForm>
            </InfoListItem>
            <InfoListItem>
              <ProfileFieldForm
                actionText="Modifier"
                isLoading={getUserLoading}
                isVisible={isVisible}
                setIsVisible={setVisible}
                title="Adresse email"
                value={data?.getUserById.email}
              >
                <CustomInput
                  name="email"
                  placeholder="johndoe@mail.com"
                  register={register}
                />

                <SubmitBtn
                  type="button"
                  onClick={() => {
                    Visible();
                    submitDatas();
                  }}
                >
                  Enregistrer
                </SubmitBtn>
              </ProfileFieldForm>
            </InfoListItem>
            <InfoListItem>
              <ProfileFieldForm
                actionText="Modifier"
                isLoading={getUserLoading}
                isVisible={isVisible}
                setIsVisible={setVisible}
                title="Numéro de téléphone"
                value={data?.getUserById.phone}
              >
                <CustomInput onlyNumeric name="phone" register={register} />
                <SubmitBtn
                  type="button"
                  onClick={() => {
                    Visible();
                    submitDatas();
                  }}
                >
                  Enregistrer
                </SubmitBtn>
              </ProfileFieldForm>
            </InfoListItem>
            <InfoListItem>
              <InfoListItemLabel style={{ justifyContent: "space-between" }}>
                <h3>KBIS</h3>
                <ProfilePicUploader
                  accept=".pdf"
                  defaultFile={data?.getUserById.proof}
                  name="proof-selector"
                  onValuesChange={(values) => handleDataChange("proof", values)}
                />
              </InfoListItemLabel>
              <InfoListItemValue>
                {!data?.getUserById.proof && <p>Aucun KBIS</p>}
                {data?.getUserById.proof && (
                  <a
                    href={data?.getUserById.proof.url}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {data?.getUserById.proof.name}
                  </a>
                )}
              </InfoListItemValue>
            </InfoListItem>
          </InfoList>
        </div>
      </Wrapper>
    </div>
  );
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  @media (max-width: 820px) {
    margin: 0;
  }
`;

const WrapperProfilePic = styled.div`
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: space-between;
  margin-top: 20px;

  @media (max-width: 820px) {
    margin-top: 16px;
  }
`;

const InfoList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;

  &.with-lines li:not(:last-of-type) {
    border-bottom: 1px solid #a8a8a8;
  }
`;

const InfoListItem = styled.li`
  padding: 18px 0;

  &:first-of-type {
    padding-top: 0;
  }

  @media (max-width: 820px) {
    padding: 6px 0;

    &:nth-child(2) {
      margin-top: 40px;
    }
  }
`;

const InfoListItemLabel = styled.div`
  display: flex;
  align-items: center;

  h3 {
    font-size: 20px;
    font-weight: 600;
    margin: 0;
  }

  button {
    padding: 0;
    background: transparent;
    border: none;
    color: #000;
    font-size: 15px;
    margin-left: auto;
    text-decoration: underline;
    cursor: pointer;
  }
`;

const InfoListItemValue = styled.div`
  margin-top: 10px;

  p {
    margin: 0;
    font-weight: 100;
    font-size: 15px;
    color: #333;
  }

  @media (max-width: 820px) {
    margin-top: 5px;
  }
`;

const CompanyLogoMask = styled.div`
  width: 100px;
  height: 100px;
  background: #000;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-size: 20px;
    font-weight: 600;
    color: #fff;
  }

  @media (max-width: 820px) {
    width: 60px;
    height: 60px;

    span {
      font-size: 15px;
      font-weight: 500;
      color: #fff;
    }
  }
`;

const CompanyLogoThumb = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;

  span {
    font-size: 20px;
    font-weight: 600;
    color: #fff;
  }
`;

const SubmitBtn = styled.button`
  padding: 10px;
  min-width: 100px;
  border: none;
  background-color: #000;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  border-radius: 7px;
  margin-top: 10px;
  cursor: pointer;
`;

export default Profile;
