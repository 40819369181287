import BubbleChoiceItem from "../BubbleChoiceItem";

const MoreFilterBubbleChoice = ({
  options,
  title,
  subTitle,
  selectedFilter,
  handleOnChange,
  selectOn,
}) => {
  return (
    <div className="flex flex-col gap-3 font-roboto lg:border-b lg:border-[#e5e5e5] lg:pb-5">
      {title || subTitle ? (
        <div className="flex flex-col">
          {title && (
            <h3 className="pt-3 text-3xl font-medium lg:pt-5">{title}</h3>
          )}
          {subTitle && <h4 className="lg:pt-1">{subTitle}</h4>}
        </div>
      ) : null}
      <BubbleChoiceItem
        isMultiple
        handleOnChange={handleOnChange}
        options={options}
        selectedFilter={selectedFilter}
        selectOn={selectOn}
        type="object"
      />
    </div>
  );
};

export default MoreFilterBubbleChoice;
