const CustomCheckbox = ({
  name,
  register,
  error,
  children,
  customOnChange,
}) => {
  return (
    <div className="relative flex items-start gap-2">
      <div className="flex h-6 items-center">
        <input
          aria-describedby="comments-description"
          className={`h-4 w-4 rounded border-gray-300 text-mplgreen transition-all duration-300 focus:ring focus:ring-emerald-500/20 ${
            error && "border-red-500"
          }`}
          id={name}
          name={name}
          type="checkbox"
          {...register(name)}
          onChange={customOnChange}
        />
      </div>
      {children}
    </div>
  );
};

export default CustomCheckbox;
