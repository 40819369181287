import styled from "styled-components";

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TitlePart = styled.h1`
  font-weight: 600;
  font-size: ${(props) => props.size || "20"}px;
  color: ${(props) => props.color || "#000"};
  margin: ${(props) => props.margin || "inherit"};
  ${(props) => (props.underline ? "text-decoration: underline;" : "")}

  &:not(:first-of-type) {
    padding-left: ${(props) => props.left || 40}px;
    position: relative;

    &::before {
      content: ">";
      position: absolute;
      left: ${(props) => (props.left ? props.left / 3 : "15")}px;
      top: 50%;
      transform: translateY(-50%);
      color: #333 !important;
      font-weight: normal;
    }
  }
`;

export const StatusPart = styled.h3`
  font-size: 20px;
  font-weight: 200;
  padding-left: 10px;
  display: flex;
  align-items: center;
`;
