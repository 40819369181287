export const services = [
  { id: "can_products_base", title: "Gestion de la base articles" },
  { id: "can_receive", title: "Réception" },
  { id: "can_storage", title: "Stockage" },
  { id: "can_prepare", title: "Préparation" },
  { id: "can_packaging", title: "Emballage" },
  { id: "can_shipping", title: "Expédition" },
  { id: "can_transportation", title: "Transport" },
  { id: "can_interfacing", title: "Interfaçage" },
];
