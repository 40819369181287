import PropTypes from "prop-types";

const Pagination = ({
  totalItems,
  itemsPerPage,
  currentPage,
  onPageChange,
}) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      onPageChange(page);
    }
  };

  const renderPageNumbers = () => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <li key={i}>
          <a
            className={`flex h-8 items-center justify-center px-3 ${
              i === currentPage
                ? "border border-gray-300 bg-mplgreen/10 text-mplgreen"
                : "border border-gray-300 bg-white text-gray-500"
            }`}
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handlePageChange(i);
            }}
          >
            {i}
          </a>
        </li>
      );
    }
    return pages;
  };

  return (
    <nav aria-label="Page navigation">
      <ul className="inline-flex -space-x-px text-sm">
        <li>
          <a
            className={`ml-0 flex h-8 items-center justify-center rounded-l-lg px-3 leading-tight ${
              currentPage === 1
                ? "cursor-not-allowed border border-gray-300 bg-white text-gray-500"
                : "border border-gray-300 bg-white text-gray-700 hover:bg-gray-100"
            }`}
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handlePageChange(currentPage - 1);
            }}
          >
            Previous
          </a>
        </li>
        {renderPageNumbers()}
        <li>
          <a
            className={`flex h-8 items-center justify-center rounded-r-lg px-3 leading-tight ${
              currentPage === totalPages
                ? "cursor-not-allowed border border-gray-300 bg-white text-gray-500"
                : "border border-gray-300 bg-white text-gray-700 hover:bg-gray-100"
            }`}
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handlePageChange(currentPage + 1);
            }}
          >
            Next
          </a>
        </li>
      </ul>
    </nav>
  );
};

Pagination.propTypes = {
  totalItems: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default Pagination;
