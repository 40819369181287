import { gql } from "@apollo/client";

export const GET_TENDER_NOTICES = gql`
  query getTenderNotice {
    searchTenderNotice {
      count
      tenderNotices {
        id
        offerTitle
        companyName
        logo {
          name
          url
        }
        localisation {
          label
          code
        }
        workingArea {
          workingArea {
            label
            code
          }
        }
        publicationDate
        closingDate
        sector {
          label
          code
        }
        createdAt
        published
        publishedAt
      }
    }
  }
`;

export const GET_TENDER_NOTICE = gql`
  query getTenderNotice($id: ID!) {
    getTenderNoticeById(id: $id) {
      id
      offerTitle
      companyName
      logo {
        name
        url
      }
      localisation {
        label
        code
      }
      workingArea {
        workingArea {
          label
          code
        }
      }
      publicationDate
      closingDate
      sector {
        label
        code
      }
      serviceDescription
      estimatedValue
      contractDuration
      additionalInformation
      contact
      complementaryLinks
    }
  }
`;

export const CREATE_TENDER_NOTICE = gql`
  mutation createTender($input: TenderNoticeInputAdmin!) {
    createTender(input: $input) {
      id
    }
  }
`;
