import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import CustomRadioButton from "../../components/input/CustomRadioButton";
import CustomButton from "../../components/ui/CustomButton";
import useTailwindBreakpoint from "../../hooks/useTailwindBreakpoint";
import { GetNumberOfCompatibility } from "../../queries/specification/specification";
import MobileCorrespondingToSpecification from "./MobileCorrespondingToSpecification";

export default function CorrespondingToSpecification() {
  const navigate = useNavigate();
  const [isSendingSpec, setSendingSpec] = useState(false);
  const location = useLocation();

  const { isXsTw, isMdTw, isSmTw } = useTailwindBreakpoint();

  const {
    control,
    formState: { errors },
    watch,
  } = useForm();

  const [search] = useSearchParams();
  const { data, loading } = useQuery(GetNumberOfCompatibility, {
    variables: { specId: location.state?.specId ?? search.get("specId") },
    fetchPolicy: "no-cache",
  });

  const watchSendSpec = watch("specification-send-spec");

  useEffect(() => {
    setSendingSpec(watchSendSpec);
  }, [watchSendSpec]);

  const previousStep = () => {
    navigate(
      `/dashboard/specification-create/${
        location.state?.specId ?? search.get("specId")
      }`
    );
  };

  const nextStep = () => {
    if (isSendingSpec) {
      navigate("/dashboard/specification-add-message", {
        state: { specId: location.state?.specId },
      });
    } else navigate("/dashboard/specification-congrats");
  };

  const handleDataChange = (value) => {
    setSendingSpec(value);
  };

  if (isXsTw || isMdTw || isSmTw) {
    return (
      <MobileCorrespondingToSpecification
        compatibilityCount={data?.getNumberOfCompatibility}
        control={control}
        isLoading={loading}
        nextStep={nextStep}
        previousStep={previousStep}
      />
    );
  }

  return (
    <Container>
      <Title>Votre cahier des charges en quelques clics</Title>
      <Content>
        <Subtitle>
          {loading ? "-" : data?.getNumberOfCompatibility ?? 0} prestataire
          {data?.getNumberOfCompatibility > 1 && "s"} correspond
          {data?.getNumberOfCompatibility > 1 && "ent"} à vos critères
        </Subtitle>
        {(data?.getNumberOfCompatibility ?? 0) > 0 && (
          <CustomRadioButton
            checked={isSendingSpec}
            error={errors.sendSpec?.active}
            name="specification-send-spec"
            title="Souhaitez-vous la communication de votre cahier des charges auprès des logisticiens de notre plateforme qui répondent à vos critères ?"
            width="80%"
            onChange={(e) => handleDataChange(e.target?.checked)}
          />
        )}
      </Content>
      <StepsControls>
        <CustomButton color="black" variant="outlined" onClick={previousStep}>
          Retour
        </CustomButton>
        <CustomButton isLoading={loading} onClick={nextStep}>
          Terminer
        </CustomButton>
      </StepsControls>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 40px;
  height: 100%;
  flex-grow: 1;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 60%;
  flex-grow: 1;
`;
const Title = styled.p`
  font-size: 27px;
  color: #000;
  font-weight: 700;
  cursor: pointer;
`;

const Subtitle = styled.p`
  font-size: 30px;
  color: #000;
  font-weight: 400;
  cursor: pointer;
`;

const StepsControls = styled.div`
  box-sizing: border-box;
  display: flex;
  z-index: 10;
  padding: 1.25rem 40px;
  background-color: #ffffff;
  justify-content: space-between;
  border-width: 0;
  border-top-width: 1px;
  border-style: solid;
  box-shadow: none;
  margin: 0 -40px;
  margin-bottom: -30px;
  width: 100vw;
`;
