import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../context/AuthContext";
import { NavContext } from "../../../../context/NavContext";

function useCompareHook(filterHooks, mfHooks, hits) {
  const { togglePremium, toggleRegisterForm } = useContext(NavContext);
  const { user } = useContext(AuthContext);
  const [selectedForCompare, setSelectedForCompare] = useState([]);
  const [filteredForCompare, setFilteredForCompare] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState({});
  const [filtered, setFiltered] = useState([]);

  const [isInCompareMode, setCompareMode] = useState(false);
  const [isInSelectMode, setSelectMode] = useState(false);
  const [selectedForSendingSpec, setSelectedForSendingSpec] = useState([]);
  const needSubscription = (callback) => {
    if (
      user?.subscriptions?.length &&
      user.subscriptions[0].subscription.name.startsWith("Premium")
    ) {
      callback();
    } else {
      if (user) {
        togglePremium();
      } else {
        toggleRegisterForm();
      }
    }
  };

  const onListingSelected = (value, listingId) => {
    if (selectedForCompare.length === 5) {
      return;
    }
    if (value) {
      setSelectedForCompare((p) => [...p, listingId]);
    } else {
      setSelectedForCompare((p) => {
        const index = p.findIndex((s) => s === listingId);
        if (index !== -1) p.splice(index);
        return [...p];
      });
    }
  };
  useEffect(() => {
    setFilteredForCompare([
      ...selectedForCompare.filter(
        (x) => hits.findIndex((h) => h.id === x) !== -1
      ),
    ]);
  }, [hits, selectedForCompare]);
  useEffect(() => {
    if (isInCompareMode) {
      setSelectedFilter({ ...filterHooks, ...mfHooks });
    } else {
      setSelectedFilter({});
    }
  }, [isInCompareMode]);

  const removeComparedListing = (listingId) => {
    var array = [...selectedForCompare];
    setSelectedForCompare(array.filter((listing) => listing !== listingId));
    if (array.length === 1) {
      setCompareMode(false);
      setSelectMode((p) => !p);
    }
  };

  const onComparedListingSelected = (value, listingId) => {
    if (selectedForSendingSpec.length === 5) {
      return;
    }
    if (value) {
      setSelectedForSendingSpec((p) => [...p, listingId]);
    } else {
      setSelectedForSendingSpec((p) => {
        const index = p.findIndex((s) => s === listingId);
        if (index !== -1) p.splice(index);
        return [...p];
      });
    }
  };

  return {
    onListingSelected,
    onComparedListingSelected,
    removeComparedListing,
    selectedForCompare,
    isInCompareMode,
    needSubscription,
    isInSelectMode,
    setCompareMode,
    setSelectMode,
    selectedForSendingSpec,
    selectedFilter,
    setSelectedForSendingSpec,
    setSelectedForCompare,
    setSelectedFilter,
    filteredForCompare,
    filtered,
    setFiltered,
  };
}

export default useCompareHook;
