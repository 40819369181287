import { useLazyQuery } from "@apollo/client";
import { createContext, useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import useLocationPlaceId from "../hooks/useLocationPlaceId";
import GetListingSettingDatas from "../queries/listings/listings";
import { NavContext } from "./NavContext";

export const SearchContext = createContext();

export const SearchProvider = (props) => {
  const { placeId: placeIdAroundMe } = useLocationPlaceId();

  const { setLocation, setSelectedAreas, setSelectedServices } =
    useContext(NavContext);
  const [isOpenLocation, setIsOpenLocation] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [showOptions, setShowOptions] = useState(false);
  const [selectedArea, setSelectedArea] = useState([]);
  const [selectedService, setSelectedService] = useState([]);
  const [options, setOptions] = useState([
    { value: "ALL", label: "Toute la France" },
  ]);

  const [alreadyCalled, SetAlreadyCalled] = useState(false);
  const [getListingSettingDatas] = useLazyQuery(GetListingSettingDatas);
  const [settingDatas, setSettingDatas] = useState(null);
  const [search] = useSearchParams();

  useEffect(() => {
    if (placeIdAroundMe)
      setOptions((prev) => [
        { value: placeIdAroundMe, label: "Autour de moi" },
        ...prev,
      ]);
  }, [placeIdAroundMe]);

  useEffect(() => {
    let options = search.get("GeoSearch");
    options = JSON.parse(options);
    let _selectedArea = search.get("selectedArea");
    _selectedArea = JSON.parse(_selectedArea);
    let prestations = search.get("prestationsIds");
    prestations = JSON.parse(prestations);
    setSelectedOption(options ?? selectedOption);
    setSelectedArea(_selectedArea ?? selectedArea);
    setSelectedService(prestations ?? selectedService);
    setLocation(options ?? selectedOption);
    setSelectedAreas(_selectedArea ?? selectedArea);
    setSelectedServices(prestations ?? selectedService);
  }, [search]);

  useEffect(() => {
    if (!alreadyCalled) {
      getListingSettingDatas({ variables: { test: "" } }).then((r) => {
        setSettingDatas(r.data?.getListingSettingDatas);
      });
      SetAlreadyCalled(true);
    }
  }, []);

  return (
    <SearchContext.Provider
      value={{
        isOpenLocation,
        setIsOpenLocation,
        selectedOption,
        setSelectedOption,
        showOptions,
        setShowOptions,
        selectedArea,
        setSelectedArea,
        selectedService,
        setSelectedService,
        options,
        settingDatas,
        setOptions,
      }}
    >
      {props.children}
    </SearchContext.Provider>
  );
};
