import styled from "styled-components";
import checkIcon from "../../../assets/images/checkIcon.png";
import errorIcon from "../../../assets/images/errorIcon.png";
import LoadingBtn from "../../../components/others/dashboard/LoadingBtn";

// eslint-disable-next-line react/prop-types
function PriceContainer({
  price,
  subType,
  infos,
  subTypeState,
  isActual = false,
  isSubscribed = false,
  showStatus = true,
  onChoose = null,
  styles = {},
  disabled = false,
  loading,
}) {
  let priceTTC = price * 1.2;
  if (subTypeState !== "MONTHLY") {
    priceTTC /= 12;
    priceTTC = priceTTC.toFixed(2);
    price /= 12;
    price = price.toFixed(2);
  } else {
    priceTTC = priceTTC.toFixed(2);
    price = price.toFixed(2);
  }
  return (
    <ContentContainer className={styles.container}>
      <Category className={styles.category}>
        <Text className={styles.subsname}>{subType}</Text>
        {showStatus && isSubscribed && (
          <Actual>{isActual ? "Votre formule actuelle" : "À venir"}</Actual>
        )}
        {!!price && (
          <>
            <Price>
              {price}€ HT
              <SubPrice>/ mois</SubPrice>
            </Price>
            <PriceTTC>
              (soit {priceTTC}€ TTC
              <SubPrice>/ mois)</SubPrice>
            </PriceTTC>
            {subTypeState !== "MONTHLY" && (
              <PriceTTC style={{ marginTop: "0.2rem", color: "#1FC585" }}>
                Facturation annuelle
              </PriceTTC>
            )}
          </>
        )}
      </Category>
      <InfosContainer>
        {/* eslint-disable-next-line react/prop-types */}
        {infos?.map((info, idx) =>
          info.isValidate ? (
            <Info
              key={(Math.random() * 100).toString() + idx}
              className={styles.infoline}
            >
              <Logo>
                <Image alt="" src={checkIcon} />
              </Logo>
              <div
                className={`${styles.infolinetext} max-w-[70%] break-words text-left`}
              >
                {info.title}
              </div>
            </Info>
          ) : (
            <Info
              key={(Math.random() * 100).toString() + idx}
              className={styles.infoline}
            >
              <LogoError>
                <Image alt="" src={errorIcon} />
              </LogoError>
              <div
                className={`${styles.infolinetext} max-w-[70%] break-words text-left`}
              >
                {info.title}
              </div>
            </Info>
          )
        )}
        <LoadingBtn
          background="#1fc585"
          color="#1fc585"
          disabled={disabled || isSubscribed}
          loading={loading}
          style={{ width: "30%", marginTop: "2rem", height: "40px" }}
          type="button"
          onClick={() => {
            onChoose();
          }}
        >
          <TextButton>Choisir</TextButton>
        </LoadingBtn>
      </InfosContainer>
    </ContentContainer>
  );
}

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  //width: 45%;
  max-width: 440px;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 40px;
  overflow: hidden;
  position: relative;
  border: 1px black solid;
  margin-top: 1rem;
  padding: 25px 4px;
`;

const Text = styled.p`
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 5px;
  font-family: "Roboto Bold", sans-serif;
  display: flex;
  align-items: center;
`;
const Actual = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: #1fc585;
  margin-left: 10px;
  margin-top: 10px;
`;

const Price = styled.p`
  font-size: 1.3rem;
  display: flex;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const PriceTTC = styled.p`
  font-size: 0.7rem;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const SubPrice = styled.p`
  font-size: 0.8rem;
  display: flex;
  margin: 0;
  align-self: flex-end;
`;

const Category = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 10%;
`;

const InfosContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 90%;
  width: 100%;
  margin-bottom: 20px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 3rem;
  justify-content: flex-start;
  width: 100%;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
`;

const Image = styled.img`
  height: 15px;
  width: 15px;
`;

const Logo = styled.div`
  width: 25px;
  height: 25px;
  background: #1fc585;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
`;

const LogoError = styled.div`
  width: 25px;
  height: 25px;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 20px;
`;

const TextButton = styled.div`
  font-size: 1rem;
  color: white;
`;

export default PriceContainer;
