import { Link } from "react-router-dom";

const GreenButton = ({
  children,
  onClick,
  type = "button",
  to,
  className,
  icon,
  color,
}) => {
  const bgColor = color || "bg-mplgreen";

  const extraClassName = `disabled:bg-[#B2B2B2FF] relative lg:text-xl lg:py-2 py-2 rounded-lg px-4 font-roboto text-base font-semibold text-white flex items-center justify-center ${className} ${bgColor}`;

  if (to) {
    return (
      <Link className={extraClassName} to={to}>
        {children}
      </Link>
    );
  }

  return (
    <button className={extraClassName} type={type} onClick={onClick}>
      <div className="flex w-full items-center justify-center">
        <span>{children}</span>
        {icon ? (
          <img alt="icon" className="absolute right-4" src={icon} />
        ) : null}
      </div>
    </button>
  );
};

export default GreenButton;
