import React from "react";
import styled from "styled-components";

export default function SplitPanel(props) {
  return (
    <SplitPanelContainer>
      <LeftPanel>{props.left}</LeftPanel>
      <RightPanel>{props.right}</RightPanel>
    </SplitPanelContainer>
  );
}

const SplitPanelContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 100%;
  flex-grow: 1;
`;

const LeftPanel = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const RightPanel = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;
