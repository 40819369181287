import React, { useContext, useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import MonthPicker from "react-month-picker";
import "react-month-picker/css/month-picker.css";

import { useLazyQuery } from "@apollo/client";
import moment from "moment";
import styled from "styled-components";
import {
  Title,
  TitlePart,
} from "../../../components/others/dashboard/styled/PageTitleElements";
import { SearchContext } from "../../../context/SearchContext";
import { GetReporting } from "../../../queries/reporting/reporting";
export default function Reporting() {
  const [getReporting, { data }] = useLazyQuery(GetReporting, {
    fetchPolicy: "cache-first",
  });
  const { settingDatas } = useContext(SearchContext);
  const [chart, setChart] = useState([["Secteur d'activité", "Visiteur"]]);
  const [cityChart, setCityChart] = useState([["Ville", "Visiteur"]]);
  const [monthValue, setMonthValue] = useState({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
  });
  useEffect(() => {
    const start = moment(`${monthValue.month}/01/${monthValue.year} 00:00:00`);
    const end = moment(
      `${monthValue.month + 1}/01/${monthValue.year} 00:00:00`
    );
    if (settingDatas) {
      getReporting({ variables: { start, end } });
    }
  }, [monthValue, settingDatas]);
  useEffect(() => {
    if (data) {
      const d =
        data?.getReporting.activityAreaChart?.map((x) => [
          settingDatas?.workingDomains.find((w) => w.code === x.name)?.label ??
            "Inconnu",
          x.count,
        ]) ?? [];
      d.unshift(["Secteur d'activité", "Visiteur"]);
      const city =
        data?.getReporting.cityChart?.map((x) => [x.name, x.count]) ?? [];
      city.unshift(["Ville", "Visiteur"]);
      setCityChart(city);
      setChart(d);
    }
  }, [data]);
  const options = {
    width: "100%",
    height: 300,
    is3D: true,
    backgroundColor: "transparent",
    legend: {
      position: "right",
      alignment: "center",
      textStyle: { fontSize: 16, bold: true },
      maxLines: 3,
    },
    chartArea: {
      width: "100%", // Adjust this value to give more space for the legend
    },
    pieSliceTextStyle: {
      fontSize: 16,
      bold: true,
    },
    responsive: true,
  };

  const pickerLang = {
    months: [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Août",
      "Septembre",
      "Octobre",
      "Novembre",
      "Decembre",
    ],
    from: "De",
    to: "à",
  };

  const showPicker = () => {
    if (monthPickerRef && monthPickerRef.current) {
      monthPickerRef.current.show();
    }
  };

  const hidePicker = () => {
    if (monthPickerRef && monthPickerRef.current) {
      monthPickerRef.current.dismiss();
    }
  };

  const handleAMonthChange = (year, month) => {
    setMonthValue({ year: year, month: month });
    hidePicker();
  };

  const monthPickerRef = React.useRef(null);

  return (
    <div className="px-4 py-8 md:px-12">
      <Title>
        <TitlePart size="30">Reporting d’activité</TitlePart>
      </Title>
      <Wrapper>
        <InfoListContainer>
          <InfoList className="with-lines">
            <InfoListItem>
              <InfoListItemTitle>
                <PeriodDiv>
                  <p>Période</p>
                  <MonthPicker
                    ref={monthPickerRef}
                    lang={pickerLang.months}
                    value={monthValue}
                    onChange={handleAMonthChange}
                  >
                    <PeriodValue onClick={showPicker}>
                      {pickerLang.months[monthValue.month - 1] +
                        " " +
                        monthValue.year}
                    </PeriodValue>
                  </MonthPicker>
                </PeriodDiv>
                {/* <p>
                Nombre total de visiteurs : {data?.getReporting.numberOfVisitor ?? '-'}
								</p> */}
              </InfoListItemTitle>
            </InfoListItem>
            <CardGrid>
              <BlackCard>
                <h1>NB de visiteurs sur l’annonce</h1>
                <p>{data?.getReporting.numberOfVisitor ?? "-"}</p>
              </BlackCard>
              <BlackCard>
                <h1>NB de visiteurs redirigés sur votre site</h1>
                <p>{data?.getReporting.websiteClicked ?? "-"}</p>
              </BlackCard>
              <BlackCard>
                <h1>NB de demandes de devis reçues</h1>
                <p>{data?.getReporting.quoteSend ?? "-"}</p>
              </BlackCard>
              <BlackCard>
                <h1>NB de demandes de devis initiées</h1>
                <p>{data?.getReporting.quoteInitied ?? "-"}</p>
              </BlackCard>
            </CardGrid>
            <GraphCard>
              <div className="grid-cols-1 lg:grid-cols-2">
                <div className="col-span-1 text-center font-bold">
                  Secteur d’activité des visiteurs
                </div>
                <div className="col-span-1 -mt-7">
                  <Chart
                    chartType="PieChart"
                    data={chart}
                    options={{
                      ...options,
                    }}
                  />
                </div>
              </div>
              <div className="grid-cols-1 lg:grid-cols-2">
                <div className="col-span-1 text-center font-bold">
                  Régions des visiteurs
                </div>
                <div className="col-span-1 -mt-7">
                  <Chart
                    chartType="PieChart"
                    data={cityChart}
                    options={{
                      ...options,
                    }}
                  />
                </div>
              </div>
            </GraphCard>
          </InfoList>
        </InfoListContainer>
      </Wrapper>
    </div>
  );
}

const Wrapper = styled.div`
  width: 100%;
  margin-top: 20px;
`;

const InfoListContainer = styled.div`
  min-width: 80%;
  max-width: 700px;
`;

const InfoList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;

  &.with-lines li:not(:last-of-type) {
    border-bottom: 1px solid #a8a8a8;
  }
`;

const InfoListItem = styled.li`
  padding: 30px 0 18px 0;

  &:first-of-type {
    padding-top: 0;
  }
`;

const InfoListItemTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    font-size: 15px;
    font-weight: 600;
    margin: 0;
  }
`;

const PeriodDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  .rmp-btn:hover {
    background-color: rgba(140, 140, 140, 0.9) !important;
    color: white;
  }
`;

const PeriodValue = styled.div`
  display: flex;
  border: 1px solid #000000;
  border-radius: 10px;
  font-weight: 400;
  background: white;
  font-size: 16px;
  justify-content: space-evenly;
  align-items: center;
  padding: 5px 25px;
  cursor: pointer;
`;

const CardGrid = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  padding: 20px;
`;

const BlackCard = styled.div`
  display: flex;
  flex-direction: column;
  color: #ffffff;
  background: black;
  padding: 15px 20px;
  align-items: start;
  margin: 0;
  h1 {
    font-weight: 600;
    font-size: 15px;
  }

  p {
    font-weight: 600;
    font-size: 40px;
    margin: 0;
  }
`;

const GraphCard = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #000000;
  margin: 0px 20px;
  padding: 10px 5px;

  p {
    font-weight: 600;
    font-size: 15px;
  }
`;
