import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation login(
    $email: String!
    $password: String!
    $host: String!
    $sessionId: String!
  ) {
    login(
      loginUserInput: {
        email: $email
        password: $password
        host: $host
        sessionId: $sessionId
      }
    ) {
      user {
        id
        name
        company
        activityArea {
          label
          code
        }
        siret
        email
        phone
        role
        listingNumber
        subscriptions {
          id
          startedAt
          endsAt
          revokeDate
          running
          duration
          subscribedAt
          subscription {
            id
            name
            price
          }
        }
      }
      accessToken
      refreshToken
    }
  }
`;

export const GetNewToken = gql`
  mutation refreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      user {
        id
        name
        company
        activityArea {
          label
          code
        }
        siret
        email
        phone
        role
        listingNumber
        status
        subscriptions {
          id
          startedAt
          endsAt
          revokeDate
          running
          duration
          subscribedAt
          subscription {
            id
            name
            price
          }
        }
      }
      accessToken
      refreshToken
    }
  }
`;

export default LOGIN;
