import { useCallback, useState } from "react";

const useModal = () => {
  const [isShowing, setIsShowing] = useState(false);
  const [params, setParams] = useState({});

  const toggle = useCallback((forceShow = false) => {
    if (forceShow) {
      setIsShowing(true);
      return;
    }
    setIsShowing((prev) => !prev);
  }, []);

  return {
    isShowing,
    toggle,
    params,
    setParams,
  };
};

export default useModal;
