import { useNavigate } from "react-router-dom";
import CustomButton from "../../../../components/ui/CustomButton";
import { ROLES } from "../../../../constants/Roles";

const SuccessFullPayment = ({
  isSubscriptionShowed,
  toggleSubscription,
  modalParams,
  user,
}) => {
  const navigate = useNavigate();

  const onClick = () => {
    if (isSubscriptionShowed) {
      toggleSubscription();
    }
    if (modalParams?.redirect) {
      navigate(modalParams.redirect, { state: modalParams.state });
    } else {
      navigate(
        user?.role === ROLES.COMPANY
          ? "/dashboard/specification-create"
          : "/dashboard/listing-create"
      );
    }
  };
  let title = "Commencer mon Annonce";
  if (modalParams?.redirect) {
    title = "Poursuivre ma demande de devis";
  } else if (user?.role === ROLES.COMPANY)
    title = "Commencer mon cahier des charges";
  return (
    <div className="flex w-full flex-col items-center justify-center">
      <div className="flex flex-col">
        <div
          style={{
            display: "flex",
            marginTop: "10%",
            marginBottom: "10%",
            minHeight: "5rem",
            flexDirection: "column",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <h3 className="mb-5">
            Félicitations, votre abonnement est désormais effectif !
          </h3>
          <CustomButton onClick={onClick}>{title}</CustomButton>
        </div>
      </div>
    </div>
  );
};

export default SuccessFullPayment;
