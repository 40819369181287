import { css } from "styled-components/macro";

import RobotoFontLight from "../assets/fonts/Roboto/Roboto-Light.ttf";
import RobotoFontRegular from "../assets/fonts/Roboto/Roboto-Regular.ttf";
import RobotoFontMedium from "../assets/fonts/Roboto/Roboto-Medium.ttf";
import RobotoFontBold from "../assets/fonts/Roboto/Roboto-Bold.ttf";

const fonts = css`
  @font-face {
    font-family: "Roboto Light";
    src: url(${RobotoFontLight}) format("truetype");
  }

  @font-face {
    font-family: "Roboto Regular";
    src: url(${RobotoFontRegular}) format("truetype");
  }

  @font-face {
    font-family: "Roboto Medium";
    src: url(${RobotoFontMedium}) format("truetype");
  }

  @font-face {
    font-family: "Roboto Bold";
    src: url(${RobotoFontBold}) format("truetype");
  }
`;

export default fonts;
