const CustomTextarea = ({
  rows = 4,
  name,
  register,
  label,
  defaultValue,
  placeholder,
  error,
}) => {
  return (
    <div>
      {label && (
        <label
          className="mb-2 block text-sm font-medium leading-6 text-gray-900 lg:text-lg"
          htmlFor={name}
        >
          {label}
        </label>
      )}
      <div>
        <textarea
          className="block h-full w-full rounded-xl border-gray-300 py-1.5 text-gray-900 shadow-sm  transition-all duration-300 placeholder:text-gray-400 focus:border-emerald-500 focus:ring focus:ring-emerald-500/20 sm:text-sm sm:leading-6"
          defaultValue={defaultValue}
          id={name}
          name={name}
          placeholder={placeholder}
          rows={rows}
          {...register(name)}
        />
        {error && <p className="mt-1 text-sm text-red-600">{error.message}</p>}
      </div>
    </div>
  );
};

export default CustomTextarea;
