import { useLazyQuery, useMutation } from "@apollo/client";
import jwtDecode from "jwt-decode";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import CustomInput from "../../components/ui/CustomInput";
import { GetUserByID } from "../../queries/profile/profile";
import { UpdatePasswordWithToken } from "../../queries/resetPassword/updatePasswordWithToken";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup.object().shape({
  newPassword: yup.string().required("Nouveau mot de passe est requis"),
  confirmPassword: yup
    .string()
    .oneOf(
      [yup.ref("newPassword"), null],
      "Les mots de passe ne correspondent pas"
    )
    .required("Confirmation du mot de passe est requise"),
});

const ResetPassword = () => {
  const { token } = useParams();
  const [isVerified, setIsVerified] = useState(null);
  const [user, setUser] = useState(null);
  const [er, setEr] = useState({});
  const [success, setSuccess] = useState({});

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const nav = useNavigate();
  const decodedToken = jwtDecode(token);
  const [updatePasswordWithToken] = useMutation(UpdatePasswordWithToken);
  const [getUserById] = useLazyQuery(GetUserByID);

  const verifyToken = () => {
    if (decodedToken.exp < Date.now() / 1000) {
      setIsVerified(false);
    } else {
      setIsVerified(true);
    }
  };

  useEffect(() => {
    verifyToken();
    getUserById({
      variables: {
        id: decodedToken.id,
      },
    }).then((r) => {
      setUser(r.data?.getUserById);
    });
  }, []);

  const handleResetPassword = (data) => {
    if (data.newPassword !== data.confirmPassword) {
      setEr({ message: "Les mots de passe ne correspondent pas" });
    } else {
      setEr({});
      updatePasswordWithToken({
        variables: {
          updatePasswordInput: {
            newpassword: data.newPassword,
          },
          token: token,
        },
      })
        .then((response) => {
          const updateStatus = response?.data?.updatePasswordWithToken;
          if (updateStatus === "Invalid password") {
            setEr({ message: "Mot de passe incorrect" });
          } else if (updateStatus === "Password updated") {
            setEr({});
            setSuccess({ message: "Mot de passe modifié avec succès" });
            nav("/");
          } else {
            setEr({ message: "Erreur inattendue" });
          }
        })
        .catch((e) => {
          setEr({ message: "Une erreur est survenue" });
        });
    }
  };

  return (
    <Container>
      <Title>Bonjour, {decodedToken.name} !</Title>
      {isVerified && !success.message && (
        <Form onSubmit={handleSubmit(handleResetPassword)}>
          <CustomInput
            showToggle
            errors={errors}
            name="newPassword"
            placeholder="Nouveau mot de passe"
            register={register}
            type="password"
          />
          <CustomInput
            showToggle
            errors={errors}
            name="confirmPassword"
            placeholder="Confirmer votre mot de passe"
            register={register}
            type="password"
          />
          {er.message && <ErrorMessage>{er.message}</ErrorMessage>}
          <Button type="submit">Envoyer</Button>
        </Form>
      )}
      {success.message && <SuccessMessage>{success.message}</SuccessMessage>}
      {!isVerified && !success.message && (
        <p>Le lien de réinitialisation de mot de passe a expiré</p>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 75px;
  width: 100%;
  height: 55vh;
`;

const Title = styled.h1`
  font-family: "Roboto Regular", sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 20px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  width: 60%;
`;

const Button = styled.button`
  width: 30%;
  background-color: #1fc585;
  border: none;
  border-radius: 15px;
  padding: 15px 20px;
  margin-top: 17px;
  font-size: 15px;
  font-family: "Roboto Regular", sans-serif;
  color: white;
  cursor: pointer;
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 15px;
  font-family: "Roboto Regular", sans-serif;
`;

const SuccessMessage = styled.p`
  color: green;
  font-size: 15px;
  font-family: "Roboto Regular", sans-serif;
`;

export default ResetPassword;
