import { cloneDeep } from "lodash";
import { useMemo, useState } from "react";
import { ROLES } from "../../../constants/Roles";
import PriceContainer from "./PriceContainer";
import SubscriptionToggle from "./SubscriptionToggle";

function SubType({
  onSubChoose = null,
  subscriptions = [],
  data = [],
  loading,
}) {
  const [subTypeState, setSubTypeState] = useState("MONTHLY");

  const proInfosLogi = useMemo(() => {
    return [
      {
        title: "1 vitrine client clé en main ",
        isValidate: true,
      },
      {
        title: "Gestion de votre visibilité en ligne",
        isValidate: true,
      },
      {
        title: "Messagerie intégrée pour gérer vos demandes clients",
        isValidate: true,
      },
      {
        title: "Suivi des performances de votre vitrine client",
        isValidate: false,
      },
      {
        title: "Analyse de votre audience",
        isValidate: false,
      },
      {
        title:
          "Réception automatique des cahiers des charges clients compatibles à vos solutions logistiques",
        isValidate: false,
      },
    ];
  }, []);

  const premiumInfosLogi = proInfosLogi.map((x) => ({
    ...x,
    isValidate: true,
  }));
  premiumInfosLogi[0].title = "3 vitrines clients clés en main ";
  const premiumPlusInfo = cloneDeep(premiumInfosLogi);
  premiumPlusInfo[0].title = "10 vitrines clients clés en main ";

  const logisticianSub = data
    ?.map((d) => {
      const t = Object.assign({}, d);
      if (d.userType === ROLES.LOGISTICIAN) {
        t.info = d.name.includes("Pro")
          ? proInfosLogi
          : d.name.includes("+")
          ? premiumPlusInfo
          : premiumInfosLogi;
        return t;
      }
    })
    .filter(Boolean)
    .sort((a, b) => a.price - b.price);

  const runningSubs = useMemo(() => {
    return subscriptions.find((x) => x.running);
  }, [subscriptions]);

  const checkIsActual = (type) => {
    return (
      runningSubs?.subscription.name === type &&
      ((runningSubs?.duration === "MONTHLY" && subTypeState === "MONTHLY") ||
        (runningSubs?.duration === "ANNUAL" && subTypeState === "ANNUAL"))
    );
  };

  const checkIsSubscribed = (type) => {
    return (
      subscriptions.findIndex(
        (x) =>
          x.subscription.name === type &&
          ((x.duration === "MONTHLY" && subTypeState === "MONTHLY") ||
            (x.duration === "ANNUAL" && subTypeState === "ANNUAL"))
      ) > -1
    );
  };

  const toggleItems = useMemo(() => {
    return [
      {
        label: "Mensuel",
        onClick: () => setSubTypeState("MONTHLY"),
        isActive: subTypeState === "MONTHLY",
      },
      {
        label: "Annuel",
        onClick: () => setSubTypeState("ANNUAL"),
        isActive: subTypeState === "ANNUAL",
      },
    ];
  }, [subTypeState]);

  return (
    <div className="flex flex-col items-center">
      <SubscriptionToggle toggleItems={toggleItems} />

      <div className="mt-4 flex flex-wrap items-center justify-center gap-4 font-roboto-regular lg:flex-nowrap">
        {logisticianSub?.map((sub, i) => (
          <>
            {subTypeState === sub.period && (
              <PriceContainer
                key={i}
                infos={sub.info}
                isActual={checkIsActual(sub.name)}
                isSubscribed={checkIsSubscribed(sub.name)}
                loading={loading}
                price={sub.price}
                subType={sub.name}
                subTypeState={subTypeState}
                onChoose={() => onSubChoose && onSubChoose(sub)}
              />
            )}
          </>
        ))}
      </div>
    </div>
  );
}

export default SubType;
