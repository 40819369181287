import { useLazyQuery } from "@apollo/client";
import { IonIcon } from "@ionic/react";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { document } from "ionicons/icons";
import moment from "moment";
import { useContext, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import tw from "tailwind-styled-components";
import {
  Title,
  TitlePart,
} from "../../../components/others/dashboard/styled/PageTitleElements";
import { subscriptionsTypesDetails } from "../../../constants/SubscriptionsTypesDetails";
import { AuthContext } from "../../../context/AuthContext";
import {
  GetUserInvoices,
  GetUserSubscriptions,
} from "../../../queries/subscription/subscription";
import PriceContainer from "../../subscriptions/components/PriceContainer";
import SubscriptionTab from "../../subscriptions/components/SubscriptionTab";

function Subscriptions() {
  const subsInfos = subscriptionsTypesDetails;
  const durationENToFR = { MONTHLY: "Mensuel", ANNUAL: "Annuel" };
  const navigate = useNavigate();
  const [getUserInvoices] = useLazyQuery(GetUserInvoices, {
    fetchPolicy: "no-cache",
  });
  const { user, dispatch } = useContext(AuthContext);
  const [selectedTab, setSelectedTab] = useState("subs");
  const [invoices, setInvoices] = useState([]);
  const computeEndSubs = (subs) => {
    const ends = new Date(subs.startedAt);
    subs.duration === "ANNUAL"
      ? ends.setFullYear(ends.getFullYear() + 1)
      : ends.setMonth(ends.getMonth() + 1);
    return ends;
  };
  const [getUserSubscriptions] = useLazyQuery(GetUserSubscriptions, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    getUserSubscriptions().then((r) => {
      if (
        !r.data?.getUserSubscriptions ||
        r.data?.getUserSubscriptions.length === 0
      ) {
        navigate("/subscriptions");
        return;
      }
      dispatch({
        type: "SET_SUBSCRIPTIONS",
        payload: r.data?.getUserSubscriptions,
      });
    });
    getUserInvoices().then((r) => setInvoices(r.data?.getUserInvoices));
  }, []);

  const tabItems = useMemo(() => {
    return [
      {
        label: "Abonnements",
        onClick: () => setSelectedTab("subs"),
        isActive: selectedTab === "subs",
      },
      {
        label: "Factures",
        onClick: () => setSelectedTab("invo"),
        isActive: selectedTab === "invo",
      },
    ];
  }, [selectedTab]);

  return (
    <>
      <Title>
        <TitlePart size="30">Abonnement et factures</TitlePart>
      </Title>
      <Wrapper>
        <SubscriptionTab tabs={tabItems} />
        <TabsContentWrapper>
          {selectedTab === "subs" && (
            <TabsContent className="flex">
              {(user.subscriptions || []).map((item, idx) => (
                <PriceWrapper
                  key={
                    item.subscription?.name +
                    "_" +
                    item.duration +
                    "_" +
                    (idx + 1)
                  }
                >
                  <PriceContainer
                    infos={
                      subsInfos[
                        (
                          item.subscription?.name +
                          "_" +
                          user?.role
                        ).toLowerCase()
                      ]
                    }
                    isSubscribed={true}
                    price={item.subscription?.price}
                    showStatus={!item.running}
                    styles={{
                      subsname: "!text-lg !text-left",
                      infoline: "!pl-0 mb-4",
                      infolinetext: "m-0 !leading-5 p-0",
                      container: "!w-full",
                      category: "!justify-start !items-start mb-4",
                    }}
                    subType={`${item.running ? "Abonnement en cours " : ""} 
										${item.subscription?.name} 
										(${durationENToFR[item.duration]?.toLowerCase()})
								`}
                    subTypeState={item.duration}
                  />
                  <PriceActionContainer>
                    {item.running && (
                      <ActionButton
                        onClick={() =>
                          !item.revokeDate && user.subscriptions.length < 2
                            ? navigate("/subscriptions")
                            : null
                        }
                      >
                        {item.revokeDate
                          ? "Fin le " +
                            format(computeEndSubs(item), "dd MMM yyyy", {
                              locale: fr,
                            })
                          : "Changer"}
                      </ActionButton>
                    )}
                    {((!item.revokeDate && user.subscriptions.length < 2) ||
                      !item.running) && (
                      <ActionButton
                        $outline={true}
                        onClick={() => navigate("/subscriptions/revoke")}
                      >
                        Resilier
                      </ActionButton>
                    )}
                  </PriceActionContainer>
                  <PriceDurationContainer>
                    <DurationText>
                      Date de souscription:{" "}
                      {moment(item.subscribedAt).format("DD MMM yyyy")}
                    </DurationText>
                    {(item.revokeDate || user.subscriptions.length > 1) && (
                      <DurationText $green={true}>
                        {item.running
                          ? "Date de resiliation: "
                          : "Effectif le: "}
                        {moment(
                          item.running ? item.revokeDate : item.startedAt
                        ).format("DD MMM yyyy")}
                      </DurationText>
                    )}
                  </PriceDurationContainer>
                </PriceWrapper>
              ))}
            </TabsContent>
          )}
          {selectedTab === "invo" && (
            <TabsContent>
              <InvoicesList>
                {invoices.map((item) => (
                  <InvoiceItem key={item.number}>
                    <InvoiceIcon icon={document} />
                    <Link
                      className="ml-2 text-[#1FC585]"
                      target="_blank"
                      to={item.pdf}
                    >
                      Facture No {item.number}
                    </Link>
                  </InvoiceItem>
                ))}
              </InvoicesList>
            </TabsContent>
          )}
        </TabsContentWrapper>
      </Wrapper>
    </>
  );
}

const Wrapper = tw.div`w-full mt-[28px]`;
const Tabs = tw.div`flex max-w-[500px] border-0 border-solid border-b border-gray-300`;
const TabsItem = tw.span`
    py-4 text-sm font-semibold border-0 mr-6 cursor-pointer
    ${(p) =>
      p.$selected
        ? "text-[#1FC585] border-solid border-b-2 border-[#1FC585]"
        : ""}
`;
const TabsContentWrapper = tw.div`mt-5 min-h-[500px]`;
const TabsContent = tw.div``;
const PriceWrapper = tw.div`mr-6 w-[460px]`;
const PriceActionContainer = tw.div`flex justify-center gap-4`;
const ActionButton = tw.button`
    h-8 bg-[#1FC585] text-white border-0 rounded-md mt-6 px-7 text-sm font-semibold cursor-pointer
    ${(p) =>
      p.$outline
        ? "bg-white border border-solid border-[#1FC585] text-[#1FC585]"
        : ""}
`;
const PriceDurationContainer = tw.div`text-center mt-6`;
const DurationText = tw.p`
    m-0 text-sm text-black
    ${(p) => (p.$green ? "text-[#1FC585]" : "")}
`;
const InvoicesList = tw.ul`m-0 p-0 list-none`;
const InvoiceItem = tw.li`flex py-3 text-sm !text-[#1FC585]`;
const InvoiceIcon = tw(IonIcon)`text-xl`;

export default Subscriptions;
