import moment from "moment";
import googleMarker from "../../../../assets/svg/googleMarker.svg";
import SelectedLittleGrayBubble from "../../../ui/SelectedLittleGrayBubble";

const TenderNoticeCard = ({
  companyName,
  publishedAt,
  offerTitle,
  logo,
  localisation,
  workingArea,
  publicationDate,
  closingDate,
  sector,
}) => {
  const formattedPublishedAt = moment(publishedAt).fromNow();
  const formattedPublicationDate = moment(publicationDate).format("DD/MM/YYYY");
  const formattedClosingDate = moment(closingDate).format("DD/MM/YYYY");
  const remainingDays = moment(closingDate, "YYYY-MM-DD").diff(
    moment(),
    "days"
  );

  return (
    <div
      className="block w-full rounded-lg border border-gray-200 bg-white p-6 shadow hover:cursor-pointer hover:bg-gray-100"
      href="#"
    >
      <div className="flex items-center justify-between">
        <div className="mb-2 flex items-center gap-2">
          {logo && (
            <img
              alt={logo.name}
              className="h-12 w-12 rounded-full object-cover"
              src={logo.url}
            />
          )}
          <span className="text-sm text-gray-500">{companyName}</span>
        </div>
        <div>
          <p className="text-sm text-gray-500">Publié {formattedPublishedAt}</p>
        </div>
      </div>
      <h5 className="mb-2 text-left text-xl font-bold tracking-tight text-gray-900">
        {offerTitle}
      </h5>
      <div className="mb-3 flex items-start justify-between gap-10 border-b border-black pb-3 lg:items-center">
        <div className="flex items-center gap-2">
          <img
            alt="Illustration"
            className="hidden w-[30px] lg:block"
            src={googleMarker}
          />
          <span className="flex flex-wrap items-center gap-2 text-gray-700">
            <span>Localisation: </span>
            {localisation.map((location) => (
              <SelectedLittleGrayBubble
                key={location.label}
                label={location.label}
                size="sm"
              />
            ))}
          </span>
        </div>
        <div className="flex flex-wrap items-center gap-2 text-gray-700">
          <span>Périmètre:</span>
          {workingArea.map((wa) => (
            <SelectedLittleGrayBubble
              key={wa.workingArea.label}
              label={wa.workingArea.label}
              size="sm"
            />
          ))}
        </div>
      </div>
      <div className="mb-3 flex flex-col items-center justify-between border-b border-black pb-3 lg:flex-row">
        <div className="flex w-full gap-2 lg:items-center">
          <span className="flex w-full items-center justify-between gap-2 text-sm text-gray-700 lg:justify-start lg:text-base">
            <span>Date de parution:</span>
            <span>{formattedPublicationDate}</span>
          </span>
        </div>
        <div className="flex w-full items-center gap-2">
          <span className="flex w-full items-center justify-between gap-2 text-sm text-gray-700 lg:justify-end lg:text-base">
            <span>Date de clotûre:</span>
            <span className="text-red-500">
              {formattedClosingDate} (Reste {remainingDays} jours)
            </span>
          </span>
        </div>
      </div>
      <div className="flex items-center gap-2">
        <span className="flex flex-wrap items-center gap-2 text-gray-700">
          <span>Secteur:</span>
          {sector.map((sector) => (
            <SelectedLittleGrayBubble
              key={sector.label}
              label={sector.label}
              size="sm"
            />
          ))}
        </span>
      </div>
    </div>
  );
};

export default TenderNoticeCard;
