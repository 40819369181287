export const deliveryMinOptions = [
  { label: "6", value: 6 },
  { label: "12", value: 12 },
  { label: "24", value: 24 },
  { label: "48", value: 48 },
  { label: "72", value: 72 },
];

export const contractMinOptions = [
  { label: "< 1 an", value: 5, end: 11 },
  { label: "1 an", value: 12, start: 12, end: 23 },
  { label: "2 ans", value: 24, start: 24, end: 36 },
  { label: "3 ans", value: 48, start: 37, end: 48 },
  { label: "> 3 ans", value: 50, start: 49 },
];

export const collabsnbrOptions = [
  { code: 5, label: "1 - 10" },
  { code: 25, label: "10 - 50" },
  { code: 75, label: "50 - 100" },
  { code: 250, label: "100 - 500" },
  { code: 600, label: ">  500" },
];

export const refnbrOptions = [
  { label: "< 100", value: 50, end: 99 },
  { label: "100-1000", value: 500, start: 100, end: 1000 },
  { label: "> 1000", value: 1500, start: 1001 },
];

export const receivenbrOptions = [
  { label: "< 5", value: 3, end: 4 },
  { label: "< 10", value: 9, start: 4, end: 9 },
  { label: "< 15", value: 14, start: 10, end: 14 },
  { label: "< 20", value: 19, start: 4, end: 19 },
  { label: "< 25", value: 24, start: 20, end: 24 },
  { label: "> 25", value: 26, start: 25 },
];

export const pcknbrOptions = [
  { label: "< 50", value: 40, end: 49 },
  { label: "< 100", value: 90, start: 50, end: 99 },
  { label: "< 150", value: 140, start: 100, end: 149 },
  { label: "< 200", value: 190, start: 150, end: 199 },
  { label: "< 500", value: 400, start: 50, end: 499 },
  { label: "> 500", value: 550, start: 500 },
];

export const paletnbrOptions = [
  { label: "< 50", value: 40, end: 49 },
  { label: "< 100", value: 90, start: 50, end: 99 },
  { label: "< 150", value: 140, start: 100, end: 149 },
  { label: "< 200", value: 190, start: 150, end: 199 },
  { label: "< 500", value: 400, start: 50, end: 499 },
  { label: "> 500", value: 550, start: 500, end: 999 },
  { label: "> 1000", value: 1200, start: 1000 },
];

export const cmdnbrOptions = [
  { label: "< 50", value: 40, end: 49 },
  { label: "< 100", value: 90, start: 50, end: 99 },
  { label: "< 150", value: 140, start: 100, end: 149 },
  { label: "< 200", value: 190, start: 150, end: 199 },
  { label: "< 500", value: 400, start: 50, end: 499 },
  { label: "> 500", value: 550, start: 500, end: 999 },
  { label: "> 1000", value: 1200, start: 1000 },
];

export const preparednbrOptions = [
  { label: "< 50", value: 40, end: 49 },
  { label: "< 100", value: 90, start: 50, end: 99 },
  { label: "< 150", value: 140, start: 100, end: 149 },
  { label: "< 200", value: 190, start: 150, end: 199 },
  { label: "< 500", value: 400, start: 50, end: 499 },
  { label: "> 500", value: 550, start: 500, end: 999 },
  { label: "> 1000", value: 1200, start: 1000 },
];

export const shippingnbrOptions = [
  { label: "< 5", value: 4, end: 4 },
  { label: "< 10", value: 9, start: 4, end: 9 },
  { label: "< 15", value: 14, start: 10, end: 14 },
  { label: "< 20", value: 19, start: 4, end: 19 },
  { label: "< 25", value: 24, start: 20, end: 24 },
  { label: "> 25", value: 26, start: 25 },
];

export const maxcapacityOptions = [
  { label: "< 200", value: 190, end: 199 },
  { label: "< 500", value: 400, start: 200, end: 499 },
  { label: "< 1000", value: 900, start: 499, end: 999 },
  { label: "< 5000", value: 4900, start: 999, end: 4999 },
  { label: "< 20000", value: 19000, start: 4999, end: 19999 },
  { label: "> 20000", value: 22000, start: 20000 },
];
