import cx from "classnames";
import { useContext, useMemo } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import placeholder from "../../assets/images/placeholder-annonce.png";
import { AuthContext } from "../../context/AuthContext";
import { NavContext } from "../../context/NavContext";
import { formatResponseTime } from "../../utils/date";
import Rating from "../ui/Rating";

function AnnouncementItem({
  listing,
  selectForCompare,
  selected,
  isInSelectMode,
  isSelectedFromMap,
}) {
  const { user } = useContext(AuthContext);
  const { toggleRegisterForm } = useContext(NavContext);
  const formattedTime = formatResponseTime(listing?.averageResponseTime);
  const location = useLocation();

  const getGeoSearch = () => {
    const params = new URLSearchParams(location.search);
    const geoSearch = JSON.parse(params.get("GeoSearch"));
    if (geoSearch){
      return JSON.stringify(geoSearch.bounds);
    }
    return null;
  };

  const locationName = useMemo(() => {
    if (listing?._geoloc?.length > 0) {
      return listing?._geoloc[0]?.label;
    }
    return null;
  }, [listing?._geoloc]);

  const numberOfLocation = useMemo(() => {
    if (listing?.geolocTotal > 1) {
      return ` +${listing?.geolocTotal - 1} autres sites`;
    }
    return null;
  }, [listing?.geolocTotal]);

  const infoArea = useMemo(() => {
    let area = null;
    let availableNow = null;

    if (listing?.areaFreeValue || listing?.areaValue) {
      const formattedArea = new Intl.NumberFormat().format(
        listing.areaFreeValue || listing?.areaValue
      );

      if (listing?.areaFreeValue) {
        availableNow = (
          <>
            disponible maintenant
            <span className="h-2 w-2 shrink-0 rounded-full bg-mplgreen" />
          </>
        );
      }

      area = (
        <span className="flex items-center gap-1">
          {formattedArea} m² {availableNow}
        </span>
      );
    }

    return area;
  }, [listing.areaFreeValue, listing?.areaValue]);

  const getTitle = (listing) => {
    return listing?.title || "Pas de titre";
  };

  const onAnnonceClicked = () => {
    if (!user) {
      toggleRegisterForm();
    } else {
      const bounds = getGeoSearch();
      let url = `/detail-announcements/${listing.id}`;

      if (bounds) {
        url += `?bounds=${bounds}`;
      }
      window.open(url, "_blank");
    }
  };

  return (
    <div className="flex" id={listing.objectID}>
      <div className="relative flex w-full">
        {isInSelectMode && (
          <>
            {!selected && (
              <CardChecker onClick={() => selectForCompare(true)} />
            )}
            {selected && (
              <CardChecked onClick={() => selectForCompare(false)}>
                <AiOutlineCheck />
              </CardChecked>
            )}
          </>
        )}
        <button
          className={cx(
            "flex w-full flex-col overflow-hidden rounded-xl border transition-all duration-300 hover:border-emerald-500 hover:ring hover:ring-emerald-500/20",
            {
              "border-mplgreen border-[3px]": isSelectedFromMap,
              "border-black": !isSelectedFromMap,
            }
          )}
          onClick={onAnnonceClicked}
        >
          <img
            alt="placeholder"
            className="max-h-[286px] min-h-[286px] w-full object-cover"
            src={listing?.primaryPhoto ? listing?.primaryPhoto : placeholder}
          />
          {formattedTime && (
            <div
              className="absolute left-4 top-4 rounded-xl bg-white px-2 py-1 text-xs font-medium"
              style={{
                boxShadow: "0px 4px 4px 0px #00000040",
              }}
            >
              Délai de réponse: {formattedTime}
            </div>
          )}
          <div className="mx-5 my-3 flex flex-col items-start">
            <div className="text-left font-roboto text-lg font-medium">
              {getTitle(listing)}
            </div>
            <div>
              <Rating
                readOnly
                rating={listing.averageRating ? listing.averageRating : 5}
                ratingCount={listing.countRating ? listing.countRating : 1}
                ratingCountClass="text-xs"
                sizeStar={14}
              />
            </div>
            {(locationName || numberOfLocation || infoArea) && (
              <div className="mt-2 flex flex-wrap gap-2">
                {locationName && (
                  <div className="rounded-xl border border-black px-3 py-1 font-roboto text-xs font-medium">
                    {locationName}
                  </div>
                )}
                {numberOfLocation && (
                  <div className="rounded-xl border border-black px-3 py-1 font-roboto text-xs font-medium">
                    {numberOfLocation}
                  </div>
                )}
                {infoArea && (
                  <div className="rounded-xl border border-black px-3 py-1 font-roboto text-xs font-medium">
                    {infoArea}
                  </div>
                )}
              </div>
            )}
          </div>
        </button>
      </div>
    </div>
  );
}

const CardChecker = styled.div`
  position: absolute;
  display: flex;
  top: 10px;
  right: 10px;
  width: 1.7rem;
  height: 1.7rem;
  background-color: #fff;
  border: 1px solid black;
  border-radius: 4px;

  &:hover {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.75);
  }
`;
const CardChecked = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18pt;
  top: 10px;
  right: 10px;
  width: 1.7rem;
  height: 1.7rem;
  background-color: #1fc585;
  border-radius: 4px;
  color: white;
  border: 1px solid black;

  &:hover {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.75);
  }
`;

export default AnnouncementItem;
