import { useMemo, useReducer } from "react";

// We are excluding this object from the advanced filter reset to ensure it is the only element affected.
const initialAdvancedFilterState = {
  minContractTime: [],
  refProducts: [],
  receiveNumberDaily: [],
  receivePackageDaily: [],
  dailyPalets: [],
  workingArea: [],
  nbrCmdDay: [],
  nbrPreparedDay: [],
  nbrExpeditionDay: [],
  capacityPalet: [],
  tech: [],
  ecommerce: [],
  certification: [],
  approval: [],
  specificity: [],
  areaFree: [],
};

const initialState = {
  productBaseGig: [],
  receiveGig: [],
  storageGig: [],
  preparegig: [],
  packagingGig: [],
  shippingGig: [],
  transportGig: [],
  interfacingGig: [],

  advancedFilters: initialAdvancedFilterState,
};

const reducer = (state, action) => {
  const { payload } = action;

  switch (action.type) {
    case "SET_PRIMARY_FILTER_VALUE":
      return { ...state, [payload.key]: payload.value };
    case "SET_ADVANCED_FILTER_VALUE":
      return {
        ...state,
        advancedFilters: {
          ...state.advancedFilters,
          [payload.key]: payload.value,
        },
      };
    case "RESET_ADVANCED_FILTERS":
      return { ...state, advancedFilters: initialAdvancedFilterState };
    case "RESET_ALL_FILTERS":
      return { ...initialState };
    case "SET_INITIAL_STATE":
      return { ...payload };
    default:
      return state;
  }
};

const useAdvancedFilter = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const nbTotalOfFilterActivated = useMemo(() => {
    let count = Object.values(state.advancedFilters).reduce(
      (acc, curr) => acc + curr.length,
      0
    );

    count += state.productBaseGig.length;
    count += state.receiveGig.length;
    count += state.storageGig.length;
    count += state.preparegig.length;
    count += state.packagingGig.length;
    count += state.shippingGig.length;
    count += state.transportGig.length;
    count += state.interfacingGig.length;

    return count;
  }, [
    state.advancedFilters,
    state.interfacingGig.length,
    state.packagingGig.length,
    state.preparegig.length,
    state.productBaseGig.length,
    state.receiveGig.length,
    state.shippingGig.length,
    state.storageGig.length,
    state.transportGig.length,
  ]);

  return { state, dispatch, nbTotalOfFilterActivated };
};

export default useAdvancedFilter;
