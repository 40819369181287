import { gql } from "@apollo/client";

export const SubscriptionUser = gql`
  subscription UserUpdated {
    userUpdated {
      id
      name
      company
      activityArea {
        label
        code
      }
      siret
      email
      phone
      role
      listingNumber
      status
      subscriptions {
        id
        startedAt
        endsAt
        revokeDate
        running
        duration
        subscribedAt
        subscription {
          id
          name
          price
        }
      }
    }
  }
`;
