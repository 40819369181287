import { useQuery } from "@apollo/client";
import { IonIcon } from "@ionic/react";
import { add, remove } from "ionicons/icons";
import { useState } from "react";
import { GET_FAQS } from "../../queries/faq/getFaq";

const QuestionItem = ({ question }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <button
      className="relative mb-2 text-left lg:mb-0"
      onClick={() => setIsActive(!isActive)}
    >
      <input
        checked={isActive}
        className="peer absolute inset-0 z-10 cursor-pointer opacity-0"
        type="checkbox"
        onChange={() => null}
      />
      <p className="inline-block max-w-[90%] text-left align-middle font-roboto font-semibold lg:mb-5 lg:text-xl">
        {question.question}
      </p>
      <IonIcon
        className="absolute right-0 top-1 hidden align-middle text-mplgreen peer-checked:inline-block lg:top-0 lg:text-2xl"
        icon={remove}
      />
      <IonIcon
        className="absolute right-0 top-1 align-middle text-mplgreen peer-checked:hidden lg:top-0 lg:text-2xl"
        icon={add}
      />
      <p className="mt-2 max-h-0 overflow-y-hidden text-left font-roboto transition-all duration-300 ease-in-out peer-checked:mb-5 peer-checked:max-h-96">
        {question.answer}
      </p>
    </button>
  );
};

function Question() {
  const { data: faqs, loading } = useQuery(GET_FAQS);

  if (loading) {
    return null;
  }

  return (
    <div className="flex flex-col">
      {(faqs.faqs || []).map((question) => (
        <QuestionItem key={question.question} question={question} />
      ))}
    </div>
  );
}

export default Question;
