import algoliasearch from "algoliasearch";
// eslint-disable-next-line no-unused-vars
import React from "react";
import RGPD from "../components/RGPD";
import Router from "../components/router/Router";
import { config } from "../config/env.config";
import GlobalStyle from "../style/globalStyle";

export const searchClient = algoliasearch(
  config.get("ALGOLIA_APP_ID"),
  config.get("ALGOLIA_SEARCH_KEY")
);

function App() {
  return (
    <>
      <Router />
      <GlobalStyle />
      <RGPD />
    </>
  );
}

export default App;
