import { MoonLoader } from "react-spinners";

const Spinner = () => {
  return (
    <div
      className="flex h-full items-center justify-center"
      data-testid="spinner-loader"
    >
      <MoonLoader color="#1fc585" />
    </div>
  );
};

export default Spinner;
