const MobileLocalizationSkeleton = () => {
  return (
    <div className="flex flex-col px-6 py-3">
      {Array.from({ length: 4 }).map((_, index) => (
        <div key={index} className="animate-pulse py-3">
          <div className="h-8 rounded bg-slate-300"></div>
        </div>
      ))}
    </div>
  );
};

export default MobileLocalizationSkeleton;
