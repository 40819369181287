import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import SubscriptionsHome from "../../../views/subscriptions/subscriptionsHome";
import ModalTw from "../index";

const ModalSubscription = ({ onClose, modalParams }) => {
  const { user } = useContext(AuthContext);

  return (
    <ModalTw
      fitContent
      content={<SubscriptionsHome modalParams={modalParams} />}
      title={
        user?.subscriptions?.length
          ? "Votre paiement a bien été effectué"
          : "Choisissez votre formule"
      }
      onClose={onClose}
    />
  );
};

export default ModalSubscription;
