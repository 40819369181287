import { useMutation } from "@apollo/client";
import { useCallback, useState } from "react";
import { CREATE_TENDER_NOTICE } from "../../queries/tenderNotice/tenderNotice";
import ModalTenderNoticeSuccess from "../ModalTW/ModalTenderNoticeSuccess";
import GreenSubHeader from "../ui/GreenSubHeader";
import TenderFormStep1 from "./components/TenderFormStep1";
import TenderFormStep2 from "./components/TenderFormStep2";
import TenderFormStep3 from "./components/TenderFormStep3";

const TenderNoticeForm = () => {
  const [step, setStep] = useState(1);
  const [showModalSuccess, setShowModalSuccess] = useState(false);

  const [formState, setFormState] = useState({
    step1: {
      offerTitle: "",
      companyName: "",
      sector: [],
      workingArea: [],
      publicationDate: {},
      closingDate: {},
      localisation: [],
    },
    step2: {
      serviceDescription: "",
      estimatedValue: "",
      contractDuration: "",
      contact: "",
      additionalInformation: "",
    },
    step3: {
      complementaryLinks: "",
      externalTender: null,
    },
  });

  const [createTender, { loading: createLoading }] =
    useMutation(CREATE_TENDER_NOTICE);

  const handleSubmitForm = useCallback(
    async (values) => {
      await createTender({
        variables: {
          input: {
            ...formState.step1,
            publicationDate: formState.step1.publicationDate.startDate,
            closingDate: formState.step1.closingDate.startDate,
            workingArea: formState.step1.workingArea.map((w) => ({
              areaCode: w,
              places: [],
            })),
            ...formState.step2,
            ...values,
          },
        },
      });
    },
    [createTender, formState]
  );

  const handleClickBack = () => {
    if (step > 1) {
      setStep((prevStep) => prevStep - 1);
    }
  };

  const handleClickNext = async (key, values) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      [key]: values,
    }));
    if (step < 3) {
      setStep((prevStep) => prevStep + 1);
    } else {
      await handleSubmitForm(values);
      setShowModalSuccess(true);
    }
  };

  return (
    <div>
      <GreenSubHeader>Déposez votre appel d’offre</GreenSubHeader>
      <section className="grid grid-cols-1 gap-12 bg-white p-6 lg:grid-cols-2 lg:gap-24 lg:p-12">
        <div className="col-span-1 flex-1 lg:mt-12">
          <div className="flex flex-col gap-8 lg:gap-10">
            <h1 className="font-roboto text-2xl font-bold text-gray-800 lg:text-[40px] lg:leading-10">
              Obtenez les meilleurs deals pour votre appel d’offre !
            </h1>
            <p className="font-roboto text-xl lg:text-[20px]">
              Besoin de transport, stockage, logistique ? Déposez votre appel
              d’offre en quelques clics !
            </p>
            <p className="flex flex-col rounded-lg bg-gray-100 px-4 py-2 text-lg lg:px-8 lg:py-4 lg:text-xl">
              <div className="font-roboto font-medium">
                Besoin d’un contact immédiat ?
              </div>
              <br />
              <div className="whitespace-pre-wrap">
                Notre équipe est disponible 7/7 au +33(0)6.76.77.76.38 ou par
                mail à <span>contact@monprestatairelogistique.com</span> pour
                échanger avec vous.
              </div>
            </p>
          </div>
        </div>
        <div className="col-span-1 rounded-xl bg-gray-200 p-2 px-4 lg:p-4 lg:px-8">
          <div className="h-full">
            {step === 1 && (
              <TenderFormStep1
                defaultValues={formState.step1}
                nextStep={handleClickNext}
              />
            )}
            {step === 2 && (
              <TenderFormStep2
                createLoading={createLoading}
                defaultValues={formState.step2}
                nextStep={handleClickNext}
                previousStep={handleClickBack}
              />
            )}
            {step === 3 && (
              <TenderFormStep3
                createLoading={createLoading}
                defaultValues={formState.step3}
                nextStep={handleClickNext}
                previousStep={handleClickBack}
              />
            )}
          </div>
        </div>
      </section>
      {showModalSuccess && <ModalTenderNoticeSuccess />}
    </div>
  );
};

export default TenderNoticeForm;
