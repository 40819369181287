import { useEffect, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import styled from "styled-components";

export function Field({
  title = null,
  placeholder = null,
  type = "text",
  register = null,
  value = "",
}) {
  return (
    <FieldWrapper>
      <FieldWrap style={type === "textarea" ? { height: "fit-content" } : null}>
        {type !== "textarea" && (
          <input
            {...register}
            defaultValue={value}
            placeholder={placeholder || ""}
            type={type}
          />
        )}
        {type === "textarea" && (
          <TextareaAutosize
            defaultValue={value}
            maxRows={10}
            placeholder={placeholder || ""}
            style={{
              resize: "vertical",
              width: "100%",
            }}
            {...register}
          />
        )}
        {title && type !== "textarea" && <label htmlFor="field">{title}</label>}
      </FieldWrap>
    </FieldWrapper>
  );
}

function ProfileFieldForm({
  title,
  actionText,
  value,
  children,
  isVisible,
  setIsVisible,
  isLoading,
}) {
  const [toggleField, setToggleField] = useState(false);

  useEffect(() => {
    if (isVisible) {
      setToggleField(false);
    }
  }, [isVisible]);

  useEffect(() => {
    if (!toggleField) {
      setIsVisible(false);
    }
  }, [setIsVisible, toggleField]);

  return (
    <>
      <InfoLabel>
        <h3>{title || ""}</h3>
        <button
          type="button"
          onClick={() => {
            setToggleField(!toggleField);
          }}
        >
          {!toggleField ? actionText : "Annuler"}
        </button>
      </InfoLabel>
      <InfoValues>
        {isLoading ? (
          <div className="max-w-sm animate-pulse" role="status">
            <div className="mb-2 h-4 w-48 rounded-full bg-gray-200"></div>
            <span className="sr-only">Loading...</span>
          </div>
        ) : (
          !toggleField && (
            <p>{(value !== "" && value) || "Information non fournie"}</p>
          )
        )}
        {toggleField && children}
      </InfoValues>
    </>
  );
}

const InfoLabel = styled.div`
  display: flex;
  align-items: center;

  h3 {
    font-size: 20px;
    font-weight: 600;
    margin: 0;
  }

  button {
    padding: 0;
    background: transparent;
    border: none;
    color: #000;
    font-size: 15px;
    font-weight: 500;
    margin-left: auto;
    text-decoration: underline;
    cursor: pointer;
  }
`;

const InfoValues = styled.div`
  margin-top: 10px;

  p {
    margin: 0;
    font-weight: 100;
    font-size: 15px;
    color: #333;
  }
`;

const FieldWrapper = styled.div`
  width: 100%;
`;

const FieldWrap = styled.div`
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #000;
  padding: 5px 10px;
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
  height: 50px;
  display: flex;
  flex-direction: column;

  label {
    font-size: 14px;
    color: #777;
    order: 1;
  }

  input,
  textarea {
    border: none;
    width: 100%;
    outline: none;
    font-size: 15px;
    font-weight: 800;
    margin-top: 3px;
    flex-grow: 1;
    order: 2;
  }
`;

export default ProfileFieldForm;
