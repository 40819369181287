import { IonIcon } from "@ionic/react";
import { informationCircleOutline } from "ionicons/icons";
import { useCallback, useMemo, useState } from "react";
import TippyTooltip from "../../../../TippyTooltip";

const ItemMoreFilterOption = ({ option, filter, handleOnChange }) => {
  const [, setForceRender] = useState(true);

  const isSelected = useMemo(() => {
    return !!filter.selectedFilter.find(
      (selected) => selected[filter.selectOn] === option[filter.selectOn]
    );
  }, [filter.selectOn, filter.selectedFilter, option]);

  const toggleValueFilter = useCallback(
    (label, selectedFilter) => {
      const tmp = [...selectedFilter];

      const index = tmp.findIndex(
        (selected) => selected[filter.selectOn] === label
      );

      if (index >= 0) {
        tmp.splice(index, 1);
      } else {
        tmp.push(
          filter.options.find((option) => option[filter.selectOn] === label)
        );
      }

      setForceRender((prevState) => !prevState);
      handleOnChange(tmp);
    },
    [filter.options, filter.selectOn, handleOnChange]
  );

  return (
    <div className="flex items-center justify-between gap-4 first:mt-5">
      <label className="flex grow gap-1 text-left" htmlFor={option.label}>
        {option.label}
        {option.description && (
          <TippyTooltip content={option.description}>
            <IonIcon icon={informationCircleOutline} />
          </TippyTooltip>
        )}
      </label>

      <div className="relative shrink">
        <input
          checked={isSelected}
          className="peer h-5 w-5 appearance-none rounded border border-mplgreen checked:bg-mplgreen checked:hover:!bg-mplgreen focus:ring-0 focus-visible:bg-red-300"
          id={option.label}
          type="checkbox"
          value={isSelected}
          onChange={() =>
            toggleValueFilter(option[filter.selectOn], filter.selectedFilter)
          }
        />
      </div>
    </div>
  );
};

export default ItemMoreFilterOption;
