const BackButton = ({ onClick }) => {
  return (
    <button
      className="absolute left-5 top-5 flex items-center justify-center rounded-lg bg-white p-1 shadow-md"
      onClick={onClick}
    >
      <svg
        className="h-6 w-6"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.5"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.75 19.5L8.25 12l7.5-7.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </button>
  );
};

export default BackButton;
