import { IonIcon } from "@ionic/react";
import { personCircle } from "ionicons/icons";
import { useContext, useState } from "react";
import menuMobile from "../../../assets/images/menuMobile.png";
import { NotificationContext } from "../../../context/NotificationContext";
import Dropdown from "../../others/header/dropdown/Dropdown";
import { menu } from "ionicons/icons";

const BurgerMenuComponent = ({ toggleLoginForm, toggleRegisterForm }) => {
  const { notifications } = useContext(NotificationContext);
  const [isDropdownShow, setIsDropdownShow] = useState(false);

  return (
    <div className="flex items-center">
      <div className="relative flex flex-col">
        <button onClick={() => setIsDropdownShow((prev) => !prev)}>
          <div className="w-9 bg-black lg:hidden">
            <img alt="menu mobile" src={menuMobile} />
          </div>
          <div className="hidden h-[42px] items-center gap-2 rounded-full bg-white px-3 lg:mr-12 lg:flex">
            <IonIcon className="text-3xl" icon={menu} />

            <div className="relative flex items-center justify-center">
              <IonIcon className="text-3xl" icon={personCircle} />
              <div style={{ height: "100%" }}>
                {notifications?.length > 0 && (
                  <div className="absolute -right-4 -top-2 inline-flex h-6 w-6 items-center justify-center rounded-full border-2 border-white bg-mplgreen text-xs font-bold text-white">
                    {notifications.length}
                  </div>
                )}
              </div>
            </div>
          </div>
        </button>
        {isDropdownShow && (
          <Dropdown
            setIsDropdownShow={setIsDropdownShow}
            toggleLoginForm={toggleLoginForm}
            toggleRegisterForm={toggleRegisterForm}
          />
        )}
      </div>
    </div>
  );
};

export default BurgerMenuComponent;
