import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";
import { GetUserByID } from "../../queries/profile/profile";

export const UseRefreshUser = () => {
  const { user, dispatch } = useContext(AuthContext);

  const [fetchUser] = useLazyQuery(GetUserByID, {
    variables: { id: (user || {})?.id },
    fetchPolicy: "no-cache",
    initialFetchPolicy: "no-cache",
  });

  useEffect(() => {
    function handleFocus() {
      fetchUser().then((res) => {
        dispatch({
          type: "UPDATE_USER",
          payload: { user: { ...user, status: res.data.getUserById.status } },
        });
      });
    }

    if (user?.status === "WAITING_FOR_VALIDATION")
      window.addEventListener("focus", handleFocus);

    return () => {
      window.removeEventListener("focus", handleFocus);
    };
  }, [dispatch, fetchUser, user]);

  return null;
};
