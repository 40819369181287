export function getTitleWithCount(arr, defaultText, truncate = false) {
  if (arr.length === 0) {
    return defaultText;
  } else {
    const firstTitle = arr[0].title.split(" ")[0];
    const count = arr.length - 1;

    return (
      <div
        className={`flex items-center justify-center gap-1 ${
          truncate ? "mx-auto truncate" : ""
        }`}
      >
        <span className="inline-block truncate">{firstTitle} </span>
        {count ? <span>{` +${count}`}</span> : null}
      </div>
    );
  }
}
