import { IonIcon } from "@ionic/react";
import { closeOutline, search } from "ionicons/icons";
import Select, { components } from "react-select";
import styled from "styled-components";
import { translateTypeToFrench } from "../../../utils/localization";
import MultiSelectDropdown from "../../MultiSelectDropdown/MultiSelectDropdown";
import useSearchBarHooks from "./hooks/useSearchBarhooks";
import AroundMe from "../../../assets/images/AroundMe.png";

const services = [
  { id: "can_products_base", title: "Gestion de la base articles" },
  { id: "can_receive", title: "Réception" },
  { id: "can_storage", title: "Stockage" },
  { id: "can_prepare", title: "Préparation" },
  { id: "can_packaging", title: "Emballage" },
  { id: "can_shipping", title: "Expédition" },
  { id: "can_transportation", title: "Transport" },
  { id: "can_interfacing", title: "Interfaçage" },
];

function SearchBar({ onSearch, resetSearch = false }) {
  const hooks = useSearchBarHooks({ onSearch, resetSearch });
  const borderBlackStyle = {
    border: "1px solid black",
    boxShadow: "rgba(0, 0, 0, 0.50) 0 2px 4px",
  };
  const borderWhiteStyle = {
    boxShadow: "none",
  };
  const Option = (props) => {
    return (
      <components.Option {...props}>
        <div className="location-icon">
          {props.data.label !== "Autour de moi" ? (
            <svg
              className="bi bi-geo-alt"
              fill="currentColor"
              height="16"
              viewBox="0 0 16 16"
              width="16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
              <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
            </svg>
          ) : (
            <img
              alt="around me"
              className="-ml-1 w-[28px] shrink-0"
              src={AroundMe}
            />
          )}
        </div>
        <div className="w-full max-w-[95%]">
          {props.data.label === "Toute la France" ? (
            <span className="font-roboto text-lg">{props.label}</span>
          ) : props.data.label === "Autour de moi" ? (
            <span className="-ml-1.5 font-roboto text-lg">{props.label}</span>
          ) : (
            <>
              <div className="truncate font-roboto text-lg font-bold">
                <span>{props.data.city} </span>
                {props.data.postal_code ? `(${props.data.postal_code})` : ""}
              </div>
              <div className="flex items-center justify-between text-gray-700">
                <span>
                  {props.data.administrative_area
                    ? `${props.data.administrative_area}, `
                    : null}
                  {props.data.country}
                </span>
                <span>{translateTypeToFrench(props.data.type)}</span>
              </div>
            </>
          )}
        </div>
      </components.Option>
    );
  };
  const IndicatorsContainer = () => null;

  return (
    <SearchBarContainer>
      <Form>
        <SearchBarItem
          style={hooks.isOpenLocation ? borderBlackStyle : borderWhiteStyle}
        >
          <p>Localisation</p>
          <div className="flex items-center">
            <div className="grow" onClick={hooks.togglingLocation}>
              <Select
                isClearable
                components={{ Option, IndicatorsContainer }}
                defaultValue={hooks.location}
                options={hooks.options}
                placeholder="Saisir la ville ou région"
                styles={{
                  control: (baseStyles, state) => {
                    return {
                      ...baseStyles,
                      border: "none",
                      maxWidth: "300px",
                      boxShadow: state.isFocused
                        ? "none"
                        : baseStyles.boxShadow,
                      cursor: "pointer",
                      fontSize: "18px",
                      padding: "0 !important",
                      color: hooks.options.length > 0 ? "#9E9E9E" : "#000",
                    };
                  },
                  valueContainer: (baseStyles, state) => ({
                    ...baseStyles,
                    paddingLeft: "0",
                    width:
                      state.hasValue || hooks.location
                        ? "max-content"
                        : "270px",
                    marginTop: "-8px",
                  }),
                  input: (baseStyles, state) => ({
                    ...baseStyles,
                    paddingTop: "0",
                    marginTop: "0",
                  }),
                  menu: (baseStyles, state) => ({
                    ...baseStyles,
                    position: "absolute",
                    top: "71px",
                    left: "-45px",
                    width: "22vw",
                    zIndex: "100",
                    backgroundColor: "#fff",
                    border: "1px solid #ccc",
                    borderRadius: "24px",
                    borderTop: "none",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                    minWidth: "250%",
                  }),
                  menuList: (baseStyles, state) => ({
                    ...baseStyles,
                    margin: "20px",
                    overflowY: "auto",
                    maxHeight: "200px",
                  }),
                  option: (baseStyles, state) => ({
                    ...baseStyles,
                    padding: "15px",
                    borderRadius: "10px",
                    color: "black",
                    backgroundColor: state.isFocused && "#f0f0f0",
                    cursor: "pointer",
                    display: "flex",
                    "& .location-icon": {
                      marginRight: "10px",
                      alignSelf: "center",
                    },
                  }),
                }}
                value={hooks.location}
                onChange={hooks.handleChange}
                onInputChange={hooks.handleInputChange}
              />
            </div>
            {hooks.location && (
              <RemoveIcon
                className="shrink-0"
                height="20px"
                icon={closeOutline}
                width="20px"
                onClick={() => {
                  hooks.setLocation("");
                  hooks.setSelectedOption(null);
                }}
              />
            )}
          </div>
        </SearchBarItem>
        <Hr
          style={
            !hooks.isOpenLocation && !hooks.isOpenArea
              ? { visibility: "visible" }
              : { visibility: "hidden" }
          }
        />
        <SearchBarItem
          style={hooks.isOpenArea ? borderBlackStyle : borderWhiteStyle}
        >
          <p>Secteur</p>
          <MultiSelectDropdown
            clearSelection={hooks.clearSelectedAria}
            hiddenCheckbox={true}
            isOpen={hooks.isOpenArea}
            isSecteur={true}
            options={
              hooks.settingDatas?.workingDomains.map((i) => ({
                id: i.code,
                title: i.label,
              })) ?? []
            }
            selected={hooks.selectedArea ?? []}
            titleHeader="Quel ?"
            toggleDisplay={hooks.togglingArea}
            toggleOption={hooks.toggleOptionArea}
          />
        </SearchBarItem>
        <Hr
          style={
            !hooks.isOpenArea && !hooks.isOpenService
              ? { visibility: "visible" }
              : { visibility: "hidden" }
          }
        />

        <DivServiceAndButton
          style={hooks.isOpenService ? borderBlackStyle : borderWhiteStyle}
        >
          <SearchBarPrestations>
            <p>Prestations</p>

            <MultiSelectDropdown
              clearSelection={hooks.clearSelectedService}
              hiddenCheckbox={false}
              isOpen={hooks.isOpenService}
              isSecteur={false}
              options={services ?? []}
              selected={hooks.selectedService}
              titleHeader="Type ?"
              toggleDisplay={hooks.togglingService}
              toggleOption={hooks.toggleOptionService}
            />
          </SearchBarPrestations>
          {!hooks.searchBig && (
            <SearchButton type="button" onClick={() => hooks.toMiniSearchBar()}>
              <SearchIcon icon={search} />
              <p>Rechercher</p>
            </SearchButton>
          )}
          {hooks.searchBig && (
            <SearchBigButton
              type="button"
              onClick={() => hooks.toMiniSearchBar()}
            >
              <SearchIcon icon={search} />
              <p>Rechercher</p>
            </SearchBigButton>
          )}
        </DivServiceAndButton>
      </Form>
      {hooks.error && (
        <ErrorDiv>
          Sélectionner au moins un critère pour lancer votre recherche
        </ErrorDiv>
      )}
    </SearchBarContainer>
  );
}

const SearchBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 50px 0;

  @media (max-width: 1023px) {
    display: none;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  width: 80%;
  border-radius: 60px;
  box-sizing: border-box;
`;

const ErrorDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: red;
  padding-top: 10px;
`;
const SearchBarItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1 0 auto;
  max-width: 35%;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0px 20px 20px 50px;
  border-radius: 60px;
  z-index: 1;

  p {
    font-family: "Roboto Regular", sans-serif;
    font-size: 24px;
    color: #000 !important;
    font-weight: 600;
    margin-bottom: 5px;
    margin-top: 21px;
    line-height: 1.8rem;
  }
`;

const SearchBarPrestations = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0px 0px 20px 0px;
  p {
    font-family: "Roboto Regular", sans-serif;
    font-size: 24px;
    color: #000 !important;
    font-weight: 600;
    margin-bottom: 5px;
    margin-top: 21px;
    line-height: 1.8rem;
  }
`;

const DivServiceAndButton = styled.div`
  padding: 0px 20px 0px 50px;
  border-radius: 60px;
  z-index: 1;
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Hr = styled.hr`
  width: 1px;
  height: 40px;
  border: none;
  border-left: 1px solid #bbbbbb;
`;

const SearchIcon = styled(IonIcon)`
  font-size: 50px;
  color: #fff;
`;

const RemoveIcon = styled(IonIcon)`
  font-size: 35px;
  width: 20px;
  height: 20px;
  color: black;
  margin-top: -8px;
`;

const SearchBigButton = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-left: 20px;
  border: none;
  outline: none;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  transition: all 0.3s ease;
  width: 180px;
  height: 80px;
  border-radius: 50px;
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  p {
    display: block;
  }
`;
const SearchButton = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  background-color: #000;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-left: 20px;
  border: none;
  outline: none;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    width: 180px;
    height: 80px;
    border-radius: 50px;
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);

    p {
      display: block;
    }
  }
  p {
    font-size: 20px;
    display: none;
    color: #fff;
    transition: all 0.3s ease;
  }
`;

export default SearchBar;
