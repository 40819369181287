import { IonIcon } from "@ionic/react";
import { chevronBack } from "ionicons/icons";
import PropTypes from "prop-types";
import { useRef } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import useModalHook from "./hooks/modalHook";

function Modal({
  isShowing,
  hide,
  header,
  titleHeader,
  content,
  width,
  background = true,
  marginTop,
  goback,
  withInner = false,
  ...props
}) {
  const wrapperRef = useRef(null);
  useModalHook(wrapperRef, hide);
  return isShowing
    ? ReactDOM.createPortal(
        <PopUp>
          <ModalContent
            key={titleHeader}
            background={background}
            content={content}
            goback={goback}
            header={header}
            hide={hide}
            marginTop={marginTop}
            titleHeader={titleHeader}
            width={width}
            withInner={withInner}
            wrapperRef={wrapperRef}
          >
            {props.children}
          </ModalContent>
        </PopUp>,
        document.body
      )
    : null;
}

const ModalContent = ({
  withInner,
  header,
  content,
  children,
  width,
  marginTop,
  wrapperRef,
  background,
  hide,
  titleHeader,
  goback,
}) => {
  return (
    <>
      {!withInner && (
        <PopUpInner
          ref={wrapperRef}
          className="!px-4 lg:px-6"
          marginTop={marginTop}
          style={
            !background
              ? {
                  background: "none",
                  backgroundColor: "none",
                  boxShadow: "none",
                }
              : null
          }
          width={width}
        >
          {header ? (
            <div className="relative flex items-center justify-center">
              {goback && (
                <button
                  type="button"
                  onClick={() => {
                    goback();
                  }}
                >
                  <GoBackIcon icon={chevronBack} />
                </button>
              )}
              {!goback && (
                <button
                  className="absolute left-0 top-1/2 -translate-y-1/2 text-2xl"
                  type="button"
                  onClick={() => {
                    hide();
                  }}
                >
                  &times;
                </button>
              )}
              <div className="px-5 text-xl font-bold">{titleHeader}</div>
            </div>
          ) : null}
          {content || children}
        </PopUpInner>
      )}
      {withInner && (content || children)}
    </>
  );
};
const GoBackIcon = styled(IonIcon)`
  font-size: 25px;
`;

const PopUp = styled.div`
  z-index: 700;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-items: center;
`;

const PopUpInner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${(props) => props.width || "400px"};
  border-radius: 24px;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 25px;
  max-height: 90%;
  min-width: 25%;
`;

Modal.defaultProps = {
  titleHeader: "",
  content: null,
  children: null,
  marginTop: "45px",
};

Modal.propTypes = {
  isShowing: PropTypes.bool.isRequired,
  hide: PropTypes.func,
  header: PropTypes.bool,
  titleHeader: PropTypes.string,
  marginTop: PropTypes.string,
  content: PropTypes.element,
  children: PropTypes.element,
  width: PropTypes.string,
  withInner: PropTypes.bool,
};

export default Modal;
