import { gql } from "@apollo/client";

export const GetAccountSecurityInfos = gql`
  query getAccountSecurityInfos {
    getAccountSecurityInfos {
      disabledAt
      lastChangePassword
      sessions {
        id
        os
        browser
        active
        createdAt
        lastupdated
        actual
        sessionId
      }
    }
  }
`;

export const UserSessionEnd = gql`
  query endSession($sessionId: Int!) {
    endSession(sessionId: $sessionId)
  }
`;

export const UserLogout = gql`
  query logout {
    logout
  }
`;

export const UpdatePassword = gql`
  mutation updatePassword($oldpassword: String!, $newpassword: String!) {
    updatePassword(
      updatePasswordInput: {
        oldpassword: $oldpassword
        newpassword: $newpassword
      }
    )
  }
`;

export const DisableAccount = gql`
  mutation disableAccount($reason: String!) {
    disableAccount(disableAccountInput: { reason: $reason }) {
      id
    }
  }
`;

export const EnableAccount = gql`
  query enableAccount {
    enableAccount {
      id
    }
  }
`;
