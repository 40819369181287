import { useCallback } from "react";
import MobileModal from "..";
import { getAdvancedFilters, getPrimaryFilters } from "../../../constants/allFilters";
import GreenButton from "../../ui/GreenButton";
import ItemMoreFilter from "./components/ItemMoreFilter";

const MobileMoreFilter = ({ onClose, state, settingDatas, dispatch }) => {
  const handleResetAllFilters = useCallback(() => {
    dispatch({ type: "RESET_ALL_FILTERS" });
  }, [dispatch]);

  return (
    <MobileModal
      title="Plus de filtres"
      onClose={onClose}
    >
      <div className="flex h-full flex-col font-roboto">
        <div className="h-1 grow overflow-auto">
          {getPrimaryFilters(state, settingDatas, dispatch).map((filter) => (
            <ItemMoreFilter
              key={filter.id}
              filter={filter}
            />
          ))}
          {getAdvancedFilters(state, settingDatas, dispatch).map((filter) => (
            <ItemMoreFilter
              key={filter.id}
              filter={{
                ...filter,
                placeholder: filter.subTitle || filter.title,
              }}
            />
          ))}
        </div>
        <div className="mt-auto flex items-center justify-between border-t border-t-mplgray px-7 py-4">
          <button
            className="border-b border-black font-bold"
            onClick={handleResetAllFilters}
          >
            Tout effacer
          </button>
          <GreenButton onClick={onClose}>Fermer</GreenButton>
        </div>
      </div>
    </MobileModal>
  );
};

export default MobileMoreFilter;
