import { useLazyQuery, useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { GetUserByEmail } from "../../../queries/profile/profile";
import { ResetLinkPassword } from "../../../queries/resetPassword/sendLinkEmail";
import Alert from "../../ui/Alert";
import CustomButton from "../../ui/CustomButton";
import CustomInput from "../../ui/CustomInput";
import ModalTw from "../index";

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Doit être une adresse email valide")
    .required("Adresse mail est obligatoire"),
});

const ModalForgotPassword = ({ onClose }) => {
  const [resetLinkPassword, { loading, error }] =
    useMutation(ResetLinkPassword);
  const [getUserByEmail] = useLazyQuery(GetUserByEmail);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: "",
    },
  });

  const content = useMemo(() => {
    return (
      <form>
        {error && (
          <Alert message="Cette adresse email n'existe pas" type="danger" />
        )}
        {!error && isSubmitSuccessful && (
          <Alert message="Un email vous a été envoyé" type="success" />
        )}
        <CustomInput
          error={errors.email}
          name="email"
          placeholder="Adresse mail"
          register={register}
        />
      </form>
    );
  }, [error, errors.email, isSubmitSuccessful, register]);

  const onSubmit = useCallback(
    async (values) => {
      const email = values.email.toLowerCase();

      const userExists = await getUserByEmail({
        variables: { email },
      });

      if (userExists) {
        await resetLinkPassword({ variables: { email } });
      }
    },
    [getUserByEmail, resetLinkPassword]
  );

  return (
    <ModalTw
      content={content}
      footer={
        <CustomButton
          isFull
          isLoading={loading}
          onClick={handleSubmit(onSubmit)}
        >
          Réinitialiser mot de passe
        </CustomButton>
      }
      title="Mot de passe oublié !"
      onClose={onClose}
    />
  );
};

export default ModalForgotPassword;
