import { useLazyQuery } from "@apollo/client";
import {
  ArrowLeftIcon,
  ChevronDownIcon,
  TrashIcon,
  UserCircleIcon,
} from "@heroicons/react/20/solid";
import cx from "classnames";
import * as moment from "moment";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { FcApproval } from "react-icons/fc";
import { Link } from "react-router-dom";
import uuid from "react-uuid";
import styled from "styled-components";
import ModalQuoteNotPremium from "../../../components/ModalTW/ModalQuoteNotPremium";
import { AuthContext } from "../../../context/AuthContext";
import useTailwindBreakpoint from "../../../hooks/useTailwindBreakpoint";
import { GetStripeSessionId } from "../../../queries/subscription/subscription";
import { isRoleLogistician } from "../../../utils/user";

const MessageContent = ({ msg }) => {
  const getDate = (d) => {
    const date = moment(d);
    return date.fromNow();
  };

  return (
    <>
      <p className="mb-2 text-end text-xs text-gray-500">
        {getDate(msg?.createdAt)}
      </p>
      {msg.estimate?.file && (
        <Info>
          <h3 className="mb-3 text-xl font-medium leading-6 text-gray-900">
            Demande de devis
          </h3>
          <InfoDoc>
            <div>
              Annonce:{" "}
              <span className="text-lg font-medium">
                {msg.estimate?.listing.description?.title}
              </span>
            </div>
            <div>
              Cahier des charges:{" "}
              <Link
                download
                className="text-lg font-medium text-gray-900 underline-offset-2 hover:underline"
                target="_blank"
                to={msg.estimate?.file.url}
              >
                {msg.sender.company}-
                {moment(msg.createdAt).format("DD-MM-YYYY")} (télécharger)
              </Link>
            </div>
          </InfoDoc>
        </Info>
      )}
      {!msg.estimate?.file && msg.attached.length > 0 && (
        <div className="mb-3">
          <div className="font-medium">Fichier join(s):</div>
          <div className="flex flex-col space-y-1">
            {msg.attached.map((x) => (
              <Link
                key={x.url}
                download
                className="text-sm underline-offset-2 hover:underline"
                target="_blank"
                to={x.url}
              >
                Devis {x?.name} (télécharger)
              </Link>
            ))}
          </div>
        </div>
      )}
      <pre className="whitespace-pre-wrap font-roboto-regular">
        {msg.message}
      </pre>
    </>
  );
};

function DetailQuote({
  selectedUser,
  messages,
  onSend,
  unselectQuote,
  isFromMulti,
  sendMessageLoading,
  isPremium,
}) {
  const [specificationFiles, SetSpecificationFiles] = useState([]);
  const [isShowSpecMoreActive, setIsShowSpecMoreActive] = useState(true);

  const { user } = useContext(AuthContext);
  const [isModalNotPremiumOpen, setIsModalNotPremiumOpen] = useState(false);

  const scrollContainerRef = useRef();
  const textareaRef = useRef(null);
  const [attach, setAttach] = useState([]);
  const { isXsTw } = useTailwindBreakpoint();
  const [getStripeSessionId] = useLazyQuery(GetStripeSessionId);

  const { register, handleSubmit, watch, reset } = useForm({
    defaultValues: {
      message: "",
    },
  });

  const message = watch("message");

  useEffect(() => {
    if (isRoleLogistician(user) && !isPremium && isFromMulti) {
      setIsModalNotPremiumOpen(true);
    } else {
      setIsModalNotPremiumOpen(false);
    }
  }, [isFromMulti, isPremium, user]);

  useEffect(() => {
    const specFiles = [];
    for (const message of messages) {
      if (message.estimate)
        specFiles.push({
          ...message.estimate.file,
          name:
            message.sender.company +
            "-" +
            moment(message.createdAt).format("DD-MM-YYYY"),
        });
    }
    SetSpecificationFiles(specFiles);
  }, [messages]);

  const scrollToBottom = (el) => {
    el?.scrollTo({ top: el.scrollHeight + 1000 });
  };

  useEffect(() => {
    // setTimeout needed to wait for the DOM to update and to scroll to the bottom.
    setTimeout(() => {
      scrollToBottom(scrollContainerRef.current);
    }, 0);
  }, [messages]);

  useEffect(() => {
    const textarea = textareaRef.current;
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
  }, [message]);

  const onSubmit = useCallback(
    (values) => {
      onSend(values.message.trim(), selectedUser?.id, attach);
      setAttach([]);

      reset();
    },
    [attach, setAttach, onSend, reset, selectedUser?.id]
  );

  const isSendDisabled = useMemo(() => {
    return (message === "" && attach.length === 0) || sendMessageLoading;
  }, [attach.length, message, sendMessageLoading]);

  const { ref: RHFref, ...textareaRegistered } = register("message");

  const redirectToStripe = () => {
    if (user.subscriptions.length > 0) {
      getStripeSessionId({
        variables: {
          successUrl: window.location.href.includes("?")
            ? window.location.href + "&portal=true"
            : window.location.href + "?portal=true",
        },
      }).then(async (r) => window.open(r.data.getStripeSessionId, "_self"));
    } else {
      window.location.href = "/subscriptions";
    }
  };

  const handleClickShowSpec = useCallback(() => {
    setIsShowSpecMoreActive((prev) => !prev);
  }, []);

  return (
    <div className="relative flex h-full w-full flex-col bg-white px-4 py-6 pb-0">
      {isXsTw && (
        <button
          className="-mx-4 -mt-6 mb-4 inline-flex items-center justify-center rounded-lg bg-gray-50 p-5 text-base font-medium text-gray-500"
          onClick={unselectQuote}
        >
          <ArrowLeftIcon className="mr-2 h-5 w-5" />
          <span className="w-full">Liste des conversations</span>
        </button>
      )}

      <div className="rounded-2xl px-6 py-4 shadow">
        <div>
          <div className="flex items-center">
            <div className="flex h-10 w-10 items-center justify-center overflow-hidden rounded-full bg-mplgreen text-white">
              {selectedUser?.profilePic !== null ? (
                <img
                  alt="image company"
                  className="h-full w-full object-cover"
                  src={selectedUser?.profilePic.url}
                />
              ) : (
                <span>{selectedUser?.company[0].toUpperCase()}</span>
              )}
            </div>
            <div className="ml-3 flex flex-col">
              <div className="text-sm font-semibold">
                {selectedUser?.company}
              </div>
              <div className="text-xs">
                {selectedUser?.status === "VALIDATED" ? (
                  <p className="flex items-center">
                    <span className="mr-1">Profil vérifié</span>{" "}
                    <FcApproval></FcApproval>
                  </p>
                ) : (
                  <p>Profil non vérifié</p>
                )}
              </div>
            </div>
          </div>
          <div className="mt-5">
            {specificationFiles.length > 0 && (
              <>
                <div className="flex items-center gap-x-2">
                  <h2 className="text-lg font-semibold text-gray-900">
                    Cahier des charges envoyé(s)
                  </h2>
                  <button
                    className="rounded-full border border-gray-200"
                    style={{
                      boxShadow: `rgba(0, 0, 0, 0.1) 1px 1px 1px`,
                    }}
                    onClick={handleClickShowSpec}
                  >
                    <ChevronDownIcon
                      className={cx(
                        "h-5 w-5 rounded-full duration-75 ease-in-out bg-white",
                        {
                          "transform -rotate-180": isShowSpecMoreActive,
                        }
                      )}
                    />
                  </button>
                </div>
                <ul
                  className={cx(
                    "max-h-[0px] max-w-md list-inside transform duration-75 ease-in-out list-disc space-y-1 overflow-y-auto text-gray-500",
                    {
                      "max-h-[85px] mt-2": isShowSpecMoreActive,
                    }
                  )}
                >
                  {specificationFiles.map((x) => (
                    <div key={uuid()} className="flex">
                      <li>{x?.name}</li>

                      <Link
                        download
                        className="ml-2 text-blue-500 underline-offset-2 hover:underline"
                        target="_blank"
                        to={x?.url}
                      >
                        Télécharger
                      </Link>
                    </div>
                  ))}
                </ul>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="h-[380px] grow overflow-hidden py-4 pb-0">
        <div ref={scrollContainerRef} className="h-full overflow-y-auto">
          <div className="grid grid-cols-12 gap-y-2">
            {messages.map((msg, index) => {
              if (msg.sender.id === selectedUser?.id) {
                return (
                  <div
                    key={index}
                    className="col-start-1 col-end-10 rounded-lg p-3"
                  >
                    <div className="flex flex-row items-center">
                      <div className="hidden h-10 w-10 shrink-0 items-center justify-center rounded-full md:flex">
                        <UserCircleIcon />
                      </div>
                      <div className="relative ml-3 rounded-xl bg-white px-4 py-2 text-sm shadow">
                        <MessageContent msg={msg} />
                      </div>
                    </div>
                  </div>
                );
              }

              return (
                <div
                  key={index}
                  className="col-start-3 col-end-13 rounded-lg p-3"
                >
                  <div className="flex flex-row-reverse items-center justify-start">
                    <div className="relative mr-3 rounded-xl bg-indigo-100 px-4 py-2 text-sm shadow">
                      <MessageContent msg={msg} />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <form
        className="sticky bottom-0 flex flex-row items-center bg-white py-3"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="sticky bottom-0 flex w-full flex-row items-center overflow-hidden rounded-3xl border px-2">
          <textarea
            className="flex h-10 w-full resize-none items-center border-none focus:ring-0"
            disabled={isRoleLogistician(user) && !isPremium && isFromMulti}
            placeholder="Taper votre message..."
            rows={1}
            {...textareaRegistered}
            ref={(e) => {
              RHFref(e);
              textareaRef.current = e;
            }}
          />
          <div className="flex flex-row">
            <label className="flex h-10 w-8 cursor-pointer items-center justify-center text-gray-400">
              <svg
                className="h-5 w-5"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                ></path>
              </svg>
              <input
                hidden
                multiple
                id="attach"
                name="attach"
                type="file"
                onChange={(evt) => {
                  setAttach(Array.from(evt.target.files, (v) => v));
                  evt.target.value = null;
                }}
              />
            </label>
          </div>
        </div>
        <div className="ml-6">
          <button
            className={cx(
              "flex h-10 w-10 items-center justify-center rounded-full bg-gray-200 text-white transition-colors duration-150 ease-in-out",
              {
                "bg-mplgreen hover:bg-mplgreen-hover":
                  !isSendDisabled || sendMessageLoading,
              }
            )}
            disabled={isSendDisabled}
            type="submit"
          >
            {sendMessageLoading ? (
              <svg
                aria-hidden="true"
                className="inline h-4 w-4 animate-spin text-white"
                fill="none"
                role="status"
                viewBox="0 0 100 101"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="#E5E7EB"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentColor"
                />
              </svg>
            ) : (
              <svg
                className="-mr-px h-5 w-5 rotate-90"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                ></path>
              </svg>
            )}
          </button>
        </div>
      </form>
      {attach.length > 0 && (
        <ul className="mb-4 mt-2 max-w-md list-inside space-y-2 text-gray-700">
          {attach.map((x, i) => (
            <li key={x.name} className="flex items-center">
              <div>{x.name}</div>
              <button
                className="ml-1 h-5 w-5 text-red-500"
                onClick={() =>
                  setAttach((prev) => {
                    const newAttach = [...prev];
                    newAttach.splice(i, 1);
                    return newAttach;
                  })
                }
              >
                <TrashIcon />
              </button>
            </li>
          ))}
        </ul>
      )}

      {isModalNotPremiumOpen && (
        <ModalQuoteNotPremium
          unselectQuote={unselectQuote}
          onClick={redirectToStripe}
          onClose={() => setIsModalNotPremiumOpen(false)}
        />
      )}
    </div>
  );
}

const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  text-align: start;
`;

const InfoDoc = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-bottom: 0.5rem;
  p {
    font-size: large;
    color: black;
    align-self: center;
    text-decoration-line: underline;
  }
`;

export default DetailQuote;
