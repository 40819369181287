import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import CustomTextArea from "../../components/input/CustomTextArea";
import CustomButton from "../../components/ui/CustomButton";
import useTailwindBreakpoint from "../../hooks/useTailwindBreakpoint";
import { SendToCompatible } from "../../queries/specification/specification";
import MobileAddMessageSpecification from "./MobileAddMessageSpecification";

export default function AddMessageSpecification() {
  const navigate = useNavigate();
  const { isXsTw, isSmTw, isMdTw } = useTailwindBreakpoint();
  const {
    register,
    getValues,
    formState: { errors },
  } = useForm();
  const location = useLocation();

  const previousStep = () => {
    navigate(-1);
  };
  const [sendToCompatible, { loading }] = useMutation(SendToCompatible);
  const nextStep = () => {
    const values = getValues();
    sendToCompatible({
      variables: {
        specId: location.state?.specId,
        msg: values.optionalMessage,
      },
    }).then(() => navigate("/dashboard/specification-congrats"));
  };

  if (isXsTw || isSmTw || isMdTw) {
    return (
      <MobileAddMessageSpecification
        isLoading={loading}
        nextStep={nextStep}
        previousStep={previousStep}
        register={register}
      />
    );
  }

  return (
    <Container>
      <Title>Demande de devis</Title>
      <Content>
        <Subtitle>Ajouter un message (optionnel)</Subtitle>

        <CustomTextArea
          error={errors.optionalMessage}
          minRows={4}
          name="optionalMessage"
          register={register("optionalMessage")}
        />
      </Content>
      <StepsControls>
        <CustomButton color="black" variant="outlined" onClick={previousStep}>
          Retour
        </CustomButton>
        <CustomButton isLoading={loading} onClick={nextStep}>
          Valider
        </CustomButton>
      </StepsControls>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 40px;
  height: 100%;
  flex-grow: 1;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 90%;
  flex-grow: 1;
  padding-bottom: 50px;
  align-items: start;
  gap: 8px;
  margin-top: 16px;
`;
const Title = styled.p`
  font-size: 27px;
  color: #000;
  font-weight: 700;
  cursor: pointer;
`;

const Subtitle = styled.p`
  font-size: 25px;
  color: #000;
  font-weight: 500;
  cursor: pointer;
`;

const StepsControls = styled.div`
  box-sizing: border-box;
  display: flex;
  z-index: 10;
  padding: 1.25rem 40px;
  background-color: #ffffff;
  justify-content: space-between;
  border-width: 0;
  border-top-width: 1px;
  border-style: solid;
  box-shadow: none;
  margin: 0 -40px;
  margin-bottom: -30px;
  width: 100vw;
`;
