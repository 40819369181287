import cx from "classnames";

const SubscriptionTab = ({ tabs }) => {
  return (
    <div className="mb-4 flex">
      <ul
        className="-mb-px flex flex-wrap border-b border-gray-200 text-center text-sm font-medium"
        data-tabs-toggle="#myTabContent"
        id="myTab"
        role="tablist"
      >
        {tabs.map((tab) => (
          <li key={tab.label} className="mr-2" role="presentation">
            <button
              aria-controls="profile"
              aria-selected="false"
              className={cx("text-lg", {
                "bg-white text-mplgreen border-b-mplgreen border-b":
                  tab.isActive,
                "text-gray-500 hover:text-gray-700": !tab.isActive,
              })}
              data-tabs-target="#profile"
              id="profile-tab"
              role="tab"
              type="button"
              onClick={tab.onClick}
            >
              {tab.label}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SubscriptionTab;
