import { gql } from "@apollo/client";
const specType = `
  id
  user {
    email
    company
  }
  description {
      id
      name
      website
      domainDescription
      searchDescription
      advantageAndDefault
      workingDomains {
          code
          label
          active
        }
    }
  productManagement {
      id
      conditioning_pack {active,comment}
      conditioning_palet {active,comment}
      levelDimensionRemark
      levelWeightsRemark
      stockageType {active,comment}
      dataArticle {active,comment}
      lotManagement {active,comment,label,code}
      shelfLifeManagement {active,comment,label,code}
      serialNumberManagement {active,comment,label,code}
      securityManagement {active,comment,label,code}
      kitManagement {active,comment}
      barecodeManagement {active,comment}
      referenceNumber {value,comment}
      articleTypology {
          code
          label
          description
          active
      }
      levelDimension {
          unit
          pack
          palet
      }
      levelWeight {
          unit
          pack
          palet
      }
  }
  receive {
      id
      adminCreation {active,comment}
      interfaceCreation {active,comment}
      useOnBoard {active,comment}
      receiveUnit {active,comment}
      receivePalet {active,comment}
      barcodeUse {active,comment}
      labellingSupport {active,comment}
      editingDocument {active,comment}
      beforeReceive {active,comment}
      afterReceive {active,comment}
      frequencyFixed {active,comment}
      frequencyNotFix {active,comment}
      qualitycheck {active,comment,label,code}
      conformityDeclaration {active,comment,label,code}
      crossDocking {active,comment,label,code}
      nightReceive {active,comment}
      unloadRamp {active,comment}
      vracReceive {active,comment,label,code}
      receiveTrailer {active,comment}
      receiveLightTrailer {active,comment}
      receiveContainer {active,comment}
      receivePerDay {value,comment}
      receivePerWeek {value,comment}
      receivePerMonth {value,comment}
      articlePerDay {value,comment}
      articlePerWeek {value,comment}
      articlePerMonth {value,comment}
      receiveTypology {
          code
          label
          description
          active
      }
  }
  storage {
      id
      paletStorageNow {value,comment}
      itemStorageNow {value,comment}
      storageIncompatibility {active,comment}
      dedicatedZone {active,comment}
      constraint {active,comment}
      temperatureControl {active,comment,label,code}
      oversizedStorage {active,comment,label,code}
      specificStorage {active,comment,label,code}
      askedArea {value,comment}
      separateArea {active,comment}
      outsideStorageProtected {active,comment,label,code}
      outsideStorage {active,comment,label,code}
      averagePerDay {value,comment}
      storagePerDay
      destockingPerDay
      storageTypology {
          code
          label
          description
          active
      }
      temperatureArea {
          ambient
          temper
          postifCold
          negatifCold
      }
      size {
        forty
        eighty
        hundredTwenty
        hundred
        twoHundred
        other
      }
  }
  preparation {
      id
      adminCreation {active,comment,label,code}
      interfacingCreation {active,comment,label,code}
      crossDock {active,comment}
      palet {active,comment,label,code}
      detail {active,comment,label,code}
      custom {active,comment}
      requiresClawChip {active,comment,label,code}
      requiresProductMarking {active,comment,label,code}
      specificDate {active,comment,label,code}
      specificLot {active,comment,label,code}
      reservedStock {active,comment,label,code}
      seasonalityCommand {active,comment,label,code}
      activityPic {active,comment,label,code}
      fabricationOrder {active,comment,label,code}
      cmdPerDay
      cmdPerWeek
      cmdPerMonth
      preapredPerDay
      preapredPerWeek
      preapredPerMonth
      paletPerDay
      paletPerWeek
      paletPerMonth
      preparationTypology {
          code
          label
          description
          active
      }
  }
  packing {
      id
      simplePackaging {active,comment,label,code}
      customPackaging {active,comment,label,code}
      personalizedPackaging {active,comment,label,code}
      isSilageNecessary {active,comment,label,code}
  }
  shipping {
      id
      interfacingCreation {active,comment}
      transportAgent {active,comment,label,code}
      frequencyDay {active,comment}
      frequencyWeek {active,comment}
      frequencyMonth {active,comment}
      orderedPerLivraison {active,comment}
      logisticianChoice {active,comment,label,code}
      supportTracker {active,comment}
      afterManagement {active,comment,label,code}
      activityPic {active,comment}
      transporterNbr {value,comment}
      shippingPerDay {value,comment}
      shippingPerWeek {value,comment}
      shippingPerMonth {value,comment}
      shippingTypology {
          code
          label
          description
          active
      }
  }
  transport {
      id
      rollOff {active,comment}
      tanker {active,comment}
      refrigerated {active,comment}
      flatbed {active,comment}
      semiTrailer {active,comment,label,code}
      tautliner {active,comment}
      porter {active,comment,label,code}
      car {active,comment,label,code}
      vanette {active,comment}
      van {active,comment}
      lightOther {active,comment}
      bicycle {active,comment}
      cargoBicycle {active,comment}
      electricVehicles {active,comment}
      otherClean {active,comment}
      classic {active,comment,label,code}
      biofuels {active,comment,label,code}
      lpg {active,comment,label,code}
      cng {active,comment,label,code}
      hybrid {active,comment,label,code}
      electric {active,comment,label,code}
      oneOff {active,comment}
      occasional {active,comment}
      recurring {active,comment}
      nonPerishable {active,comment}
      perishable {active,comment}
      dangerous {active,comment}
      rawMaterials {active,comment}
      alcohol {active,comment}
      liquid {active,comment}
      runs {active,comment,label,code}
      express {active,comment}
      messaging {active,comment}
      consolidation {active,comment}
      partialLot {active,comment}
      completeLot {active,comment}
      chartering {active,comment}
      airTransport {active,comment,label,code}
      seaTransport {active,comment,label,code}
      railTransport {active,comment,label,code}
      multimodalTransport {active,comment,label,code}
      onSite {active,comment}
      relayPoint {active,comment}
      homeDelivery {active,comment}
      appointmentDelivery {active,comment}
      urgentDelivery {active,comment}
      shipmentTracking {active,comment}
      notificationManagement {active,comment}
      geolocation {active,comment,label,code}
      quotations {active,comment}
      eBilling {active,comment}
      lastMileDelivery {active,comment,label,code}
      averageVolume {value,comment}
      averageDimensions {value,comment}
      departureLocations {active,comment}
      arrivalLocations {active,comment}
  }
  specificity {
      id
      environemental {active,comment}
      alcoholmanagement {active,comment,label,code}
      spec {
        id
        type {
            code
            label
            description
        }
        remark
      }
     appr {
        id
        type {
            code
            label
            description
        }
        remark
      }
     certification {
        id
        type {
            code
            label
            description
        }
        remark
      }
    certificationOther {active,comment}
  }
  interfacing {
      id
      interfacingTools {active,comment}
      interfacingCreation {active,comment}
      interfacingReceive {active,comment}
      stockInterfacingCreation {active,comment}
      cmdCreation {active,comment}
      loadingCreation {active,comment}
      receiveReport {active,comment}
      preparationReport {active,comment}
      expeditionReport {active,comment}
      globalPictures {active,comment}
      detailledPictures {active,comment}
      other {active,comment}
      webservice {active,comment,label,code}
      ftp {active,comment,label,code}
      sld {active,comment,label,code}
      edi {active,comment,label,code}
      extranetPortal {active,comment,label,code}
      techOther {active,comment}
      erp {active,comment}
      ecommercePortal {active,comment}
      externalTools {active,comment}
  }
  workbookFile {
      id
      name
      url
  }
  createdAt
  updatedAt
`;
export const GetSpecificationByID = gql`
query SpecificationById($id: String!) {
  specificationById(id: $id) {
     ${specType}
  }
}`;

export const GetSpecificationWorkbook = gql`
  query GetSpecificationWorkbook($id: String!) {
    getSpecificationWorkbook(id: $id) {
      id
      description {
        name
      }
      workbookFile {
        id
        name
        url
      }
    }
  }
`;

export const DeleteSpecification = gql`
  mutation DeleteSpecification($id: String!) {
    deleteSpecification(id: $id)
  }
`;

export const CreateOrUpdateSpecification = gql`
  mutation CreateOrUpdateSpecification(
    $id: String
    $description: UpdateSpecificationDescriptionInput!
    $productManagement: UpdateSpecificationProductInput
    $receive: UpdateSpecificationReceiveInput
    $storage: UpdateSpecificationStorageInput
    $preparation: UpdateSpecificationPreparationInput
    $packing: UpdateSpecificationPackingInput
    $shipping: UpdateSpecificationShippingInput
    $transport: UpdateSpecificationTransportInput
    $interfacing: UpdateSpecificationInterfacingInput
    $specificity: UpdateSpecificationSpecificityInput
    $workbookFile: FilesInput
    $finished: Boolean
  ) {
    createOrUpdateSpecification(
      input: {
        id: $id
        description: $description
        productManagement: $productManagement
        receive: $receive
        storage: $storage
        preparation: $preparation
        packing: $packing
        shipping: $shipping
        transport: $transport
        interfacing: $interfacing
        specificity: $specificity
        workbookFile: $workbookFile
        finished: $finished
      }
    )
  }
`;

export const SpecificationByUser = gql`
  query Specifications {
    specifications {
      id
      description {
        name
      }
      workbookFile {
        id
        name
        url
      }
      createdAt
      updatedAt
    }
  }
`;

export const GetNumberOfCompatibility = gql`
  query GetNumberOfCompatibility($specId: String!) {
    getNumberOfCompatibility(specId: $specId)
  }
`;
export const SendToCompatible = gql`
  mutation SendToCompatible($specId: String!, $msg: String!) {
    sendToCompatible(specId: $specId, msg: $msg)
  }
`;

export const SpecificationsSettings = gql`
  query SpecificationsSettings {
    specificationsSettings {
      articleTypology {
        code
        label
      }
      receiveTypology {
        code
        label
      }
      shippingTypology {
        code
        label
      }
      storageTypology {
        code
        label
      }
      transportTypology {
        code
        label
      }
      preparationTypology {
        code
        label
      }
    }
  }
`;
