import { useState } from "react";
import magnifyingGlass from "../../assets/svg/magnifying-glass-white.svg";
import { getTitleWithCount } from "../../utils/search";
import ItemSearchMobile from "../ItemSearchMobile";
import MobileActivityArea from "../MobileModal/MobileActivityArea";
import MobileLocalization from "../MobileModal/MobileLocalization";
import MobileServices from "../MobileModal/MobileServices";
import useSearchBarHooks from "../others/searchBar/hooks/useSearchBarhooks";
import GreenButton from "../ui/GreenButton";

const MobileSearchBar = () => {
  const [isLocationModalOpen, setIsLocationModalOpen] = useState(false);
  const [isActivityAreaModalOpen, setIsActivityAreaModalOpen] = useState(false);
  const [isServicesModalOpen, setIsServicesModalOpen] = useState(false);

  const hooks = useSearchBarHooks({ onSearch: () => null, resetSearch: false });

  return (
    <div className="mb-6 flex w-full flex-col lg:hidden">
      <div className="flex w-full flex-col gap-3 rounded-xl bg-mplgreen p-2">
        <ItemSearchMobile
          title="Localisation"
          value={hooks.location?.label || "Saisir la ville ou région"}
          onClick={() => {
            setIsLocationModalOpen(true);
          }}
        />
        <ItemSearchMobile
          title="Secteur"
          value={getTitleWithCount(
            hooks.selectedArea,
            "Quel secteur d’expertise ?"
          )}
          onClick={() => {
            setIsActivityAreaModalOpen(true);
          }}
        />
        <ItemSearchMobile
          title="Prestation"
          value={getTitleWithCount(hooks.selectedService, "Quel type ?")}
          onClick={() => {
            setIsServicesModalOpen(true);
          }}
        />
        <GreenButton
          className="h-12"
          color="bg-black"
          icon={magnifyingGlass}
          onClick={hooks.toMiniSearchBar}
        >
          <span>Rechercher</span>
        </GreenButton>

        {isLocationModalOpen && (
          <MobileLocalization onClose={() => setIsLocationModalOpen(false)} />
        )}
        {isActivityAreaModalOpen && (
          <MobileActivityArea
            onClose={() => setIsActivityAreaModalOpen(false)}
          />
        )}
        {isServicesModalOpen && (
          <MobileServices onClose={() => setIsServicesModalOpen(false)} />
        )}
      </div>
      {hooks.error && (
        <div className="mt-3 text-center text-xs text-red-600">
          Sélectionner au moins un critère pour lancer votre recherche
        </div>
      )}
    </div>
  );
};

export default MobileSearchBar;
