import { useCallback, useMemo, useState } from "react";
import MobileModal from "../";
import { getTitleWithCount } from "../../../utils/search";
import ItemSearchMobile from "../../ItemSearchMobile";
import useSearchBarHooks from "../../others/searchBar/hooks/useSearchBarhooks";
import MobileActivityArea from "../MobileActivityArea";
import MobileLocalization from "../MobileLocalization";
import MobileServices from "../MobileServices";

const ModalResearch = ({ onClose }) => {
  const [isLocationModalOpen, setIsLocationModalOpen] = useState(false);
  const [isActivityAreaModalOpen, setIsActivityAreaModalOpen] = useState(false);
  const [isServicesModalOpen, setIsServicesModalOpen] = useState(false);

  const hooks = useSearchBarHooks({ onSearch: () => null, resetSearch: false });

  const researchFields = useMemo(
    () => [
      {
        title: "Localisation",
        value: hooks.location?.label || "Où ?",
        onClick: () => setIsLocationModalOpen(true),
      },
      {
        title: "Secteur",
        value: getTitleWithCount(
          hooks.selectedAreas,
          "Quel secteur d’expertise ?"
        ),
        onClick: () => setIsActivityAreaModalOpen(true),
      },
      {
        title: "Prestation",
        value: getTitleWithCount(hooks.selectedServices, "Quel type ?"),
        onClick: () => setIsServicesModalOpen(true),
      },
    ],
    [hooks.location?.label, hooks.selectedAreas, hooks.selectedServices]
  );

  return (
    <>
      <MobileModal title="Recherche" onClose={onClose}>
        <div className="flex flex-col gap-3 px-8 py-6">
          {researchFields.map((field) => (
            <ItemSearchMobile key={field.title} {...field} />
          ))}
        </div>
      </MobileModal>
      {isLocationModalOpen && (
        <MobileLocalization onClose={() => setIsLocationModalOpen(false)} />
      )}
      {isActivityAreaModalOpen && (
        <MobileActivityArea onClose={() => setIsActivityAreaModalOpen(false)} />
      )}
      {isServicesModalOpen && (
        <MobileServices onClose={() => setIsServicesModalOpen(false)} />
      )}
    </>
  );
};

export default ModalResearch;
