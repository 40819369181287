export const subscriptionsTypesDetails = {
  pro_logistician: [
    {
      title: "Mise à diposition d’une vitrine client complète",
      isValidate: true,
    },
    {
      title: "Gestion de votre référencement digital",
      isValidate: true,
    },
    {
      title: "Réception des demandes clients",
      isValidate: true,
    },
    {
      title: "Analyse concurrentielle sur les demandes clients reçues",
      isValidate: false,
    },
    {
      title: "Suivi d’activité de votre vitrine client",
      isValidate: false,
    },
    {
      title:
        "Réception automatique des cahiers des charges clients compatibles à vos solutions logistiques",
      isValidate: false,
    },
  ],

  premium_logistician: [
    {
      title: "Mise à diposition d’une vitrine client complète",
      isValidate: true,
    },
    {
      title: "Gestion de votre référencement digital",
      isValidate: true,
    },
    {
      title: "Réception des demandes clients",
      isValidate: true,
    },
    {
      title: "Analyse concurrentielle sur les demandes clients reçues",
      isValidate: true,
    },
    {
      title: "Suivi d’activité de votre vitrine client",
      isValidate: true,
    },
    {
      title:
        "Réception automatique des cahiers des charges clients compatibles à vos solutions logistiques",
      isValidate: true,
    },
  ],

  pro_company: [
    {
      title: "Accès complet aux informations des logisticiens",
      isValidate: true,
    },
    {
      title: "Envoie illimité de demandes de devis",
      isValidate: true,
    },
    {
      title: "Création d’un cahier des charges clé en main",
      isValidate: true,
    },
    {
      title: "Accès à l’outils de comparaison des logisticiens",
      isValidate: false,
    },
    {
      title: "Export Excel des données de comparaison",
      isValidate: false,
    },
    {
      title: "Envoie simulatané d’un cahier des charges",
      isValidate: false,
    },
  ],

  premium_company: [
    {
      title: "Accès complet aux informations des logisticiens",
      isValidate: true,
    },
    {
      title: "Envoie illimité de demandes de devis",
      isValidate: true,
    },
    {
      title: "Création d’un cahier des charges clé en main",
      isValidate: true,
    },
    {
      title: "Accès à l’outils de comparaison des logisticiens",
      isValidate: true,
    },
    {
      title: "Export Excel des données de comparaison",
      isValidate: true,
    },
    {
      title: "Envoie simulatané d’un cahier des charges",
      isValidate: true,
    },
  ],
};
