import { getPrimaryFilters } from "../../../../constants/allFilters";
import { getPluralizedText } from "../../../../utils/string";
import FilterDropdownItem from "./components/FilterDropdownItem";

function Filter({
  nbrResults,
  toggleAdvancedFilters,
  dispatch,
  state,
  settingDatas,
  numberOfAdvancedSet,
  selectedIds,
}) {
  return (
    <div className="mb-2 hidden w-full bg-white px-5 pt-5 lg:block">
      <div className="inline-flex items-center rounded-full border border-black p-4">
        <div className="w-max whitespace-nowrap px-2 font-bold">
          {nbrResults} {getPluralizedText(nbrResults, "Résultat", "Résultats")}
        </div>
        <div className="ml-2 flex flex-wrap gap-2">
          {getPrimaryFilters(state, settingDatas, dispatch).map((filter) => (
            <FilterDropdownItem
              key={filter.id}
              filter={filter}
              selectedIds={selectedIds}
            />
          ))}
        </div>
        <div className="relative ml-[10px]">
          <button
            className={`flex h-[38px] w-max items-center justify-center rounded-3xl border px-4 shadow-md ${
              numberOfAdvancedSet > 0
                ? "border-mplgreen text-mplgreen"
                : "border-black"
            }`}
            style={{
              boxShadow: "0 4px 4px rgba(0, 0, 0, 0.25)",
            }}
            onClick={toggleAdvancedFilters}
          >
            Plus de filtres
          </button>
          {numberOfAdvancedSet > 0 && (
            <div className="absolute -top-2 right-0 flex aspect-auto h-5 w-5 items-center justify-center rounded-full bg-mplgreen p-1 text-xs text-white">
              <span>{numberOfAdvancedSet}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Filter;
