import { useLazyQuery } from "@apollo/client";
import { useContext, useState } from "react";
import { useAlert } from "react-alert";
import { useNavigate, useParams } from "react-router-dom";
import tw from "tailwind-styled-components";
import LoadingBtn from "../../../components/others/dashboard/LoadingBtn";
import StepsWrapper from "../../../components/others/dashboard/StepsWrapper";
import { AuthContext } from "../../../context/AuthContext";
import { ChangeUserSubscription } from "../../../queries/subscription/subscription";

function ChangeSubscription() {
  const params = useParams();
  const navigate = useNavigate();
  const alert = useAlert();
  const { dispatch } = useContext(AuthContext);
  const [changeSubscription] = useLazyQuery(ChangeUserSubscription, {
    fetchPolicy: "no-cache",
  });
  const [step, setStep] = useState(1);
  const [confirmChange, setConfirmChange] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleStepChange = (newstep) => {
    switch (newstep) {
      case 0:
        navigate(-1);
        break;
      case 1:
        setStep(1);
        break;
      case 2:
        if (!confirmChange) {
          navigate(-1);
          return;
        }

        setLoading("confirm");
        changeSubscription({ variables: params })
          .then((r) => {
            dispatch({
              type: "SET_SUBSCRIPTIONS",
              payload: r.data?.changeSubscription,
            });
            setStep(2);
          })
          .catch((err) =>
            alert.error("Une erreur est servenue. Veuillez réessayer.")
          )
          .finally(() => setLoading(false));
        break;
    }
  };

  return (
    <>
      <StepsWrapper
        hideControlsAtEnd={true}
        hideTitleAtEnd={true}
        loading={loading === "confirm"}
        showProgress={false}
        step={step}
        stepMax={2}
        title="Changer votre abonnement"
        onStepChanges={handleStepChange}
      >
        <Container>
          {step === 1 && (
            <Step1>
              <StepTitle>
                Confirmez vous la modification de votre abonnement ?
              </StepTitle>
              <SelectableList>
                <SelectableItem
                  $selected={confirmChange}
                  onClick={() => setConfirmChange(true)}
                >
                  Oui je le confirme
                </SelectableItem>
                <SelectableItem
                  $selected={!confirmChange}
                  onClick={() => setConfirmChange(false)}
                >
                  Non je souhaite annuler
                </SelectableItem>
              </SelectableList>
            </Step1>
          )}
          {step === 2 && (
            <Step2 className="text-center">
              <StepTitle className="text-2xl">
                Votre demande de modification d’abonnement a bien été prise en
                compte. Vos accès vont être restreints.
              </StepTitle>
              <LoadingBtn
                className="mt-7 !bg-[#1FC585]"
                onClick={() => navigate("/subscriptions")}
              >
                Retour à Abonnements et factures
              </LoadingBtn>
            </Step2>
          )}
        </Container>
      </StepsWrapper>
    </>
  );
}

const SelectableList = tw.ul`mt-8 p-0 list-none`;
const SelectableItem = tw.li`
    relative py-7 border-0 border-b border-gray-300 border-solid text-gray-600 text-base pr-10 cursor-pointer
    before:absolute before:content-[''] before:w-4 before:h-4 before:border
    before:border-gray-300 before:border-solid before:rounded-full before:right-0 before:top-[40%]
    ${(p) => (p.$selected ? "before:bg-[#1FC585] text-black" : "")}
`;

const Container = tw.div`max-w-[700px] mx-auto box-border mt-24`;
const Step1 = tw.div``;
const StepTitle = tw.h3`text-2xl text-black font-semibold`;
const Step2 = tw.div``;

export default ChangeSubscription;
