import styled, { keyframes } from "styled-components";

function LoadingBtn({
  children,
  background,
  color,
  loading,
  style,
  onClick,
  className,
  disabled,
  type = "submit",
}) {
  return (
    <>
      <Button
        {...{ background, color, style, onClick, type, className }}
        disabled={disabled || !!loading}
      >
        {loading ? <LoadingIcon /> : children}
      </Button>
    </>
  );
}

const rotate = keyframes`
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
`;

const LoadingIcon = styled.div`
  display: inline-block;
  width: ${(props) => props.width || 20}px;
  height: ${(props) => props.width || 20}px;

  &:after {
    content: " ";
    display: block;
    width: ${((props) => props.width || 20) - 16}px;
    height: ${((props) => props.width || 20) - 16}px;
    margin: 5px;
    border-radius: 50%;
    border: 6px solid ${(props) => props.color || "#fff"};
    border-color: ${(props) => props.color || "#fff"} transparent
      ${(props) => props.color || "#fff"} transparent;
    animation: ${rotate} 1.2s linear infinite;
  }
`;

const Button = styled.button`
  padding: 0px 20px;
  height: 45px;
  min-width: 100px;
  font-size: 14px;
  font-weight: 600;
  color: ${(props) => props.color || "#fff"};
  background: ${(props) => props.background || "#000"};
  border: 0;
  border-radius: 5px;
  cursor: pointer;

  &:disabled {
    background: ${(props) =>
      props.background === "transparent" ? "#d7d7d7" : "#aaa"} !important;
    cursor: unset;
  }
`;

export default LoadingBtn;
