import { useMutation } from "@apollo/client";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../components/ui/CustomButton";
import { DeleteSpecification } from "../../../queries/specification/specification";

const MobileListSpecificationItem = ({ specification, setSpecifications }) => {
  const navigate = useNavigate();
  const [removeSpecification, { loading: deleteLoading }] =
    useMutation(DeleteSpecification);

  const deleteSpecification = (id) => {
    removeSpecification({ variables: { id } }).then(() => {
      setSpecifications((state) => state.filter((item) => item.id !== id));
    });
  };

  const handleClickModify = useCallback(
    (id) => {
      navigate("/dashboard/specification-create/" + id);
    },
    [navigate]
  );

  return (
    <div className="flex items-center justify-between gap-2">
      <div>
        <div>{specification.description.name}</div>
        <div className="text-xs text-gray-500">
          Date de création:{" "}
          {format(new Date(specification.createdAt), "d MMMM yyyy", {
            locale: fr,
          })}
        </div>
        <div className="text-xs text-gray-500">
          Dernière modification:{" "}
          {format(new Date(specification.updatedAt), "d MMMM yyyy", {
            locale: fr,
          })}
        </div>
      </div>
      <div className="flex gap-3">
        <CustomButton
          size="small"
          onClick={() => handleClickModify(specification.id)}
        >
          Modifier
        </CustomButton>
        <CustomButton
          color="red-500"
          isLoading={deleteLoading}
          size="small"
          variant="outlined"
          onClick={() => deleteSpecification(specification.id)}
        >
          Supprimer
        </CustomButton>
      </div>
    </div>
  );
};

export default MobileListSpecificationItem;
