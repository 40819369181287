import { useEffect } from "react";

const QuoteSteps2 = ({ setMessage }) => {
  const handleAreaChange = (e) => {
    setMessage(e.target.value);
  };

  useEffect(() => {
    window.scrollTo(0, 20);
  }, []);

  return (
    <div className="mt-9 w-full">
      <div className="flex h-full">
        <div className="flex w-full flex-col gap-6">
          <div className="text-left font-medium lg:text-2xl">
            Ajouter un message (optionnel)
          </div>
          <textarea
            className="grow rounded border border-black"
            onChange={handleAreaChange}
          />
        </div>
      </div>
    </div>
  );
};

export default QuoteSteps2;
