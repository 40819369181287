import { Switch } from "@headlessui/react";
import { IonIcon } from "@ionic/react";
import { informationCircleOutline } from "ionicons/icons";
import { Controller } from "react-hook-form";
import TippyTooltip from "../../TippyTooltip";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const CustomToggle = ({ name, control, label, description, tip }) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <Switch.Group
          as="div"
          className="flex items-center justify-between gap-4"
        >
          <span className="flex grow flex-col">
            <label
              className="flex grow cursor-pointer flex-col"
              onClick={() => {
                field.onChange(!field.value);
              }}
            >
              <Switch.Label
                passive
                as="span"
                className="text-sm font-medium leading-6 text-gray-900"
              >
                <span>
                  {label}{" "}
                  {tip && (
                    <TippyTooltip content={tip}>
                      <IonIcon icon={informationCircleOutline} />
                    </TippyTooltip>
                  )}
                </span>
              </Switch.Label>
              {description && (
                <Switch.Description as="span" className="text-sm text-gray-500">
                  {description}
                </Switch.Description>
              )}
            </label>
          </span>
          <Switch
            {...field}
            className={classNames(
              field.value ? "bg-mplgreen" : "bg-gray-200",
              "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-mplgreen focus:ring-offset-2"
            )}
          >
            <span
              aria-hidden="true"
              className={classNames(
                field.value ? "translate-x-5" : "translate-x-0",
                "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
              )}
            />
          </Switch>
        </Switch.Group>
      )}
    />
  );
};

export default CustomToggle;
