import { IonIcon } from "@ionic/react";
import { informationCircleOutline } from "ionicons/icons";
import { useCallback, useState } from "react";
import { BsCheckSquare, BsSquare } from "react-icons/bs";
import styled from "styled-components";
import { MultiDropdownContainer } from "../../../components/MultiSelectDropdown/MultiSelectDropdown";
import TippyTooltip from "../../../components/TippyTooltip";

function MenuList(props) {
  const [selectedChildren, setSelectedChildren] = useState(
    props.getValue() ?? []
  );

  const selectedFilter = props.selectProps?.selectedFilter;

  const handleSelect = (value) => {
    const index = selectedChildren.findIndex((s) => s.label === value.label);

    if (index >= 0) {
      setSelectedChildren(
        selectedChildren.filter((s) => s.label !== value.label)
      );
      props.setValue(selectedChildren.filter((s) => s.label !== value.label));
    } else {
      setSelectedChildren([...selectedChildren, value]);
      props.setValue([...selectedChildren, value]);
    }
  };

  const handleRemove = () => {
    setSelectedChildren([]);
    props.setValue([]);
  };

  const isChildSelected = useCallback(
    (value) => {
      return selectedChildren.includes(value);
    },
    [selectedChildren]
  );

  return (
    <MultiDropdownContainer>
      <DropDownListContainer
        style={props.search ? { top: 0, left: "-5rem" } : null}
      >
        <DropdownOptions>
          {props.children?.map((child, index) => {
            const isSelected =
              !!selectedFilter.filter(
                (selected) => selected.label === child.props.data.label
              ).length > 0;

            return (
              <DropdownOption
                key={index}
                onClick={() => handleSelect(child.props.data)}
              >
                <CheckboxInput
                  checked={isSelected}
                  type="checkbox"
                  onChange={() => {}}
                />
                {isSelected && <BsCheckSquare fill="#1FC585" />}
                {!isSelected && !isChildSelected(child.props.data.label) && (
                  <BsSquare fill="#1FC585" />
                )}
                {!isSelected && isChildSelected(child.props.data.label) && (
                  <BsCheckSquare fill="#1FC585" />
                )}
                <label
                  className="ml-2 flex items-center gap-1"
                  htmlFor={"chklist_lab_" + index}
                >
                  {child.props.data.label}
                  {child.props.data.description && (
                    <TippyTooltip content={child.props.data.description}>
                      <IonIcon icon={informationCircleOutline} />
                    </TippyTooltip>
                  )}
                </label>
              </DropdownOption>
            );
          })}
        </DropdownOptions>
        <ButtonContainer>
          <DropdownDeleteButton onClick={handleRemove}>
            Effacer
          </DropdownDeleteButton>
        </ButtonContainer>
      </DropDownListContainer>
    </MultiDropdownContainer>
  );
}

const DropDownListContainer = styled("div")`
  z-index: 1000;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border-radius: 24px;
  border: 1px solid #000000;
  width: max-content;
`;

const DropdownOptions = styled.ul`
  z-index: 100;
  width: 100%;
  margin: 0 20px;
  padding: 0;
  font-size: 18px;
  max-height: 300px;
  overflow-y: auto;
`;

const DropdownOption = styled.li`
  z-index: 100;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style: none;
  padding: 12px;
  border-radius: 10px;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const CheckboxInput = styled.input`
  z-index: 100;
  margin-right: 6px;
  opacity: 0;
  position: absolute;
  border: 1px solid white;
`;
const ButtonContainer = styled.div`
  z-index: 100;
  width: 100%;
  padding: 0 12px;
  display: flex;
  margin: 0;
  border-top: 1px solid #9a9797;
`;

const DropdownDeleteButton = styled.button`
  z-index: 100;
  margin-bottom: 15px;
  margin-top: 15px;
  margin-right: auto;
  border: 0;
  padding: 0;
  color: #838383;
  background: none;
  font-weight: 700;
  font-size: 17px;
  cursor: pointer;
`;
export default MenuList;
