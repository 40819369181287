import { useLazyQuery, useSubscription } from "@apollo/client";
import { createContext, useContext, useEffect, useState } from "react";
import {
  NotificationSubscription,
  RetrieveUserNotification,
} from "../queries/notifications/notifications";
import { SubscriptionUser } from "../queries/user/user";
import { AuthContext } from "./AuthContext";

export const NotificationContext = createContext();

export function NotificationProvider(props) {
  const { user, dispatch } = useContext(AuthContext);
  const [retrieveUserNotification] = useLazyQuery(RetrieveUserNotification, {
    fetchPolicy: "no-cache",
  });
  const [notifications, setNotifications] = useState([]);
  const { data, loading } = useSubscription(NotificationSubscription, {
    skip: !user,
  });
  useEffect(() => {
    if (user) {
      retrieveUserNotification().then((r) =>
        setNotifications(r.data?.retrieveUserNotification ?? [])
      );
    }
  }, []);
  useEffect(() => {
    if (notifications?.length > 0)
      document.title = `${document.title} (${notifications?.length})`;
    else document.title = document.title.split("(")[0];
  }, [notifications]);

  useEffect(() => {
    if (data?.notification && !loading && user) {
      setNotifications((p) => [...p, data.notification]);
    }
    if (!user) {
      setNotifications([]);
    }
  }, [data, user]);

  const { data: userData, loading: userLoading } = useSubscription(
    SubscriptionUser,
    { skip: !user }
  );
  useEffect(() => {
    if (userData?.userUpdated && !userLoading && user) {
      dispatch({
        type: "UPDATE_USER",
        payload: { user: userData.userUpdated },
      });
    }
  }, [userData, user]);

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        setNotifications,
      }}
    >
      {props.children}
    </NotificationContext.Provider>
  );
}
