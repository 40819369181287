import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  cmdnbrOptions,
  contractMinOptions,
  maxcapacityOptions,
  paletnbrOptions,
  pcknbrOptions,
  preparednbrOptions,
  receivenbrOptions,
  refnbrOptions,
  shippingnbrOptions,
} from "../../../../constants/listingsOptions";
import { SearchContext } from "../../../../context/SearchContext";

function useMoreFilterHook() {
  const [minContractTime, setMinContractTime] = useState([]);
  const minContractRef = {
    items: contractMinOptions,
  };

  const [tech, setTech] = useState([]);
  const [ecommerce, setEcommerce] = useState([]);
  const [certification, setCertification] = useState([]);
  const [specificity, setSpecificity] = useState([]);
  const [workingArea, setWorkingArea] = useState([]);
  const { settingDatas } = useContext(SearchContext);
  const [refProducts, setRefProducts] = useState([]);
  const refProductsRef = {
    items: refnbrOptions,
  };

  const [receiveNumberDaily, setReceiveNumberDaily] = useState([]);
  const receiveNumberDailyRef = {
    items: receivenbrOptions,
  };

  const [receivePackageDaily, setReceivePackageDaily] = useState([]);
  const receivePackageDailyRef = {
    items: pcknbrOptions,
  };

  const [dailyPalets, setDailyPalets] = useState([]);
  const dailyPaletsRef = {
    items: paletnbrOptions,
  };
  const [nbrCmdDay, setNbrCmdDay] = useState([]);
  const nbrCmdDayRef = {
    items: cmdnbrOptions,
  };
  const [nbrPreparedDay, setNbrPreparedDay] = useState([]);
  const nbrPreparedDayRef = {
    items: preparednbrOptions,
  };
  const [nbrExpeditionDay, setNbrExpeditionDay] = useState([]);
  const nbrExpeditionDayRef = {
    items: shippingnbrOptions,
  };
  const [capacityPalet, setcapacityPalet] = useState([]);
  const capacityPaletRef = {
    items: maxcapacityOptions,
  };
  const [areaFree, setAreaFree] = useState(false);

  const [nbrOf2ndFiter, setNbrFilter] = useState(0);

  const [search] = useSearchParams();
  useEffect(() => {
    let queryFilter = search.get("mfHooks");
    queryFilter = JSON.parse(queryFilter);
    setMinContractTime(queryFilter?.minContractTime ?? minContractTime);
    setTech(queryFilter?.tech ?? tech);
    setEcommerce(queryFilter?.ecommerce ?? ecommerce);
    setCertification(queryFilter?.certification ?? certification);
    setSpecificity(queryFilter?.specificity ?? specificity);
    setWorkingArea(queryFilter?.workingArea ?? workingArea);
    setRefProducts(queryFilter?.refProducts ?? refProducts);
    setReceiveNumberDaily(
      queryFilter?.receiveNumberDaily ?? receiveNumberDaily
    );
    setReceivePackageDaily(
      queryFilter?.receivePackageDaily ?? receivePackageDaily
    );
    setDailyPalets(queryFilter?.dailyPalets ?? dailyPalets);
    setNbrCmdDay(queryFilter?.nbrCmdDay ?? nbrCmdDay);
    setNbrPreparedDay(queryFilter?.nbrPreparedDay ?? nbrPreparedDay);
    setNbrExpeditionDay(queryFilter?.nbrExpeditionDay ?? nbrExpeditionDay);
    setcapacityPalet(queryFilter?.capacityPalet ?? capacityPalet);
    setAreaFree(queryFilter?.areaFree ?? areaFree);
    setNbrFilter(queryFilter?.nbrOf2ndFiter ?? nbrOf2ndFiter);
  }, [search]);

  return {
    minContractTime,
    setMinContractTime,
    workingArea,
    setWorkingArea,
    refProducts,
    setRefProducts,
    receiveNumberDaily,
    setReceiveNumberDaily,
    receivePackageDaily,
    setReceivePackageDaily,
    dailyPalets,
    setDailyPalets,
    minContractRef,
    settingDatas,
    refProductsRef,
    receiveNumberDailyRef,
    receivePackageDailyRef,
    dailyPaletsRef,
    nbrOf2ndFiter,
    setNbrFilter,
    nbrCmdDay,
    setNbrCmdDay,
    nbrCmdDayRef,
    nbrPreparedDay,
    setNbrPreparedDay,
    nbrPreparedDayRef,
    nbrExpeditionDay,
    setNbrExpeditionDay,
    nbrExpeditionDayRef,
    capacityPalet,
    setcapacityPalet,
    capacityPaletRef,
    tech,
    setTech,
    ecommerce,
    setEcommerce,
    certification,
    setCertification,
    specificity,
    setSpecificity,
    areaFree,
    setAreaFree,
  };
}

export default useMoreFilterHook;
