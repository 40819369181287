export const formatResponseTime = (minutes) => {
  if (!minutes) return null;

  if (minutes <= 60) {
    return "1H";
  } else if (minutes <= 120) {
    return "2H";
  } else if (minutes <= 180) {
    return "3H";
  } else if (minutes <= 240) {
    return "4H";
  } else if (minutes <= 300) {
    return "5H";
  } else if (minutes <= 360) {
    return "6H";
  } else if (minutes <= 1440) {
    return "24H";
  } else if (minutes <= 2880) {
    return "48H";
  } else if (minutes <= 4320) {
    return "72H";
  } else if (minutes <= 10080) {
    return "Moins d’une semaine";
  } else {
    return "Plus d’une semaine";
  }
};
