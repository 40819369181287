import { useLazyQuery } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useContext, useMemo } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import * as yup from "yup";
import { SearchContext } from "../../../../../context/SearchContext";
import { CHECK_EMAIL } from "../../../../../queries/register/register";
import CustomButton from "../../../../ui/CustomButton";
import CustomCheckbox from "../../../../ui/CustomCheckbox";
import CustomInput from "../../../../ui/CustomInput";
import CustomSelect from "../../../../ui/CustomSelect";
import ModalTw from "../../../index";

const validationSchema = yup.object().shape({
  name: yup.string().required("Nom prénom est obligatoire"),
  company: yup.string().required("Entreprise est obligatoire"),
  activityArea: yup
    .mixed()
    .test(
      "activityArea",
      "Secteur d'activité est obligatoire",
      (value) =>
        typeof value === "object" &&
        value !== null &&
        value.label &&
        value.value
    )
    .required("Secteur d'activité est obligatoire"),
  siret: yup
    .string()
    .test("siret-validation", "Siret non valide.", function (value) {
      if (value && value.length === 14) {
        return true;
      } else return !value;
    }),
  email: yup.string().email("Email non valide.").required("Email requis."),
  phone: yup
    .string()
    .matches(
      /^(\+33|0033|0)([123456789])[0-9]{8}$/g,
      "Numéro de téléphone non valide."
    )
    .required("Numéro de téléphone requis."),
  password: yup
    .string()
    .min(6, "Le mot de passe doit faire plus de 6 caractères.")
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,}$/,
      "Le mot de passe doit contenir au moins une minuscule, une majuscule et un chiffre."
    )
    .required("Mot de passe requis."),
  cgu: yup.boolean().oneOf([true], "Vous devez accepter les CGU"),
});

const RegisterStep1 = ({ setFormState, setStep, onClose, toggleLoginForm }) => {
  const { settingDatas } = useContext(SearchContext);
  const [checkUserExists, { loading }] = useLazyQuery(CHECK_EMAIL);

  const {
    register,
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      company: "",
      activityArea: "",
      siret: "",
      email: "",
      phone: "",
      password: "",
      cgu: false,
    },
    resolver: yupResolver(validationSchema),
  });

  const selectItems = useMemo(() => {
    if (!settingDatas?.workingDomains) return [];

    return settingDatas?.workingDomains.map((c) => ({
      label: c.label,
      value: c.code,
    }));
  }, [settingDatas?.workingDomains]);

  const onSubmit = useCallback(
    async (values) => {
      const { data } = await checkUserExists({
        variables: { email: values.email },
      });

      if (data.checkUserExists) {
        setError("email", { message: "Cet email existe déjà." });
      } else {
        delete values.cgu;
        setFormState(values);
        setStep((prev) => prev + 1);
      }
    },
    [checkUserExists, setError, setFormState, setStep]
  );

  const handleClickOpenLoginForm = useCallback(() => {
    toggleLoginForm();
    onClose();
  }, [onClose, toggleLoginForm]);

  const content = useMemo(() => {
    return (
      <form className="max-h-[280px] overflow-y-auto sm:max-h-screen">
        <div className="flex flex-col gap-2">
          <CustomInput
            error={errors?.name}
            name="name"
            placeholder="Nom prénom"
            register={register}
          />
          <CustomInput
            error={errors?.company}
            name="company"
            placeholder="Entreprise"
            register={register}
          />
        </div>
        <CustomSelect
          control={control}
          customHeight="h-44"
          error={errors?.activityArea}
          items={selectItems}
          name="activityArea"
          placeholder="Secteur d'activité"
        />
        <div className="flex flex-col gap-2">
          <CustomInput
            error={errors?.siret}
            name="siret"
            placeholder="Numéro de SIRET (optionnel)"
            register={register}
          />
          <CustomInput
            error={errors?.email}
            name="email"
            placeholder="Adresse mail"
            register={register}
          />
          <CustomInput
            onlyNumeric
            error={errors?.phone}
            name="phone"
            placeholder="Numéro de téléphone"
            register={register}
          />
          <CustomInput
            error={errors?.password}
            name="password"
            placeholder="Créer un mot de passe"
            register={register}
            type="password"
          />

          <CustomCheckbox error={errors?.cgu} name="cgu" register={register}>
            <div className="text-left text-xs leading-6">
              <label className="text-left text-gray-900" htmlFor="cgu">
                En m'inscrivant, j'accepte les{" "}
                <Link
                  className="italic text-blue-500 underline underline-offset-2"
                  target="_blank"
                  to="/conditions_generales"
                >
                  CGU
                </Link>{" "}
                de monprestatairelogistique.com
              </label>
            </div>
          </CustomCheckbox>
        </div>
      </form>
    );
  }, [
    control,
    errors?.activityArea,
    errors?.company,
    errors?.email,
    errors?.name,
    errors?.password,
    errors?.phone,
    errors?.cgu,
    errors?.siret,
    register,
    selectItems,
  ]);

  return (
    <ModalTw
      content={content}
      footer={
        <div className="flex flex-col gap-4">
          <CustomButton
            isFull
            isLoading={loading}
            onClick={handleSubmit(onSubmit)}
          >
            Je m'inscris
          </CustomButton>
          <div className="flex grow items-center gap-2">
            <CustomButton
              isFull
              variant="outlined"
              onClick={handleClickOpenLoginForm}
            >
              Se connecter
            </CustomButton>
          </div>
        </div>
      }
      title="Bienvenue sur monprestatairelogistique.com"
      onClose={onClose}
    />
  );
};

export default RegisterStep1;
