function Alert({ type, message, alertTitle }) {
  let textColor;
  let bgColor;

  switch (type) {
    case "info":
      textColor = "text-blue-800";
      bgColor = "bg-blue-50";
      break;
    case "danger":
      textColor = "text-red-800";
      bgColor = "bg-red-50";
      break;
    case "success":
      textColor = "text-green-800";
      bgColor = "bg-green-50";
      break;
    case "warning":
      textColor = "text-yellow-800";
      bgColor = "bg-yellow-50";
      break;
    case "dark":
      textColor = "text-gray-800";
      bgColor = "bg-gray-50";
      break;
    default:
      throw new Error("Invalid alert type");
  }

  return (
    <div
      className={`mb-4 p-4 text-sm ${textColor} rounded-lg ${bgColor}`}
      role="alert"
    >
      <span className="font-medium">{alertTitle}</span> {message}
    </div>
  );
}

export default Alert;
