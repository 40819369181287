import LogRocket from "logrocket";
import PropTypes from "prop-types";
import { createContext, useEffect, useMemo, useReducer } from "react";
import uuid from "react-uuid";
import { setAmplitudeUserId } from "../constants/amplitude";

function getSessionid() {
  if (
    localStorage.getItem("sessionId") !== "" &&
    localStorage.getItem("sessionId") !== null &&
    localStorage.getItem("sessionId") !== undefined
  ) {
    return localStorage.getItem("sessionId");
  }
  let sessionId = uuid();
  localStorage.setItem("sessionId", sessionId);
  return sessionId;
}

const INITIAL_STATE = {
  sessionId: getSessionid(),
  user: JSON.parse(localStorage.getItem("user") || null),
  token: JSON.parse(localStorage.getItem("token") || null),
  refreshToken: JSON.parse(localStorage.getItem("refreshToken") || null),
  loading: false,
  error: null,
};

export const AuthContext = createContext(INITIAL_STATE);

const AuthReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN_START":
      return {
        sessionId: state.sessionId,
        user: state.user,
        token: state.token,
        refreshToken: state.refreshToken,
        loading: true,
        error: null,
      };
    case "LOGIN_SUCCESS":
      setAmplitudeUserId(action.payload.user.id);
      return {
        sessionId: state.sessionId,
        user: action.payload.user,
        token: action.payload.token,
        refreshToken: action.payload.refreshToken,
        loading: false,
        error: null,
      };
    case "REFRESH":
      return {
        sessionId: state.sessionId,
        user: action.payload.user,
        token: action.payload.token,
        refreshToken: action.payload.refreshToken,
        loading: false,
        error: null,
      };
    case "UPDATE_USER":
      return {
        sessionId: state.sessionId,
        user: action.payload.user,
        token: state.token,
        refreshToken: state.refreshToken,
        loading: false,
        error: null,
      };
    case "LOGIN_FAILURE":
      return {
        sessionId: state.sessionId,
        user: state.user,
        token: state.token,
        refreshToken: state.refreshToken,
        loading: false,
        error: action.payload,
      };
    case "LOGOUT":
      localStorage.clear();
      localStorage.setItem("sessionId", state.sessionId);
      return {
        sessionId: state.sessionId,
        user: null,
        token: null,
        refreshToken: null,
        loading: false,
        error: null,
      };
    case "SET_HASLISTING":
      return {
        ...state,
        user: { ...state.user, listingNumber: action.payload.listingNumber },
      };
    case "SET_SUBSCRIPTIONS":
      localStorage.setItem(
        "user",
        JSON.stringify({
          ...state.user,
          id: state.user.id,
          subscriptions: action.payload,
        })
      );
      return {
        ...state,
        user: { ...state.user, subscriptions: action.payload },
      };
    default:
      return state;
  }
};

export function AuthContextProvider({ children }) {
  const [state, dispatch] = useReducer(AuthReducer, INITIAL_STATE);

  const value = useMemo(
    () => ({
      sessionId: state.sessionId,
      user: state.user,
      token: state.token,
      refreshToken: state.refreshToken,
      loading: state.loading,
      error: state.error,
      dispatch,
    }),
    [state.user, state.loading, state.error, dispatch]
  );

  useEffect(() => {
    if (state.user !== null && state.user !== undefined) {
      localStorage.setItem("user", JSON.stringify(state.user));
      localStorage.setItem("token", JSON.stringify(state.token));
      if (state.refreshToken) {
        localStorage.setItem(
          "refreshToken",
          JSON.stringify(state.refreshToken)
        );
      }
      LogRocket.identify(state.user.id, state.user);
    } else localStorage.removeItem("user");
  }, [state.user, state.token, state.refreshToken]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

AuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
