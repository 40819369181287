import { useMutation } from "@apollo/client";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { BsCheckLg } from "react-icons/bs";
import { Link } from "react-router-dom";
import styled from "styled-components";
import logo from "../../../assets/images/Monprestatairelogistque.png";
import { ROLES } from "../../../constants/Roles";
import { AuthContext } from "../../../context/AuthContext";
import { SubscribeToNewsletter } from "../../../queries/newsletter/newsletter";
const FooterComponent = () => {
  const { user } = useContext(AuthContext);
  const [subscribeToNewsletter] = useMutation(SubscribeToNewsletter);
  const {
    getValues,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const [isSubmitted, setSubmitted] = useState(false);
  const onNewsletter = () => {
    const value = getValues();
    subscribeToNewsletter({
      variables: { email: value.email.toLowerCase() },
    }).then(() => setSubmitted(true));
  };
  return (
    <div className="mt-auto bg-black p-4 text-white xl:px-11 xl:py-5">
      <div className="xl:flex xl:flex-row xl:items-center xl:justify-around">
        <img
          alt="Logo StockAway"
          className="hidden xl:block xl:w-[200px]"
          src={logo}
        />
        <div>
          <div className="flex flex-col items-center gap-4 underline underline-offset-4 xl:flex-row xl:gap-6">
            <Link to="/conditions_generales">Conditions générales</Link>
            <Link to="/politique_confidentialite">
              Politique de confidentialité
            </Link>
            {user?.role !== ROLES.LOGISTICIAN && (
              <Link
                className="hidden"
                to="https://blog.monprestatairelogistique.com//"
              >
                Blog
              </Link>
            )}
            <Link className="hidden xl:block" to="/apropos">
              À propos
            </Link>
            <Link to="/contact">Contact</Link>
          </div>
        </div>
        <form
          className="hidden xl:flex xl:flex-col xl:items-center"
          onSubmit={handleSubmit(onNewsletter)}
        >
          <NewsletterText>Abonnez-vous à notre newsletter</NewsletterText>
          {!isSubmitted && (
            <>
              <NewsletterMail
                type="email"
                {...register("email", {
                  required: "Email requis.",
                  pattern: {
                    value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                    message: "Email non valide.",
                  },
                })}
                id="newsletter_mail"
                placeholder="contact@monprestatairelogistique.com"
              />
              {errors.email && <Error>{errors.email.message}</Error>}
              <SendButton type="submit">Envoyer</SendButton>
            </>
          )}
          {isSubmitted && (
            <div
              style={{
                display: "flex",
                color: "white",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <BsCheckLg fill="#1FC585" style={{ marginRight: "1%" }} />
              Demande envoyée
            </div>
          )}
        </form>
      </div>
      <p className="mt-3 text-center font-roboto-regular text-xs xl:text-base xl:font-semibold">
        2023. Monprestatairelogistique.com - Tous droits réservés
      </p>
    </div>
  );
};

const Error = styled.div`
  font-family: "Roboto Regular", sans-serif;
  font-size: 13px;
  text-align: center;
  padding-top: 5px;
  color: red;
`;

const NewsletterText = styled.p`
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  margin: 20px 0 10px 0;
  text-decoration: none;
`;

const SendButton = styled.button`
  background-color: #1fc585;
  color: #fff;
  width: 174px;
  height: 30px;
  font-size: 15px;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  margin-top: 10px;
  cursor: pointer;
`;

const NewsletterMail = styled.input`
  width: 253px;
  height: 34px;
  border-radius: 70px;
  padding-left: 15px;
  font-size: 13px;
  font-weight: 300;
  background-color: #fff;
  color: grey;
  border: none;
  outline: none;
`;
export default FooterComponent;
