import { useState } from "react";
import tw from "tailwind-styled-components";
import { config } from "../../../../config/env.config";

function ProfilePicUploader({
  defaultFile,
  max = 10,
  name = "file-upload-selector",
  accept,
  onValuesChange,
  classes = null,
}) {
  const [loading, setLoading] = useState(false);

  const doUploadFiles = (_file) => {
    if (_file === undefined || _file === null) return;

    const fd = new FormData();
    [].forEach.call(_file, (file) => fd.append("file", file));
    fd.append("file", _file);
    setLoading(true);

    fetch(`${config.get("BACKEND_URL")}/files`, {
      method: "POST",
      body: fd,
    })
      .then((r) => r.json())
      .then((r) => {
        const newList = r[0];
        onValuesChange && onValuesChange(newList);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Container className={classes?.container}>
        {!!loading && (
          <Loading className={classes?.loading}>
            <Loader className={classes?.Loader}></Loader>
          </Loading>
        )}
        {!loading && (
          <FileSelector value className={classes?.selector} htmlFor={name}>
            Modifier
          </FileSelector>
        )}
      </Container>
      {/*  */}
      <FileInput
        accept={accept}
        id={name}
        max={max}
        maxLength={max}
        type="file"
        onChange={(evt) => doUploadFiles(evt.target.files)}
      />
    </>
  );
}

const Loading = tw.div`flex items-center justify-center rounded-full w-9 h-9 border-solid border`;
const Loader = tw.div`loader !w-6 !h-6`;
const Container = tw.div`flex items-center`;
const FileSelector = tw.label`
     underline cursor-pointer
`;
const FileInput = tw.input`hidden`;

export default ProfilePicUploader;
