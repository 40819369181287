import { createContext, useContext, useEffect, useMemo, useRef } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import ModalSearchBar from "../others/searchBar/ContentSearchBarModal/ModalSearchBar";
import FooterComponent from "./components/FooterComponent";
import HeaderComponent from "./components/HeaderComponent";
import useLayoutHook from "./hooks/layoutHooks";

const WrapperRefContext = createContext(null);

function LayoutWrapper({
  children,
  displayBigSearchBar,
  setDisplayBigSearchBar,
  doSaveListingRef, //reference to the function in CreateListing component
  doSaveSpecificationRef,
}) {
  const wrapperRef = useRef(null);
  const { pathname } = useLocation();

  useEffect(() => {
    if (wrapperRef.current) wrapperRef.current.scrollTo(0, 0);
  }, [pathname]);

  const {
    handleOutsideClick,
    handleDisplayBigSearchBar,
    setIsShowDropDown,
    isShowDropDown,
    isSearchBarVisible,
    setIsSearchBarVisible,
    isCreateSpec,
    isCreateListing,
  } = useLayoutHook({ setDisplayBigSearchBar, displayBigSearchBar });
  const route = useLocation().pathname;

  const shouldDisplayFooter = useMemo(() => {
    if (isCreateSpec || isCreateListing) return false;

    const subpathsToCheck = [
      "/secondarySearch",
      "/dashboard/specification-",
      "/dashboard/quote",
    ];

    const isMatchingSubpath = subpathsToCheck.some((subpath) =>
      route.includes(subpath)
    );

    return !isMatchingSubpath;
  }, [isCreateListing, isCreateSpec, route]);

  if (route === "/dashboard/specification-intro") {
    return children;
  }

  return (
    <Wrapper onClick={handleOutsideClick}>
      <WrapperRefContext.Provider value={wrapperRef.current}>
        <HeaderComponent
          displayBigSearchBar={displayBigSearchBar}
          doSaveListingRef={doSaveListingRef}
          doSaveSpecificationRef={doSaveSpecificationRef}
          handleDisplayBigSearchBar={handleDisplayBigSearchBar}
          isSearchBarVisible={isSearchBarVisible}
          isShowDropDown={isShowDropDown}
          setIsSearchBarVisible={setIsSearchBarVisible}
          setIsShowDropDown={setIsShowDropDown}
        />
        <div
          ref={wrapperRef}
          className="flex w-full grow flex-col overflow-y-auto overflow-x-hidden"
        >
          {displayBigSearchBar && (
            <ModalSearchBar setDisplaySearchBar={handleDisplayBigSearchBar} />
          )}
          {children}
          {shouldDisplayFooter && <FooterComponent />}
        </div>
      </WrapperRefContext.Provider>
    </Wrapper>
  );
}

export const useWrapperRef = () => {
  const context = useContext(WrapperRefContext);
  if (context === undefined) {
    throw new Error(
      "useWrapperRef must be used within a LayoutWrapper component"
    );
  }
  return context;
};

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export default LayoutWrapper;
