import CustomButton from "../../../components/ui/CustomButton";

const MobileCongratsSpecification = ({ nextStep, previousStep }) => {
  return (
    <div className="flex h-full flex-1 flex-col overflow-auto p-5 pb-0 md:p-8 md:pb-0">
      <h1 className="mb-4 text-xl font-medium">
        Félicitations, votre cahier des charges a été créé !
      </h1>
      <div className="sticky bottom-0 -mx-5 mt-auto flex justify-center gap-4 border-t border-mplgray bg-white px-3 py-4 md:-mx-8">
        <CustomButton
          isFull
          color="black"
          variant="outlined"
          onClick={previousStep}
        >
          Retour a l'acceuil
        </CustomButton>
        <CustomButton isFull onClick={nextStep}>
          Voir
        </CustomButton>
      </div>
    </div>
  );
};

export default MobileCongratsSpecification;
