import { gql } from "@apollo/client";

export const UpdatePasswordWithToken = gql`
  mutation UpdatePasswordWithToken(
    $updatePasswordInput: UpdatePasswordWithTokenInput!
    $token: String!
  ) {
    updatePasswordWithToken(
      updatePasswordInput: $updatePasswordInput
      token: $token
    )
  }
`;
