import { useContext, useEffect, useMemo, useState } from "react";
import { searchClient } from "../../../../app/App";
import { config } from "../../../../config/env.config";
import { SearchContext } from "../../../../context/SearchContext";
import useModal from "../../../../hooks/useModal";
import useFilterHook from "./filterHooks";
import useMoreFilterHook from "./moreFilterHooks";

const useSecondarySearchHook = (state) => {
  const { isShowing: isAdvancedFiltersShowed, toggle: toggleAdvancedFilters } =
    useModal();

  useEffect(() => {
    if (isAdvancedFiltersShowed) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isAdvancedFiltersShowed]);

  const { selectedService, selectedOption, selectedArea } =
    useContext(SearchContext);
  const [hits, setHits] = useState([]);
  const [titleHits, setTitleHits] = useState(null);
  const [secondarySearchLoading, setSecondarySearchLoading] = useState(true);

  const filterhooks = useFilterHook();
  const mfHooks = useMoreFilterHook();
  const [facetFilters, setfacetFilters] = useState([]);
  const [numericFilters, setnumericFilters] = useState([]);
  const [insidePolygon, setinsidePolygon] = useState(undefined);

  const numberOfAdvancedSet = useMemo(() => {
    let count = facetFilters.reduce((total, item) => {
      if (!item) return total;

      return (
        total +
        ([
          "spec.code",
          "certifications.code",
          "approvals.code",
          "tech.code",
          "ecommerce.code",
          "workingArea.label",
        ].some((prefix) => {
          return item.startsWith(prefix);
        })
          ? 1
          : 0)
      );
    }, 0);

    count += numericFilters.flat().length;

    return count;
  }, [facetFilters, numericFilters]);

  useEffect(() => {
    let _facetFilters = [];
    const stringTofilter = (obj, value) => {
      if (
        obj === "storage_gigs.label" &&
        value?.[0] === "disponible immédiatement"
      )
        return null;

      return value?.map((v) => `${obj}:${v}`);
    };

    _facetFilters = _facetFilters.concat(
      stringTofilter(
        "workingLogistic",
        Object.keys(
          selectedService.reduce(
            (acc, service) => ({ ...acc, [service.id]: true }),
            {}
          ) ?? {}
        )
      )
    );

    _facetFilters = _facetFilters.concat(
      stringTofilter(
        "workingDomain.label",
        selectedArea.map((x) => x.title) ?? []
      )
    );
    _facetFilters = _facetFilters.concat(
      stringTofilter(
        "receive_gigs.label",
        state.receiveGig.map((x) => x.label)
      )
    );
    _facetFilters = _facetFilters.concat(
      stringTofilter(
        "storage_gigs.label",
        state.storageGig.map((x) => x.label)
      )
    );
    _facetFilters = _facetFilters.concat(
      stringTofilter(
        "prepare_gigs.label",
        state.preparegig.map((x) => x.label)
      )
    );
    _facetFilters = _facetFilters.concat(
      stringTofilter(
        "transportation_gigs.label",
        state.transportGig.map((x) => x.label)
      )
    );
    _facetFilters = _facetFilters.concat(
      stringTofilter(
        "packaging_gigs.label",
        state.packagingGig.map((x) => x.label)
      )
    );
    _facetFilters = _facetFilters.concat(
      stringTofilter(
        "interfacing_gigs.label",
        state.interfacingGig.map((x) => x.label)
      )
    );
    _facetFilters = _facetFilters.concat(
      stringTofilter(
        "products_base_gigs.label",
        state.productBaseGig.map((x) => x.label)
      )
    );
    _facetFilters = _facetFilters.concat(
      stringTofilter(
        "shipping_gigs.label",
        state.shippingGig.map((x) => x.label)
      )
    );
    _facetFilters = _facetFilters.concat(
      stringTofilter(
        "workingArea.label",
        state.advancedFilters.workingArea.map((x) => x.label)
      )
    );
    _facetFilters = _facetFilters.concat(
      stringTofilter(
        "spec.code",
        state.advancedFilters.specificity.map((s) => s.code)
      )
    );

    _facetFilters = _facetFilters.concat(
      stringTofilter(
        "certifications.code",
        state.advancedFilters.certification.map((c) => c.code)
      )
    );

    _facetFilters = _facetFilters.concat(
      stringTofilter(
        "approvals.code",
        state.advancedFilters.approval.map((c) => c.code)
      )
    );

    _facetFilters = _facetFilters.concat(
      stringTofilter(
        "tech.code",
        (state.advancedFilters.tech || []).map((t) => t.code)
      )
    );

    _facetFilters = _facetFilters.concat(
      stringTofilter(
        "ecommerce.code",
        (state.advancedFilters.ecommerce || []).map((e) => e.code)
      )
    );

    if (
      state.storageGig.find((x) => x.label === "disponible immédiatement")
        ?.code === true
    )
      _facetFilters = _facetFilters.concat(`areaFree:true`);

    setfacetFilters(_facetFilters.filter(Boolean));
    setinsidePolygon(
      selectedOption?.bounds?.length > 0 ? selectedOption.bounds : undefined
    );
  }, [selectedArea, selectedOption, selectedService, state]);

  // create numeric filter for algolia
  useEffect(() => {
    const stateToNumeric = (obj, value) => {
      const resp = value?.map((x) => {
        const toreturn = [];
        if (x?.start && x?.end) {
          return [`${obj}:${x.start} TO ${x.end}`];
        }
        if (x?.start) {
          toreturn.push(`${obj}>=${x.start}`);
        }
        if (x?.end) {
          toreturn.push(`${obj}<=${x.end}`);
        }
        return toreturn;
      });
      return resp && resp.length > 0 ? [resp.flat()] : resp;
    };
    let _numericFilters = [];
    _numericFilters = _numericFilters.concat(
      stateToNumeric(
        "min_contract_delay",
        state.advancedFilters.minContractTime
      )
    );
    _numericFilters = _numericFilters.concat(
      stateToNumeric("nbr_ref_products", state.advancedFilters.refProducts)
    );
    _numericFilters = _numericFilters.concat(
      stateToNumeric("nbr_recv_day", state.advancedFilters.receiveNumberDaily)
    );
    _numericFilters = _numericFilters.concat(
      stateToNumeric("nbr_packs_day", state.advancedFilters.receivePackageDaily)
    );
    _numericFilters = _numericFilters.concat(
      stateToNumeric("nbr_palet_day", state.advancedFilters.dailyPalets)
    );
    _numericFilters = _numericFilters.concat(
      stateToNumeric("nbr_prepared_day", state.advancedFilters.nbrPreparedDay)
    );
    _numericFilters = _numericFilters.concat(
      stateToNumeric(
        "nbr_expedition_day",
        state.advancedFilters.nbrExpeditionDay
      )
    );
    _numericFilters = _numericFilters.concat(
      stateToNumeric("capacity_palet", state.advancedFilters.capacityPalet)
    );
    _numericFilters = _numericFilters.concat(
      stateToNumeric("nbr_cmd_day", state.advancedFilters.nbrCmdDay)
    );

    setnumericFilters(_numericFilters);
  }, [state.advancedFilters]);

  // Call Algolia when one of filters update
  useEffect(() => {
    let timeoutId;

    const onSearchParamsUpdate = async () => {
      try {
        setSecondarySearchLoading(true);
        const t = searchClient.initIndex(config.get("ALGOLIA_INDEX"));
        let r;

        // Define the common search parameters
        const commonParams = {
          facetFilters: facetFilters.concat("published:true"),
          numericFilters,
          facetingAfterDistinct: true,
          enableReRanking: true,
        };

        if (insidePolygon && insidePolygon.length === 4) {
          const [northeastLat, northeastLng, southwestLat, southwestLng] =
            insidePolygon;
          const centerLat = (northeastLat + southwestLat) / 2;
          const centerLng = (northeastLng + southwestLng) / 2;

          // First, try searching the surrounding area
          r = await t.search("", {
            ...commonParams,
            aroundLatLng: `${centerLat},${centerLng}`,
            aroundRadius: 75000, // 75 km
          });

          if (r.hits.length > 0) {
            setTitleHits(null);
            setHits(r.hits);
            return;
          }

          // if still no results, no "aroundRadius" to still allow algolia to search by distance
          r = await t.search("", {
            ...commonParams,
            aroundLatLng: `${centerLat},${centerLng}`,
          });

          if (r.hits.length > 0) {
            setTitleHits({
              city: selectedOption.label,
              message: "Voici les resultats les plus proches",
            });
            setHits(r.hits);
            return;
          }

          // if still no results, no "aroundRadius" to still allow algolia to search by distance
          r = await t.search("", {
            ...commonParams,
            aroundLatLng: `${centerLat},${centerLng}`,
          });

          if (r.hits.length > 0) {
            setHits(r.hits);
            return;
          }
        }

        // If still no results, search everywhere
        r = await t.search("", {
          ...commonParams,
        });

        setTitleHits(null);
        setHits(r.hits);
      } catch (e) {
        console.log(e);
      } finally {
        setSecondarySearchLoading(false);
      }
    };

    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      onSearchParamsUpdate();
    }, 200);

    return () => clearTimeout(timeoutId);
  }, [facetFilters, insidePolygon, numericFilters, selectedOption?.label]);

  return {
    filterhooks,
    hits,
    titleHits,
    selectedService,
    selectedOption,
    selectedArea,
    mfHooks,
    isAdvancedFiltersShowed,
    numberOfAdvancedSet,
    toggleAdvancedFilters,
    secondarySearchLoading,
    prestationIds:
      selectedService.reduce(
        (acc, service) => ({ ...acc, [service.id]: true }),
        {}
      ) ?? {},
  };
};

export default useSecondarySearchHook;
