import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import CustomButton from "../ui/CustomButton";
import CustomToggle from "../ui/CustomToggle";
import Cookies from "js-cookie";

const RGPD = () => {
  const cookieRGPD = Cookies.get("rgpd");

  const [showRGPD, setShowRGPD] = useState(cookieRGPD === undefined);
  const [isShowMore, setIsShowMore] = useState(false);

  const { control, reset } = useForm({
    defaultValues: {
      functional: true,
      experience: true,
      measure: true,
      marketing: true,
    },
  });

  const handleClick = useCallback(
    (value) => {
      reset({
        functional: value,
        experience: value,
        measure: value,
        marketing: value,
      });

      setTimeout(() => {
        Cookies.set("rgpd", "true", { expires: 7 });
        setShowRGPD(false);
      }, 300);
    },
    [reset]
  );

  if (!showRGPD) {
    return null;
  }

  return (
    <form
      className="absolute inset-x-0 bottom-0 z-50 bg-white p-4"
      style={{
        boxShadow: "0px 5px 13px 2px rgba(0, 0, 0, 0.25)",
      }}
    >
      <div className="mx-auto flex flex-col gap-3 md:max-w-full md:flex-row">
        <div className="font-roboto font-semibold text-gray-900">
          Ce site web utilise des cookies afin de vous offrir la meilleure
          expérience utilisateur possible sur notre plateforme.
        </div>
        <div className="grid w-full max-w-[500px] grid-cols-2">
          <div className="col-span-1 flex flex-col items-center justify-center gap-2 border border-gray-200 bg-gray-100 py-3">
            <span className="font-medium">Fonctionnalité</span>
            <div className="shrink-0">
              <CustomToggle control={control} name="functional" />
            </div>
          </div>
          <div className="col-span-1 flex flex-col items-center justify-center gap-2 border border-gray-200 bg-gray-100 py-3">
            <span className="font-medium">Expérience</span>
            <CustomToggle control={control} name="experience" />
          </div>
          <div className="col-span-1 flex flex-col items-center justify-center gap-2 border border-gray-200 bg-gray-100 py-3">
            <span className="font-medium">Mesure</span>
            <CustomToggle control={control} name="measure" />
          </div>
          <div className="col-span-1 flex flex-col items-center justify-center gap-2 border border-gray-200 bg-gray-100 py-3">
            <span className="font-medium">Marketing</span>
            <CustomToggle control={control} name="marketing" />
          </div>
        </div>
        <div className="flex shrink-0 flex-col gap-2 lg:min-w-[300px]">
          <div className="flex gap-2 pt-3">
            <CustomButton
              isFull
              color="black"
              variant="outlined"
              onClick={() => handleClick(false)}
            >
              Refuser tout
            </CustomButton>
            <CustomButton isFull onClick={() => handleClick(true)}>
              Accepter
            </CustomButton>
          </div>

          <CustomButton
            isFull
            variant="outlined"
            onClick={() => setIsShowMore(!isShowMore)}
          >
            En savoir plus
          </CustomButton>
        </div>
      </div>
      {isShowMore && (
        <div className="mt-3">
          <div className="font-roboto font-semibold text-gray-900">
            <p className="text-gray-700">
              Pour Monprestatairelogistique.com, votre expérience sur notre site
              est une priorité.
              <br />
              <br /> C'est pourquoi nous utilisons des cookies et autres
              traceurs pour vous fournir notre service, le personnaliser, en
              mesurer l’audience et améliorer votre expérience.
              <br />
              <br />
              Sur la base de votre consentement des informations liées à votre
              navigation sur notre site (telle que l’IP, les pages visitées
              etc.) sont stockées et/ou lues sur votre terminal par
              Monprestatairelogistique.com.
              <br />
              <br />
              Ces dernières pouvant également être traitées sur la base de votre
              consentement ou de leur intérêt légitime, auquel vous pouvez vous
              opposer en cliquant sur le bouton « Refuser », afin de diffuser
              des publicités personnalisées, mesurer leurs performances, obtenir
              des données d'audience en lien avec cette publicité et développer
              et améliorer les produits.
              <br />
              <br />
              En cliquant sur “Tout accepter”, vous consentez à l’utilisation de
              cookies pour l’ensemble des finalités ci-dessus. Vous pouvez
              refuser en cliquant sur "Refuser tout".
            </p>
          </div>
        </div>
      )}
    </form>
  );
};

export default RGPD;
