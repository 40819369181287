import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { GET_AROUND_ME } from "../../queries/citiesSearch/citiesSearch";

const useLocationPlaceId = () => {
  const [placeId, setPlaceId] = useState(null);
  const [error, setError] = useState(null);
  const [fetchAroundMe] = useLazyQuery(GET_AROUND_ME);

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          try {
            const response = await fetchAroundMe({
              variables: {
                lat,
                lng,
              },
            });
            if (response?.data?.getAroundMe) {
              setPlaceId(response?.data?.getAroundMe);
            }
          } catch (err) {
            setError(err);
          }
        },
        (err) => {
          setError(err);
        }
      );
    } else {
      setError(new Error("Geolocation is not supported by this browser."));
    }
  }, [fetchAroundMe]);

  useEffect(() => {
    (async () => {
      await fetchAroundMe();

      // Watch for changes in geolocation permission status
      if (navigator.permissions) {
        const permissionStatus = await navigator.permissions.query({
          name: "geolocation",
        });
        permissionStatus.onchange = () => {
          if (permissionStatus.state === "granted") {
            fetchAroundMe();
          }
        };
      }
    })();
  }, [fetchAroundMe]);

  return { placeId, error };
};

export default useLocationPlaceId;
