import { useCallback, useMemo } from "react";
import YouTube from "react-youtube";
import cycle from "../../../assets/images/cycle.png";
import stats from "../../../assets/images/stats.png";
import { useWrapperRef } from "../../../components/layout/LayoutWrapper";
import CustomButton from "../../../components/ui/CustomButton";
import useTailwindBreakpoint from "../../../hooks/useTailwindBreakpoint";

function OptimizeLogisticsSection({ handleDisplaySearchBar }) {
  const { isXsTw, isSmTw, isMdTw } = useTailwindBreakpoint();
  const wrapperRef = useWrapperRef();

  const opts = {
    playerVars: {
      controls: 1,
    },
  };

  const advantages = useMemo(
    () => [
      { logo: cycle, text: "Adoptez un seul process pour vos démarches" },
      {
        logo: stats,
        text: "Simplifiez vos demandes de devis via nos fonctionnalités",
      },
    ],
    []
  );

  const handleOnClick = useCallback(() => {
    if (isXsTw || isSmTw || isMdTw) {
      wrapperRef.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      handleDisplaySearchBar();
    }
  }, [handleDisplaySearchBar, isMdTw, isSmTw, isXsTw, wrapperRef]);

  return (
    <div className="md:mt-0 lg:mt-[90px] xl:mt-[180px]">
      <div className="my-3 mt-[40px] md:grid md:grid-cols-2 md:items-center md:gap-x-[50px] lg:my-0 lg:mb-16 xl:items-stretch">
        <div className="flex flex-col items-center md:col-span-1 md:items-start lg:flex-none lg:items-start lg:gap-0">
          <h2 className="mb-[40px] text-center font-roboto text-2xl font-bold text-mplgreen md:mb-0 md:text-left md:text-[38px] md:leading-normal lg:text-left lg:font-roboto-regular lg:text-[55px] lg:leading-[3.9rem]">
            Optimisez votre chaîne logistique grâce à notre réseau
          </h2>

          <div className="mb-[40px] w-screen md:mb-0">
            <YouTube
              className="md:hidden"
              iframeClassName="aspect-video w-full h-full"
              opts={opts}
              videoId="oEdM_yWTisg"
            />
          </div>

          <CustomButton
            className="mb-5 md:order-3 lg:self-center lg:p-4 lg:text-xl"
            onClick={handleOnClick}
          >
            Commencer ma recherche
          </CustomButton>

          <p className="mb-5 font-roboto-regular text-xl font-bold text-mplgreen md:mb-0 lg:order-1 lg:mt-5 lg:font-normal">
            Comment ça marche ?
          </p>
          <p className="mxl:leading-[23px] mb-5 font-roboto-regular lg:order-2 lg:mb-[30px] lg:mt-[10px] lg:text-xl xl:leading-[23px]">
            Monprestatairelogistique.com est une plateforme en ligne de mise en
            relation entre les acteurs de la logistique et les entreprises de
            façon simple, transparente et sans intermédiaire.
          </p>
        </div>
        <div className="hidden md:col-span-1 md:flex ">
          <YouTube
            className="h-auto w-full flex-1"
            iframeClassName="h-full md:h-auto md:aspect-video w-full"
            opts={opts}
            videoId="oEdM_yWTisg"
          />
        </div>
      </div>
      <div className="mb-5 flex flex-col gap-7 md:flex-row md:justify-evenly lg:gap-0">
        {advantages.map((advantage) => (
          <div
            key={advantage.text}
            className="flex flex-1 items-center gap-4 font-roboto font-bold md:flex-initial md:gap-0"
          >
            <img
              alt="cycleIcon"
              className="w-16 md:w-[60px] lg:w-[111px]"
              src={advantage.logo}
            />
            <p className="font-roboto-regular text-base md:ml-5 md:w-[210px] lg:w-[253px] lg:text-xl">
              {advantage.text}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default OptimizeLogisticsSection;
