import { useMemo } from "react";
import clock from "../../../assets/images/clock.png";
import dollar from "../../../assets/images/dollar.png";
import upstats from "../../../assets/images/upstats.png";

function AdvantagesSection() {
  const advantagesContent = useMemo(() => {
    return [
      {
        logo: clock,
        title: "Gagnez du temps",
        description: "En accédant et comparant une multitude de logisticiens",
      },
      {
        logo: dollar,
        title: "Faites des économies",
        description: "Aucune commission n'est prise sur la mise en relation",
      },
      {
        logo: upstats,
        title: "Améliorez votre visibilité",
        description: "On facilite vos démarches d'appels d'offres",
      },
    ];
  }, []);

  return (
    <div className="flex w-full flex-col gap-10 md:flex-row lg:w-[80%] lg:justify-between">
      {advantagesContent.map((advantage) => (
        <div
          key={advantage.title}
          className="flex w-full items-center gap-3 md:flex-col lg:w-[271px] lg:gap-0"
        >
          <img
            alt="clock"
            className="w-[80px] lg:w-[100px]"
            src={advantage.logo}
          />
          <div className="flex flex-col md:items-center">
            <h5 className="font-roboto text-lg font-bold text-mplgreen md:text-center lg:font-roboto-regular lg:text-2xl">
              {advantage.title}
            </h5>
            <p className="font-roboto text-base text-white md:text-center lg:my-5 lg:font-roboto-regular lg:text-xl">
              {advantage.description}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
}

export default AdvantagesSection;
