import { useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import Select from "react-select";
import MenuList from "../../../MenuList";

const FilterDropdownItem = ({ filter, selectedIds }) => {
  const [isHover, setIsHover] = useState(null);

  const handleMouseEnter = (id) => {
    setIsHover(id);
  };
  const handleMouseLeave = () => {
    setIsHover(null);
  };

  return (
    <div
      onMouseEnter={() => handleMouseEnter(filter.id)}
      onMouseLeave={() => handleMouseLeave(filter.id)}
    >
      <Select
        isClearable
        isMulti
        closeMenuOnSelect={false}
        components={{ MenuList }}
        controlShouldRenderValue={false}
        hideSelectedOptions={false}
        isSearchable={false}
        options={filter.options}
        placeholder={
          <div className="flex items-center gap-2">
            <div>{`${filter.placeholder} ${
              filter.selectedFilter.length
                ? "+" + filter.selectedFilter.length
                : ""
            }`}</div>
            {filter.selectedFilter.length === 0 && <FiChevronDown />}
          </div>
        }
        selectedFilter={filter.selectedFilter}
        styles={{
          ...selectStyle,
          control: (baseStyles, { hasValue, isFocused }) => {
            return {
              ...selectStyle.control(baseStyles, {
                hasValue: hasValue || selectedIds?.includes(filter.id),
                isFocused,
                isHover,
              }),
            };
          },
          placeholder: (baseStyles, { hasValue, isFocused }) => {
            return {
              ...selectStyle.placeholder(baseStyles, {
                isHover,
                hasValue,
                isFocused,
              }),
            };
          },
        }}
        value={filter.selectedFilter}
        onChange={filter.handleOnChange}
      />
    </div>
  );
};

const selectStyle = {
  control: (baseStyles, { hasValue, isHover, isFocused }) => {
    let baseState = {
      backgroundColor: "white",
      borderColor: "black",
    };

    if (hasValue || isFocused) {
      baseState.borderColor = "#1FC585 !important";
    }
    if (isHover) {
      baseState.borderColor = "#1FC585 !important";
    }
    if (isHover && hasValue) {
      baseState.borderColor = "#1FC585 !important";
      baseState.backgroundColor = "#1FC585";
    }

    return {
      ...baseStyles,
      width: "8rem",
      cursor: "pointer",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "25px",
      boxShadow: "0 4px 4px rgba(0, 0, 0, 0.25)",
      borderWidth: "1px",
      borderStyle: "solid",
      ...baseState,
    };
  },

  input: (baseStyles) => ({
    ...baseStyles,
    zIndex: 1,
    pointerEvents: "none",
    color: "transparent",
    textShadow: "0 0 0 #2196f3",
  }),
  placeholder: (baseStyles, { hasValue, isHover }) => {
    let baseState = {
      color: "black",
    };

    if (hasValue) {
      baseState.color = "#1FC585";
    }
    if (hasValue && isHover) {
      baseState.color = "white";
    }

    return {
      ...baseStyles,
      position: "absolute",
      // color: hasValue && !isFocused ? "#1FC585" : "#000000",
      fontWeight: 400,
      fontSize: "16px",
      padding: 0,
      zIndex: 0,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      ...baseState,
    };
  },
  valueContainer: (baseStyles) => ({
    ...baseStyles,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }),
  indicatorsContainer: (baseStyles) => ({
    ...baseStyles,
    display: "none !important",
  }),
  menuList: (baseStyles) => ({
    ...baseStyles,
    display: "flex !important",
    flexDirection: "column !important",
    justifyContent: "flex-start !important",
    alignItems: "flex-start !important",
    flexWrap: "nowrap !important",
    zIndex: 3,
  }),
  menu: (baseStyles) => ({
    ...baseStyles,
    borderRadius: "25px",
    display: "flex",
  }),
  menuPortal: (baseStyles) => ({
    ...baseStyles,
    display: "flex",
    flexDirection: "column !important",
  }),
  option: (baseStyles) => ({
    ...baseStyles,
    wordBreak: "break-word",
  }),
};

export default FilterDropdownItem;
