import { Link } from "react-router-dom";

const OutlinedButton = ({ to, children, color, onClick }) => {
  let baseClassName =
    "font-semibold border flex w-max text-lg font-roboto-regular items-center justify-center whitespace-nowrap rounded-full px-5 py-[7px] leading-normal relative";

  if (color === "white") {
    baseClassName += " text-white border-white";
  } else if (color === "green") {
    baseClassName += ` text-[#1fc585] border-[#1fc585]`;
  }

  if (to) {
    return (
      <Link className={baseClassName} to={to}>
        {children}
      </Link>
    );
  }

  return (
    <button className={baseClassName} onClick={onClick}>
      {children}
    </button>
  );
};

export default OutlinedButton;
