import * as amplitude from "@amplitude/analytics-browser";
import { config } from "../config/env.config";

let identifyEvent = null;
export const initAmplitude = () => {
  amplitude.init(config.get("AMPLITUDE_KEY"), undefined, {
    defaultTracking: true,
  });
  identifyEvent = new amplitude.Identify();
};

export const setAmplitudeUserId = (userId) => {
  amplitude.setUserId(userId);
};

export const setAmplitudeUserProperties = (userprops, userProperties) => {
  amplitude.identify(identifyEvent);
  identifyEvent.set(userprops, userProperties);
};

export const track = (eventType, eventProperties = {}) => {
  amplitude.track(eventType, eventProperties);
};
