import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Title,
  TitlePart,
} from "../../components/others/dashboard/styled/PageTitleElements";
import useTailwindBreakpoint from "../../hooks/useTailwindBreakpoint";
import { SpecificationByUser } from "../../queries/specification/specification";
import ListSpecificationItem from "./ListSpecificationItem";
import MobileListSpecification from "./MobileListSpecification";

export default function ListSpecification() {
  const [specifications, setSpecifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [getSpecification] = useLazyQuery(SpecificationByUser, {
    fetchPolicy: "no-cache",
  });
  const { isLgTw } = useTailwindBreakpoint();

  useEffect(() => {
    getSpecification().then((r) => {
      setSpecifications(r.data?.specifications);
      setLoading(false);
    });
  }, []);

  if (!isLgTw) {
    return (
      <MobileListSpecification
        setSpecifications={setSpecifications}
        specifications={specifications}
      />
    );
  }

  return (
    <div className="px-4 py-8 md:px-12">
      <Title>
        <TitlePart>Mon Cahier des charges</TitlePart>
      </Title>
      <Wrapper>
        <table className="table" style={{ borderSpacing: 0, width: "100%" }}>
          <thead>
            <tr>
              <TableCell className="border-bottom">
                CAHIER DES CHARGES
              </TableCell>
              <TableCell className="border-bottom">DATE DE CREATION</TableCell>
              <TableCell className="border-bottom">
                DERNIÈRE MODIFICATION
              </TableCell>
              <TableCell colSpan={2}> </TableCell>
            </tr>
          </thead>
          <tbody>
            {specifications?.map((item, index) => (
              <ListSpecificationItem
                key={item.id}
                index={index}
                item={item}
                setSpecifications={setSpecifications}
              />
            ))}
            {loading && (
              <tr>
                <td colSpan={5}>
                  <div className="mt-3 h-12 w-full animate-pulse bg-gray-200"></div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </Wrapper>
    </div>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;

const TableCell = styled.td`
  min-width: 25%;
  padding: 25px 0;
  font-size: 15px;
  text-align: center;
  &.border-bottom {
    border-bottom: 0.5px solid #a8a8a8;
  }

  &.center {
    text-align: center;
  }
`;
