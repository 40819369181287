import { useQuery } from "@apollo/client";
import moment from "moment/moment";
import { useNavigate, useParams } from "react-router-dom";
import googleMarker from "../../assets/svg/googleMarker.svg";
import { GET_TENDER_NOTICE } from "../../queries/tenderNotice/tenderNotice";
import SelectedLittleGrayBubble from "../ui/SelectedLittleGrayBubble";
import Spinner from "../ui/Spinner";

const TenderNoticeDetails = () => {
  const params = useParams();
  const navigate = useNavigate();

  const { data, loading } = useQuery(GET_TENDER_NOTICE, {
    variables: { id: params.id },
  });

  if (loading) return <Spinner />;

  if (!data || !data?.getTenderNoticeById) return null;

  const { getTenderNoticeById: tenderNotice } = data;

  const formattedPublicationDate = moment(tenderNotice.publicationDate).format(
    "DD/MM/YYYY"
  );
  const formattedClosingDate = moment(tenderNotice.closingDate).format(
    "DD/MM/YYYY"
  );
  const remainingDays = moment(tenderNotice.closingDate, "YYYY-MM-DD").diff(
    moment(),
    "days"
  );

  return (
    <div className="flex flex-col px-4 py-8 md:px-12">
      <button
        className="mb-8 flex w-fit items-center rounded-lg border border-mplgreen bg-white px-3 py-1.5 text-center text-sm font-medium text-mplgreen shadow-md"
        type="button"
        onClick={() => navigate(-1)}
      >
        <svg
          className="h-6 w-6"
          fill="red"
          stroke="currentColor"
          strokeWidth="1.5"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <span className="ml-3">Retour à la liste des appels d’offres</span>
      </button>

      <section className="bg-white">
        <div className="mb-11 block w-full">
          <div className="mb-2 flex items-center gap-2">
            {tenderNotice.logo && (
              <img
                alt={tenderNotice.logo.name}
                className="h-12 w-12 rounded-full object-cover"
                src={tenderNotice.logo.url}
              />
            )}
            <span className="text-sm text-gray-500">
              {tenderNotice.companyName}
            </span>
          </div>
          <h5 className="mb-2 text-left text-xl font-bold tracking-tight text-gray-900">
            {tenderNotice.offerTitle}
          </h5>
          <div className="mb-3 flex items-center justify-between border-b border-black pb-3">
            <div className="flex items-center gap-2">
              <img alt="Illustration" className="w-[30px]" src={googleMarker} />
              <span className="flex flex-wrap items-center gap-2 text-gray-700">
                <span>Localisation: </span>
                {tenderNotice.localisation.map((location) => (
                  <SelectedLittleGrayBubble
                    key={location.label}
                    label={location.label}
                    size="sm"
                  />
                ))}
              </span>
            </div>
            <div className="flex flex-wrap items-center gap-2 text-gray-700">
              <span>Périmètre:</span>
              {tenderNotice.workingArea.map((wa) => (
                <SelectedLittleGrayBubble
                  key={wa.workingArea.label}
                  label={wa.workingArea.label}
                  size="sm"
                />
              ))}
            </div>
          </div>
          <div className="mb-3 flex flex-col items-center justify-between border-b border-black pb-3 lg:flex-row">
            <div className="flex w-full gap-2 lg:items-center">
              <span className="flex w-full items-center justify-between gap-2 text-sm text-gray-700 lg:justify-start lg:text-base">
                <span>Date de parution:</span>
                <span>{formattedPublicationDate}</span>
              </span>
            </div>
            <div className="flex w-full items-center gap-2">
              <span className="flex w-full items-center justify-between gap-2 text-sm text-gray-700 lg:justify-end lg:text-base">
                <span>Date de clotûre:</span>
                <span className="text-red-500">
                  {formattedClosingDate} (Reste {remainingDays} jours)
                </span>
              </span>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <span className="flex flex-wrap items-center gap-2 text-gray-700">
              <span>Secteur:</span>
              {tenderNotice.sector.map((sector) => (
                <SelectedLittleGrayBubble
                  key={sector.label}
                  label={sector.label}
                  size="sm"
                />
              ))}
            </span>
          </div>
        </div>
        <dl>
          <dt className="mb-2 font-semibold leading-none text-gray-900">
            Description des prestations
          </dt>
          <dd className="mb-4 font-light text-gray-500 sm:mb-5">
            {tenderNotice.serviceDescription}
          </dd>
          {tenderNotice.estimatedValue && (
            <>
              <dt className="mb-2 font-semibold leading-none text-gray-900">
                Valeur estimee
              </dt>
              <dd className="mb-4 font-light text-gray-500 sm:mb-5">
                {tenderNotice.estimatedValue}
              </dd>
            </>
          )}
          {tenderNotice.contractDuration && (
            <>
              <dt className="mb-2 font-semibold leading-none text-gray-900">
                Duree du contrat
              </dt>
              <dd className="mb-4 font-light text-gray-500 sm:mb-5">
                {tenderNotice.contractDuration}
              </dd>
            </>
          )}
          <dt className="mb-2 font-semibold leading-none text-gray-900">
            Information complementaire
          </dt>
          <dd className="mb-4 font-light text-gray-500 sm:mb-5">
            {tenderNotice.additionalInformation}
          </dd>
          <dt className="mb-2 font-semibold leading-none text-gray-900">
            Contact
          </dt>
          <dd className="mb-4 font-light text-gray-500 sm:mb-5">
            {tenderNotice.contact}
          </dd>
          {tenderNotice.complementaryLinks && (
            <>
              <dt className="mb-2 font-semibold leading-none text-gray-900">
                Liens complementaire
              </dt>
              <dd className="mb-4 flex flex-wrap gap-3 font-light text-blue-500 underline underline-offset-2 sm:mb-5">
                {tenderNotice.complementaryLinks.split(/[\s,]+/).map((url) => {
                  if (!url) return;
                  return (
                    <a key={url} href={url} rel="noreferrer" target="_blank">
                      {url}
                    </a>
                  );
                })}
              </dd>
            </>
          )}
        </dl>
      </section>
    </div>
  );
};

export default TenderNoticeDetails;
