import { useMemo } from "react";
import MobileModal from "..";
import { services } from "../../../constants/services";
import useSearchBarHooks from "../../others/searchBar/hooks/useSearchBarhooks";
import GreenButton from "../../ui/GreenButton";

const MobileServices = ({ onClose }) => {
  const { selectedService, toggleOptionService, clearSelectedService } =
    useSearchBarHooks({
      onSearch: () => null,
      resetSearch: false,
    });

  const areaChecked = useMemo(() => {
    if (!services) return [];

    return services.map((service) => ({
      id: service.id,
      title: service.title,
      checked: !!selectedService?.find((s) => s.id === service.id),
    }));
  }, [selectedService]);

  return (
    <MobileModal title="Prestations" onClose={onClose}>
      <div className="flex h-full flex-col font-roboto">
        <div className="h-1 grow overflow-auto">
          {areaChecked.map((area) => (
            <div
              key={area.id}
              className="flex items-center justify-between border border-b-mplgray px-11 py-5"
            >
              <label className="grow font-bold" htmlFor={area.id}>
                {area.title}
              </label>

              <input
                checked={area.checked}
                className="peer h-5 w-5 appearance-none rounded border border-mplgreen checked:bg-mplgreen checked:hover:!bg-mplgreen focus:ring-0 focus-visible:bg-red-300"
                id={area.id}
                type="checkbox"
                value={area.checked}
                onChange={() => toggleOptionService({ item: area })}
              />
            </div>
          ))}
        </div>
        <div className="mt-auto flex items-center justify-between border-t border-t-mplgray px-7 py-4">
          <button
            className="border-b border-black font-bold"
            onClick={clearSelectedService}
          >
            Tout effacer
          </button>
          <GreenButton onClick={onClose}>Fermer</GreenButton>
        </div>
      </div>
    </MobileModal>
  );
};

export default MobileServices;
