import { gql } from "@apollo/client";

export const GetUserByID = gql`
  query getUserById($id: String!) {
    getUserById(id: $id) {
      id
      name
      company
      status
      activityArea {
        label
        code
      }
      siret
      email
      phone
      role
      apecode
      creationDate
      address
      profilePic {
        id
        name
        url
      }
      proof {
        id
        name
        url
      }
      subscriptions {
        id
        duration
        endsAt
        revokeDate
        running
        startedAt
        subscribedAt
        subscription {
          id
          name
          price
        }
      }
    }
  }
`;

export const GetUserByEmail = gql`
  query getUserByEmail($email: String!) {
    getUserByEmail(email: $email) {
      id
      name
      company
      status
      activityArea {
        label
        code
      }
      siret
      email
      phone
      role
      apecode
      creationDate
      address
      profilePic {
        id
        name
        url
      }
      proof {
        id
        name
        url
      }
    }
  }
`;

export const UpdateUser = gql`
  mutation updateUser(
    $company: String!
    $siret: String!
    $apecode: String!
    $address: String!
    $email: String!
    $phone: String!
    $proof: FilesInput
    $profilePic: FilesInput
  ) {
    updateUser(
      updateUserInput: {
        company: $company
        siret: $siret
        apecode: $apecode
        address: $address
        email: $email
        phone: $phone
        profilePic: $profilePic
        proof: $proof
      }
    ) {
      id
      name
      company
      status
      activityArea {
        label
        code
      }
      siret
      email
      phone
      role
      apecode
      address
      profilePic {
        id
        name
        url
      }
      proof {
        id
        name
        url
      }
    }
  }
`;

export const AskForValidation = gql`
  mutation askForValidation {
    askForValidation
  }
`;
