import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  from,
  fromPromise,
  InMemoryCache,
  split,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { createClient } from "graphql-ws";
import jwtDecode from "jwt-decode";
import { useContext } from "react";
import { positions, Provider as AlertProvider, transitions } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import { config } from "../../config/env.config";
import { AuthContext } from "../../context/AuthContext";
import { NotificationProvider } from "../../context/NotificationContext";
import { GetNewToken } from "../../queries/login/login";

const alertOptions = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_RIGHT,
  timeout: 5000,
  offset: "20px",
  // you can also just use 'scale'
  transition: transitions.SCALE,
};
const TokenMiddlewareWrapper = ({ uri, children }) => {
  const { user, token, dispatch, refreshToken } = useContext(AuthContext);

  const authMiddleware = (authToken) =>
    new ApolloLink((operation, forward) => {
      // add the authorization to the headers
      if (authToken) {
        operation.setContext({
          headers: {
            authorization: `Bearer ${authToken}`,
          },
        });
      }

      return forward(operation);
    });

  let client;
  const getNewToken = () => {
    return client
      .mutate({ mutation: GetNewToken, variables: { refreshToken } })
      .then((r) => {
        const { user, accessToken, refreshToken } = r.data.refreshToken;
        dispatch({
          type: "REFRESH",
          payload: {
            token: accessToken,
            refreshToken,
            user: {
              ...jwtDecode(accessToken),
              id: user.id,
              listingNumber: user.listingNumber,
              subscriptions: user.subscriptions ?? [],
              role: user?.role,
              company: user?.company,
              name: user?.name,
            },
          },
        });
        return accessToken;
      });
  };

  const errorLink = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) => {
          console.error(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          );
          if (message === "Unauthorized" && !path.includes("login")) {
            if (!user) {
              return;
            }
            if (!refreshToken) {
              dispatch({ type: "LOGOUT" });
              window.location.href = "/";
              return;
            }
            return fromPromise(
              getNewToken().catch(() => {
                dispatch({ type: "LOGOUT" });
                window.location.href = "/";
              })
            )
              .filter((value) => Boolean(value))
              .flatMap((accessToken) => {
                const oldHeaders = operation.getContext().headers;
                // modify the operation context with a new token
                operation.setContext({
                  headers: {
                    ...oldHeaders,
                    authorization: `Bearer ${accessToken}`,
                  },
                });
                // retry the request, returning the new observable
                return forward(operation);
              });
          }
        });
      else if (networkError) console.error(`[Network error]: ${networkError}`);
    }
  );
  
  const wsLink = new GraphQLWsLink(
    createClient({
      url: `${config.get("WEBSOCKET_URL")}`,
      connectionParams: {
        authorization: `Bearer ${token}`,
      },
    })
  );

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    authMiddleware(token).concat(uri)
  );

  client = new ApolloClient({
    link: from([errorLink, splitLink]),
    cache: new InMemoryCache(),
  });

  return (
    <ApolloProvider client={client}>
      <AlertProvider template={AlertTemplate} {...alertOptions}>
        <NotificationProvider>{children}</NotificationProvider>
      </AlertProvider>
    </ApolloProvider>
  );
};

export default TokenMiddlewareWrapper;
