import Question from "../../components/items/Question";
import GreenSubHeader from "../../components/ui/GreenSubHeader";
import Title from "../../components/ui/Title";

function Faq() {
  return (
    <div className="pb-7">
      <GreenSubHeader>FAQ - Nos réponses à vos questions</GreenSubHeader>

      <div className="p-6 md:mt-10 lg:mt-12 lg:flex-1 lg:px-14">
        <Title.H3>Les questions fréquentes </Title.H3>
        <div className="mt-6 flex flex-col items-center gap-4">
          <Question />
        </div>
      </div>
    </div>
  );
}

export default Faq;
