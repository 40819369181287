import { useQuery } from "@apollo/client";
import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SearchContext } from "../../context/SearchContext";
import { GET_TENDER_NOTICES } from "../../queries/tenderNotice/tenderNotice";
import SelectFromGeoApi from "../../views/dashboard/listings/CreateListingComponents/SelectFromGeoApi";
import SelectFromList from "../input/SelectFromList";
import Pagination from "../Pagination";
import Alert from "../ui/Alert";
import Spinner from "../ui/Spinner";
import TenderNoticeCard from "./components/TenderNoticeCard";

const ROWS_PER_PAGE = 3;

const TenderNoticeList = () => {
  const [location, setLocation] = useState([]);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const { settingDatas } = useContext(SearchContext);
  const [selectedWorkingDomains, setSelectedWorkingDomains] = useState([]);
  const startIndex = (page - 1) * ROWS_PER_PAGE;
  const endIndex = startIndex + ROWS_PER_PAGE;
  let { data, loading } = useQuery(GET_TENDER_NOTICES);

  const handlePageChange = async (page) => {
    setPage(page);
  };

  useEffect(() => {
    if (location.length || selectedWorkingDomains.length) {
      setPage(1);
    }
  }, [location.length, selectedWorkingDomains.length]);

  const filteredTenderNotices = useMemo(() => {
    if (!data || !data.searchTenderNotice.tenderNotices.length) return [];

    let filteredNotices = data.searchTenderNotice.tenderNotices;

    if (location.length > 0) {
      filteredNotices = filteredNotices.filter((tenderNotice) =>
        location.some((loc) =>
          tenderNotice.localisation.label.includes(loc.label)
        )
      );
    }

    if (selectedWorkingDomains.length > 0) {
      filteredNotices = filteredNotices.filter((tenderNotice) =>
        tenderNotice.sector.some((sector) =>
          selectedWorkingDomains.includes(sector.code)
        )
      );
    }

    return filteredNotices;
  }, [data, location, selectedWorkingDomains]);

  if (loading) return <Spinner />;

  if (data.searchTenderNotice.tenderNotices.length === 0) {
    return (
      <div className="flex flex-col px-4 py-8 md:px-12">
        <Alert
          message="Nous vous informons qu'aucune offre d'appel n'a été émise pour le moment. Nous vous invitons à consulter régulièrement notre plateforme pour être informé(e) de toutes nouvelles publications. Nous vous remercions pour votre compréhension et votre intérêt."
          type="warning"
        />
      </div>
    );
  }

  const currentTenderNotices = filteredTenderNotices.slice(
    startIndex,
    endIndex
  );

  return (
    <div className="flex flex-col px-4 py-8 md:px-12">
      <div className="mb-12 flex w-full flex-col items-start gap-4 lg:flex-row">
        <div className="flex w-full flex-1 flex-col">
          <label className="mb-1 font-medium">Localisation</label>
          <SelectFromGeoApi
            isCol
            picked={location}
            placeholder="Localisation (ville, région, pays)"
            onValuesChange={setLocation}
          />
        </div>
        <div className="flex w-full flex-1 flex-col">
          <label className="mb-1 font-medium">Secteurs</label>
          <SelectFromList
            options={settingDatas?.workingDomains}
            picked={selectedWorkingDomains}
            placeholder="Secteurs d'activité"
            selectOn="code"
            onValuesChange={(value) => setSelectedWorkingDomains(value)}
          />
        </div>
      </div>
      <div className="flex flex-col gap-5">
        {currentTenderNotices.length ? (
          currentTenderNotices.map((tenderNotice) => (
            <button
              key={tenderNotice.id}
              data-testid="tender-notice-card"
              onClick={() =>
                navigate(`/dashboard/tender-notice/${tenderNotice.id}`)
              }
            >
              <TenderNoticeCard
                closingDate={tenderNotice.closingDate}
                companyName={tenderNotice.companyName}
                createdAt={tenderNotice.createdAt}
                localisation={tenderNotice.localisation}
                logo={tenderNotice.logo}
                offerTitle={tenderNotice.offerTitle}
                publicationDate={tenderNotice.publicationDate}
                publishedAt={tenderNotice.publishedAt}
                sector={tenderNotice.sector}
                workingArea={tenderNotice.workingArea}
              />
            </button>
          ))
        ) : (
          <Alert
            message="Aucune offre d'appel ne correspond à vos critères de recherche."
            type="warning"
          />
        )}
        {filteredTenderNotices.length ? (
          <div className="ml-auto">
            <Pagination
              currentPage={page}
              itemsPerPage={ROWS_PER_PAGE}
              totalItems={filteredTenderNotices.length}
              onPageChange={handlePageChange}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default TenderNoticeList;
