import { useEffect } from "react";
import ReactDOM from "react-dom";
import closeSVG from "../../assets/svg/close.svg";

const MobileModal = ({ onClose, title, children }) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return ReactDOM.createPortal(
    <div className="absolute inset-0 z-50 overflow-hidden bg-white">
      <div className="flex h-full flex-col font-roboto">
        <div className="relative border-b border-[#A8A8A8] py-4 text-center">
          <h1 className="text-xl font-bold">{title}</h1>
          <button
            className="absolute right-9 top-1/2 -translate-y-1/2"
            onClick={onClose}
          >
            <img alt="close" src={closeSVG} />
          </button>
        </div>
        {children}
      </div>
    </div>,
    document.body
  );
};

export default MobileModal;
