import { yupResolver } from "@hookform/resolvers/yup";
import { PDFDocument } from "pdf-lib";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import pdfLogo from "../../../../assets/images/pdf-logo.png";
import { config } from "../../../../config/env.config";
import CustomButton from "../../../ui/CustomButton";
import CustomTextarea from "../../../ui/CustomTextarea";

const urlValidator = Yup.string().url("L'URL '{{value}}' n'est pas valide");

const step3Schema = Yup.object({
  complementaryLinks: Yup.string()
    .notRequired()
    .test("valid-urls", "", (value) => {
      if (!value) return true;

      const urls = value.split(/[\s,]+/);
      for (let url of urls) {
        try {
          urlValidator.validateSync(url);
        } catch (err) {
          return new Yup.ValidationError(
            `L'URL '${url}' n'est pas valide`,
            null,
            "complementaryLinks"
          );
        }
      }
      return true; // If all URLs are valid
    }),
});

const TenderFormStep3 = ({
  nextStep,
  previousStep,
  createLoading,
  defaultValues,
}) => {
  const [loading, setLoading] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);
  const fileInputRef = useRef(null);

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(step3Schema),
    defaultValues,
  });

  const onSubmit = (values) => {
    nextStep("step3", values);
  };

  const uploadFile = async (file) => {
    if (!file) {
      console.error("No file provided");
      return;
    }

    try {
      setError("externalTender", null);
      const fileBuffer = await file.arrayBuffer();
      await PDFDocument.load(fileBuffer);
    } catch (error) {
      setError("externalTender", {
        type: "manual",
        message: "Le fichier n'est pas un PDF valide",
      });
      return;
    }

    const fd = new FormData();
    fd.append("file", file);

    setLoading(true);

    try {
      const response = await fetch(`${config.get("BACKEND_URL")}/files`, {
        method: "POST",
        body: fd,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok" + response.statusText);
      }

      const responseData = await response.json();

      return responseData[0];
    } catch (error) {
      console.error("Fetch error: ", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = () => {
    setValue("externalTender", null);
    setPreviewUrl(null);
  };

  const handleChange = async (event) => {
    const file = event.currentTarget.files[0];
    const fileUploaded = await uploadFile(file);

    setValue("externalTender", fileUploaded);
    setPreviewUrl(fileUploaded ? fileUploaded : "");
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <form
      className="flex h-full flex-col justify-between"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="grid grid-cols-1 gap-1 lg:grid-cols-2">
        <div className="mb-2 sm:col-span-2">
          <CustomTextarea
            error={errors?.complementaryLinks}
            label="Liens complémentaires"
            name="complementaryLinks"
            placeholder="http(s)://exemple.com http(s)://exemple.com"
            register={register}
          />
        </div>
        <div className="flex flex-col gap-1 sm:col-span-2">
          <div className="flex flex-col items-center gap-3 lg:flex-row">
            <CustomButton
              isLoading={loading}
              variant="outlined"
              onClick={handleButtonClick}
            >
              Déposer un fichier
              <input
                ref={fileInputRef}
                hidden
                accept="application/pdf"
                name="externalTender"
                type="file"
                onChange={handleChange}
              />
            </CustomButton>
            <div className="flex items-center gap-2">
              <div className="flex w-7 items-center">
                <img
                  alt="pdf-logo"
                  className="ml-2 aspect-auto"
                  src={pdfLogo}
                />
              </div>
              {previewUrl && (
                <div className="ml-2 flex items-center gap-2">
                  <a className="text-[10px] text-gray-800">{previewUrl.name}</a>
                  <button onClick={handleDelete}>
                    <svg
                      className="h-4 w-4"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={1.5}
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </div>
              )}
            </div>
          </div>
          {errors?.externalTender?.message && (
            <p className="mt-1 text-sm text-red-600">
              {errors.externalTender.message}
            </p>
          )}
        </div>
      </div>
      <div className="mt-auto flex items-center justify-between sm:col-span-2">
        <CustomButton color="black" variant="outlined" onClick={previousStep}>
          Précédent
        </CustomButton>
        <CustomButton isLoading={createLoading} type="submit">
          Confirmer
        </CustomButton>
      </div>
    </form>
  );
};

export default TenderFormStep3;
