import { gql } from "@apollo/client";

export const FindAllSubscription = gql`
  query subscriptions {
    subscriptions {
      id
      name
      price
      period
      userType
    }
  }
`;

export const GetUserSubscriptions = gql`
  query getUserSubscriptions {
    getUserSubscriptions {
      id
      startedAt
      endsAt
      revokeDate
      running
      duration
      subscribedAt
      subscription {
        id
        name
        price
      }
    }
  }
`;

export const CreateStripePayment = gql`
  query createStripePayment {
    createStripePayment
  }
`;

export const GetStripeSessionId = gql`
  query getStripeSessionId($successUrl: String!) {
    getStripeSessionId(successUrl: $successUrl)
  }
`;

export const ReceiveStripePayment = gql`
  mutation receiveStripePayment {
    receiveStripePayment
  }
`;

export const ChangeUserSubscription = gql`
  query changeSubscription($type: String!, $duration: String!) {
    changeSubscription(type: $type, duration: $duration) {
      id
      startedAt
      endsAt
      revokeDate
      running
      duration
      subscribedAt
      subscription {
        id
        name
        price
      }
    }
  }
`;

export const RevokeUserSubscription = gql`
  mutation revokeSubscription($reason: String!) {
    revokeSubscription(reason: $reason)
  }
`;

export const GetUserInvoices = gql`
  query getUserInvoices {
    getUserInvoices {
      number
      pdf
    }
  }
`;
