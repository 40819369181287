import { useMutation } from "@apollo/client";
import cx from "classnames";
import jwtDecode from "jwt-decode";
import { useCallback, useContext, useMemo, useState } from "react";
import { AuthContext } from "../../../../../context/AuthContext";
import { NavContext } from "../../../../../context/NavContext";
import { ADD_USER } from "../../../../../queries/register/register";
import { getIp } from "../../../../../utils/localization";
import { isRoleLogistician } from "../../../../../utils/user";
import CustomButton from "../../../../ui/CustomButton";
import ModalTw from "../../../index";

const RegisterStep2 = ({ onClose, formState }) => {
  const [isCompany, setIsCompany] = useState(null);
  const [createUser, { loading }] = useMutation(ADD_USER);
  const { sessionId, dispatch } = useContext(AuthContext);
  const { toggleSubscription } = useContext(NavContext);

  const choiceItems = useMemo(
    () => [
      {
        label: "Proposer vos services logistiques",
        isActivate: isCompany === false,
        onClick: () => setIsCompany(false),
      },
      {
        label: "Ou",
      },
      {
        label: "Trouver un prestataire logistique",
        isActivate: isCompany === true,
        onClick: () => setIsCompany(true),
      },
    ],
    [isCompany]
  );

  const content = useMemo(() => {
    return choiceItems.map((item) => {
      if (item.label === "Ou")
        return <div className="my-4 font-roboto-regular">ou</div>;

      return (
        <button
          key={item.label}
          className={cx(
            {
              "text-mplgreen border-mplgreen": item.isActivate,
            },
            "border border-black w-full py-2 px-4 rounded-md"
          )}
          onClick={item.onClick}
        >
          {item.label}
        </button>
      );
    });
  }, [choiceItems]);

  const handleSubmit = useCallback(async () => {
    const { data } = await createUser({
      variables: {
        ...formState,
        email: formState.email.toLowerCase(),
        activityArea: formState.activityArea?.value,
        role: isCompany ? "COMPANY" : "LOGISTICIAN",
        host: getIp(),
        sessionId,
      },
    });

    const { user, accessToken, refreshToken } = data.createUser;

    dispatch({
      type: "LOGIN_SUCCESS",
      payload: {
        token: accessToken,
        refreshToken,
        user: {
          ...jwtDecode(accessToken),
          id: user.id,
          listingNumber: user.listingNumber,
          subscriptions: user.subscriptions ?? [],
          role: user?.role,
        },
      },
    });

    onClose();
    if (isRoleLogistician(user)) toggleSubscription();
  }, [
    createUser,
    dispatch,
    formState,
    isCompany,
    onClose,
    sessionId,
    toggleSubscription,
  ]);

  return (
    <ModalTw
      content={content}
      footer={
        <CustomButton isFull isLoading={loading} onClick={handleSubmit}>
          Valider
        </CustomButton>
      }
      title="Vous souhaitez ?"
      onClose={onClose}
    />
  );
};

export default RegisterStep2;
