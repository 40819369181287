import { useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import styled from "styled-components";

export default function CustomTextArea({
  register,
  defaultValue,
  minRows,
  title,
  ...props
}) {
  const [error, setError] = useState();
  const onChange = (e) => {
    register.onChange(e);
    if (e?.target?.value.length >= 400) {
      setError({
        message: "Maximum 500 caractères. " + e?.target?.value.length + "/500",
      });
    } else {
      setError();
    }
  };

  return (
    <Container
      style={{
        padding: props.padding ? props.padding : "0",
      }}
    >
      {title && (
        <label className="flex w-full whitespace-nowrap text-lg font-medium leading-[44px] text-gray-900">
          {title}
        </label>
      )}

      <TextareaAutosize
        className="block h-full w-full rounded-xl border-gray-300 py-1.5 text-gray-900 shadow-sm transition-all duration-300 placeholder:text-gray-400 focus:border-emerald-500 focus:ring focus:ring-emerald-500/20 sm:text-sm sm:leading-6"
        defaultValue={defaultValue}
        maxLength={500}
        maxRows={10}
        minRows={minRows}
        name={props.name}
        placeholder={props.placeholder}
        style={{
          resize: "vertical",
          fontFamily: "Roboto Regular, sans-serif",
          fontSize: "15px",
          borderRadius: "15px",
        }}
        {...register}
        onChange={onChange}
      />
      {error && <Error>{error.message}</Error>}
    </Container>
  );
}
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Error = styled.div`
  font-family: "Roboto Regular", sans-serif;
  font-size: 13px;
  text-align: center;
  padding-top: 5px;
  color: red;
`;
