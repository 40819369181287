import { gql } from "@apollo/client";

export const GetUserPaymentMethods = gql`
  query getUserPaymentMethods {
    getUserPaymentMethods {
      id
      brand
      last4
      default
    }
  }
`;

export const GetPaymentSessionId = gql`
  query getPaymentSessionId {
    getPaymentSessionId
  }
`;

export const SetPaymentMethodAsDefault = gql`
  mutation setPaymentMethodAsDefault($cardId: String!) {
    setPaymentMethodAsDefault(pid: $cardId)
  }
`;

export const DeletePaymentMethod = gql`
  mutation deletePaymentMethod($cardId: String!) {
    deletePaymentMethod(cardId: $cardId)
  }
`;
