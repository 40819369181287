import { useLazyQuery, useMutation } from "@apollo/client";
import { IonIcon } from "@ionic/react";
import { chevronBackOutline } from "ionicons/icons";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import CustomButton from "../../components/ui/CustomButton/index";
import { config } from "../../config/env.config";
import { ROLES } from "../../constants/Roles";
import { track } from "../../constants/amplitude";
import { AuthContext } from "../../context/AuthContext";
import { CreateQuote } from "../../queries/quote/quote";
import { GetSpecificationWorkbook } from "../../queries/specification/specification";
import QuoteSteps0 from "./quoteSteps/quoteSteps0";
import QuoteSteps1 from "./quoteSteps/quoteSteps1";
import QuoteSteps2 from "./quoteSteps/quoteSteps2";

const Quote = () => {
  const { user } = useContext(AuthContext);
  const [createQuote] = useMutation(CreateQuote);
  const nbrSteps = 3;
  const navigate = useNavigate();
  const location = useLocation();
  const [step, setStep] = useState(user?.role === ROLES.LOGISTICIAN ? 1 : 0);
  const [choice, setChoice] = React.useState(
    user?.role === ROLES.LOGISTICIAN ? true : null
  );
  const [file, setFile] = useState(null);
  const [specification, setSpecification] = useState(null);
  const [message, setMessage] = useState("");
  const listingInfo = location.state?.listingInfo;
  const stateChoice = location.state?.specificationId;
  const stateSpecificationId = location.state?.specificationId;
  const [isLoading, setIsLoading] = useState(false);

  const returnUrl = location.state?.returnUrl;
  const [getSpecificationWorkbook] = useLazyQuery(GetSpecificationWorkbook, {
    fetchPolicy: "no-cache",
  });
  useEffect(() => {
    listingInfo.forEach((info) =>
      track("quote_initied", { id: info.listingId })
    );
  }, [listingInfo]);

  const handleGetSpecificationWorbook = useCallback(
    async (stateSpecificationId) => {
      const { data } = await getSpecificationWorkbook({
        variables: { id: stateSpecificationId },
      });
      setSpecification(data?.getSpecificationWorkbook);
    },
    [getSpecificationWorkbook]
  );

  useEffect(() => {
    if (stateChoice) {
      setStep(1);
      setChoice(false);

      if (stateSpecificationId && !specification?.workbookFile) {
        setIsLoading(true);
        handleGetSpecificationWorbook(stateSpecificationId);
      } else {
        setIsLoading(false);
      }
    }
  }, [
    handleGetSpecificationWorbook,
    specification?.workbookFile,
    specification,
    stateChoice,
    stateSpecificationId,
  ]);

  const doSaveQuote = async () => {
    try {
      let newfile = null;

      if (file) {
        setIsLoading(true);
        const fd = new FormData();
        fd.append("file", file);

        const response = await fetch(`${config.get("BACKEND_URL")}/files`, {
          method: "POST",
          body: fd,
        });

        if (!response.ok) {
          throw new Error("File upload failed");
        }

        const resp = await response.json();
        newfile = resp[0];
      }

      const quotePromises = listingInfo.map((info) =>
        createQuote({
          variables: {
            userId: user.id,
            message,
            listingId: info.listingId,
            fileId: newfile ? newfile.id : specification.workbookFile.id,
            isFromMulti: false,
            isWorkBook: !!specification,
          },
        })
          .then(() => {
            track("quote_send", { id: info.listingId });
          })
          .finally(() => {
            setIsLoading(false);
          })
      );

      await Promise.all(quotePromises);

      navigate("/devis/success", { state: { returnUrl } });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  return (
    <div className="flex grow flex-col">
      <div className="flex grow flex-col p-6 lg:pb-12 lg:pt-8">
        <button className="flex lg:hidden" onClick={() => navigate(-1)}>
          <IonIcon icon={chevronBackOutline} />
        </button>
        <button
          className="hidden items-center gap-2 lg:flex"
          onClick={() => navigate(-1)}
        >
          <IonIcon icon={chevronBackOutline} />
          <Access>Demande de devis</Access>
        </button>
        <h1 className="mt-11 text-xl font-medium lg:hidden">
          Demande de devis
        </h1>
        <div className="flex w-full grow justify-center">
          {step === 0 && <QuoteSteps0 choice={choice} setChoice={setChoice} />}
          {step === 1 && (
            <QuoteSteps1
              choice={choice}
              filename={file?.name ?? ""}
              setFile={setFile}
              setSpecification={setSpecification}
              specification={specification}
              state={location.state}
            />
          )}
          {step === 2 && <QuoteSteps2 setMessage={setMessage} />}
        </div>
      </div>
      <div className="sticky bottom-0 mt-auto flex justify-center gap-4 border-t border-mplgray bg-white px-3 py-4 md:mx-[50px] md:justify-between">
        <div className="flex-1 md:grow-0">
          <CustomButton
            isFull
            color="black"
            variant="outlined"
            onClick={() =>
              step === 0 || (step === 1 && user?.role === ROLES.LOGISTICIAN)
                ? navigate(-1)
                : setStep(step - 1)
            }
          >
            Retour
          </CustomButton>
        </div>
        <div className="flex-1 md:grow-0">
          <CustomButton
            isFull
            disabled={
              (choice === null && step === 0) ||
              (file === null &&
                specification?.workbookFile == null &&
                step === 1)
            }
            isLoading={isLoading}
            type="submit"
            onClick={() => {
              if (step !== nbrSteps - 1)
                setStep((prevStep) =>
                  prevStep < nbrSteps ? prevStep + 1 : prevStep
                );
              if (step === nbrSteps - 1) doSaveQuote();
            }}
          >
            Suivant
          </CustomButton>
        </div>
      </div>
    </div>
  );
};

const Access = styled.p`
  font-size: 25px;
  color: #000;
  font-weight: 600;
  cursor: pointer;
`;

export default Quote;
