import { useMemo } from "react";
import { Link } from "react-router-dom";
import DefaultSpinner from "../DefaultSpinner";

const CustomButton = ({
  to,
  children,
  isLoading,
  color = "mplgreen",
  variant = "contained",
  isFull,
  type = "button",
  onClick,
  disabled,
  size = "normal",
  className,
}) => {
  const { baseColor, textColor, borderColor, hoverColor, fontSize } =
    useMemo(() => {
      let baseColor = "";
      let textColor = "text-white";
      let borderColor = "";
      let hoverColor = "";
      let fontSize = "";

      if (size === "small") {
        fontSize = "text-xs !py-1.5 !px-3";
      } else if (size === "large") {
        fontSize = "text-lg";
      }

      if (variant === "outlined") {
        textColor = `text-${color}`;
        borderColor = `border border-${color}`;
        baseColor = "bg-transparent";
      } else {
        baseColor = `bg-${color} hover:bg-${color}-hover`;
      }

      return { baseColor, textColor, borderColor, hoverColor, fontSize };
    }, [color, variant, size]);

  const ButtonOrLink = to ? Link : "button";

  return (
    <ButtonOrLink
      className={`inline-flex items-center justify-center rounded-lg px-5 py-2.5 font-roboto-regular font-semibold ${
        isFull && "w-full"
      } transition-colors duration-200 ease-in-out focus:bg-none disabled:opacity-60  ${textColor} ${baseColor} ${borderColor} ${hoverColor} ${fontSize} ${className}`}
      disabled={isLoading || disabled}
      to={to}
      type={type}
      onClick={onClick}
    >
      {isLoading && <DefaultSpinner />}
      {children}
    </ButtonOrLink>
  );
};

export default CustomButton;
