import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import tw from "tailwind-styled-components";
import LoadingBtn from "../../../components/others/dashboard/LoadingBtn";
import {
  Title,
  TitlePart,
} from "../../../components/others/dashboard/styled/PageTitleElements";
import { stripePromise } from "../../../constants/StripePay";
import {
  DeletePaymentMethod,
  GetPaymentSessionId,
  GetUserPaymentMethods,
  SetPaymentMethodAsDefault,
} from "../../../queries/paymentMethods/paymentMethods";

function PaymentMethods() {
  const [getUserPaymentMethods] = useLazyQuery(GetUserPaymentMethods, {
    fetchPolicy: "no-cache",
  });
  const alert = useAlert();
  const [deletePaymentMethod] = useMutation(DeletePaymentMethod, {
    fetchPolicy: "no-cache",
  });

  const [setPaymentMethodAsDefault] = useMutation(SetPaymentMethodAsDefault, {
    fetchPolicy: "no-cache",
  });
  const doDelete = (item) => {
    deletePaymentMethod({ variables: { cardId: item?.id } }).then(() => {
      alert.success("Le mode paiement a bien été retiré.");
      loadUserPaymentMethods();
    });
  };
  const setToDefault = (item) => {
    setPaymentMethodAsDefault({ variables: { cardId: item?.id } }).then(() => {
      alert.success("Le mode paiement par défaut a bien été changé");
      loadUserPaymentMethods();
    });
  };

  const [getPaymentSessionId] = useLazyQuery(GetPaymentSessionId, {
    fetchPolicy: "no-cache",
  });
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  const openAddCardModal = async () => {
    setLoading(true);
    getPaymentSessionId()
      .then(async (r) => {
        (await stripePromise).redirectToCheckout({
          sessionId: r.data?.getStripeClientSecret,
        });
      })
      .catch((err) => {})
      .finally(() => setLoading(false));
  };

  const loadUserPaymentMethods = () => {
    setLoading(true);
    getUserPaymentMethods().then((r) => {
      setList(r.data?.getUserPaymentMethods);
      setLoading(false);
    });
  };

  useEffect(() => {
    loadUserPaymentMethods();
  }, []);

  return (
    <div className="px-4 py-8 md:px-12">
      <Title>
        <TitlePart size="30">Mode de paiement</TitlePart>
      </Title>
      <Wrapper>
        <PayMethodsList>
          {list.map((item, idx) => (
            <li
              key={`${(Math.random() * 100).toString()}_${idx}`}
              className="flex w-full flex-col items-start py-3 md:flex-row md:items-center"
            >
              <div className="mb-3 flex items-center md:mb-0 md:mr-3">
                <PayMethThumbs>
                  <PayMethThumbsImg src="https://logos-marques.com/wp-content/uploads/2021/07/Mastercard-logo.png" />
                  <PayMethThumbsImg src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5e/Visa_Inc._logo.svg/2560px-Visa_Inc._logo.svg.png" />
                </PayMethThumbs>
                <PayMethLabel>
                  {item.brand} **********{item.last4}
                </PayMethLabel>
              </div>
              {!item.default && (
                <div className="flex flex-col items-start gap-1 md:flex-row md:gap-4">
                  <PayMethEditButton onClick={() => setToDefault(item)}>
                    Utiliser comme carte par défaut
                  </PayMethEditButton>
                  <PayMethEditButton onClick={() => doDelete(item)}>
                    Supprimer
                  </PayMethEditButton>
                </div>
              )}
            </li>
          ))}
        </PayMethodsList>
        {loading && <LoadingSkeleton />}
        {!loading && (
          <>
            <LoadingBtn
              className="mt-10 !bg-[#1FC585]"
              onClick={() => openAddCardModal()}
            >
              Ajouter un mode de paiement
            </LoadingBtn>
          </>
        )}
      </Wrapper>
    </div>
  );
}

const Wrapper = tw.div`max-w-[530px] mt-[28px]`;
const PayMethodsList = tw.ul`m-0 p-0 list-none`;
const PayMethodsItem = tw.li`flex items-center w-full py-3`;
const PayMethThumbs = tw.div`flex items-center gap-1 border border-solid rounded-lg p-2`;
const PayMethThumbsImg = tw.img`w-6`;
const PayMethLabel = tw.span`font-semibold text-base ml-3 capitalize`;
const PayMethEditButton = tw.button`bg-transparent underline text-black font-semibold text-sm border-0 cursor-pointer`;
const LoadingSkeleton = tw.div`h-12 bg-gray-200 animate-pulse`;
const NoCard = tw.div`p-4 bg-white rounded-md border border-solid border-gray-200 text-black text-sm`;

export default PaymentMethods;
