import styled from "styled-components";
import ImageLeftSide from "../../assets/images/placeholder-annonce.png";
import SplitPanel from "../../components/element/SplitPanel";
import CustomButton from "../../components/ui/CustomButton";
import useTailwindBreakpoint from "../../hooks/useTailwindBreakpoint";
import MobileCreateSpecificationIntro from "./MobileCreateSpecificationIntro";

export default function CreateSpecificationsIntro() {
  const { isXsTw, isMdTw, isSmTw } = useTailwindBreakpoint();

  if (isXsTw || isMdTw || isSmTw) {
    return <MobileCreateSpecificationIntro />;
  }

  return (
    <Container>
      <SplitPanel
        left={<ImageSide src={ImageLeftSide} />}
        right={
          <IntroSide>
            <Side>
              <Title>Créer mon cahier des charges</Title>
              <Subtitle>On vous accompagne étape par étape</Subtitle>
            </Side>
            <ButtonDiv>
              <CustomButton to="/dashboard/specification-create">
                Démarrer
              </CustomButton>
            </ButtonDiv>
          </IntroSide>
        }
      ></SplitPanel>
    </Container>
  );
}
const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-grow: 1;
`;
const ImageSide = styled.img`
  display: flex;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Side = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  width: 100%;
  height: 0;
  flex-grow: 1;
`;

const Title = styled.div`
  font-size: 60px;
  font-weight: 500;
  color: white;
  line-height: 60px;
  padding-bottom: 15px;
`;

const Subtitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  color: white;
`;

const IntroSide = styled.div`
  display: flex;
  flex-direction: column;
  background: black;
  height: 100%;
  padding: 30px 70px;
`;

const ButtonDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
`;
