import { useCallback, useMemo } from "react";
import { useWrapperRef } from "../../../components/layout/LayoutWrapper";
import CustomButton from "../../../components/ui/CustomButton";
import useTailwindBreakpoint from "../../../hooks/useTailwindBreakpoint";

export default function WhyChooseUsSection({ handleDisplaySearchBar }) {
  const wrapperRef = useWrapperRef();
  const { isXsTw, isSmTw, isMdTw } = useTailwindBreakpoint();

  const handleOnClick = useCallback(() => {
    if (isXsTw || isSmTw || isMdTw) {
      wrapperRef.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      handleDisplaySearchBar();
    }
  }, [handleDisplaySearchBar, isMdTw, isSmTw, isXsTw, wrapperRef]);

  const content = useMemo(() => {
    return [
      <p key={0}>
        Monprestatairelogistique.com est la
        <b>
          {" "}
          première plateforme innovante dédiée à la logistique qui révolutionne
          le processus de recherche, de référencement et de mise en relation
          directe entre prestataires logistiques et entreprises.{" "}
        </b>
      </p>,
      <p key={1}>
        Fondée en 2022 à Paris avec une ambition claire : être la référence
        incontournable en matière de logistique en France, en Europe et dans les
        pays francophones.
      </p>,
      <p key={2}>
        Nous sommes là pour vous accompagner et vous offrir une solution
        tout-en-un novatrice qui répond à vos besoins de visibilité,
        d'acquisition client et de simplification des processus.
      </p>,
      <p key={3}>
        Nous comprenons les défis que représente l'explosion du e-commerce et
        nous savons que trouver le prestataire logistique idéal peut être
        complexe, fastidieux et opaque. C'est pourquoi nous avons créé
        Monprestatairelogistique.com : pour{" "}
        <b>
          vous offrir une expérience transparente, efficace et personnalisée.
        </b>
      </p>,
      <p key={4}>
        En choisissant Monprestatairelogistique.com, vous choisissez la liberté
        de sélectionner vous-même les partenaires qui correspondent parfaitement
        à vos besoins. Nous vous offrons un contrôle total sur votre processus
        de prise de décision, car{" "}
        <b>
          personne ne connaît mieux que vous les exigences de votre entreprise.
        </b>
      </p>,
    ];
  }, []);

  return (
    <div className="xl:px-36 xl:pt-5">
      <div className="font-roboto">
        <div className="flex flex-col items-center gap-6 lg:gap-12">
          <h1 className="py-7 text-center font-roboto-regular text-xl font-bold lg:mb-0 lg:py-0 lg:text-[55px]">
            Pourquoi nous choisir ?
          </h1>
          <div className="flex flex-col gap-4 font-roboto-regular lg:text-xl">
            {content.map((paragraph) => paragraph)}
          </div>
          <CustomButton
            className="mt-4 lg:self-center lg:p-4 lg:text-xl"
            type="button"
            onClick={handleOnClick}
          >
            Commencer ma recherche
          </CustomButton>
        </div>
      </div>
    </div>
  );
}
