import { XMarkIcon } from "@heroicons/react/20/solid";
import cx from "classnames";
import { useEffect, useRef } from "react";

const ModalTw = ({
  onClose,
  title,
  content,
  footer,
  closable = true,
  fitContent,
  isFixed = true,
}) => {
  const modal = useRef(null);

  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!modal.current) return;
      if (modal.current.contains(target)) return;

      onClose();
    };

    let timeoutId;

    if (closable)
      timeoutId = setTimeout(() => {
        document.addEventListener("click", clickHandler);
      }, 0);

    return () => {
      if (closable) {
        clearTimeout(timeoutId);
        document.removeEventListener("click", clickHandler);
      }
    };
  });

  return (
    <div className={cx("left-0 top-0 z-40 flex h-full w-full items-center justify-center bg-black/90 px-4 py-5", {
      "fixed min-h-screen": isFixed,
      absolute: !isFixed,
    })}>
      <div
        ref={modal}
        className={cx(
          "relative max-h-full w-full overflow-y-auto rounded-[20px] bg-white px-8 py-12 text-center md:px-[70px] md:py-[60px]",
          {
            "max-w-[570px]": !fitContent,
            "max-w-fit": fitContent,
          }
        )}
      >
        {closable && (
          <div className="absolute right-0 top-0 block pr-4 pt-4">
            <button
              className="rounded-md bg-white text-gray-400"
              type="button"
              onClick={() => onClose()}
            >
              <span className="sr-only">Close</span>
              <XMarkIcon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
        )}
        {title && (
          <h3 className="pb-2 text-xl font-bold sm:text-2xl">{title}</h3>
        )}
        <span className="mx-auto mb-3 inline-block h-1 w-[90px] rounded"></span>
        <div
          className={cx("text-base leading-relaxed", {
            "mb-5": footer,
          })}
        >
          {content}
        </div>
        {footer && <div>{footer}</div>}
      </div>
    </div>
  );
};

export default ModalTw;
