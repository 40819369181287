import {
  cmdnbrOptions,
  contractMinOptions,
  maxcapacityOptions,
  paletnbrOptions,
  preparednbrOptions,
  receivenbrOptions,
  refnbrOptions,
  shippingnbrOptions,
} from "./listingsOptions";

export const getPrimaryFilters = (state, settingDatas, dispatch) => {
  const handleOnChange = (key) => (value) => {
    dispatch({ type: "SET_PRIMARY_FILTER_VALUE", payload: { key, value } });
  };

  return [
    {
      id: "can_products_base",
      placeholder: "Gestion",
      selectedFilter: state.productBaseGig,
      options: settingDatas?.logisticGigTypes,
      selectOn: "label",
      handleOnChange: handleOnChange("productBaseGig"),
    },
    {
      id: "can_receive",
      placeholder: "Réception",
      selectOn: "label",
      selectedFilter: state.receiveGig,
      options: settingDatas?.receptionGigTypes,
      handleOnChange: handleOnChange("receiveGig"),
    },
    {
      id: "can_storage",
      placeholder: "Stockage",
      selectOn: "label",
      selectedFilter: state.storageGig,
      options: [
        { code: true, label: "disponible immédiatement" },
        ...((settingDatas || {})?.stockageGigTypes || []),
      ],
      handleOnChange: handleOnChange("storageGig"),
    },
    {
      id: "can_prepare",
      placeholder: "Preparation",
      selectOn: "label",
      selectedFilter: state.preparegig,
      options: settingDatas?.preparationGigTypes,
      handleOnChange: handleOnChange("preparegig"),
    },
    {
      id: "can_packaging",
      placeholder: "Emballage",
      selectOn: "label",
      selectedFilter: state.packagingGig,
      options: settingDatas?.emballageGigTypes,
      handleOnChange: handleOnChange("packagingGig"),
    },
    {
      id: "can_shipping",
      placeholder: "Expedition",
      selectOn: "label",
      selectedFilter: state.shippingGig,
      options: settingDatas?.expeditionGigTypes,
      handleOnChange: handleOnChange("shippingGig"),
    },
    {
      id: "can_transportation",
      placeholder: "Transport",
      selectOn: "label",
      selectedFilter: state.transportGig,
      options: settingDatas?.transportGigTypes,
      handleOnChange: handleOnChange("transportGig"),
    },
    {
      id: "can_interfacing",
      placeholder: "Interfacage",
      selectOn: "label",
      selectedFilter: state.interfacingGig,
      options: settingDatas?.interfaceGigTypes,
      handleOnChange: handleOnChange("interfacingGig"),
    },
  ];
};

const whitelistSpecTypes = [
  "B2C",
  "GDR",
  "B2B",
  "GDL",
  "GDI",
  "OPT",
  "GDE",
  "GDD",
  "GDK",
  "DRO",
];

const getSortedSpecTypes = (settingDatas) =>
  whitelistSpecTypes
    .map((code) => {
      return settingDatas?.specTypes.find((dataItem) => dataItem.code === code);
    })
    .filter(Boolean);

const whitelistCertificationTypes = [
  "ADR",
  "AFB",
  "BCO",
  "CER",
  "ECO",
  "ICPE",
  "IFS",
  "ILO",
  "IRI",
  "IS1",
  "IS6",
  "IS7",
  "IS0",
  "IS3",
  "IS8",
  "IS4",
  "IS5",
  "IS9",
  "IS2",
  "QSE",
];

const getSortedCertificationTypes = (settingDatas) =>
  whitelistCertificationTypes
    .map((code) => {
      return settingDatas?.certificationTypes.find(
        (dataItem) => dataItem.code === code
      );
    })
    .filter(Boolean);

const whitelistApprovalTypes = ["ACI", "EI", "ESAT", "EA", "ESUT", "OEA"];

const getSortedApprovalTypes = (settingDatas) =>
  whitelistApprovalTypes
    .map((code) => {
      return settingDatas?.approvalTypes.find(
        (dataItem) => dataItem.code === code
      );
    })
    .filter(Boolean);

export const getAdvancedFilters = (state, settingDatas, dispatch) => {
  const handleOnChange = (key) => (value) => {
    dispatch({ type: "SET_ADVANCED_FILTER_VALUE", payload: { key, value } });
  };

  return [
    {
      id: "minContractTime",
      selectOn: "label",
      title: "Durée minimum contractuelle",
      selectedFilter: state.advancedFilters.minContractTime,
      options: contractMinOptions,
      useBubbleComponent: true,
      name: "minContractTime",
      handleOnChange: handleOnChange("minContractTime"),
    },
    {
      id: "workingArea",
      selectOn: "label",
      title: "Périmètre d'activité",
      selectedFilter: state.advancedFilters.workingArea,
      options: settingDatas?.workingArea,
      name: "workingArea",
      handleOnChange: handleOnChange("workingArea"),
    },
    {
      id: "refProducts",
      selectOn: "label",
      subTitle: "Nombre de références produits",
      title: "Volumétrie",
      selectedFilter: state.advancedFilters.refProducts,
      options: refnbrOptions,
      useBubbleComponent: true,
      name: "refProducts",
      handleOnChange: handleOnChange("refProducts"),
    },
    {
      id: "receiveNumberDaily",
      selectOn: "label",
      subTitle: "Nombre de récéption par jour",
      selectedFilter: state.advancedFilters.receiveNumberDaily,
      options: receivenbrOptions,
      useBubbleComponent: true,
      name: "receiveNumberDaily",
      handleOnChange: handleOnChange("receiveNumberDaily"),
    },
    {
      id: "receivePackageDaily",
      selectOn: "label",
      subTitle: "Nombre de colis réceptionnés par jour",
      selectedFilter: state.advancedFilters.receivePackageDaily,
      options: receivenbrOptions,
      useBubbleComponent: true,
      name: "receivePackageDaily",
      handleOnChange: handleOnChange("receivePackageDaily"),
    },
    {
      id: "dailyPalets",
      selectOn: "label",
      subTitle: "Nombre de palettes réceptionnées par jour",
      selectedFilter: state.advancedFilters.dailyPalets,
      options: paletnbrOptions,
      useBubbleComponent: true,
      name: "dailyPalets",
      handleOnChange: handleOnChange("dailyPalets"),
    },
    {
      id: "nbrCmdDay",
      selectOn: "label",
      subTitle: "Nombre de commandes par jour",
      selectedFilter: state.advancedFilters.nbrCmdDay,
      options: cmdnbrOptions,
      useBubbleComponent: true,
      name: "nbrCmdDay",
      handleOnChange: handleOnChange("nbrCmdDay"),
    },
    {
      id: "nbrPreparedDay",
      selectOn: "label",
      subTitle: "Nombre de colis préparés par jour",
      selectedFilter: state.advancedFilters.nbrPreparedDay,
      options: preparednbrOptions,
      useBubbleComponent: true,
      name: "nbrPreparedDay",
      handleOnChange: handleOnChange("nbrPreparedDay"),
    },
    {
      id: "nbrExpeditionDay",
      selectOn: "label",
      subTitle: "Nombre d'expéditions par jour",
      selectedFilter: state.advancedFilters.nbrExpeditionDay,
      options: shippingnbrOptions,
      useBubbleComponent: true,
      name: "nbrExpeditionDay",
      handleOnChange: handleOnChange("nbrExpeditionDay"),
    },
    {
      id: "capacityPalet",
      selectOn: "label",
      subTitle: "Capacité de stockage en palette",
      selectedFilter: state.advancedFilters.capacityPalet,
      options: maxcapacityOptions,
      useBubbleComponent: true,
      name: "capacityPalet",
      handleOnChange: handleOnChange("capacityPalet"),
    },
    {
      id: "tech",
      selectOn: "code",
      title: "IT",
      selectedFilter: state.advancedFilters.tech,
      options: settingDatas?.techTypes.filter((x) => x.code !== "ECOM"),
      useBubbleComponent: true,
      name: "tech",
      handleOnChange: handleOnChange("tech"),
    },
    {
      id: "ecommerce",
      selectOn: "code",
      title: "Plateforme ecommerce",
      selectedFilter: state.advancedFilters.ecommerce,
      options: settingDatas?.ecommerceTypes,
      useBubbleComponent: true,
      name: "ecommerce",
      handleOnChange: handleOnChange("ecommerce"),
    },
    {
      id: "certification",
      selectOn: "code",
      title: "Certifications",
      selectedFilter: state.advancedFilters.certification,
      options: getSortedCertificationTypes(settingDatas),
      name: "certification",
      handleOnChange: handleOnChange("certification"),
    },
    {
      id: "approval",
      selectOn: "code",
      title: "Agréments",
      selectedFilter: state.advancedFilters.approval,
      options: getSortedApprovalTypes(settingDatas),
      name: "approval",
      handleOnChange: handleOnChange("approval"),
    },
    {
      id: "specificity",
      selectOn: "code",
      title: "Spécificités",
      selectedFilter: state.advancedFilters.specificity,
      options: getSortedSpecTypes(settingDatas),
      name: "specificity",
      handleOnChange: handleOnChange("specificity"),
    },
  ];
};
