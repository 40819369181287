import { useLazyQuery } from "@apollo/client";
import { useContext, useMemo } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { GetStripeSessionId } from "../../../queries/subscription/subscription";
import CustomButton from "../../ui/CustomButton";
import ModalTw from "../index";

const ModalPremium = ({
  title,
  content,
  buttonText = "Passer Premium",
  redirect = true,
  onClose,
}) => {
  const { user } = useContext(AuthContext);
  const [getStripeSessionId] = useLazyQuery(GetStripeSessionId);
  const redirectToStripe = () => {
    if (user.subscriptions.length > 0) {
      getStripeSessionId({
        variables: {
          successUrl: window.location.href.includes("?")
            ? window.location.href + "&portal=true"
            : window.location.href + "?portal=true",
        },
      }).then(async (r) => window.open(r.data.getStripeSessionId, "_self"));
    } else {
      window.location.href = "/subscriptions";
    }
  };

  const contentModal = useMemo(() => {
    return content ? (
      content
    ) : (
      <>
        Pour y accéder vous devez être{" "}
        <span style={{ fontWeight: "bold" }}>Premium</span>
      </>
    );
  }, [content]);

  return (
    <ModalTw
      content={contentModal}
      footer={
        <CustomButton
          isFull
          onClick={() => (redirect ? redirectToStripe() : null)}
        >
          {buttonText}
        </CustomButton>
      }
      title={title ?? "Vous n'avez pas les accès"}
      onClose={onClose}
    />
  );
};

export default ModalPremium;
